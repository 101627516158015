/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	Component,
	Injectable
} from '@angular/core';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntity
} from '@shared/interfaces/entities/entity';
import {
	IEntityDefinition
} from '@shared/interfaces/entities/entity-definition.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	ISecurityDefinitionDto
} from '@api/interfaces/security/security-definition.dto.interface';
import {
	ISecurityItemDto
} from '@api/interfaces/security/security-item.dto.interface';
import {
	isEqual
} from 'lodash-es';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

/**
 * A class representing a common interface to gather entity information.
 *
 * @export
 * @class EntityService
 */
@Injectable()
export class EntityService
{
	/**
	 * Creates an instance of an EntityService.
	 *
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance service for this component.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type service for this component.
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The entity layout service for this component.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service for this component.
	 * @param {EntityVersionApiService} entityVersionApiService
	 * The entity version service for this component.
	 * @memberof EntityService
	 */
	public constructor(
		private readonly entityDefinitionApiService: EntityDefinitionApiService,
		private readonly entityInstanceApiService: EntityInstanceApiService,
		private readonly entityLayoutApiService: EntityLayoutApiService,
		private readonly entityLayoutTypeApiService: EntityLayoutTypeApiService,
		private readonly entityTypeApiService: EntityTypeApiService,
		private readonly entityVersionApiService: EntityVersionApiService,
		private readonly resolverService: ResolverService)
	{
	}

	/**
	 * Gets the id descending query used for the order by
	 * in common entity queries.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly idDescendingQuery: string = 'Id desc';

	/**
	 * Gets the id descending query used for the order by
	 * in common entity queries.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly enabled: string = 'Enabled';

	/**
	 * Gets the property name for an entity type group.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly group: string = 'Group';

	/**
	 * Gets the property name for an entity layout type id.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly layoutTypeId: string = 'LayoutTypeId';

	/**
	 * Gets the property name for an entity layout type name.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly layoutTypeName: string = 'Name';

	/**
	 * Gets the property name for an entity version number.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly number: string = 'Number';

	/**
	 * Gets the property name for an entity version id.
	 *
	 * @type {string}
	 * @memberof EntityService
	 */
	private readonly versionId: string = 'VersionId';

	/**
	 * Gets a formly layout based on the defined layout type of summary for the
	 * sent entity type id.
	 *
	 * @async
	 * @param {number} entityTypeId
	 * The entity type to gather a layout for.
	 * @param {string} layoutName
	 * The entity layout type to load.
	 * @returns {Promise<FormlyFieldConfig[]>}
	 * An awaitable promise that will return a formly field layout representing
	 * the sent entity type id with a layout type of summary.
	 * @memberof WorkItemExpandComponent
	 */
	public async getFormlyLayout(
		pageContext: IDynamicComponentContext<Component, any>,
		entityType: IEntityType,
		layoutName: string,
		entityInstanceId: number,
		disableAllFields: boolean = false): Promise<FormlyFieldConfig[]>
	{
		const entityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${layoutName}'`,
					`${AppConstants.commonProperties.id} `
						+ AppConstants.sortDirections.descending);

		const entityLayout: IEntityLayout =
			await this.entityLayoutApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.typeId} eq `
						+ `${entityType.id} and `
						+ `${AppConstants.commonProperties.layoutTypeId} eq `
						+ entityLayoutType.id,
					`${AppConstants.commonProperties.versionId} `
						+ AppConstants.sortDirections.descending);

		const entitySecurityAccessPolicy: any =
			await this.getEntityAccessPolicies(
				entityType.group,
				entityInstanceId);

		const formlyLayout: FormlyFieldConfig[] =
			new EntityLayout(entityLayout)
				.getFormlyEntityLayout(
					pageContext,
					entitySecurityAccessPolicy?.data);

		if (disableAllFields === true)
		{
			FormlyHelper.disableAllFields(formlyLayout);
		}

		return formlyLayout;
	}

	/**
	 * Gathers data and returns a fully populated Entity.
	 *
	 * @param {number} entityInstanceId
	 * The id of the entity to be fully populated.
	 * @param {string} entityInstanceType
	 * The type of the entity to be fully populated.
	 * @param {string} [entityLayoutTypeName]
	 * If sent, this will return the layout associated with
	 * this layout type for this entity.
	 * @memberof EntityService
	 */
	public async populateEntity(
		entityInstanceId: number,
		entityInstanceType: string,
		entityLayoutTypeName?: string): Promise<IEntity>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityInstanceType;
		const entityInstance: IEntityInstance =
			await this.entityInstanceApiService.get(entityInstanceId);

		const entityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				`${this.group} eq '${entityInstanceType}'`,
				this.idDescendingQuery);

		const entityVersion: IEntityVersion =
			await this.entityVersionApiService.getSingleQueryResult(
				`${AppConstants.commonProperties.typeId} eq ${entityType.id} `
					+ `and ${this.number} eq ${entityInstance.versionNumber} `
					+ `and ${this.enabled} eq true`,
				this.idDescendingQuery);

		let entityLayoutType: IEntityLayoutType;
		if (!AnyHelper.isNullOrEmpty(entityLayoutTypeName))
		{
			entityLayoutType =
				await this.entityLayoutTypeApiService.getSingleQueryResult(
					`${this.layoutTypeName} eq '${entityLayoutTypeName}'`,
					this.idDescendingQuery,
					true);
		}

		const instanceFilter: string =
			`${AppConstants.commonProperties.typeId} eq ${entityType.id} `
				+ `and ${this.versionId} eq ${entityVersion.id}`;

		const initialPromiseArray: Promise<IBaseEntity>[] =
			[
				this.entityDefinitionApiService.getSingleQueryResult(
					instanceFilter,
					this.idDescendingQuery)
			];

		if (!AnyHelper.isNullOrEmpty(entityLayoutType))
		{
			const layoutFilter: string =
				instanceFilter
					+ ` and ${this.layoutTypeId} eq ${entityLayoutType.id}`;

			initialPromiseArray.push(
				this.entityLayoutApiService.getSingleQueryResult(
					layoutFilter,
					this.idDescendingQuery,
					true));
		}

		return Promise.all(
			initialPromiseArray)
			.then((
				[
					entityDefinition,
					entityLayout
				]) =>
			{
				const entity: IEntity =
					<IEntity>
					{
						entityDefinition: entityDefinition,
						entityInstance: entityInstance,
						entityLayout: entityLayout,
						entityLayoutType: entityLayoutType,
						entityType: entityType,
						entityVersion: entityVersion
					};

				return Promise.resolve(entity);
			});
	}

	/**
	 * Verifies the set of entity types matching the sent wildcard filter
	 * has at least one entity type with ownership level view or
	 * greater permissions.
	 *
	 * @async
	 * @param {string} wildcardFilter
	 * The wildcard filter of entitys type to verify access for.
	 * @param {IEntityLayoutType} entityLayoutType
	 * The entity layout type used for an access view check. This value defaults
	 * to null.
	 * @returns {Promise<boolean>}
	 * An awaitable value signifying whether or not the entity type has at
	 * least one entity version allowed, and that the version has a full
	 * layout type available to link to.
	 * @memberof EntityService
	 */
	public async verifyWildcardEntityTypeAccess(
		wildcardFilter: string,
		entityLayoutType: IEntityLayoutType = null): Promise<boolean>
	{
		const entityTypes: IEntityType[] =
			await this.entityTypeApiService.query(
				wildcardFilter,
				AppConstants.empty);

		if (entityTypes.length === 0)
		{
			return false;
		}

		const initialPromiseArray: Promise<any>[] = [];
		entityTypes.forEach(
			(entityType: IEntityType) =>
			{
				initialPromiseArray.push(
					this.verifyEntityTypeAccess(
						entityType,
						entityLayoutType));
			});

		const allowedEntities: boolean[] =
			await Promise.all(initialPromiseArray);

		return allowedEntities.some(
			(allowed: boolean) =>
				allowed === true);
	}

	/**
	 * Verifies the entity type has ownership level view or greater permissions.
	 *
	 * @async
	 * @param {IEntityType} entityType
	 * The entity type to verify access for.
	 * @param {IEntityLayoutType} entityLayoutType
	 * The entity layout type used for an access view check. This value defaults
	 * to null.
	 * @returns {Promise<boolean>}
	 * An awaitable value signifying whether or not the entity type has at
	 * least one entity version allowed, and that the version has a full
	 * layout type available to link to.
	 * @memberof EntityService
	 */
	public async verifyEntityTypeAccess(
		entityType: IEntityType,
		entityLayoutType: IEntityLayoutType = null): Promise<boolean>
	{
		const entityVersions: IEntityVersion[] =
			await this.entityVersionApiService.query(
				AppConstants.commonProperties.typeId
					+ ` eq ${entityType.id}`,
				AppConstants.empty);

		if (entityVersions.length === 0)
		{
			return false;
		}

		if (AnyHelper.isNull(entityLayoutType)
			|| entityLayoutType.name === AppConstants.layoutTypes.generated)
		{
			return true;
		}

		const entityVersionIds: string =
			ApiFilterHelper.commaSeparatedStringValues(
				entityVersions.map(
					(entityVersion: IEntityVersion) =>
						entityVersion.id.toString()),
				AppConstants.empty);

		const entityLayouts: IEntityLayout[] =
			await this.entityLayoutApiService.query(
				AppConstants.commonProperties.layoutTypeId
					+ ` eq ${entityLayoutType.id}`
					+ ' and '
					+ AppConstants.commonProperties.typeId
					+ ` eq ${entityType.id}`
					+ ' and '
					+ AppConstants.commonProperties.versionId
					+ ` in (${entityVersionIds})`,
				AppConstants.empty);

		return entityLayouts.length > 0;
	}

	/**
	 * Verifies the entity type has ownership level view or greater permissions.
	 *
	 * @async
	 * @param {string} entityTypeGroup
	 * The entity type group to verify access for.
	 * @param {string} entityLayoutTypeName
	 * The entity layout type name used for an access view check. This value
	 * defaults to null.
	 * @returns {Promise<boolean>}
	 * An awaitable value signifying whether or not the entity type has at
	 * least one entity version allowed, and that the version has a full
	 * layout type available to link to.
	 * @memberof EntityService
	 */
	public async verifyEntityTypeAccessByGroup(
		entityTypeGroup: string,
		entityLayoutTypeName: string = null): Promise<boolean>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.group
					+ ` eq '${entityTypeGroup}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		const entityLayoutType: IEntityLayoutType =
			AnyHelper.isNullOrWhitespace(entityLayoutTypeName)
				? null
				: await this.entityLayoutTypeApiService.getSingleQueryResult(
					AppConstants.commonProperties.name
						+ ` eq '${entityLayoutTypeName}'`,
					AppConstants.empty,
					true);

		if (!AnyHelper.isNullOrWhitespace(entityLayoutTypeName)
			&& AnyHelper.isNull(entityLayoutType))
		{
			return false;
		}

		return this.verifyEntityTypeAccess(
			entityType,
			entityLayoutType);
	}

	/**
	 * Given an entity type and version number, this will get the matching
	 * entity definition.
	 *
	 * @async
	 * @param {number} typeId
	 * The entity type id.
	 * @param {string} versionNumber
	 * The entity version number.
	 * @returns {IEntityDefinition}
	 * The matching entity definition for this sent type id and version number.
	 * @memberof EntityService
	 */
	public async getEntityDefinition(
		typeId: number,
		versionNumber: number): Promise<IEntityDefinition>
	{
		const entityVersion: IEntityVersion =
			await this.entityVersionApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.typeId} eq `
						+ `${typeId} `
						+ `and ${AppConstants.commonProperties.number} eq `
						+ versionNumber,
					AppConstants.empty);

		return this.entityDefinitionApiService
			.getSingleQueryResult(
				`${AppConstants.commonProperties.typeId} eq `
					+ `${typeId} `
					+ `and ${AppConstants.commonProperties.versionId} eq `
					+ entityVersion.id,
				AppConstants.empty);
	}

	/**
	 * Given a parent entity instance id, an entity type group, and a wildcard
	 * entity type name filter, this will get all of the children matching
	 * that wildcard value.
	 *
	 * @async
	 * @param {number} entityInstanceId
	 * The entity instance to get wildcard children for.
	 * @param {string} entityInstanceType
	 * The entity instance parent type group.
	 * @param {string} wildcardChildFilter
	 * The wildcard that will be checked against supported child types in order
	 * to find that set of associated children. Ie. 'WorkItem'
	 * @param {string} orderBy
	 * The order by value to use when querying for wildcard children.
	 * @returns {Promise<IEntityInstance[]>}
	 * An awaitable value that will contain all of the children of the sent
	 * entity instance by id matching the wildcard child type.
	 * @memberof EntityService
	 */
	public async getWildcardChildren(
		entityInstanceId: number,
		entityInstanceType: string,
		wildcardChildFilter: string,
		orderBy: string): Promise<IEntityInstance[]>
	{
		const entity: IEntity =
			await this.populateEntity(
				entityInstanceId,
				entityInstanceType);

		const childEntityTypes: string[] =
				new EntityDefinition(entity.entityDefinition)
					.supportedChildTypes
					.filter(
						(type: string) =>
							type === wildcardChildFilter
								|| type.startsWith(`${wildcardChildFilter}.`));

		const supportedEntityTypes: EntityType[] =
			await this.getEntityTypesFromNameList(childEntityTypes);

		const filter: string =
			ApiFilterHelper.getEntityTypeFilter(supportedEntityTypes);

		this.entityInstanceApiService
			.entityInstanceTypeGroup = entityInstanceType;

		return this.getFullHierarchyDataSet(
			entityInstanceId,
			entityInstanceType,
			filter,
			orderBy);
	}

	/**
	 * Given a set of entity types, this query will return all entity
	 * instances of each entity type.
	 *
	 * @async
	 * @param {IEntityType[]} entityTypes
	 * The entity type array identifying the set of instances to collect.
	 * @param {string} filter
	 * The filter value to use when querying for multiple entity type instances.
	 * @param {string} orderBy
	 * The order by value to use when querying for multiple entity type
	 * instances.
	 * @note This will currently only handle up to two sort values as the
	 * returned set is sorted in-place.
	 * @returns {Promise<IEntityInstance[]>}
	 * An awaitable value that will contain all of the entity instances that
	 * are of the sent entity type collection. This value will be filtered and
	 * ordered based on the sent parameters.
	 * @memberof EntityService
	 */
	public async queryMultipleEntityTypes(
		entityTypes: IEntityType[],
		filter: string,
		orderBy: string): Promise<IEntityInstance[]>
	{
		const promiseArray: Promise<IEntityInstance[]>[] = [];
		entityTypes.forEach(
			(entityType: IEntityType) =>
			{
				const promise: Promise<IEntityInstance[]> =
					new Promise(
						async (resolve: any) =>
						{
							this.entityInstanceApiService
								.entityInstanceTypeGroup =
									entityType.group;

							const resultSet: IEntityInstance[] =
								await ApiHelper.getFullDataSet<IEntityInstance>(
									this.entityInstanceApiService,
									filter,
									AppConstants.empty);

							resolve(resultSet);
						});

				promiseArray.push(promise);
			});

		const data: IEntityInstance[][] =
			await Promise.all(
				promiseArray);
		const combinedData: IEntityInstance[] =
			[].concat.apply([], data);

		return ObjectHelper.handleOrderBySort(
			combinedData,
			orderBy);
	}

	/**
	 * Given a set of entity type names, this query will return the set of
	 * entity type object associated.
	 *
	 * @async
	 * @param {string[]} entityNames
	 * The entity type name array identifying the set of types to collect.
	 * @returns {Promise<EntityType[]>}
	 * An awaitable value that will contain all of the entity types that
	 * are in the sent entity type name collection.
	 * @memberof EntityService
	 */
	public async getEntityTypesFromNameList(
		entityNames: string[]): Promise<EntityType[]>
	{
		if (entityNames.length === 0)
		{
			return [];
		}

		return this.entityTypeApiService
			.query(
				`(${AppConstants.commonProperties.name} in `
					+ `("${entityNames.join('","')}"))`,
				AppConstants.commonProperties.id)
			.then((item: IEntityType[]) =>
				item.map((entityType: IEntityType) =>
					new EntityType(entityType)));
	}

	/**
	 * Gets and returns a full hierarchy dataset, requerying for the limit
	 * until no additional items are found.
	 *
	 * @async
	 * @param {number} instanceId
	 * The associated instance to find hierarchy data for.
	 * @param {string} instanceTypeGroup
	 * The instance type group matching the sent instance id.
	 * @param {string} filter
	 * The filter to use when querying for hierarchy data.
	 * @param {string} orderBy
	 * The order by definition for this hierarchy dataset.
	 * @param {boolean} getChildren
	 * If sent this will use the get children method, if this value is false
	 * then get parents will be used. This value defaults to true.
	 * @param {string} hieararchyTypeGroup
	 * If sent this will limit the hierarchy queries to a specific associated
	 * entity type group.
	 * @returns {Promise<IEntityInstance[]>}
	 * The full hierarchy based data set matching this query.
	 * @memberof EntityService
	 */
	public async getFullHierarchyDataSet(
		instanceId: number,
		instanceTypeGroup: string,
		filter: string,
		orderBy: string,
		getChildren: boolean = true,
		hieararchyTypeGroup: string = null): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			instanceTypeGroup;

		let resultSet: IEntityInstance[] =
			getChildren === true
				? await this.entityInstanceApiService
					.getChildren(
						instanceId,
						filter,
						orderBy,
						0,
						AppConstants.dataLimits.large,
						hieararchyTypeGroup)
				: await this.entityInstanceApiService
					.getParents(
						instanceId,
						filter,
						orderBy,
						0,
						AppConstants.dataLimits.large,
						hieararchyTypeGroup);
		let dataCount: number = resultSet.length;

		while (dataCount === AppConstants.dataLimits.large)
		{
			const nestedResultSet: IEntityInstance[] =
				getChildren === true
					? await this.entityInstanceApiService
						.getChildren(
							instanceId,
							filter,
							orderBy,
							0,
							AppConstants.dataLimits.large,
							hieararchyTypeGroup)
					: await this.entityInstanceApiService
						.getParents(
							instanceId,
							filter,
							orderBy,
							0,
							AppConstants.dataLimits.large,
							hieararchyTypeGroup);
			dataCount = nestedResultSet.length;

			resultSet =
				[
					...resultSet,
					...nestedResultSet
				];
		}

		return resultSet;
	}

	/*
	 * Gets a entity access policies.
	 *
	 * @async
	 * @param {number} entityTypeId
	 * The entity type id.
	 * @param {number} entityVersionId
	 * The entity version id.
	 * @returns {Promise<any>}
	 * An awaitable promise that will return a the json formatted
	 * entity access policies.
	 * @memberof WorkItemExpandComponent
	 */
	public async getEntityAccessPolicies(
		entityTypeGroup: string,
		entityInstanceId: number): Promise<any>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup = entityTypeGroup;

		return this.entityInstanceApiService
			.getPermissions(entityInstanceId);
	}

	/**
	 * Get a value indicating true if the current user is authorized
	 * to perform an action on an entity.
	 *
	 * @param {IEntityInstance} entityInstance
	 * The entity instance on which to check permissions.
	 * @param {string} actionName
	 * The name of the action on which to check permissions.
	 * @returns {boolean}
	 * The value indicating true if user is authorized, false if not.
	 * @memberof EntityService
	 */
	public async actionAuthorized(
		entityInstance: IEntityInstance,
		actionName: string): Promise<boolean>
	{
		const type: IEntityType = await this.entityTypeApiService
			.getSingleQueryResult(
				`Name eq '${entityInstance.entityType}'`,
				AppConstants.empty);

		const permissions: ISecurityDefinitionDto =
			await this.getEntityAccessPolicies(
				type.group,
				entityInstance.id);

		const action: any = permissions.actions
			.find((item: ISecurityItemDto) =>
				item.path === actionName
					&& item.rights.execute);

		return !AnyHelper.isNull(action);
	}

	/** Gets an accumulated aggregate object by entity type.
	 *
	 * @async
	 * @param {string} typeWildCard
	 * The entity type wild card.
	 * This will allow to get multiple entity types to set the
	 * entity instance entity type group.
	 * @param {string} method
	 * The aggregate method.
	 * @param {string} property
	 * The aggregate property.
	 * @param {string} filter
	 * The aggregate filter.
	 * @param {string} groupBy
	 * The aggregate groupBy.
	 * @returns {Promise<IAggregate>}
	 * The value accumulated aggregate object.
	 * @memberof EntityService
	 */
	public async getAccumulatedAggregateByType(
		typeWildCard: string,
		method: string,
		property: string = AppConstants.empty,
		filter: string = AppConstants.empty,
		groupBy: string = AppConstants.empty): Promise<IAggregate[]>
	{
		const apiConfiguration: {
			objectSearch: IObjectSearch;
			apiPromise: (objectSearch: IObjectSearch) => Promise<IAggregate[]>;
		} =
		{
			objectSearch:
				<IObjectSearch>
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy
				},
			apiPromise: async (objectSearch: IObjectSearch):
				Promise<IAggregate[]> =>
				this.entityInstanceApiService
					.aggregate(
						objectSearch.method,
						objectSearch.property,
						objectSearch.filter,
						objectSearch.groupBy)
		};

		const entityByType: {
			entityType: IEntityType;
			apiResponse: IAggregate[];
		}[] =
			await this.getEntitiesByType(
				'Name.StartsWith',
				typeWildCard,
				'eq true',
				apiConfiguration);

		const accumulatedAggregate: IAggregate[] = [];

		entityByType.forEach(
			(entity) =>
			{
				entity.apiResponse.forEach(
					(entityAggregate) =>
					{
						if (!AnyHelper.isNullOrEmpty(entityAggregate))
						{
							let existingKey = false;
							let aggregateKey = -1;
							for (let index = 0;
								index < accumulatedAggregate.length;
								index++)
							{
								if (typeof accumulatedAggregate[index].key
									=== AppConstants.propertyTypes.object
									? isEqual(
										accumulatedAggregate[index].key,
										entityAggregate.key)
									: accumulatedAggregate[index].key
										.toLowerCase()
										=== entityAggregate.key
											.toLowerCase())
								{
									existingKey = true;
									aggregateKey = index;
								}
							}
							if (existingKey === true)
							{
								accumulatedAggregate[aggregateKey].value
									+= entityAggregate.value;
							}
							else
							{
								accumulatedAggregate.push(entityAggregate);
							}
						}
					});
			});

		return accumulatedAggregate;
	}

	/**
	 * Gets entities by type.
	 *
	 * @async
	 * @param {string} queryPrefix
	 * The query prefix to obtain wild card based result.
	 * @param {string} wildCard
	 * The entity type wild card.
	 * This will allow to get multiple entity types to set the
	 * entity instance entity type group.
	 * @param {string} queryOperator
	 * The query operator. This will allow to finish the quert
	 * wildcard logic. If 'eq true' then it will look for a truthy
	 * compare of the filter query statement, and similar with other
	 * query operators.
	 * @param {{
			objectSearch: IObjectSearch;
			apiPromise: (objectSearch: IObjectSearch) => Promise<any[]>;
		}} apiConfiguration
	 * The configuration that will iterate by entity type.
	 * @returns {Promise<any[]>}
	 * An array of the apiPromise return type with as many objects as
	 * entity types found with the wildcard statement and the object search
	 * configurations.
	 * @memberof EntityService
	 */
	public async getEntitiesByType(
		queryPrefix: string,
		wildCard: string,
		queryOperator: string,
		apiConfiguration: {
			objectSearch: IObjectSearch;
			apiPromise: (
				objectSearch: IObjectSearch,
				entityType?: IEntityType) => Promise<any[]>;
		}): Promise<any[]>
	{
		const filterQuery: string =
			!AnyHelper.isNullOrEmpty(queryPrefix)
				? `${queryPrefix}('${wildCard}') ${queryOperator}`
				: `${wildCard} ${queryOperator}`;

		const entityTypes =
			await this.entityTypeApiService
				.query(
					`${filterQuery}`,
					AppConstants.empty);

		const entityByType = [];

		for (const entityType of entityTypes)
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				entityType.group;

			const apiResponse =
				await apiConfiguration
					.apiPromise(
						apiConfiguration.objectSearch,
						entityType);

			entityByType.push(
				{
					entityType: entityType,
					apiResponse: apiResponse
				});
		}

		return entityByType;
	}

	/**
	 * Gets the columns object array based on the available summary data paths.
	 *
	 * @async
	 * @param {string} entityTypeGroup
	 * The entity type group to get summary columns for.
	 * @param {IEntityDefinition} entityDefinition
	 * If sent, this is the entity definition to gather summary columns for. If
	 * this value is not sent it will be found via the entity type group.
	 * @returns {Promise<ICommonTableColumn[]>}
	 * The awaitable set of summary data path columns associated with the
	 * sent entity type group or entity definition.
	 * @memberof EntityService
	 */
	 public async getDynamicSummaryColumns(
		entityTypeGroup: string,
		entityDefinition: IEntityDefinition = null):
		Promise<ICommonTableColumn[]>
	 {
		if (AnyHelper.isNullOrWhitespace(entityTypeGroup)
			&& AnyHelper.isNull(entityDefinition))
		{
			return [];
		}

		let columnIndex: number = 1;
		const summaryDataPathColumns: ICommonTableColumn[] = [];

		let entityDefinitionInterface: IEntityDefinition = entityDefinition;
		if (AnyHelper.isNull(entityDefinition))
		{
			const entityType: IEntityType =
				await this.entityTypeApiService
					.getSingleQueryResult(
						`${AppConstants.commonProperties.group} `
							+ `eq '${entityTypeGroup}'`,
						AppConstants.empty);
			entityDefinitionInterface =
				await this.entityDefinitionApiService
					.getSingleQueryResult(
						`${AppConstants.commonProperties.typeId} `
							+ `eq '${entityType.id}'`,
						AppConstants.empty);
		}
		const entityDefinitionImplementation: EntityDefinition =
			new EntityDefinition(entityDefinitionInterface);

		if (AnyHelper.isNull(entityDefinitionImplementation.summaryDataPaths)
			|| entityDefinitionImplementation.summaryDataPaths.length === 0)
		{
			return [];
		}

		for (const summaryDataPath of
			entityDefinitionImplementation.summaryDataPaths)
		{
			const cleanedPath: string =
				(summaryDataPath.anyOfPath || summaryDataPath.path)
					.replace(
						AppConstants.nestedDataKeyPrefix
							+ AppConstants.nestedDataIdentifier,
						AppConstants.empty)
					.replace(
						/\[\d+\]/g,
						'[]');
			const propertyDefinition: any =
				entityDefinitionImplementation.getPropertyDefinition(
					cleanedPath);

			 summaryDataPathColumns.push(
				{
					dataKey: summaryDataPath.path.replace(
						AppConstants.nestedDataKeyPrefix,
						AppConstants.empty),
					columnHeader:
						summaryDataPath.dataLabel
							|| propertyDefinition.value.description
							|| `${StringHelper.beforeCapitalSpaces(
								(StringHelper.toProperCase(
									StringHelper.getLastSplitValue(
										propertyDefinition.key,
										AppConstants.characters.period))))}`,
					displayOrder: columnIndex++,
					dataFormatType:
						AnyHelper.isNullOrWhitespace(
							propertyDefinition.value.outputFormat)
							? null
							: StringHelper.toProperCase(
								propertyDefinition.value.outputFormat)
				});
		}

		return summaryDataPathColumns;
	}

	/**
	 * Gets a business logic entity.
	 *
	 * @async
	 * @param {IEntityInstance} instance
	 * The entity instance that the business logic entity will represent.
	 * @returns {BusinessLogicEntity}
	 * A business logic entity.
	 * @memberof EntityService
	 */
	public getBusinessLogicEntity(
		instance: IEntityInstance): BusinessLogicEntity
	{
		return new BusinessLogicEntity(
			instance,
			this.resolverService);
	}
}