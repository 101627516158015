/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentParameterDirective
} from '@shared/directives/display-component-parameter.directive';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	Location
} from '@angular/common';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-generic-base-page',
	templateUrl: './generic-base-page.component.html',
	styleUrls: [
		'./generic-base-page.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of the generic base page.
 *
 * @export
 * @class GenericBasePageComponent
 * @extends {DisplayComponentParameterDirective}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class GenericBasePageComponent
	extends DisplayComponentParameterDirective
	implements OnInit, OnDestroy
{
	/**
	 * Initializes a new instance of the GenericBasePageComponent.
	 * This component is used to display a display component in a
	 * generic base page.
	 *
	 * @param {ActivatedRoute} route
	 * The route used in this component.
	 * @param {Router} router
	 * The router used in this component.
	 * @param {Location} location
	 * The Angular common location service used for url interaction.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof GenericBasePageComponent
	 */
	public constructor(
		public route: ActivatedRoute,
		public router: Router,
		public location: Location,
		public siteLayoutService: SiteLayoutService,
		public sessionService: SessionService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public resolver: ResolverService)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);

		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;

		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the user associated with this dashboard to make
	 * this available in the page context source.
	 *
	 * @type {IUser}
	 * @memberof GenericBasePageComponent
	 */
	public sessionUser: IUser;

	/**
	 * Gets or sets the user type group to allow this to be available
	 * in the page context source.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	public sessionUserTypeGroup: string = 'Users';

	/**
	 * Gets or sets the display component name.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	public displayComponentName: string;

	/**
	 * Gets or sets the base page display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof GenericBasePageComponent
	 */
	public basePageInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the display component instance.
	 *
	 * @type {DisplayComponentInstance}
	 * @memberof GenericBasePageComponent
	 */
	public displayComponentInstance: DisplayComponentInstance;

	/**
	 * Gets or sets the name value of the dynamic component.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	public dynamicComponent: string;

	/**
	* Gets or sets the redraw value of the display component.
	*
	* @type {boolean}
	* @memberof GenericBasePageComponent
	*/
	public redrawing: boolean = false;

	/**
	 * Gets or sets the changed value of the parameter data. This is
	 * used to define if the apply button is available due to new data.
	 *
	 * @type {boolean}
	 * @memberof GenericBasePageComponent
	 */
	public valueChanged: boolean = false;

	/**
	 * Gets or sets the valid value of the contained parameter form.
	 *
	 * @type {boolean}
	 * @memberof GenericBasePageComponent
	 */
	public isValid: boolean = false;

	/**
	 * Gets or sets the edit mode of the display component.
	 *
	 * @type {boolean}
	 * @memberof GenericBasePageComponent
	 */
	public editMode: boolean;

	/**
	 * Gets or sets the display component name.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	public pageTitle: string = AppConstants.empty;

	/**
	* Gets or sets the page context operation group name. This will
	* be displayed as the second level of the context menu.
	*
	* @type {string}
	* @memberof GenericBasePageComponent
	*/
	public pageContextOperationGroupName: string;

	/**
	 * Gets or sets the utility menu operation group name.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	public utilityMenuOperationGroupName: string = AppConstants.empty;

	/**
	 * Gets or sets the data associated with the layout schema and
	 * displayed in the formly component.
	 *
	 * @type {any}
	 * @memberof GenericBasePageComponent
	 */
	public parameterLayoutData: any;

	/**
	 * Gets or sets the layout schema displayed in the formly section
	 * of this component.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof GenericBasePageComponent
	 */
	public parameterLayoutSchema: FormlyFieldConfig[];

	/**
	 * Gets or sets the class of the container for a use available
	 * height content container. This container will have it's height
	 * set as the available content height minus the header and padding
	 * on load and site layout changes.
	 *
	 * @type {string}
	 * @memberof BasePageDirective
	 */
	public fixedHeightContentClass: string = 'dynamic-component-container';

	/**
	 * Gets the available route parameter identifiers.
	 *
	 * @type {object}
	 * @memberof GenericBasePageComponent
	 */
	protected routeData:
	{
		data: object;
	} = {
		data: {}
	};

	/**
	 * Gets the display component identifier for url parameter
	 * lookups.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	private readonly displayComponentIdentifier: string = 'displayComponent';

	/**
	 * Gets the identifier for the display component type that this will allow.
	 *
	 * @type {string}
	 * @memberof GenericBasePageComponent
	 */
	private readonly basePageIdentifier: string = 'BasePage';

	/**
	 * Gets or sets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route
	 * changes to this component.
	 *
	 * @type {(
		future: ActivatedRouteSnapshot,
		curr: ActivatedRouteSnapshot) => boolean}
	* @memberof GenericBasePageComponent
	*/
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot,
			curr: ActivatedRouteSnapshot) => boolean;

	/**
	 * On component initialization event.
	 * This method is used to set this component for route based
	 * initialization.
	 *
	 * @memberof GenericBasePageComponent
	 */
	public ngOnInit(): void
	{
		const displayComponentParameterValue: string =
			this.route.snapshot.paramMap.get(
				this.displayComponentIdentifier) as unknown as string;
		this.displayComponentName =
			StringHelper.toProperCase(
				displayComponentParameterValue);

		if (!this.displayComponentName.startsWith(
			this.basePageIdentifier))
		{
			throw new Error(
				'The generic display requires a base page display component. '
					+ `'${this.displayComponentName}' was sent.`);
		}

		this.editMode =
			this.route.snapshot.url[1].path ===
				AppConstants.viewTypes.edit;

		this.subscriptions.add(
			this.route.queryParams.subscribe(
				(parameters: Params) =>
				{
					this.routeData =
						ObjectHelper.mapFromRouteData(
							parameters);

					this.pageContext =
						<IDynamicComponentContext<Component, any>>
							{
								source: this,
								data: <any> {
								}
							};

					this.initializeDynamicComponent();
				}));
	}

	/**
	 * On component destroy event.
	 * This method is used to reset the route strategy to it's original
	 * implementation on destroy.
	 *
	 * @memberof GenericBasePageComponent
	 */
	public ngOnDestroy(): void
	{
		this.subscriptions.unsubscribe();

		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot,
				curr: ActivatedRouteSnapshot) => boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Initializes a display component for display in this page.
	 *
	 * @async
	 * @memberof GenericBasePageComponent
	 */
	public async initializeDynamicComponent(): Promise<void>
	{
		this.loading = true;

		this.basePageInstance =
			await this.displayComponentService
				.populateDisplayComponentInstance(
					this.displayComponentName,
					this.pageContext,
					null,
					null,
					true);

		if (AnyHelper.isNull(this.basePageInstance))
		{
			this.navigateToAccessDenied();
			this.loading = false;

			return;
		}

		const jsonDefinition: any =
			this.basePageInstance
				.displayComponentDefinition
				.jsonDefinition;

		if (!AnyHelper.isNullOrWhitespace(jsonDefinition.pageGuardPromise))
		{
			const pageGuardPromise: boolean =
				await StringHelper.transformToDataPromise(
					StringHelper.interpolate(
						jsonDefinition.pageGuardPromise,
						this.pageContext),
					this.pageContext);

			if (pageGuardPromise === false)
			{
				const pageGuardResources: string[] =
					await StringHelper.transformToDataPromise(
						StringHelper.interpolate(
							jsonDefinition.pageGuardResources,
							this.pageContext),
						this.pageContext);
				const pageGuardMessage: string =
					AnyHelper.isNullOrWhitespace(
						jsonDefinition.pageGuardMessage)
						? null
						: await StringHelper.transformToDataPromise(
							StringHelper.interpolate(
								jsonDefinition.pageGuardMessage,
								this.pageContext),
							this.pageContext);

				EventHelper.dispatchNavigateToAccessDeniedEvent(
					this.location.path(),
					pageGuardResources,
					pageGuardMessage);
			}
		}

		this.displayComponentInstance =
			await this.displayComponentService
				.populateDisplayComponentInstance(
					this.basePageInstance.displayComponentDefinition
						.jsonDefinition.displayComponent,
					this.pageContext,
					null,
					null,
					true);

		if (AnyHelper.isNull(this.displayComponentInstance))
		{
			this.navigateToAccessDenied();
			this.loading = false;

			return;
		}

		const basePageData: any =
			await this.displayComponentFactory
				.getMergedInitialParameterData(
					this.basePageInstance
						.jsonInitialParameters,
					this.pageContext,
					this.routeData?.data);
		const mergedData: any =
			await this.displayComponentFactory
				.getMergedInitialParameterData(
					this.displayComponentInstance
						.jsonInitialParameters,
					this.pageContext,
					basePageData);
		this.pageContext.data = mergedData;

		this.initializeBasePage();
		await this.setupPrimaryDisplayComponent();
	}

	/**
	 * The initial stand up of the page based on parameters sent in the
	 * base page display component definition.
	 *
	 * @memberof GenericBasePageComponent
	 */
	public initializeBasePage(): void
	{
		const pageDefinition: any =
			this.basePageInstance
				.displayComponentDefinition.jsonDefinition;
		this.pageTitle = pageDefinition.loadingPageTitle;

		if (this.basePageInstance.visible === false
			|| this.displayComponentInstance.visible === false)
		{
			this.navigateToAccessDenied();
			this.loading = false;

			return;
		}

		this.operationGroupName =
			pageDefinition.operationGroupName;
		this.pageContextOperationGroupName =
			pageDefinition.pageContextOperationGroupName;
		this.utilityMenuOperationGroupName =
			pageDefinition.utilityMenuOperationGroupName;
		this.informationMenuDisplayComponentInstanceName =
			pageDefinition.informationMenuDisplayComponentName;

		this.setupInformationMenuItems(this.pageContext);
	}

	/**
	 * Loads and initializes the interface for the primary display component
	 * on this page.
	 *
	 * @async
	 * @memberof GenericBasePageComponent
	 */
	public async setupPrimaryDisplayComponent(): Promise<void>
	{
		if (this.basePageInstance.visible === false
			|| this.displayComponentInstance.visible === false)
		{
			return;
		}

		this.dynamicComponent =
			this.displayComponentInstance
				.displayComponentDefinition
				.componentName;
		this.pageContext =
			await this.displayComponentFactory
				.getDynamicComponentContext(
					this.displayComponentInstance,
					this.pageContext,
					this.editMode === false);

		const title: string =
			StringHelper.interpolate(
				this.basePageInstance
					.displayComponentDefinition
					.jsonDefinition
					.pageTitleTemplate,
				this.pageContext.data);
		this.pageTitle =
			this.editMode === false
				? title
				: `Edit ${title}`;

		this.parameterLayoutData =
			this.displayComponentFactory
				.getParameterData(
					this.pageContext.data);
		this.parameterLayoutSchema =
			this.displayComponentFactory
				.getParameterLayoutSchema(
					this.displayComponentInstance
						.jsonParameterDefinition,
					this.displayComponentInstance
						.jsonParameterLayout,
					this.pageContext);
		this.displayParameterFilter =
			!AnyHelper.isNull(this.parameterLayoutSchema);

		this.loading = false;
	}

	/**
	 * Handles a click on the apply action found in this component. This will
	 * update the applied parameter data.
	 *
	 * @memberof GenericBasePageComponent
	 */
	public async applyParameters(): Promise<void>
	{
		this.redrawing = true;
		this.pageContext =
			await this.displayComponentFactory
				.getDynamicComponentContext(
					this.displayComponentInstance,
					<IDynamicComponentContext<Component, any>>
					{
						source: this.pageContext.source,
						data: await this.displayComponentFactory
							.getMergedInitialParameterData(
								this.displayComponentInstance
									.jsonInitialParameters,
								this.pageContext,
								this.parameterLayoutData.data)
					},
					this.editMode === false);

		this.settingsActive = false;
		this.valueChanged = false;
		this.redrawing = false;

		this.siteLayoutChanged();
		this.updateRouteData();
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed parameter set.
	 * @memberof GenericBasePageComponent
	 */
	public validParametersChanged(
		isValid: boolean): void
	{
		this.isValid = isValid;
	}

	/**
	 * Handles the parameter changed event sent from the child dynamic
	 * formly component. This will notify the component of changes to
	 * data in the formly display.
	 *
	 * @param {boolean} changed
	 * The changed truthy of the current displayed parameter set.
	 * @memberof GenericBasePageComponent
	 */
	public parametersChanged(
		changed: boolean): void
	{
		this.valueChanged = changed;
	}

	/**
	 * Adds the current display component parameter set to the URL
	 * when data changes are applied.
	 *
	 * @memberof GenericBasePageComponent
	 */
	public updateRouteData(): void
	{
		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							relativeTo: this.route,
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(
										this.parameterLayoutData)
							}
						})
					.toString());
	}

	/**
	 * Calculates and displays an access not allowed banner with details
	 * associated with the currently loaded page.
	 *
	 * @memberof GenericBasePageComponent
	 */
	private navigateToAccessDenied(): void
	{
		if (AnyHelper.isNull(this.basePageInstance))
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					this.displayComponentName
				]);

			return;
		}

		if (AnyHelper.isNull(this.displayComponentInstance))
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					this.basePageInstance.displayComponentDefinition
						.jsonDefinition.displayComponent
				]);

			return;
		}

		EventHelper.dispatchNavigateToAccessDeniedEvent(
			this.location.path(),
			[
				this.displayComponentName,
				this.basePageInstance.displayComponentDefinition
					.jsonDefinition.displayComponent
			]);
	}
}