/**
 * @copyright WaterStreet. All rights reserved.
 */

// tslint:enable: no-any

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	InterpolationHelper
} from '@shared/helpers/interpolation.helper';
import {
	Md5
} from 'ts-md5';

/**
 * A class containing static helper methods
 * for the string type.
 *
 * @export
 * @class StringHelper
 */
export class StringHelper
{
	/**
	 * Gets the standard international format to use in this helper.
	 *
	 * @static
	 * @type {string}
	 * @memberof StringHelper
	 */
	private static readonly internationalFormat: string = 'en-US';

	/**
	 * Gets a collection of default whitespace characters.
	 *
	 * @static
	 * @type {string[]}
	 * @memberof StringHelper
	 */
	private static readonly defaultWhitespace: string[]
		= [
			String.fromCodePoint(9), // Tab
			String.fromCodePoint(10), // LF
			String.fromCodePoint(11), // VT
			String.fromCodePoint(12), // FF
			String.fromCodePoint(13), // CR
			String.fromCodePoint(32) // Space
		];

	/**
	 * Returns the string in proper case format.
	 *
	 * @static
	 * @param {string} value
	 * The string to set to a proper case value.
	 * @returns {string}
	 * The proper case string representation of the parameter
	 * value.
	 * @memberof StringHelper
	 */
	public static toProperCase(
		value: string): string
	{
		return (value == null
			|| value.length === 0)
			? value
			: value
				.charAt(0)
				.toUpperCase()
					+ value.slice(1);
	}

	/**
	 * Returns the string with added spaces before each capital letter.
	 *
	 * @static
	 * @param {string} value
	 * The string value with capital letters.
	 * @returns {string}
	 * The string value with added spaces before each capital letter.
	 * @memberof StringHelper
	 */
	public static beforeCapitalSpaces(
		value: string): string
	{
		return (value == null
			|| value.length === 0)
			? value
			: value
				.replace(
					/([a-z])([A-Z])/g,
					'$1 $2');
	}

	/**
	 * Returns the string with added spaces before each capital letter.
	 *
	 * @static
	 * @param {string} value
	 * The string value with numbers.
	 * @returns {string}
	 * The string value with added spaces between each number.
	 * @memberof StringHelper
	 */
	public static betweenNumberSpaces(
		value: string): string
	{
		return (value == null
			|| value.length === 0)
			? value
			: value
				.replace(
					/(?:[a-z])(?=\d)|(?:\d)(?=[a-z])/gi,
					'$& ');
	}

	/**
	 * Gets a left and right trimmed version of the input $this value.
	 * If no args are specified, the default whitespace characters are
	 * used to trim the $this.
	 *
	 * @static
	 * @param {string} $this A string to be trimmed.
	 * @param {...string[]} args A collection of characters to trim.
	 * @returns {string} A trimmed representation of $this.
	 * @memberof StringHelper
	 */
	public static trim(
		$this: string,
		...args: string[]): string
	{
		return this.trimRight(
			this.trimLeft(
				$this,
				...args),
			...args);
	}

	/**
	 * Gets a left trimmed version of the input $this value.
	 * If no args are specified, the default whitespace characters are
	 * used to trim the $this.
	 *
	 * @static
	 * @param {string} $this A string to be trimmed.
	 * @param {...string[]} args A collection of characters to trim.
	 * @returns {string} A trimmed representation of $this.
	 * @memberof StringHelper
	 */
	public static trimLeft(
		$this: string,
		...args: string[]): string
	{
		if (AnyHelper.isNull($this))
		{
			return null;
		}

		if (args.length === 0)
		{
			args.push(...this.defaultWhitespace);
		}

		while (args.includes($this.charAt(0)))
		{
			// eslint-disable-next-line no-param-reassign
			$this = $this.substring(1);
		}

		return $this;
	}

	/**
	 * Gets a right trimmed version of the input $this value.
	 * If no args are specified, the default whitespace characters are
	 * used to trim the $this.
	 *
	 * @static
	 * @param {string} $this A string to be trimmed.
	 * @param {...string[]} args A collection of characters to trim.
	 * @returns {string} A trimmed representation of $this.
	 * @memberof StringHelper
	 */
	public static trimRight(
		$this: string,
		...args: string[]): string
	{
		if (AnyHelper.isNull($this))
		{
			return null;
		}

		if (args.length === 0)
		{
			args.push(...this.defaultWhitespace);
		}

		while (args.includes($this.charAt($this.length - 1)))
		{
			// eslint-disable-next-line no-param-reassign
			$this = $this.substring(0, $this.length - 1);
		}

		return $this;
	}

	/**
	 * Gets an MD5 hash representing the inputed $this.
	 *
	 * @static
	 * @param {string} $this A string to get MD5 hash for.
	 * @returns {string} A string representing the MD5 hash for $this.
	 * @memberof StringHelper
	 */
	public static getHash(
		$this: string): string
	{
		if (AnyHelper.isNullOrEmpty($this))
		{
			return null;
		}

		return Md5.hashStr($this);
	}

	/**
	 * Returns a cleaned string, this will accept an array of strings to be
	 * removed and return the original with these no longer in the value.
	 *
	 * @static
	 * @param {string} value
	 * The string to set to clean.
	 * @param {string[]} [stringsToRemove]
	 * The array of strings to be removed.
	 * The default value removes the space character.
	 * @returns {string}
	 * The string with all sent parameter strings removed.
	 * @memberof StringHelper
	 */
	public static getCleanedValue(
		value: string,
		stringsToRemove: string[] = [' ']): string
	{
		if (AnyHelper.isNullOrEmpty(value))
		{
			return value;
		}

		let formattedValue: string = value;
		let regexReplace: RegExp;
		stringsToRemove.forEach((string: string) =>
		{
			regexReplace = new RegExp(string, 'g');
			formattedValue =
				formattedValue.replace(regexReplace, '');
		});

		return formattedValue;
	}

	/**
	 * Returns the final string value with trimmed whitespaces,
	 * following the splitting of the string on the sent split
	 * character.
	 *
	 * @static
	 * @param {string} value
	 * The string to split on the sent split character. Thi
	 * @param {string} [splitCharacter]
	 * The character to split this string on. If this character is not found,
	 * the full trimmed string is returned. This defaults to the ',' character.
	 * @returns {string}
	 * The final string value following the split character sent.
	 * @memberof StringHelper
	 */
	public static getLastSplitValue(
		value: string,
		splitCharacter: string = AppConstants.characters.comma): string
	{
		if (AnyHelper.isNullOrEmpty(value))
		{
			return value;
		}

		const splitStrings: string[] =
			value.split(splitCharacter);

		return StringHelper.trim(
			splitStrings[splitStrings.length - 1]);
	}

	/**
	 * Returns a string title representation of the entity type.
	 *
	 * @static
	 * @param {string} entityType
	 * the entity type.
	 * @param {string} [subType]
	 * THe optional sub type added after a hyphen.
	 * @param {bool} [excludeBaseType]
	 * The optional value indicating if the first or base
	 * entity type should be included.
	 * @returns {string}
	 * The result title.
	 * @memberof StringHelper
	 */
	public static getEntityTypeTitle(
		entityType: string,
		subType: string = null,
		excludeBaseType = true): string
	{
		const split = entityType.split(AppConstants.characters.period);

		if (split.length <= 1)
		{
			return entityType;
		}

		if (excludeBaseType)
		{
			split.shift();
		}

		const title: string =  this.beforeCapitalSpaces(
			split.join(AppConstants.empty));

		return AnyHelper.isNullOrEmpty(subType)
			? title
			: title + ` - ${subType}`;
	}

	/**
	 * Returns a concatenated string value.
	 *
	 * @static
	 * @param {string} value
	 * The string value to add to the concatenate string.
	 * @param {string} concatenateString
	 * The concatenated string.
	 * @param {string} concatenateKey
	 * The concatenate key.
	 * @returns {string}
	 * The concatenated string.
	 * @memberof StringHelper
	 */
	 public static getConcatenatedValue(
		value: string,
		concatenateString: string,
		concatenateKey: string = null): string
	{
		if (AnyHelper.isNullOrEmpty(value))
		{
			return concatenateString;
		}

		return concatenateString.concat(
			AnyHelper.isNull(concatenateKey)
				? value
				: concatenateKey, value);
	}

	/**
	 * Returns a parsed camelcase string by separating each capitalized
	 * word as space separated. This will return all values capitalized
	 * unless the overload {useProperCase} is set to false.
	 *
	 * @static
	 * @param {string} value
	 * The camelcase string to normalize by adding a space prior to
	 * capitalizations.
	 * @param {boolean} [useProperCase]
	 * A truthy defining if each camel case 'word' is capitalized.
	 * @returns {string}
	 * The normalized with spaces camelcase string.
	 * @memberof StringHelper
	 */
	public static normalizeCamelcase(
		value: string,
		useProperCase: boolean = true): string
	{
		if (AnyHelper.isNullOrEmpty(value))
		{
			return value;
		}

		const formattedValue: string =
			StringHelper.trim(
				value.replace(/([A-Z])/g, ' $1'));

		return useProperCase
			? formattedValue.replace(/^./,
				function(substring: string)
				{
					return substring.toUpperCase();
				})
			: formattedValue.toLowerCase();
	}

	// Original algorithm
	// eslint-disable-next-line max-len
	// https://ourcodeworld.com/articles/read/353/how-to-convert-numbers-to-words-number-spelling-in-javascript
	/**
	 * Accepts whole number inputs and returns the english representation of the
	 * number sent.
	 *
	 * @static
	 * @param {string} value
	 * The string that will have digits translated into words.
	 * @param {boolean} [useProperCase]
	 * Whether or not number values should be capitalized in the output.
	 * The default value is to not capitalize number values.
	 * @returns {string}
	 * An english number to word translation for the sent value.
	 * @memberof StringHelper
	 */
	public static numberToWords(
		value: string,
		useProperCase: boolean = false): string
	{
		/* eslint-disable @typescript-eslint/no-explicit-any */
		const string = value.toString();
		const and = 'and';

		let end: number;
		let chunk: number;
		let ints: number[];
		let index: number;
		let word: string;

		if (!/^\d+$/.test(string))
		{
			return 'NaN';
		}

		// Is number zero?
		if (parseInt(string, 10) === 0) {
			return useProperCase
				? 'Zero'
				: 'zero';
		}

		// Array of units as words.
		const units: string[] =
		[
			'',
			'one',
			'two',
			'three',
			'four',
			'five',
			'six',
			'seven',
			'eight',
			'nine',
			'ten',
			'eleven',
			'twelve',
			'thirteen',
			'fourteen',
			'fifteen',
			'sixteen',
			'seventeen',
			'eighteen',
			'nineteen'
		];

		// Array of tens as words.
		const tens: string[] =
		[
			'',
			'',
			'twenty',
			'thirty',
			'forty',
			'fifty',
			'sixty',
			'seventy',
			'eighty',
			'ninety'
		];

		// Array of scales as words.
		const scales: string[] =
		[
			'',
			'thousand',
			'million',
			'billion',
			'trillion',
			'quadrillion',
			'quintillion',
			'sextillion',
			'septillion',
			'octillion',
			'nonillion',
			'decillion',
			'undecillion',
			'duodecillion',
			'tredecillion',
			'quatttuor-decillion',
			'quindecillion',
			'sexdecillion',
			'septen-decillion',
			'octodecillion',
			'novemdecillion',
			'vigintillion',
			'centillion'
		];

		// Split user argument into 3 digit chunks from right to left.
		let start: number = string.length;
		const chunks: any[] | string[] = [];

		while (start > 0)
		{
			end = start;
			start = Math.max(0, start - 3);
			chunks.push(
				string.slice(
					start,
					end));
		}

		// Check if function has enough scale words
		// to be able to stringify the user argument.
		const chunksLen: number = chunks.length;
		if (chunksLen > scales.length)
		{
			return 'Overflow';
		}

		// Stringify each integer in each chunk.
		const words: any[] = [];
		for (index = 0; index < chunksLen; index++)
		{
			chunk = parseInt(chunks[index], 10);

			if (!AnyHelper.isNullOrEmpty(chunk))
			{
				// Split chunk into array of individual integers.
				const intChunks =
					chunks[index]
						.split('')
						.reverse();
				ints = intChunks
					.map(parseFloat);

				// If tens integer is 1, i.e. 10, then add 10 to units integer.
				if (ints[1] === 1)
				{
					ints[0] += 10;
				}

				// Add scale word if chunk is not zero and array item exists.
				word = scales[index];
				if (!AnyHelper.isNullOrEmpty(word))
				{
					words.push(
						useProperCase
							? this.toProperCase(word)
							: word);
				}

				// Add unit word if array item exists.
				word = units[ints[0]];
				if (!AnyHelper.isNullOrEmpty(word))
				{
					words.push(
						useProperCase
							? this.toProperCase(word)
							: word);
				}

				// Add tens word if array item exists.
				word = tens[ints[1]];
				if (!AnyHelper.isNullOrEmpty(word))
				{
					words.push(
						useProperCase
							? this.toProperCase(word)
							: word);
				}

				// Add 'and' string after units or tens integer if:
				if (ints[0] || ints[1])
				{

					// Chunk has a hundreds integer or chunk is the first
					// of multiple chunks.
					// Altered check for chunksLen > 1 to remove 'and'
					// on single digits.
					if (ints[2] || !index && chunksLen > 1) {
						words.push(and);
					}

				}

				// Add hundreds word if array item exists.
				word = units[ints[2]];
				if (!AnyHelper.isNullOrEmpty(word))
				{
					words.push(
						useProperCase
							? this.toProperCase(word) + ' Hundred'
							: word + ' hundred');
				}
			}
		}

		words.reverse();

		return words.join(AppConstants.characters.space);
	}

	/**
	 * Maps the provided data to the provided template
	 * using string interpolation syntax.
	 *
	 * @static
	 * @param template
	 * the template.
	 * @param data
	 * thet data to map to the template.
	 * @returns {string}
	 * @memberof StringHelper
	 */
	public static interpolate(
		template: string,
		data: any): string
	{
		return InterpolationHelper
			.getTemplateParser(template)(data);
	}

	/**
	 * Returns a boolean value from a parsed string as a context level data
	 * promise. This will return true if no page context is found to run
	 * against.
	 *
	 * @static
	 * @async
	 * @param {string} booleanPromiseAsString
	 * The function as a string.
	 * @param {any} pageContext
	 * The data context or closure this function will run against.
	 * @returns {Promise<boolean>}
	 * The value found via executing this function against the page context. If
	 * the page context is null this value will return true.
	 * @memberof StringHelper
	 */
	public static async transformAndExecuteBooleanPromise(
		booleanPromiseAsString: string,
		pageContext: any): Promise<boolean>
	{
		if (AnyHelper.isNull(pageContext))
		{
			return true;
		}

		const visiblePromise: Promise<boolean> =
			StringHelper.transformToDataPromise(
				booleanPromiseAsString,
				pageContext);

		return visiblePromise;
	}

	/**
	 * Returns a parsed string as a context level data promise.
	 * Sample usage:
	 * StringHelper.transformToDataPromise(
	 * 	'return function() { return this.apiService.apiMethod(); }',
	 * 	this);
	 *
	 * @static
	 * @param {string} dataPromise
	 * The function as a string.
	 * @param {any} context
	 * The data context or closure this function will run against.
	 * @memberof StringHelper
	 */
	public static transformToDataPromise(
		dataPromise: string,
		context: any): Promise<any>
	{
		return new Promise(
			function(resolve: any)
			{
				resolve(
					Function(dataPromise)()
						.bind(context)());
			}.bind(context));
	}

	/**
	 * Returns a parsed string as a context level function.
	 * Sample usage:
	 * StringHelper.transformToFunction(
	 * 	'return function() { return { chartColors: [ \"primary\" ] }; }',
	 * 	this);
	 *
	 * @static
	 * @param {string} functionAsString
	 * The function as a string.
	 * @param {any} context
	 * The data context or closure this function will run against.
	 * @memberof StringHelper
	 */
	public static transformToFunction(
		functionAsString: string,
		context: any): Function
	{
		return Function(functionAsString)()
			.bind(context);
	}

	/**
	 * Creates a text area element that accepts new lines
	 * and sets the value as the sent text. This value is
	 * then copied as formatted text into the clipboard.
	 *
	 * @param {string} text
	 * The text value to copy to the clipboard.
	 * @memberof StringHelper
	 */
	public static copyToClipboard(
		text: string): void
	{
		const textarea =
			document.createElement('textarea');
		textarea.value = text;

		document.body.appendChild(textarea);
		textarea.select();
		document.execCommand('copy');

		document.body.removeChild(textarea);
	}

	/**
	 * Creates a comma separated string representation of the sent
	 * array string values.
	 *
	 * @param {enumeration: string[]} enumeration
	 * The array of values to translate into a sentence structured list
	 * of values.
	 * @param {enumeration: string[]} useAnd
	 * If sent and true, this will use the and value for lists larger than two
	 * in size otherwise the last item will be appended with or. This value
	 * defaults to true.
	 * @memberof StringHelper
	 */
	public static getProperEnumeration(
		enumeration: string[],
		useAnd: boolean = true): string
	{
		if (AnyHelper.isNull(enumeration))
		{
			return AppConstants.empty;
		}

		let filter: string = AppConstants.empty;
		const commaOrEmpty: string =
			enumeration.length === 2
				? AppConstants.empty
				: AppConstants.characters.comma;
		enumeration.forEach((enumerable: string, index: number) =>
		{
			const predicate: string =
				useAnd === true
					? 'and'
					: 'or';
			const existingFilterCriteria =
				index === enumeration.length - 1
					? `${commaOrEmpty} `
						+ `${predicate} ${enumerable}`
					: `${commaOrEmpty} ${enumerable}`;

			filter +=
				AnyHelper.isNullOrWhitespace(filter)
					? `${enumerable}`
					: existingFilterCriteria;
		});

		return filter;
	}

	/**
	 * Creates a returns a string value used to interpolate properties
	 * in a nested data object.
	 *
	 * @param {string} value
	 * The string to gather the data key value for.
	 * @returns {string}
	 * A a key value for use in an object containing nested data.
	 * @memberof StringHelper
	 */
	public static clearNestedDataIdentifier(
		value: string): string
	{
		return value?.replace(
			AppConstants.nestedDataIdentifier,
			AppConstants.empty);
	}

	/**
	 * Formats a number to a currency value.
	 *
	 * @param {number} value
	 * The number to be formatted to currency.
	 * @param {currency} value
	 * The currency option. USD by default.
	 * @param {locales} value
	 * The locale currency format. en-US by default.
	 * @returns {string}
	 * A currency representation of the provided number.
	 * @memberof StringHelper
	 */
	public static numberToCurrency(
		value: number,
		currency: string = 'USD',
		locales: string = this.internationalFormat): string
	{
		const currencyOptions: any =
			<any>
			{
				style: 'currency',
				currency: currency
			};

		// Add whole number rounding if applicable.
		if (value % 1 === 0)
		{
			currencyOptions.maximumSignificantDigits = 10;
		}

		const currencyFormat =
			new Intl.NumberFormat(
				locales,
				currencyOptions);

		return currencyFormat.format(
			parseFloat(value.toFixed(2)));
	}

	/**
	 * Capitalizes every initial letter on a string value that
	 * is separated by spaces.
	 *
	 * @param {string} value
	 * The string value to be formatted.
	 * @returns {string}
	 * A string value capitalized on every initial letter.
	 * @memberof StringHelper
	 */
	public static capitalizeAllWords(value: string): string
	{
		const splitValues: string[] =
			value.split(AppConstants.characters.space);
		const upperCaseArray: string[] = [];
		for (const splitValue of splitValues)
		{
			upperCaseArray.push(StringHelper.toProperCase(splitValue));
		}

		return upperCaseArray.join(AppConstants.characters.space);
	}

	/**
	 * Formats the string into an specific format type.
	 *
	 * @static
	 * @param {string} value
	 * The string value to be formatted.
	 * @param {string} formatType
	 * The format type to format the string.
	 * Available format types in AppConstants.formatTypes.
	 * @returns {string}
	 * The formatted string.
	 * @memberof StringHelper
	 */
	public static format(
		value: string,
		formatType: string): string
	{
		if (AnyHelper.isNull(value)
			|| formatType === AppConstants.formatTypes.none)
		{
			return value;
		}

		let formattedValue: string;
		const parsedInt: number =
			parseInt(
				value,
				AppConstants.parseRadix);
		const parsedFloat: number =
			parseFloat(
				value);

		switch (formatType)
		{
			case AppConstants.formatTypes.boolean:
			case AppConstants.formatTypes.yesNoBoolean:
				formattedValue =
					StringHelper.formatBooleanValue(
						value,
						formatType === AppConstants.formatTypes.yesNoBoolean);
				break;
			case AppConstants.formatTypes.currency:
				formattedValue =
					this.numberToCurrency(
						parsedFloat);
				break;
			case AppConstants.formatTypes.longDate:
			case AppConstants.formatTypes.shortDate:
				formattedValue =
					DateHelper.formatDate(
						DateTime.fromISO(value),
						formatType === AppConstants.formatTypes.longDate
							? DateHelper.presetFormats.longDateFormat
							: DateHelper.presetFormats.shortDateFormat);
				break;
			case AppConstants.formatTypes.percent:
				const numberFormatter =
					Intl.NumberFormat(
						this.internationalFormat,
						{
							style: 'percent',
							minimumFractionDigits: 0
						});
				formattedValue =
					numberFormatter.format(
						parsedFloat);
				break;
			case AppConstants.formatTypes.number:
				formattedValue =
					Intl.NumberFormat(
						this.internationalFormat).format(
						parsedInt);
				break;
			default:
				formattedValue = value;
				break;
		}

		return formattedValue;
	}

	/**
	 * Formats a string representing a boolean value into a formatted value
	 * of True/False or Yes/No depending on the yesNoOutput paramater.
	 *
	 * @static
	 * @param {string} value
	 * The string value to be checked for true equivalence.
	 * @param {boolean} yesNoOutput
	 * If sent this will format true as Yes and false as No. This value
	 * defaults to false.
	 * @returns {string}
	 * The formatted boolean based string.
	 * @memberof StringHelper
	 */
	public static formatBooleanValue(
		value: string,
		yesNoOutput: boolean = false): string
	{
		let formattedValue: string;
		const booleanValue: boolean = value === 'true';

		if (yesNoOutput === false)
		{
			formattedValue =
				booleanValue === true
					? 'True'
					: 'False';
		}
		else
		{
			formattedValue =
				booleanValue === true
					? 'Yes'
					: 'No';
		}

		return formattedValue;
	}

	/**
	 * Returns a name value that will combine the first and last name if both
	 * exist, return one or the other if only one exists, or returns
	 * an empty string if neither value exists.
	 *
	 * @static
	 * @param {string} firstName
	 * The first name string.
	 * @param {string} lastName
	 * The last name string.
	 * @returns {string}
	 * A formatted name based string.
	 * @memberof StringHelper
	 */
	public static toNameString(
		firstName: string,
		lastName: string): string
	{
		const firstNameExists: boolean =
			!AnyHelper.isNullOrWhitespace(firstName);
		const lastNameExists: boolean =
			!AnyHelper.isNullOrWhitespace(lastName);

		if (firstNameExists === false && lastNameExists === false)
		{
			return AppConstants.empty;
		}

		if (firstNameExists === true && lastNameExists === true)
		{
			return `${firstName} ${lastName}`;
		}

		return firstNameExists
			? firstName
			: lastName;
	}

	/**
	 * Returns an address value that will return the full set of existing data
	 * without extra spaces.
	 *
	 * @static
	 * @param {string} address
	 * The address string.
	 * @param {string} city
	 * The city string.
	 * @param {string} state
	 * The state string.
	 * @param {string} postalCode
	 * The postal code string.
	 * @returns {string}
	 * A formatted address based string.
	 * @memberof StringHelper
	 */
	public static toAddressString(
		address: string,
		city: string,
		state: string,
		postalCode: string): string
	{
		const commaPossibleValue: string =
			AnyHelper.isNullOrWhitespace(state) ||
				AnyHelper.isNullOrWhitespace(postalCode)
				? AppConstants.empty
				: ', ';

		const addressValue: string =
			AnyHelper.isNullOrWhitespace(address)
				? AppConstants.empty
				: `${address} `;
		const cityValue: string =
			AnyHelper.isNullOrWhitespace(city)
				? AppConstants.empty
				: `${city} `;
		const stateValue: string =
			(AnyHelper.isNullOrWhitespace(state)
				? AppConstants.empty
				: state)
				+ commaPossibleValue;
		const postalCodeValue: string =
			AnyHelper.isNullOrWhitespace(postalCode)
				? AppConstants.empty
				: postalCode;

		return `${addressValue}${cityValue}${stateValue}${postalCodeValue}`;
	}
}