/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BusinessLogicEntity
} from '@shared/business-logic-entities/business-logic-entity';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	FileEntity
} from '@shared/business-logic-entities/file-entity';
import {
	FileState
} from '@shared/constants/enums/file-state.enum';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityTypeDto
} from '@api/interfaces/entities/entity-type.dto.interface';
import {
	IWorkItem
} from '@shared/interfaces/workItems/work-item.interface';
import {
	IWorkItemKeyDates
} from '@shared/interfaces/workItems/work-item-key-dates.interface';
import {
	IWorkItemReference
} from '@shared/interfaces/workItems/work-item-reference.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	WorkItemStatus
} from '@shared/constants/enums/work-item-status.enum';

/**
 * A component representing an instance of the main work items page.
 *
 * @export
 * @class WorkItem
 */
export class WorkItem
	extends BusinessLogicEntity
	implements IWorkItem
{
	/**
	 * Initializes a new instance of the work item.
	 *
	 * @param {IWorkItem} workItem
	 * The underlying IWorkItem.
	 * @param {ResolverService} resolverService
	 * The resolver service.
	 * @memberof WorkItem
	 */
	public constructor(
		workItem: IWorkItem,
		public resolverService: ResolverService)
	{
		super(
			workItem,
			resolverService);
	}

	/**
	 * The work item data object.
	 *
	 * @memberof WorkItem
	 */
	public data: {
		assignedTo: string;
		blocked: boolean;
		description: string;
		keyDates: IWorkItemKeyDates;
		metadata: any;
		module: string;
		priority: string;
		queue: string;
		references: IWorkItemReference[];
		status: WorkItemStatus;
		subType: string;
		systemGenerated: boolean;
		type: string;
	};

	/**
	 * Gets a reference.
	 *
	 * @param {string} referenceType
	 * The reference type to get.
	 * @returns {IWorkItemReference}
	 * A work item reference.
	 * @memberof WorkItem
	 */
	public getReference(
		referenceType: string): IWorkItemReference
	{
		return this.data.references
			.find(
				(reference: IWorkItemReference) =>
					reference.type === referenceType);
	}

	/**
	 * Gets an reference identifier.
	 *
	 * @param {string} referenceType
	 * The reference type to get.
	 * @returns {string}
	 * A work item reference identifier (an id, for example).
	 * @memberof WorkItem
	 */
	public getIdentifier(referenceType: string): string
	{
		return this
			.getReference(referenceType)
			.identifier;
	}

	/**
	 * Unblocks this work item.
	 *
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public unblock(): WorkItem
	{
		this.data.blocked = false;
		this.data.keyDates.blockedDate = null;

		return this;
	}

	/**
	 * Blocks this work item.
	 *
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public block(): WorkItem
	{
		this.data.blocked = true;
		this.data.keyDates.blockedDate =
			DateHelper.getSystemDateTime().toISO();

		return this;
	}

	/**
	 * Sets this work item to ignored.
	 *
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public ignore(): WorkItem
	{
		this.unblock();
		this.data.status = WorkItemStatus.Ignored;

		return this;
	}

	/**
	 * Sets this work item to done.
	 *
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public markDone(): WorkItem
	{
		this.unblock();
		this.data.status = WorkItemStatus.Done;
		this.data.keyDates.doneDate =
			DateHelper.getSystemDateTime().toISO();

		return this;
	}

	/**
	 * resets the work item state to active and unblocked.
	 *
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public reset(): WorkItem
	{
		this.unblock();
		this.data.status = WorkItemStatus.Active;
		this.data.keyDates.doneDate = null;

		return this;
	}

	/**
	 * Sets this work item as reviewed.
	 *
	 * @param {string} reviewState
	 * The review state to mark.
	 * @returns {WorkItem}
	 * This work item.
	 * @memberof WorkItem
	 */
	public markReviewed(
		reviewState: string): WorkItem
	{
		this.addOrUpdateProperty(
			this.data.metadata,
			'reviewState',
			reviewState);

		this.addOrUpdateProperty(
			this.data.metadata,
			'reviewedBy',
			this.currentUser().data.userName);

		this.addOrUpdateProperty(
			this.data.metadata,
			'reviewDate',
			DateHelper
				.getSystemDateTime()
				.toISO());

		return this;
	}

	/**
	 * Markes the work item done/approved and approves the associated file.
	 *
	 * @async
	 * @param {IEntityInstance} parentToUpdate
	 * The optional parent entity to update.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async approveFile(
		parentToUpdate: IEntityInstance = null): Promise<void>
	{
		const fileEntity: FileEntity =
			await this.getAssociatedFileEntity();

		fileEntity.data.status.state = FileState.Approved;

		await this.fileReviewed(
			'Approved',
			fileEntity,
			parentToUpdate);
	}

	/**
	 * Markes the work item done/rejected and rejects the associated file.
	 *
	 * @async
	 * @param {IEntityInstance} parentToUpdate
	 * The optional parent entity to update.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async rejectFile(
		parentToUpdate: IEntityInstance = null): Promise<void>
	{
		const fileEntity: FileEntity =
			await this.getAssociatedFileEntity();

		fileEntity.data.status.state = FileState.Rejected;

		await this.fileReviewed(
			'Rejected',
			fileEntity,
			parentToUpdate);
	}

	/**
	 * Resets the work item and the associated file pre reviewed states.
	 *
	 * @async
	 * @param {IEntityInstance} parentToUpdate
	 * The optional parent entity to update.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async resetFileReviewState(
		parentToUpdate: IEntityInstance = null): Promise<void>
	{
		const fileEntity: FileEntity =
			await this.getAssociatedFileEntity();

		fileEntity.data.status.state = FileState.Active;

		const reviewProperties: object =
			{
				reviewState: 'Pending',
				reviewedBy: null,
				reviewDate:  null
			};

		await fileEntity
			.addOrUpdateCustomProperties(reviewProperties)
			.save();

		await this
			.reset()
			.addOrUpdateProperties(
				this.data.metadata,
				reviewProperties)
			.save();

		if (!AnyHelper.isNull(parentToUpdate))
		{
			const entityType: IEntityTypeDto = await this
				.entityTypeApiService.getSingleQueryResult(
					`Name eq \'${parentToUpdate.entityType}\'`,
					'Id',
					true);

			if (!AnyHelper.isNull(entityType))
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					entityType.group;

				await this.entityInstanceApiService
					.update(
						parentToUpdate.id,
						parentToUpdate);
			}
		}
	}

	/**
	 * Markes the work item done and the associated file as reviewed
	 *
	 * @async
	 * @param {string} reviewState
	 * The review state to mark
	 * @param {FileEntity} fileEntity
	 * The file entity reviewed.
	 * @param {WorkItem} workItem
	 * The work item to complete
	 * @param {IEntityInstance} parentToUpdate
	 * The parent entity to update.
	 * @returns {Promise<void>}
	 * An empty promise.
	 * @memberof WorkItem
	 */
	public async fileReviewed(
		reviewState: string,
		fileEntity: FileEntity,
		parentToUpdate: IEntityInstance = null): Promise<void>
	{
		const reviewProperties: object =
			{
				reviewState,
				reviewedBy: this.resolverService
					.resolveCurrentUser().data.userName,
				reviewDate:  DateHelper.getSystemDateTime().toISO()
			};

		await fileEntity
			.addOrUpdateCustomProperties(reviewProperties)
			.save();

		await this
			.markReviewed(reviewState)
			.markDone()
			.save();

		if (!AnyHelper.isNull(parentToUpdate))
		{
			const entityType: IEntityTypeDto = await this
				.entityTypeApiService.getSingleQueryResult(
					`Name eq \'${parentToUpdate.entityType}\'`,
					'Id',
					true);

			if (!AnyHelper.isNull(entityType))
			{
				this.entityInstanceApiService.entityInstanceTypeGroup =
					entityType.group;

				await this.entityInstanceApiService
					.update(
						parentToUpdate.id,
						parentToUpdate);
			}
		}
	}

	/**
	 * Gets the associated file entity.
	 *
	 * @async
	 * @returns {Promise<FileEntity>}
	 * The file entity or null if references are not set.
	 * @memberof WorkItem
	 */
	public async getAssociatedFileEntity(): Promise<FileEntity>
	{
		let fileId: number;
		let fileType: string;

		try
		{
			fileId =
				parseInt(
					this.getIdentifier('FileId'),
					AppConstants.parseRadix);

			fileType =
				this.getIdentifier('FileEntityType');
		}
		catch
		{
			return Promise.resolve(null);
		}

		const fileEntityType: IEntityTypeDto =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name.Equals("${fileType}")`,
					'Id',
					false);

		this.entityInstanceApiService
			.entityInstanceTypeGroup = fileEntityType.group;

		const fileInstance: IEntityInstance =
			await this.entityInstanceApiService.get(fileId);

		return new FileEntity(
			fileInstance,
			this.resolverService);
	}
}