<div class="floating-header {{siteLayoutService.contentCssClass}}"
	*ngIf="display === true"
	[@contentAnimation]="state">
	<div class="parameter-summary no-scrollbar"
		*ngIf="displayParameters === true">
		<p-chips [(ngModel)]="parameterLayoutSchema"
			[disabled]="true">
			<ng-template let-item pTemplate="item">
				<div class="setting-chip ellipsis-text">
					<span class='setting-chip-label'>
						{{item.templateOptions.label}}:
					</span>
					{{getChipValue(item)}}
				</div>
			</ng-template>
		</p-chips>
	</div>

	<div class="inline-display cursor-pointer"
		*ngIf="displayEditOption === true"
		(click)="displayEditClicked()">
		<i class="fa fa-fw fa-edit theme-color"
			[pTooltip]="editDisplayTooltip"
			[tooltipPosition]="iconTooltipPosition"
			[tooltipStyleClass]="iconStyleClass">
		</i>
	</div>

	<div class="inline-display cursor-pointer"
		*ngIf="displaySecondaryOption === true && useClickOnDisplay !== true"
		(click)="displaySecondaryClicked()">
		<i class="fa fa-fw fa-exchange theme-color"
			[ngClass]="{
				'active': displayingSecondary === true && displayingSettings === false
			}"
			[pTooltip]="alternateDisplayTooltip"
			[tooltipPosition]="iconTooltipPosition"
			[tooltipStyleClass]="iconStyleClass">
		</i>
	</div>

	<div class="inline-display cursor-pointer"
		*ngIf="displayParameterOption === true"
		(click)="displaySettingsClicked()">
		<i class="fa fa-fw fa-filter theme-color"
			[ngClass]="{
				'active': displayingSettings === true
			}"
			[pTooltip]="alterDataTooltip"
			[tooltipPosition]="iconTooltipPosition"
			[tooltipStyleClass]="iconStyleClass">
		</i>
	</div>

	<div class="position-relative cursor-pointer">
		<div class="filter-badge position-absolute inverse-theme-color"
			*ngIf="displayParameterOption === true && differenceCount != null">
			<span class="filter-badge-text"
				[pTooltip]="alterDataTooltip"
				[tooltipPosition]="iconTooltipPosition"
				[tooltipStyleClass]="iconStyleClass">
				{{differenceCount}}
			</span>
		</div>
	</div>
</div>