<div
	#root
	class="ui-g-12 text-center">
	<ng-template
		[ngIf]="context.data.searchable">
		<div
			class="ui-g-9 no-padding text-left p-inputgroup"
			[ngClass]="{
				'filter-bottom': context.data.sortable !== true
			}">
			<input
				#searchText
				pInputText
				type="text"
				class="ui-g-12"
				placeholder="Filter"
				(keyup.enter)="handleSearchTextAdd(searchText)" />
			<button
				pButton
				type="button"
				icon="pi pi-search"
				class="p-button-icon"
				(click)="handleSearchTextAdd(searchText)">
			</button>
		</div>
	</ng-template>

	<div
		class="ui-g-3 no-padding text-right list-actions theme-color">
		<ng-template
			[ngIf]="(context.data.filters?.length || 0) > 0">
			<div
				class="filter-add-container"
				(click)="QuickFilterMenu.toggle($event)">
				<i
					class="fa fa-filter fa-fw text-link filter-add-container">
				</i>
				<span
					class="filter-plus-icon text-link">
					+
				</span>
			</div>
			<div
				class="quick-filter-context-menu-container">
				<p-menu
					#QuickFilterMenu
					styleClass="quick-filter-context-menu"
					[popup]="true"
					[model]="quickFilterMenuItems">
				</p-menu>
			</div>
		</ng-template>

		<ng-template
			ngFor
			let-action
			[ngForOf]="context.data.actions">
			<i
				class="{{action.icon}} fa-fw text-link"
				(click)="action.command($event)"
				pTooltip="{{action.label}}"
				tooltipPosition="left"
				tooltipStyleClass="utility-menu-tooltip">
			</i>
		</ng-template>
	</div>
	
	<div
		*ngIf="(enabledFilters?.length || 0) > 0"
		class="ui-g-12 no-padding text-left filters p-chips p-component">
		<ul
			#FilterChipsContainer
			class="p-chips-multiple-container">
			<ng-template
				ngFor
				let-enabledFilter
				[ngForOf]="enabledFilters">
				<li
					class="p-chips-token inverse-theme-container">
					<div class="ui-g-12 chip-container">
						<div
							class="p-chips-token-label ui-g-10 ellipsis-text">
							{{enabledFilter.name}}
						</div>
						<div
							class="p-chips-token-icon ui-g-2 text-right cursor-pointer"
							(click)="handleRemoveFilterEvent($event, enabledFilter.name)">
							<i
								class="pi pi-fw pi-times">
							</i>
						</div>
					</div>
				</li>
			</ng-template>
		</ul>
		
	</div>

	<div *ngIf="(enabledFilters?.length || 0) > 1"
		class="multiple-filters-warning theme-color-gray text-center font-italic">
		Items have to meet all conditions to be returned.
	</div>	

	<div
		*ngIf="context.data.sortable === true && (context.data.sorters?.length || 0) > 0"
		class="ui-g-9 text-left no-padding sorter"
		[ngClass]="{
			'filter-items-displayed': (enabledFilters?.length > 0)
		}">
		<span
			class="text-link theme-color"
			(click)="SortFilterMenu.toggle($event)">
			{{selectedSorter.name}}
		</span>

		<span
			class="icon">
			<ng-template
				[ngIf]="selectedSorter.direction === 'asc'"
				[ngIfElse]="SortDESC">
				<i
					class="{{selectedSorter.iconAsc}} fa-fw text-link theme-color"
					(click)="handleSortChangeEvent($event, selectedSorter.name)">
				</i>
			</ng-template>
			<ng-template
				#SortDESC>
				<i
					class="{{selectedSorter.iconDesc}} fa-fw text-link theme-color"
					(click)="handleSortChangeEvent($event, selectedSorter.name)">
				</i>
			</ng-template>
		</span>
		<div
			#SortMenuContainer
			class="sort-context-menu-container">
			<p-menu
				#SortFilterMenu
				styleClass="sort-context-menu"
				[popup]="true"
				[model]="sorterMenuItems">
			</p-menu>
		</div>
	</div>

	<ng-template
		[ngIf]="loading"
		[ngIfElse]="List">
		<div
			class="ui-g-12 text-center">
			<i
				class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template
		#List>
		<ng-template
			[ngIf]="(context.data.data?.length || 0) > 0"
			[ngIfElse]="NoResults">
			<p-scrollPanel
				styleClass="common-list-container">
				<ul
					class="common-list ui-g-12 no-padding">
					<ng-template
						ngFor
						let-item
						[ngForOf]="context.data.data">
						<li>
							<div
								class="ui-g-12 item-container"
								(click)="selectItem($event)">
								<div class="item">
									<div
										class="actions theme-color">
										<ng-template
											ngFor
											let-action
											[ngForOf]="item.actions">
											<i
												class="{{action.icon}} fa-fw text-link"
												(click)="action.command($event, item)">
											</i>
										</ng-template>
									</div>

									<div
										class="ui-g-12 no-padding text-left line1">
										<div
											class="ellipsis-text text-link">

											<ng-template
												[ngIf]="context.data.supportMarkdown === true"
												[ngIfElse]="DescriptionWithoutMarkdown">
												<span class="markdown-element">
													<markdown
														[data]="interpolate(item.descriptionLineFormat, item.item)">
													</markdown>
												</span>
											</ng-template>

											<ng-template
												#DescriptionWithoutMarkdown>
												{{interpolate(item.descriptionLineFormat, item.item)}}
											</ng-template>
										</div>
										<div
											class="more display-none">
											(More)
										</div>
									</div>

									<div
										class="ui-g-9 no-padding text-left line2 ellipsis-text"
										*ngIf="(item.informationLineFormat || '') !== '' || (item.informationLineRelativeDateTime || '') !== ''">
										<span *ngIf="(item.informationLineRelativeDateTime || '') !== ''">
											{{interpolate(item.informationLineRelativeDateTime, item.item) | dateTimeFromIso | dateTimeToRelative:null:'long'}}
										</span>
										<span *ngIf="(item.informationLineFormat || '') !== ''">
											{{interpolate(item.informationLineFormat, item.item)}}
										</span>
									</div>

									<div class="list-item-component-container display-none cursor-default"
										*ngIf="(context.data.listItemComponent || '') != ''"
										(click)="allowClickThrough($event)">
										<app-dynamic-component
											[context]="getItemContext(item.item)"
											[displayComponent]="context.data.listItemComponent">
										</app-dynamic-component>
									</div>

									<div
										class="ui-g-3 no-padding text-right info-icons">
										<ng-template
											ngFor
											let-infoIcon
											[ngForOf]="item.informationIcons">
											<i
												class="{{infoIcon}} fa-fw">
											</i>
										</ng-template>
									</div>
								</div>
							</div>
						</li>
					</ng-template>
				</ul>
			</p-scrollPanel>
		</ng-template>

		<ng-template
			#NoResults>
			<div
				class="ui-g-12 text-center">
				{{noResultsMessage}}
			</div>
		</ng-template>
	</ng-template>
</div>

<app-color-swatch></app-color-swatch>