/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DrawerEntityComponentDirective
} from '@shared/directives/drawer-entity-component-directive';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	ISecurityEntityTypeDefinition
} from '@shared/interfaces/security/security-entity-type-definition.interface';
import {
	ISecurityItem
} from '@shared/interfaces/security/security-item.interface';
import {
	SelectItem
} from 'primeng/api';

@Component({
	selector: 'entity-create',
	templateUrl: './entity-create.component.html',
	styleUrls: [
		'./entity-create.component.scss'
	]
})

/**
 * A component representing a parent context based entity create display.
 *
 * @export
 * @class EntityCreateComponent
 * @extends {DrawerEntityComponentDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class EntityCreateComponent
	extends DrawerEntityComponentDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the entity create drawer component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition API service.
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The entity layout API service.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type API service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {EntityService} entityService
	 * The entity service.
	 * @memberof EntityCreateComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public activityService: ActivityService,
		public entityService: EntityService)
	{
		super(
			entityService,
			entityInstanceApiService);
	}

	/**
	 * Gets or sets a string representing the parent type group.
	 *
	 * @type {string}
	 * @memberof EntityCreateComponent
	 */
	@Input() public parentTypeGroup: string;

	/**
	 * Gets or sets a number representing the parent id.
	 *
	 * @type {number}
	 * @memberof EntityCreateComponent
	 */
	@Input() public parentId: number;

	/**
	 * Gets or sets an object that will be used as inputs into a newly created
	 * drawer entity. This object can be used to set defaults or context based
	 * values in the data value of a created entity.
	 *
	 * @type {any}
	 * @memberof EntityCreateComponent
	 */
	@Input() public initialCreateData: any;

	/**
	 * Gets or sets a entity definition representing the parent context entity
	 * definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof EntityCreateComponent
	 */
	public parentEntityDefinition: EntityDefinition;

	/**
	 * Gets or sets a collection of entity types representing the supported
	 * entity types of the parent context. This is used to select the
	 * allowed creation types for this drawer display.
	 *
	 * @type {EntityType[]}
	 * @memberof EntityCreateComponent
	 */
	public supportedEntityTypes: EntityType[];

	/**
	 * Gets or sets a collection of select items representing entity types to
	 * load within the selector.
	 *
	 * @type {SelectItem[]}
	 * @memberof EntityCreateComponent
	 */
	public entityTypes: SelectItem[] = [];

	/**
	 * Gets or sets an entity instance representing the entity to create.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityCreateComponent
	 */
	public entityData: IEntityInstance;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @memberof EntityCreateComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const source: EntityInstanceComponent =
			(<EntityInstanceComponent>this.context.source);
		this.parentTypeGroup = this.parentTypeGroup || source.entityType.group;
		this.parentId = this.parentId || source.id;
		this.parentEntityDefinition = source.entityDefinition;

		const childEntityTypes: string[] =
			ApiFilterHelper.getWildcardEntityTypes(
				this.parentEntityDefinition
					.supportedChildTypes,
				this.wildcardChildFilter);

		this.supportedEntityTypes =
			await this.entityService
				.getEntityTypesFromNameList(childEntityTypes);

		await this.mapEntityTypesToEntityTypes(
			this.supportedEntityTypes);

		this.loading = false;
	}

	/**
	 * A method containing the activity of creating a entity.
	 *
	 * @async
	 * @memberof EntityCreateComponent
	 */
	public async createEntity(): Promise<void>
	{
		this.saving = true;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.selectedEntityType.group;

		const createDisplayName: string =
			this.getDisplayName(this.selectedEntityType.name)
				+ ' entity';

		const newEntityId: number =
			await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.entityInstanceApiService.createEntityInstance(
						this.entityData,
						this.parentTypeGroup,
						this.parentId),
					`<strong>Creating</strong> ${createDisplayName}`,
					`<strong>Created</strong> ${createDisplayName}`,
					`${createDisplayName} was added.`,
					`${createDisplayName} was not added.`));

		this.saving = false;

		if (AnyHelper.isNull(newEntityId) !== true)
		{
			this.changeDisplayMode.emit(
				AppConstants.displayMode.list);

			this.entityAltered.emit();
		}
	}

	/**
	 * A method that handles an entity type change event.
	 *
	 * @async
	 * @param {any} event
	 * A value representing the event parameters.
	 * @memberof EntityCreateComponent
	 */
	public async handleEntityTypeChange(
		event: any): Promise<void>
	{
		this.formlyEntityLayout = null;
		this.selectedEntityType = event?.value;
		this.entityData =
			<IEntityInstance>
			{
				id: 0,
				entityType: this.selectedEntityType?.name,
				versionNumber: null, // this causes latest to be used.
				data: this.initialCreateData
			};

		if (AnyHelper.isNullOrWhitespace(this.selectedEntityType))
		{
			return;
		}

		const entityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq `
						+ `'${AppConstants.layoutTypes.drawer}'`,
					`${AppConstants.commonProperties.id} `
						+ AppConstants.sortDirections.descending);
		const entityLayout: IEntityLayout =
			await this.entityLayoutApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.typeId} eq `
						+ `${this.selectedEntityType.id} and `
						+ `${AppConstants.commonProperties.layoutTypeId} eq `
						+ entityLayoutType.id,
					`${AppConstants.commonProperties.versionId} `
						+ AppConstants.sortDirections.descending);
		this.formlyEntityLayout =
			FormlyHelper.getFormlyLayout(
				new EntityLayout(entityLayout).jsonEntityLayout,
				this.context);
	}

	/**
	 * A method that maps a collection of entity types to select items and
	 * populates the entity types selector.
	 *
	 * @async
	 * @param {EntityType[]} entityTypes
	 * A collection of EntityTypes to map and populate EntityTypes select.
	 * @memberof EntityCreateComponent
	 */
	public async mapEntityTypesToEntityTypes(
		entityTypes: EntityType[]): Promise<void>
	{
		const entityInstanceComponent: EntityInstanceComponent =
			<EntityInstanceComponent>this.context.source;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			entityInstanceComponent.entityType.group;
		const permissions: ISecurityEntityTypeDefinition[] =
			await this.entityInstanceApiService
				.getHierarchyPermissions(
					entityInstanceComponent.entityInstance.id,
					entityTypes.map(
						(entityType: EntityType) =>
							entityType.group));

		// Map types to select items if allowed and not existing.
		entityTypes.forEach(
			(entityType: EntityType) =>
			{
				const matchingTypeDefinition: ISecurityEntityTypeDefinition =
					permissions
						.find(
							(permission: ISecurityEntityTypeDefinition) =>
								permission.entityTypeGroup ===
									entityType.group);
				const matchingPermission =
					matchingTypeDefinition.securityDefinition.actions
						.find(
							(securityItem: ISecurityItem) =>
								securityItem.path ===
									AppConstants.apiMethods.create);

				if (matchingPermission.rights.execute === true
					&& AnyHelper.isNull(
						this.entityTypes.find(
							(existingEntityType: SelectItem) =>
								existingEntityType.value === entityType)))
				{
					this.entityTypes.push(
						<SelectItem>
						{
							label: this.getDisplayName(entityType.name),
							value: entityType
						});
				}
			});

		// Default to the only available type if applicable.
		if (this.supportedEntityTypes.length === 1)
		{
			this.handleEntityTypeChange({
				value: this.supportedEntityTypes[0]
			});
		}
	}
}