<ng-template [ngIf]="to.useCurrency === true"
	[ngIfElse]="NumberInput">
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		[min]="to.min"
		[max]="to.max"
		[step]="to.multipleOf"
		[showButtons]="to.multipleOf != null"
		mode="currency"
		currency="USD"
		locale="en-US"
		size="100"
		(onInput)="onChange($event)">
	</p-inputNumber>
</ng-template>
<ng-template #NumberInput>
	<p-inputNumber
		[formControl]="formControl"
		[formlyAttributes]="field"
		[inputStyleClass]="inputStyleClass"
		mode="decimal"
		[min]="to.min"
		[max]="to.max"
		[step]="to.multipleOf || 1"
		[showButtons]="true"
		[minFractionDigits]="to.minFractionDigits || 0"
		[maxFractionDigits]="to.maxFractionDigits || 0"
		size="100"
		(onInput)="onChange($event)">
	</p-inputNumber>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>