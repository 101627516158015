<span>
	<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner"
		*ngIf="loadingTableDefinitions && loadingFormlyDefinitions">
	</span>
	<div class="ui-g-12 no-padding">
		<div class="section-margin">
			<span *ngIf="!loadingFormlyDefinitions">
				<app-dynamic-formly
					[dataSet]="context.data"
					[layoutSchema]="formlyLayoutDefinition"
					[context]="context"
					(validityChanged)="context.source.validExpandComponentChanged($event)">
				</app-dynamic-formly>
			</span>
		</div>
	</div>
	<div class="ui-g-12 no-padding" *ngIf="displayRequisiteTables">
		<span *ngIf="!context.source.displayCreate">
			<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner"
				*ngIf="loadingTableDefinitions && !loadingFormlyDefinitions">
			</span>
			<div class="ui-g-6 table-container"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}"
				*ngIf="!loadingTableDefinitions">
				<app-common-table
					[tableDefinitions]="preRequisiteTableDefinitions">
				</app-common-table>
			</div>
			<div class="ui-g-6 table-container"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}"
				*ngIf="!loadingTableDefinitions">
				<app-common-table
					[tableDefinitions]="postRequisiteTableDefinitions">
				</app-common-table>
			</div>
		</span>
	</div>

	<span *ngIf='context.source.displayView'>
		<div class="ui-g-6 no-horizontal-padding graph-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div class="ui-g-6 no-padding summary-cards"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
				<div class="information-menu-item"
					*ngFor="let item of summaryCardItems">
					<app-summary-card
						[chartDefinition]="item.chartDefinition"
						[groupByCount]="item.chartDefinition?.groupByCount || item.groupByCount"
						[summaryTemplate]="item.summaryTemplate"
						[titleTemplate]="item.titleTemplate"
						[summaryCardDisplay]="item.summaryCardDisplay"
						[squareCardDisplay]="item.squareCardDisplay"
						[dataPromise]="item.chartDefinition?.dataPromise || item.dataPromise">
					</app-summary-card>
				</div>
			</div>
			<div class="ui-g-6 no-vertical-padding"
				[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
				<div *ngFor="let item of chartItem">
					<app-summary-card
						[chartDefinition]="item.chartDefinition"
						[groupByCount]="item.chartDefinition?.groupByCount || item.groupByCount"
						[summaryTemplate]="item.summaryTemplate"
						[titleTemplate]="item.titleTemplate"
						[summaryCardDisplay]="item.summaryCardDisplay"
						[squareCardDisplay]="item.squareCardDisplay"
						[dataPromise]="item.chartDefinition?.dataPromise || item.dataPromise">
					</app-summary-card>
				</div>
			</div>
		</div>

		<div class="ui-g-6 table-container"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}"
			*ngIf="!loadingInstanceTableDefinitions">
			<span class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner"
				*ngIf="loadingInstanceTableDefinitions">
			</span>
			<app-common-table
				[tableDefinitions]="actionInstanceTableDefinitions">
			</app-common-table>
		</div>
	</span>

	<div class="ui-g-12">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked($event)">
		</operation-button-bar>
	</div>
</span>
