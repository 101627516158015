/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import { AppConstants } from '@shared/constants/app.constants';
import {
	ChangeDetectorRef,
	Directive,
	Input
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from './extended-custom-control.directive';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	isArray
} from 'lodash-es';
import {
	ObjectArrayHelper
} from '@shared/helpers/object-array.helper';
import {
	Observable
} from 'rxjs';

@Directive({
	selector: '[ExtendedCustomControl]'
})

/**
 * A directive representing shared logic for a formly custom control.
 *
 * @export
 * @extends {ExtendedCustomControlDirective}
 * @class SelectCustomControlDirective
 */
export class SelectCustomControlDirective
	extends ExtendedCustomControlDirective
{
	/** Initializes a new instance of the SelectCustomControlDirective.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof SelectCustomControlDirective
	 */
	 public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the overload that will allow data options to be sent in.
	 * If this is not sent, the template options value will be used.
	 *
	 * @type {any[] | Observable<any[]>}
	 * @memberof SelectCustomControlDirective
	 */
	@Input() public dataOptions: any[] | Observable<any[]>;

	/**
	 * Gets or sets loading state of the field.
	 *
	 * @type {boolean}
	 * @memberof SelectCustomControlDirective
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the initial load flag.
	 *
	 * @type {boolean}
	 * @memberof SelectCustomControlDirective
	 */
	public initialLoad: boolean = true;

	/**
	 * Gets or sets the initial template option disable value prior to
	 * in code alters. This value is reset on destroy.
	 *
	 * @type {boolean}
	 * @memberof SelectCustomControlDirective
	 */
	public archivedDisable: boolean;

	/**
	 * Gets or sets the initial template option disable value prior to
	 * in code alters. This value is reset on destroy.
	 *
	 * @type {string}
	 * @memberof SelectCustomControlDirective
	 */
	public archivedPlaceHolder: string;

	/**
	 * Updates the formly data options based on the most current from
	 * field template options.
	 *
	 * @memberof SelectCustomControlDirective
	 */
	public updateDataOptions(): void
	{
		const modifiedValueOptions: IDropdownOption[] =
			<IDropdownOption[]>
			ObjectArrayHelper.getObjectArrayDifferences(
				<IDropdownOption[]>this.dataOptions,
				<IDropdownOption[]>this.field.templateOptions.options,
				'value');

		const modifiedLabelOptions: IDropdownOption[] =
			<IDropdownOption[]>
			ObjectArrayHelper.getObjectArrayDifferences(
				<IDropdownOption[]>this.dataOptions,
				<IDropdownOption[]>this.field.templateOptions.options,
				'label');

		if (modifiedValueOptions.length < 1
			&& modifiedLabelOptions.length < 1)
		{
			return;
		}

		this.loading = true;

		setTimeout(
			() =>
			{
				let updatedSelection: string[] | string = null;

				if (this.field.type ===
					FormlyConstants.customControls.customMultiSelect)
				{
					updatedSelection = <string[]>this.formControl.value
						?.filter((value: string) =>
							!modifiedValueOptions.find(
								(modifiedDataOption) =>
									modifiedDataOption.value === value));
				}
				else if (modifiedValueOptions.some(
					(modifiedDataOption) => modifiedDataOption.value
						=== this.formControl.value) === false)
				{
					updatedSelection = this.formControl.value;
				}

				if (this.initialLoad === false
					&& this.loading === true
					&& ((this.field.type ===
						FormlyConstants.customControls.customMultiSelect
							&& updatedSelection?.length
								!== this.formControl.value?.length)
						|| AnyHelper.isNull(updatedSelection)))
				{
					this.formControl.setValue(updatedSelection);
					this.formControl.markAsDirty();
				}

				this.dataOptions = this.field.templateOptions.options;
				super.ngOnInit();
				this.disableSelectionLogic();
				this.initialLoad = false;
				this.loading = false;
			});
	}

	/**
	 * Disables or enables the select field based on
	 * specific criteria.
	 *
	 * @memberof SelectCustomControlDirective
	 */
	public disableSelectionLogic(): void
	{
		if (isArray(this.dataOptions) === true
			&& (<any[]>this.dataOptions)?.length === 0
			|| ((<any[]>this.dataOptions)?.length === 1
				&& this.field.type !==
				FormlyConstants.customControls.customMultiSelect))
		{
			setTimeout(
				() =>
				{
					this.formControl
						.setValue((<any[]>this.dataOptions).length > 0
							&& !AnyHelper.isNullOrEmpty(
								this.dataOptions[0].value)
							? this.dataOptions[0].value
							: null);

					if (AnyHelper.isFunction(this.field.templateOptions.change))
					{
						this.field.templateOptions.change(this.field);
					}

					this.field.templateOptions.placeholder =
						AnyHelper.isNull(this.formControl.value)
							? AppConstants.placeholders.noAvailableOptions
							: this.archivedPlaceHolder;

					this.field.templateOptions.disabled = true;
				});
		}
		else
		{
			this.field.templateOptions.disabled = this.archivedDisable;
			this.field.templateOptions.placeholder = this.archivedPlaceHolder;
		}
	}
}