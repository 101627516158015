/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Params,
	Router,
	UrlCreationOptions
} from '@angular/router';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BasePageDirective
} from '@shared/directives/base-page.directive';
import {
	cloneDeep
} from 'lodash-es';
import {
	Component,
	HostListener,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	debounceTime,
	distinctUntilChanged
} from 'rxjs';
import {
	DisplayComponentFactory
} from '@shared/factories/display-component-factory';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	DocumentHelper
} from '@shared/helpers/document.helper';
import {
	DynamicComponentLookup
} from '@dynamicComponents/dynamic-component.lookup';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityFormComponent
} from '@entity/components/entity-form/entity-form.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EntityVersionApiService
} from '@api/services/entities/entity-version.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyFieldConfig,
	FormlyFormOptions
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntity
} from '@shared/interfaces/entities/entity';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityLayout
} from '@shared/interfaces/entities/entity-layout.interface';
import {
	IEntityLayoutType
} from '@shared/interfaces/entities/entity-layout-type.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	IInformationMenuItem
} from '@shared/interfaces/application-objects/information-menu-item.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	JsonSchemaLayout
} from '@shared/implementations/application-data/json-schema-layout';
import {
	Location
} from '@angular/common';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OperationEventConstants
} from '@operation/shared/operation-event.constants';
import {
	OperationEventParameterConstants
} from '@operation/shared/operation-event-parameter.constants';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	RulePresentationService
} from '@shared/services/rule-presentation.service';
import {
	RuleService
} from '@shared/services/rule.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	templateUrl: './entity-instance.component.html',
	styleUrls: [
		'./entity-instance.component.scss'
	],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing an instance of a single entity
 * component.
 *
 * @export
 * @class EntityInstanceComponent
 * @extends {BasePageDirective}
 * @implements {OnInit}
 * @implements {AfterViewChecked}
 * @implements {OnDestroy}
 * @implements {IOwnershipGuardComponent}
 */
export class EntityInstanceComponent
	extends BasePageDirective
	implements OnInit, OnDestroy, IOwnershipGuardComponent
{
	/**
	 * Creates an instance of an EntityInstanceComponent.
	 *
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @param {ActivatedRoute} route
	 * The activated route that opened this component.
	 * @param {Router} router
	 * The router used in this component.
	 * @param {Location} location
	 * The location service.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used for responsive layouts.
	 * @param {EntityService} entityService
	 * The entity service used to populate the full entity
	 * association set.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to work with entity instances.
	 * @param {OperationService} operationService
	 * The operation service to use for loading operation groups and
	 * definitions.
	 * @param {entityLayoutApiService} entityLayoutApiService
	 * The operation execution service to use for executing operation
	 * @param {DisplayComponentService} displayComponentService
	 * The service used to load and gather display component data.
	 * @param {DisplayComponentFactory} displayComponentFactory
	 * The factory used to generate display component interfaces.
	 * @param {RuleService} ruleService
	 * The rule service used to gather violations and overrides.
	 * @param {RulePresentationService} rulePresentationService
	 * The rule presentation service used to decorate entity instance based
	 * rules.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof EntityInstanceComponent
	 */
	public constructor(
		public activityService: ActivityService,
		public route: ActivatedRoute,
		public router: Router,
		public location: Location,
		public siteLayoutService: SiteLayoutService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityVersionApiService: EntityVersionApiService,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public displayComponentService: DisplayComponentService,
		public displayComponentFactory: DisplayComponentFactory,
		public ruleService: RuleService,
		public rulePresentationService: RulePresentationService,
		public resolver: ResolverService)
	{
		super(
			siteLayoutService,
			displayComponentService,
			displayComponentFactory);

		this.existingRouteReuseStrategy =
			this.router.routeReuseStrategy.shouldReuseRoute;
		this.router.routeReuseStrategy.shouldReuseRoute =
			(_future: ActivatedRouteSnapshot,
				_curr: ActivatedRouteSnapshot): boolean =>
				false;
	}

	/**
	 * Gets or sets the entity id.
	 *
	 * @type {number}
	 * @memberof EntityInstanceComponent
	 */
	public id: number;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public entityTypeGroup: string;

	/**
	 * Gets or sets the entity type group for display.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public entityTypeGroupDisplay: string;

	/**
	 * Gets or sets the layout type.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public layoutType: string;

	/**
	 * Gets or sets the operation group name.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public operationGroupName: string;

	/**
	 * Gets or sets the page level operation group name to
	 * display in the context menu.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public pageContextOperationGroupName: string;

	/**
	 * Gets or sets the page level operation group name to
	 * display in the context menu.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public utilityMenuOperationGroupName: string;

	/**
	 * Gets or sets the identifier for the top
	 * of the entity page. This is used for scrolling
	 * capabilities.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public entityTopElementIdentifier: string;

	/**
	 * Gets or sets the entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityInstanceComponent
	 */
	public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the entity layout.
	 *
	 * @type {EntityLayout}
	 * @memberof EntityInstanceComponent
	 */
	public entityLayout: EntityLayout;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {EntityType}
	 * @memberof EntityInstanceComponent
	 */
	public entityType: EntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityInstanceComponent
	 */
	public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof EntityInstanceComponent
	 */
	public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the formly form options.
	 *
	 * @type {FormlyFormOptions}
	 * @memberof EntityInstanceComponent
	 */
	public formlyOptions: FormlyFormOptions = {};

	/**
	 * Gets or sets the list of information menu items
	 * to display in this component.
	 *
	 * @type {IInformationMenuItem<any>[]}
	 * @memberof EntityInstanceComponent
	 */
	public informationMenuItems: IInformationMenuItem<any>[] = [];

	/**
	 * Gets or sets the display value for a completed data load for
	 * an async populated information menu.
	 *
	 * @type {boolean}
	 * @memberof EntityInstanceComponent
	 */
	public informationMenuItemsLoaded: boolean = false;

	/**
	 * Gets or sets the view only for a Layout JSON Schema,
	 * making an entity display not editable if false and
	 * editable if true.
	 *
	 * @type {boolean}
	 * @memberof EntityInstanceComponent
	 */
	public isViewOnly: boolean = false;

	/**
	 * Gets the entity form component instance currently displayed in this
	 * component.
	 *
	 * @type {EntityFormComponent}
	 * @memberof EntityInstanceComponent
	 */
	public entityFormComponent: EntityFormComponent;

	/**
	 * Gets or sets the entity instance rule violations.
	 *
	 * @type {IEntityInstanceRuleViolation[]}
	 * @memberof EntityInstanceComponent
	 */
	public entityInstanceRuleViolations: IEntityInstanceRuleViolation[] = [];

	/**
	 * Gets or sets the formly entity layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof EntityInstanceComponent
	 */
	public formlyEntityLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the entity identifier.
	 *
	 * @type {Promise<string>}
	 * @memberof EntityInstanceComponent
	 */
	public entityIdentifier: string;

	/**
	 * Gets or sets the active drawer component.
	 *
	 * @type {Promise<string>}
	 * @memberof EntityInstanceComponent
	 */
	public activeDrawerComponent: string;

	/**
	 * Gets or sets the active drawer item unique text found in the description.
	 *
	 * @type {Promise<string>}
	 * @memberof EntityInstanceComponent
	 */
	public activeDrawerItemDescription: any;

	/**
	 * Gets or sets the operation group redraw.
	 *
	 * @type {boolean}
	 * @memberof EntityInstanceComponent
	 */
	public operationGroupRedraw: boolean = false;

	/**
	 * Gets or sets the data for the entity as it exists
	 * in the database.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityInstanceComponent
	 */
	public databaseEntityInstance: IEntityInstance;

	/**
	 * Gets the display count used in Formly which is based upon
	 * the width of the page.
	 *
	 * @type {number}
	 * @memberof EntityInstanceComponent
	 */
	public get columnDisplayCount(): number
	{
		return this.siteLayoutService.windowWidth
			<= this.singleColumnDisplayWidth
			? 1
			: 2;
	}

	/**
	 * Gets the standard access message beginning for ownership or entity
	 * instance permission requirements.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public get standardAccessMessage(): string
	{
		return 'Access is required to this entity type '
			+ `and version of '${this.entityTypeGroup}'. `;
	}

	/**
	 * Gets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntityInstanceComponent
	 */
	public get pageContext(): IDynamicComponentContext<Component, any>
	{
		return <IDynamicComponentContext<Component, any>> {
			source: this,
			data: {
				entityInstance: this.entityInstance
			}
		};
	}

	/**
	 * Gets or sets active tab item index.
	 *
	 * @type {number}
	 * @memberof EntityInstanceComponent
	 */
	private activeTabItemIndex: number = 0;

	/**
	 * Gets the route reuse strategy for the router on initial
	 * load. This is used to reset the route reuse strategy to it's
	 * original value on destroy, but force a component refresh on route changes
	 * to this component.
	 *
	 * @type {(
	 	future: ActivatedRouteSnapshot,
	 	curr: ActivatedRouteSnapshot) => boolean}
	 * @memberof EntityInstanceComponent
	 */
	private readonly existingRouteReuseStrategy:
		(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) =>
			boolean;

	/**
	 * Gets the name of the layout type query
	 * parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly entityTypeRouteParameter: string = 'entityType';

	/**
	 * Gets the name of the id route parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly idRouteParameter: string = 'id';

	/**
	 * Gets the name of the active drawer component route parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly activeDrawerComponentIdentifier: string =
		'activeDrawerComponent';

	/**
	 * Gets the name of the active drawer item route parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly activeDrawerItemDescriptionIdentifier: string =
		'activeDrawerItemDescription';

	/**
	 * Gets the name of the layout type query parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly layoutTypeQueryParameter: string = 'layoutType';

	/**
	 * Gets or sets the width defined that will switch the form display
	 * into a single column layout.
	 *
	 * @type {number}
	 * @memberof EntityInstanceComponent
	 */
	private readonly singleColumnDisplayWidth: number = 640;

	/**
	 * Gets or sets the delay for an initial setup call.
	 * This will allow the UI time to be drawn prior to
	 * udpating scroll commands with accurate page heights.
	 *
	 * @type {number}
	 * @memberof EntityInstanceComponent
	 */
	private readonly initialSetupDelay: number = 250;

	/**
	 * Gets the name of the active tab index parameter.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	private readonly activeTabIndexIdentifier: string = 'activeTabIndex';

	/**
	 * Handles the reload entity data event sent from
	 * the event emiter post operation action from the server.
	 *
	 * @async
	 * @memberof EntityManagerDirective
	 */
	@HostListener(
	 	OperationEventConstants.reloadEntityEvent,
	 	[OperationEventParameterConstants.event])
	public async reloadEntity(): Promise<void>
	{
		DocumentHelper.setElementDisplay(
			AppConstants.cssClasses.entityLayoutMask,
			true);

		await this.entityInstanceSaved();
	}

	 /**
	 * On initialization event.
	 * Gathers required data from the url and for the entity instance
	 * in this component.
	 *
	 * @async
	 * @memberof EntityInstanceComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		await this.initializePage(
			this.route.snapshot.paramMap.get(
				this.idRouteParameter) as unknown as number,
			StringHelper.toProperCase(
				this.route.snapshot.paramMap.get(
					this.entityTypeRouteParameter)));
	}

	/**
	 * On component destroy event.
	 * This method is used to complete the debounce on the
	 * layout changed and scroll height changed subjects.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public ngOnDestroy(): void
	{
		this.subscriptions.unsubscribe();

		this.router.routeReuseStrategy.shouldReuseRoute =
			<(future: ActivatedRouteSnapshot,
				curr: ActivatedRouteSnapshot) => boolean>
			this.existingRouteReuseStrategy;
	}

	/**
	 * Shared method for handling the on initialization or route parameter
	 * change event.
	 * Gathers required data from the url and for the entity instance
	 * in this component.
	 *
	 * @async
	 * @param {number} id
	 * The entity instance id to load.
	 * @param {string} entityTypeGroup
	 * The entity type group to load from from.
	 * @memberof EntityInstanceComponent
	 */
	public async initializePage(
		id: number,
		entityTypeGroup: string): Promise<void>
	{
		this.id = id;
		this.entityTypeGroup = entityTypeGroup;
		this.entityTypeGroupDisplay =
			StringHelper.beforeCapitalSpaces(
				StringHelper.getLastSplitValue(
					this.entityTypeGroup,
					AppConstants.characters.period));
		this.isViewOnly =
			this.route.snapshot.url[1]?.path !==
				AppConstants.viewTypes.edit;

		this.route.queryParams.subscribe(
			(parameters: Params) =>
			{
				const mappedRouteData: any =
					ObjectHelper.mapFromRouteData(
						parameters);

				this.layoutType =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[this.layoutTypeQueryParameter])
						? AppConstants.layoutTypes.generated
						: mappedRouteData[this.layoutTypeQueryParameter];
				this.activeDrawerComponent =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[this.activeDrawerComponentIdentifier])
						? AppConstants.empty
						: mappedRouteData[this.activeDrawerComponentIdentifier];
				this.activeDrawerItemDescription =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[
							this.activeDrawerItemDescriptionIdentifier])
						? AppConstants.empty
						: mappedRouteData[
							this.activeDrawerItemDescriptionIdentifier];
				this.activeTabItemIndex =
					AnyHelper.isNullOrEmpty(
						mappedRouteData[this.activeTabIndexIdentifier])
						? 0
						: mappedRouteData[this.activeTabIndexIdentifier];
			});

		try
		{
			this.entityInstanceApiService.entityInstanceTypeGroup =
				this.entityTypeGroup;
			await this.entityInstanceApiService.get(this.id);
		}
		catch (exception: any)
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'Entity.Type',
					'Entity.Version',
					'Entity.Instance'
				],
				this.standardAccessMessage
					+ `The entity instance with an id of ${this.id} was `
					+ 'not available.');

			return;
		}

		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					'Entity.Type',
					'Entity.Version',
					'Entity.Layout'
				],
				this.standardAccessMessage
					+ `The associated layout type of '${this.layoutType}' is `
					+ 'also required.');

			return;
		}

		await this.setSchema();
		this.setupInformationMenuItems(this.pageContext);
		await this.setEntityInstanceViolations();
		this.loading = false;

		this.subscriptions.add(
			this.scrollHeightChangedSubject.pipe(
				debounceTime(this.tabCalculationDebounceDelay),
				distinctUntilChanged())
				.subscribe(
					(scrollHeight: number) =>
					{
						this.setTabHeights(scrollHeight);
					}));

		this.loading = false;
		setTimeout(
			() =>
			{
				this.initialSetupInProgress = true;
			},
			this.initialSetupDelay);
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof EntityInstanceComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.group
					+ ` eq '${this.entityTypeGroup}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		const entityLayoutType: IEntityLayoutType =
			await this.entityLayoutTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.name
					+ ` eq '${this.layoutType}'`,
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(entityLayoutType))
		{
			return false;
		}

		return this.entityService.verifyEntityTypeAccess(
			entityType,
			entityLayoutType);
	}

	/**
	 * Gets the displayed identifier for this entity. This will also update
	 * the entity display name if new values have been entered.
	 *
	 * @type {string}
	 * @memberof EntityInstanceComponent
	 */
	public async getEntityIdentifier(): Promise<string>
	{
		if (!AnyHelper.isNullOrWhitespace(
			this.entityDefinition?.titleFunction))
		{
			return	StringHelper.transformToFunction(
				this.entityDefinition.titleFunction,
				this.pageContext)();
		}

		if (!AnyHelper.isNullOrWhitespace(
			this.entityDefinition?.titlePromise))
		{
			return	StringHelper.transformToDataPromise(
				this.entityDefinition.titlePromise,
				this.pageContext);
		}

		if (this.entityDefinition?.summaryDataPaths?.length > 0)
		{
			const summaryTitle =
				this.entityDefinition?.getSummaryDataValue(
					this.entityInstance,
					0);

			return !AnyHelper.isNullOrEmpty(summaryTitle)
				? summaryTitle
				: this.entityType.displayName;
		}

		return this.entityType?.displayName;
	}

	/**
	 * Handles required business rules for diplay following an entity save.
	 * This will update the information menu items and rule violations.
	 *
	 * @async
	 * @memberof EntityInstanceComponent
	 */
	public async entityInstanceSaved(): Promise<void>
	{
		this.entityInstance = await this.entityInstanceApiService.get(this.id);
		this.updateLastSavedData();
		this.entityIdentifier = await this.getEntityIdentifier();
		this.setupInformationMenuItems(this.pageContext);
		EventHelper.dispatchRedrawDashboardEvent();
		await this.setEntityInstanceViolations();
		this.resetOperationButtonBar();

		FormlyHelper.fireViewCheck(
			this.formlyEntityLayout,
			true);

		DocumentHelper.setElementDisplay(
			AppConstants.cssClasses.entityLayoutMask);

		EventHelper.dispatchReloadProductSettingsEvent();
	}

	/**
	 * This function is used to save the data in the last database loaded
	 * or saved entity instance. This data is then used for comparisons
	 * to the current entity form to validate if page changes are allowed
	 * or similar business rules.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public updateLastSavedData(): void
	{
		this.databaseEntityInstance =
			cloneDeep(this.entityInstance);
	}

	/**
	 * Forces the operation button bar to reload and handle business logic
	 * such as enabled or disabled.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public resetOperationButtonBar(): void
	{
		const existingOperationGroupName: string = this.operationGroupName;
		this.operationGroupName = null;
		this.operationGroupRedraw = true;

		setTimeout(
			() =>
			{
				this.operationGroupName = existingOperationGroupName;
				this.operationGroupRedraw = false;
			});
	}

	/**
	 * Handles the tab selected event.
	 * This methed is used to set the tab selected as displayed and set the
	 * other tabs as visually hidden.
	 *
	 * @param {any} event
	 * The event sent from the observed selected tab event.
	 * @memberof EntityInstanceComponent
	 */
	public tabSelected(
		event: any): void
	{
		let tabCount: number = 0;
		this.formlyEntityLayout
			.forEach(
				(fieldConfig: FormlyFieldConfig) =>
				{
					if (FormlyHelper.isTabWrapper(fieldConfig))
					{
						fieldConfig.templateOptions.visible =
							tabCount === event.index;
						tabCount++;
					}
				});

		this.activeTabItem = this.tabItems[event.index];

		this.activeTabItemIndex = event.index;
		this.updateUrlQuery();

		if (this.activeTabItem.items?.length > 0)
		{
			this.activeSectionItem = this.activeTabItem.items[0];
		}
	}

	/**
	 * Updates the url query string upon entity type group
	 * and the defined query parameters previously loaded
	 * if existing.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public updateUrlQuery(): void
	{
		const queryParameters =
			<object>
			{
				layoutType: this.layoutType,
				activeTabIndex: this.activeTabItemIndex,
				activeDrawerComponent: this.activeDrawerComponent,
				activeDrawerItemDescription: this.activeDrawerItemDescription,
			};

		this.location
			.replaceState(
				this.router
					.createUrlTree(
						[],
						<UrlCreationOptions>
						{
							replaceUrl: true,
							queryParams: {
								routeData:
									ObjectHelper.mapRouteData(queryParameters)
							}
						})
					.toString());
	}

	/**
	 * Sets the entity form component property to the sent instance.
	 *
	 * @memberof EntityInstanceComponent
	 */
	public updateFormInstance(
		entityFormComponent: EntityFormComponent): void
	{
		this.entityFormComponent = entityFormComponent;
	}

	/**
	 * Loads and sets the local entity instance rule violations property.
	 *
	 * @async
	 * @memberof EntityInstanceComponent
	 */
	public async setEntityInstanceViolations(): Promise<void>
	{
		this.entityInstanceRuleViolations =
			await this.ruleService.getPopulatedRuleViolations(
				`InstanceId eq ${this.id}`,
				`RuleDefinition.Order ${AppConstants.sortDirections.ascending}`,
				0,
				AppConstants.dataLimits.maxResultSet);

		EventHelper.dispatchRefreshBadgePromiseEvent(
			DynamicComponentLookup.supportedTypes.rulesComponent,
			DynamicComponentLookup.targetComponents.utilityMenuComponent);
		EventHelper.dispatchRefreshComponentEvent(
			DynamicComponentLookup.supportedTypes.rulesComponent,
			DynamicComponentLookup.targetComponents.utilityMenuComponent);
	}

	/**
	 * This will find and set the provided value to the formly field config with
	 * a key matching the sent fieldKey into the formly layout.
	 *
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @param {any} fieldValue
	 * The value of the field to set.
	 * @param {boolean} fireValidityChange
	 * Executes the validity of the form if true, otherwise the
	 * validity is skipped.
	 * @memberof EntityInstanceComponent
	 */
	public setFieldValue(
		fieldKey: string,
		fieldValue: any = AppConstants.empty,
		fireValidityChange: boolean = false): void
	{
		FormlyHelper.setFieldValue(
			this.formlyEntityLayout,
			fieldKey,
			fieldValue,
			fireValidityChange);
	}

	/**
	 * This will find the existing formly field config with a key
	 * matching the sent fieldKey.
	 *
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @memberof EntityInstanceComponent
	 * @returns {FormlyFieldConfig}
	 * If found, this will return the field config with the matching key.
	 */
	public getFieldConfig(
		fieldKey: string): FormlyFieldConfig
	{
		const fields: FormlyFieldConfig[] =
			FormlyHelper.getMatchingFieldConfigurations(
				this.formlyEntityLayout,
				fieldKey);

		return fields.length > 0
			? fields[0]
			: null;
	}

	/**
	 * This will find the existing formly field config with a key
	 * matching the sent fieldKey.
	 *
	 * @param {string} fieldKey
	 * The key of the field to acquire.
	 * @memberof EntityInstanceComponent
	 * @returns {FormlyFieldConfig}
	 * If found, this will return the field config with the matching key.
	 */
	 public getMatchingFieldConfigurations(
		fieldKey: string): FormlyFieldConfig[]
	{
		return FormlyHelper.getMatchingFieldConfigurations(
			this.formlyEntityLayout,
			fieldKey);
	}

	/**
	 * Performs actions that prepare this entity instance
	 * for display based on a loaded schema and layout.
	 *
	 * @memberof EntityInstanceComponent
	 */
	private async setSchema(): Promise<void>
	{
		const entity: IEntity =
			await this.entityService
				.populateEntity(
					this.id,
					this.entityTypeGroup,
					this.layoutType);

		this.entityInstance = entity.entityInstance;
		this.updateLastSavedData();
		this.entityDefinition = new EntityDefinition(entity.entityDefinition);

		if (this.layoutType === AppConstants.layoutTypes.generated
			&& AnyHelper.isNullOrWhitespace(entity.entityLayout))
		{
			entity.entityLayout =
				<IEntityLayout>
				{
					jsonData: new JsonSchemaLayout()
						.generateDefaultLayout(
							this.entityDefinition.jsonEntityDefinition,
							this.isViewOnly)
				};
		}

		this.entityType = new EntityType(entity.entityType);
		this.entityVersion = entity.entityVersion;
		this.entityIdentifier = await this.getEntityIdentifier();
		this.operationGroupName = (this.isViewOnly)
			? null
			: this.entityDefinition.operationGroupName;
		this.pageContextOperationGroupName =
			this.entityDefinition.pageContextOperationGroupName;
		this.utilityMenuOperationGroupName =
			this.entityDefinition.utilityMenuOperationGroupName;
		this.informationMenuDisplayComponentInstanceName =
			this.entityDefinition.informationMenuDisplayComponentInstanceName;
		this.entityTopElementIdentifier =
			AppConstants.basePageSections.topIdentifier
				+ this.sectionIdentifier;

		const entitySecurityAccessPolicy: any =
			await this.entityService
				.getEntityAccessPolicies(
					entity.entityType.group,
					entity.entityInstance.id);

		this.entityLayout =
			new EntityLayout(entity.entityLayout);

		const formlyLayout: FormlyFieldConfig[] =
			this.entityLayout.getFormlyEntityLayout(
				this.pageContext,
				entitySecurityAccessPolicy?.data);

		this.formlyEntityLayout =
			await this.rulePresentationService.applyEntityInstanceRules(
				this.entityType.id,
				this.entityVersion.id,
				formlyLayout,
				this.pageContext);

		this.tabItems =
			this.entityLayout.getDataTabs(this.formlyEntityLayout);

		if (this.tabItems.length === 0)
		{
			this.sectionItems =
				this.entityLayout.getDataSections(this.formlyEntityLayout);
		}

		if (this.sectionItems.length > 0)
		{
			this.activeSectionItem = this.sectionItems[0];
		}
		else if (this.tabItems.length > 0)
		{
			let tabCount: number = 0;
			this.activeTabItem = this.tabItems[this.activeTabItemIndex];

			this.formlyEntityLayout
				.forEach(
					(fieldConfig: FormlyFieldConfig) =>
					{
						if (FormlyHelper.isTabWrapper(fieldConfig))
						{
							fieldConfig.templateOptions.visible =
								tabCount === this.activeTabItemIndex;
							tabCount++;
						}
					});

			if (!AnyHelper.isNull(this.activeTabItem.items)
				&& this.activeTabItem.items.length > 0)
			{
				this.activeSectionItem = this.activeTabItem.items[0];
			}
		}

		return Promise.resolve();
	}
}