/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiTokenLookup
} from '@api/api-token.lookup';
import {
	AppCanDeactivateGuard
} from '@shared/guards/app-can-deactivate.guard';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	HostListener,
	Injector,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig,
	FormlyFormOptions
} from '@ngx-formly/core';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityVersion
} from '@shared/interfaces/entities/entity-version.interface';
import {
	Location
} from '@angular/common';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Observable
} from 'rxjs';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

/* eslint-enable max-len */

@Component({
	selector: 'entity-form',
	templateUrl: './entity-form.component.html',
	styleUrls: ['./entity-form.component.scss'],
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing the form view a formly displayed entity instance.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class EntityFormComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AppCanDeactivateGuard}
 */
export class EntityFormComponent
implements OnInit, AppCanDeactivateGuard
{
	/**
	 * Creates an instance of an EntityFormComponent.
	 *
	 * @param {Injector} injector
	 * The injector that can be used for methods in the layout.
	 * @param {ActivityService} activityService
	 * The activity service used to save or delete and notify the user.
	 * @param {Location} location
	 * The location used for navigation following a delete.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used to handle crud actions
	 * for the entity instance.
	 * @param {ResolverService} resolver
	 * The resolver service used for display component providers.
	 * @memberof EntityFormComponent
	 */
	public constructor(
		public injector: Injector,
		public activityService: ActivityService,
		public location: Location,
		public entityInstanceApiService: EntityInstanceApiService,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the page context sent to associated context utilities
	 * and menus.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof EntityFormComponent
	 */
	@Input() public pageContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the entity display identifier. This is used
	 * for the page title and for messaging systems.
	 *
	 * @type {string}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityIdentifier: string;

	/**
	 * Gets or sets the entity type.
	 *
	 * @type {EntityType}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityType: EntityType;

	/**
	 * Gets or sets the entity version.
	 *
	 * @type {IEntityVersion}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityVersion: IEntityVersion;

	/**
	 * Gets or sets the entity type group.
	 *
	 * @type {string}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityTypeGroup: string;

	/**
	 * Gets or sets the formly form options.
	 *
	 * @type {FormlyFormOptions}
	 * @memberof EntityFormComponent
	 */
	@Input() public formlyOptions: FormlyFormOptions;

	/**
	 * Gets or sets the entity instance.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityFormComponent
	 */
	@Input()public entityInstance: IEntityInstance;

	/**
	 * Gets or sets the entity definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof EntityFormComponent
	 */
	@Input()public entityDefinition: EntityDefinition;

	/**
	 * Gets or sets the entity layout.
	 *
	 * @type {EntityLayout}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityLayout: EntityLayout;

	/**
	 * Gets or sets the entity instance rule violations.
	 *
	 * @type {IEntityInstanceRuleViolation[]}
	 * @memberof EntityFormComponent
	 */
	@Input() public entityInstanceRuleViolations:
		IEntityInstanceRuleViolation[] = [];

	/**
	 * Gets or sets the formly entity layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof EntityFormComponent
	 */
	@Input() public formlyEntityLayout: FormlyFieldConfig[] = [];

	/**
	 * Gets or sets the data for the entity as it exists
	 * in the database.
	 *
	 * @type {IEntityInstance}
	 * @memberof EntityFormComponent
	 */
	@Input() public databaseEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the event emitter to send this component instance to
	 * listening components.
	 *
	 * @type {EventEmitter<EntityFormComponent>}
	 * @memberof EntityFormComponent
	 */
	@Output() public componentInstance: EventEmitter<EntityFormComponent> =
		new EventEmitter<EntityFormComponent>();

	/**
	 * Gets or sets the shared api token lookup
	 * for injected services in formly layout functions.
	 *
	 * @type {ApiTokenLookup}
	 * @memberof EntityFormComponent
	 */
	public apiTokenLookup: ApiTokenLookup = ApiTokenLookup;

	/**
	 * Gets or sets the is saving value of this component.
	 *
	 * @type {boolean}
	 * @memberof EntityFormComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets the loading value of this component.
	 *
	 * @type {boolean}
	 * @memberof EntityFormComponent
	 */
	public loading: boolean = true;

	/**
	 * Handles the before unload event sent from the current window based
	 * on any action that will change the page.
	 *
	 * @memberof EntityFormComponent
	 * @returns {Observable<boolean> | boolean}
	 * The value that will allow the router to know if the data in this form
	 * is altered or not saved to the database. This implements the
	 * AppCanDeactivateGuard interface.
	 */
	@HostListener(
		WindowEventConstants.beforeUnloadEvent)
	public canDeactivate(): Observable<boolean> | boolean
	{
		return this.saving === false
			&& ObjectHelper.checkBusinessLogicEquality(
				this.databaseEntityInstance,
				this.entityInstance) === true ;
	}

	/**
	 * On initialization event.
	 * Configures this components display based on the sent entity
	 * information from the consuming component.
	 *
	 * @memberof EntityFormComponent
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNullOrWhitespace(
			this.entityInstance.resourceIdentifier)
			&& this.formlyEntityLayout.length > 0
			&& this.formlyEntityLayout[0].type !==
				FormlyConstants.customControls.customResourceIdentifier)
		{
			this.formlyEntityLayout =
				[
					<FormlyFieldConfig>
					{
						key: AppConstants.commonProperties.resourceIdentifier,
						type: FormlyConstants.customControls
							.customResourceIdentifier,
						templateOptions: {}
					},
					...this.formlyEntityLayout
				];
		}

		this.componentInstance.emit(this);
		this.loading = false;

		// Allow Formly to implement defaults and display values.
		setTimeout(
			() =>
			{
				EventHelper.dispatchSiteLayoutChangedEvent();
			},
			AppConstants.time.quarterSecond);
	}
}