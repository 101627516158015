/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';

/**
 * A class containing static helper methods
 * for api query and aggregate filters.
 *
 * @export
 * @class ApiFilterHelper
 */
export class ApiFilterHelper
{
	/**
	 * Returns a filter to use when gathering time based values
	 * based upon a pivot time value and a sent start and end date.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering values
	 * between two dates.
	 * @param {DateTime} startDate
	 * The start date upon which to create a filter for.
	 * @param {DateTime} endDate
	 * The end date upon which to create a filter for.
	 * @param {string} initialFilter
	 * If sent this will append the date based comparison to this existing
	 * initial filter.
	 * @returns {string}
	 * A set filter for use in gathering date based data between two
	 * data values using an api filter or query.
	 * @memberof ApiFilterHelper
	 */
	public static getBetweenDateFilter(
		pivotProperty: string,
		startDate: DateTime,
		endDate: DateTime,
		initialFilter: string = AppConstants.empty): string
	{
		return initialFilter
			+ ` ${pivotProperty} >= `
			+  `'${startDate.toISO()}' `
			+ `and ${pivotProperty} <= `
			+ `'${endDate.toISO()}'`;
	}

	/**
	 * Returns a filter to use when gathering time based values
	 * based upon a pivot time value and a sent date.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering 30 days of values.
	 * @param {DateTime} day
	 * The day upon which to create a filter for.
	 * @param {string} pivotComparator
	 * The comparator to use in this api filter such as >, <=, eq, etc.
	 * @param {string} initialFilter
	 * If sent this will append the date based comparison to this existing
	 * initial filter.
	 * @returns {string}
	 * A set filter for use in gathering date based data using
	 * an api filter or query.
	 * @memberof ApiFilterHelper
	 */
	public static getDateFilter(
		pivotProperty: string,
		day: DateTime,
		pivotComparator: string = '>',
		initialFilter: string = AppConstants.empty): string
	{
		return initialFilter
			+ ` ${pivotProperty} ${pivotComparator} '${day.toISO()}'`;
	}

	/**
	 * Returns a filter to use when matching data with values in the
	 * sent pivot property that exist in the sent string array of desired
	 * matches.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering values.
	 * @param {string[]} enumeration
	 * The sent array of matching values to include in this filter.
	 * @returns {string}
	 * A set filter for use in gathering data with values for the pivot
	 * property
	 * found in the sent array of values.
	 * @memberof ApiFilterHelper
	 */
	public static getEnumerationFilter(
		pivotProperty: string,
		enumeration: string[]): string
	{
		let filter: string = AppConstants.empty;
		enumeration.forEach((enumerable: string) =>
		{
			filter +=
				AnyHelper.isNullOrWhitespace(filter)
					? `${pivotProperty} eq '${enumerable}'`
					: ` or ${pivotProperty} eq '${enumerable}'`;
		});

		return `(${encodeURIComponent(filter)})`;
	}

	/**
	 * Returns a filter to use when gathering the last 30 days of values
	 * based upon a pivot time value and a sent day to get the last
	 * 30 days of data for.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering 30 days of values.
	 * @param {DateTime} day
	 * The day upon which to gather the last 30 days of values for.
	 * @param {number} numberOfDays
	 * The number amount of days.
	 * @param {string} initialFilter
	 * If sent this will append the date based comparison to this existing
	 * initial filter.
	 * @returns {string}
	 * A set filter for use in gathering thirty days of data using
	 * an api filter or query.
	 * @memberof ApiFilterHelper
	 */
	public static getLastNumberOfDaysFilter(
		pivotProperty: string,
		day: DateTime,
		numberOfDays: number,
		initialFilter: string = AppConstants.empty): string
	{
		const returnDate: DateTime =
			DateHelper
				.startOf(day)
				.minus(
					{
						day: numberOfDays
					});
		const daysAgo: string =
			returnDate.toISO();

		return initialFilter
			+ ` ${pivotProperty} >= '${daysAgo}'`;
	}

	/**
	 * Returns a filter to use when gathering the sent day's data values
	 * limited to midnight of the current day.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering the current
	 * day's values.
	 * @param {DateTime} day
	 * The day upon which to gather current day's values for.
	 * This should be a system JS date.
	 * @param {string} initialFilter
	 * If sent this will append the date based comparison to this existing
	 * initial filter.
	 * @returns {string}
	 * A set filter for use in gathering the current days data limited
	 * to occurrences past midnight and for use in anapi filter or query.
	 * @memberof ApiFilterHelper
	 */
	public static getStartOfDayFilter(
		pivotProperty: string,
		day: DateTime,
		initialFilter: string = AppConstants.empty): string
	{
		const returnDate: string =
			DateHelper.startOf(day).toISO();

		return initialFilter
			+ ` ${pivotProperty} >= '${returnDate}'`;
	}

	/**
	 * Returns a filter to use when matching data with values in the
	 * sent pivot property that exist in the sent string array of desired
	 * matches. This will allow wildcard or '*' matches.
	 *
	 * @static
	 * @param {string} pivotProperty
	 * The property name upon which to pivot when gathering values.
	 * @param {string[]} matches
	 * The sent array of wildcard or exact matches to include in this filter.
	 * @returns {string}
	 * A set filter for use in gathering data with values for the pivot
	 * property found in the sent array of wildcard or exact values.
	 * @memberof ApiFilterHelper
	 */
	public static getWildcardFilter(
		pivotProperty: string,
		matches: string): string
	{
		let filter: string = AppConstants.empty;
		const splitMatches =
			matches.split(AppConstants.characters.comma);

		splitMatches.forEach((enumerable: string) =>
		{
			const isWildcard: boolean =
				enumerable.indexOf(
					AppConstants.characters.asterisk) !== -1;
			const cleanedFilter: string =
				enumerable.replace(
					AppConstants.characters.asterisk,
					AppConstants.empty);
			const newFilter: string =
				isWildcard === true
					? `${pivotProperty}.Contains('${cleanedFilter}') eq true`
					: `${pivotProperty} eq '${cleanedFilter}'`;

			filter +=
				AnyHelper.isNullOrWhitespace(filter)
					? `(${newFilter})`
					: ` or (${newFilter})`;
		});

		return `(${encodeURIComponent(filter)})`;
	}

	/**
	 * Returns an enumerated filter of comma separated and quoted strings
	 * found in the sent string array.
	 *
	 * @static
	 * @param {string[]} array
	 * The array of strings to comma separate and add quote characters to.
	 * @param {string} array
	 * The character to quote this item with, this value defaults to a
	 * single quote.
	 * @returns {string}
	 * A set filter of comma separated and quoted strings for filter
	 * enumeration.
	 * @memberof ApiFilterHelper
	 */
	public static commaSeparatedStringValues(
		array: string[],
		quoteCharacter: string = '\''): string
	{
		return array
			.map((value) =>
				`${quoteCharacter}${encodeURIComponent(value)}`
					+ `${quoteCharacter}`)
			.join(',');
	}

	/**
	 * Given a set of entity types or a singular entity type, this will
	 * calculate and return a filter that can be used to filter by entity type.
	 *
	 * @static
	 * @param {EntityType | EntityType[]} entityType
	 * The set of entity types to refine into an entity type based filter.
	 * @returns {string}
	 * A filter that can be used when querying for type based logic.
	 * @memberof ApiFilterHelper
	 */
	public static getEntityTypeFilter(
		entityType: EntityType | EntityType[]): string
	{
		if (Array.isArray(entityType))
		{
			return entityType.map(
				(item: EntityType) =>
					`${AppConstants.commonProperties.typeId} eq ${item.id}`)
				.join(' or ');
		}
		else
		{
			return `TypeId eq ${entityType.id}`;
		}
	}

	/**
	 * Given a set of entity types, this will return the set of types
	 * that match the sent wildcard filter.
	 *
	 * @static
	 * @param {string[]} types
	 * The set of entity types to refine into a set of entity types that match
	 * the sent wildcard.
	 * @param {string} wildcardTypeFilter
	 * The wildcard type filter that will be used to find matching types based
	 * on an exact match or on starting with the sent filter.
	 * @returns {string[]}
	 * The list of entity types that match the sent entity type filter.
	 * @memberof ApiFilterHelper
	 */
	public static getWildcardEntityTypes(
		types: string[],
		wildcardTypeFilter: string): string[]
	{
		return types
			.filter((type: string) =>
				type === wildcardTypeFilter
					|| type.startsWith(`${wildcardTypeFilter}.`));
	}
}