<ng-template
	[ngIf]="to.actionMenuItems == null && to.addonMenuItems == null"
	[ngIfElse]="InputGroup">
	<input
		pInputText
		type="text"
		[class]="inputStyleClass"
		[formControl]="formControl"
		[formlyAttributes]="field" />
</ng-template>

<ng-template #InputGroup>
	<div class="p-grid p-fluid">
		<div class="p-inputgroup">
			<span *ngFor="let addonMenuItem of to.addonMenuItems"
				class="p-inputgroup-addon">
				<i [class]="addonMenuItem.getCustomIcon(formControl.value)"
					[style]="addonMenuItem.getCustomStyle(formControl.value)">
				</i>
			</span>
			<span class="input-group">
				<input
					pInputText
					type="text"
					[class]="inputStyleClass"
					[formControl]="formControl"
					[formlyAttributes]="field" />
			</span>
			<span *ngFor="let actionMenuItem of to.actionMenuItems">
				<button
					type="button"
					pButton pRipple
					[icon]="actionMenuItem.icon"
					styleClass="p-button-warn"
					[disabled]="actionMenuItem.disabled"
					(click)="actionMenuItem.command()">
				</button>
			</span>
		</div>
	</div>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>