/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AbstractControl,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators
} from '@angular/forms';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	FileDirective
} from '@shared/directives/file.directive';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	FileProgressType
} from '@shared/constants/enums/file-progress-type.enum';
import {
	FileService
} from '@shared/services/files/file.service';
import {
	FileUpload
} from 'primeng/fileupload';
import {
	HttpHeaderResponse,
	HttpProgressEvent
} from '@angular/common/http';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	IFileProgress
} from '@shared/interfaces/files/file-progress.interface';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';

/* eslint-enable max-len */

@Component({
	selector: 'app-edit-file',
	templateUrl: './edit-file.component.html',
	styleUrls: ['./edit-file.component.scss']
})
/**
 * A class for editing files (files).
 *
 * @export
 * @class EditFileComponent
 * @extends {FileDirective}
 * @implements {OnInit}
 */
export class EditFileComponent
	extends FileDirective
	implements OnInit
{
	/**
	 * Initializes the EditFileComponent.
	 *
	 * @param {ActivityService} activityService
	 * The activity service for this class.
	 * @param {FileService} service
	 * A new instance of the file service for this class.
	 * @memberof EditFileComponent
	 */
	public constructor(
		private readonly activityService: ActivityService,
		private readonly fileService: FileService)
	{
		super();
	}

	/**
	 * Gets the file entity selected to edit.
	 *
	 * @type {IFileEntity}
	 * @memberof EditFileComponent
	 */
	@Input()
	public originalFileEntity: IFileEntity;

	/**
	 * Gets or sets the document updated event emitter.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof EditFileComponent
	 */
	@Output()
	public updated: EventEmitter<string> = new EventEmitter<string>();

	/**
	 * Gets or sets the file uploader.
	 *
	 * @type {FileUpload}
	 * @memberof EditFileComponent
	 */
	@ViewChild('fileUploader')
		public fileUploader: FileUpload;

	/**
	 * Gets or sets the edit version of the file entity. This
	 * makes un-doing changes reliable and easy.
	 *
	 * @type {IFileEntity}
	 * @memberof EditFileComponent
	 */
	public editedFileEntity: IFileEntity;

	/**
	 * Gets or sets the progress message.
	 *
	 * @type {string}
	 * @memberof EditFileComponent
	 */
	public progressMessage: string = 'Updating...';

	/**
	 * Gets or sets a value indicating whether to
	 * display the edit documentuemnt form.
	 *
	 * @type {boolean}
	 * @memberof EditFileComponent
	 */
	public editDocumentVisible: boolean = true;

	/**
	 * Gets or sets a value indicating whether the
	 * orginal uploaded file needs to be changed.
	 *
	 * @type {boolean}
	 * @memberof EditFileComponent
	 */
	public changeFile: boolean = false;

	/**
	 * Gets or sets a value indicating whether the
	 * uploader form field should display.
	 *
	 * @type {boolean}
	 * @memberof EditFileComponent
	 */
	public showUploader: boolean = false;

	/**
	 * Gets or sets the name form control.
	 *
	 * @type {FormControl}
	 * @memberof EditFileComponent
	 */
	public nameControl: FormControl =
		new FormControl(
			AppConstants.empty,
			[
				Validators.required,
				Validators.maxLength(128)
			]);

	/**
	 * Gets or sets the form control for the category field.
	 *
	 * @type {FormControl}
	 * @memberof EditFileComponent
	 */
	public subTypeControl: FormControl =
		new FormControl(AppConstants.empty);

	/**
	 * The file extensions
	 *
	 * @type {string}
	 * @memberof EditFileComponent
	 */
	public extension: string = this.defaultFileExtension;

	/**
	 * Gets or sets the url form control for
	 * referenced files.
	 *
	 * @type {FormControl}
	 * @memberof EditFileComponent
	 */
	public urlControl: FormControl =
		new FormControl(AppConstants.empty);

	/**
	 * Gets or sets the description form control.
	 *
	 * @type {FormControl}
	 * @memberof EditFileComponent
	 */
	public descriptionControl: FormControl =
		new FormControl(
			AppConstants.empty,
			[
				Validators.maxLength(256)
			]);

	/**
	 * Gets or sets the form group.
	 *
	 * @type {FormGroup}
	 * @memberof EditFileComponent
	 */
	public editForm: FormGroup =
		new FormGroup(
			{
				'nameControl': this.nameControl,
				'urlControl': this.urlControl,
				'descriptionControl': this.descriptionControl,
				'subTypeControl': this.subTypeControl
			},
			{
				validators: (formGroup: FormGroup): ValidationErrors | null =>
				{
					const invalidErrors: ValidationErrors =
						{
							invalid: true,
							valid: false
						};

					if (AnyHelper.isNull(this.originalFileEntity))
					{
						return invalidErrors;
					}

					if (this.changeFile
						&& this.storageType !== StorageType.Referenced)
					{
						if (this.fileUploader?.hasFiles())
						{
							return null;
						}
					}

					const subType: AbstractControl = formGroup
						.get('subTypeControl');

					const name: AbstractControl = formGroup
						.get('nameControl');

					const description: AbstractControl = formGroup
						.get('descriptionControl');

					const url: AbstractControl = formGroup
						.get('urlControl');

					const noErrors: boolean = !(
						(name.invalid && name.dirty)
						|| (url.invalid && url.dirty)
						|| (description.invalid && description.dirty)
						|| (subType.invalid && subType.dirty));

					return this.changed() && noErrors
						? null
						: invalidErrors;
				}
			});

	/**
	 * A value indicating if updating the file contents should be disabled.
	 *
	 * @type {boolean}
	 * @memberof EditFileComponent
	 */
	public changeFileDisabled: boolean;

	/**
	 * Gets the storage type
	 *
	 * @type {StorageType}
	 * @memberof EditFileComponent
	 */
	public get storageType(): StorageType
	{
		return this.originalFileEntity
			.data
			.storage
			.storageType;
	}

	/**
	 * Gets the file category.
	 *
	 * @type {string}
	 * @memberof EditFileComponent
	 */
	public get fileCategory(): string
	{
		return this.categories
			.find((element: FileCategory) =>
				element.value === this.originalFileEntity
					.entityType)
			.label;
	}

	/**
	 * Gets the file entity type group.
	 *
	 * @type {string}
	 * @memberof EditFileComponent
	 */
	public get fileTypeGroup(): string
	{
		return this.categories
			.find((element: FileCategory) =>
				element.value === this.originalFileEntity
					.entityType)
			.entityType
			.group;
	}

	/**
	 * Gets or sets a value indicating if the update
	 * was successful.
	 *
	 * @type {boolean}
	 * @memberof EditFileComponent
	 */
	private updateSuccess: boolean = false;

	/**
	 * Handles the intialization event of this component.
	 *
	 * @memberof EditFileComponent
	 */
	public ngOnInit(): void
	{
		this.editedFileEntity =
			JSON.parse(
				JSON.stringify(
					this.originalFileEntity));
		this.fileEntity = this.originalFileEntity;

		try
		{
			const reviewState: string = this.fileEntity
				.data
				.metadata
				.custom
				.reviewState;

			this.changeFileDisabled =
				reviewState === 'Approved'
					|| reviewState === 'Rejected';
		}
		catch
		{
			this.changeFileDisabled = false;
		}

		delete (<any>this.editedFileEntity.data).userName;
		delete (<any>this.editedFileEntity.data).changeDateTime;

		this.nameControl
			.setValue(this.editedFileEntity
				.data
				.name);

		if (this.storageType === StorageType.Referenced)
		{
			this.urlControl
				.setValue(
					this.editedFileEntity
						.data
						.storage
						.location);

			this.urlControl.setValidators(
				[
					Validators.required,
					Validators.pattern('^(http|https)://.+/.+$')
				]);
		}

		this.descriptionControl
			.setValue(
				this.editedFileEntity
					.data
					.description);

		this.extension = this.editedFileEntity
			.data
			.metadata
			.extension;

		this.subTypes = this.categories
			.find(category =>
				category.entityType.name === this.fileEntity.entityType)
			.subTypes ?? [];

		if (this.subTypes.length > 0)
		{
			this.subTypeControl.enable();
			this.subTypeControl.setValue(this.subType);
			this.subTypeControl.addValidators(Validators.required);
			this.subTypeControl.updateValueAndValidity();
		}
		else
		{
			this.subTypeControl.setValue(null);
			this.subTypeControl.disable();
		}

		this.editForm.updateValueAndValidity();
	}

	/**
	 * Checks if a control is invalid.
	 *
	 * @param {string} controlName
	 * The name of the control to check.
	 * @returns {boolean}
	 * Returns true if it is invalid
	 * @memberof EditFileComponent
	 */
	public invalid(controlName: string): boolean
	{
		const control = this.editForm
			.get(controlName);

		return control.invalid;
	}

	/**
	 * Handles the file changed event updating\
	 * names anbd extensions.
	 *
	 * @param {string} changeType
	 * the file changed type of event
	 * @memberof EditFileComponent
	 */
	public fileChanged(changeType: string): void
	{
		const originalExt: string =
			this.originalFileEntity
				.data
				.metadata
				.extension;

		if (changeType === 'selected')
		{
			this.setExtension(
				this.fileUploader
					.files[0]
					.name);
		}
		else
		{
			this.setExtension(originalExt);
		}

		this.editForm
			.updateValueAndValidity();
	}

	/**
	 * Handles the file url changed event.
	 *
	 * @memberof EditFileComponent
	 */
	public fileUrlChanged(): void
	{
		this.setExtension(this.urlControl.value);

		this.editedFileEntity
			.data
			.storage
			.location = this.urlControl.value;

		this.editForm
			.updateValueAndValidity();
	}

	/**
	 * Handles the file name changed event.
	 *
	 * @memberof EditFileComponent
	 */
	public nameChanged(): void
	{
		this.editedFileEntity
			.data
			.name = this.nameControl.value;
	}

	/**
	 * Handles the file sub stype changed event.
	 *
	 * @memberof EditFileComponent
	 */
	public subTypeChanged()
	{
		this.editedFileEntity
			.data
			.subType = this.subTypeControl.value;
	}

	/**
	 * Handles the description name changed event.
	 *
	 * @memberof EditFileComponent
	 */
	public descriptionChanged(): void
	{
		this.editedFileEntity
			.data
			.description = this.descriptionControl.value;
	}

	/**
	 * Handles the update document button click event.
	 *
	 * @memberof EditFileComponent
	 */
	public onUpdateClicked(): void
	{
		if (this.editForm.invalid)
		{
			return;
		}

		let file: File;
		if (this.storageType !== StorageType.Referenced
			&& this.changeFile)
		{
			file = this.fileUploader.hasFiles()
				? this.fileUploader.files[0]
				: null;

			if (!AnyHelper.isNull(file))
			{
				this.editedFileEntity
					.data
					.metadata
					.sizeInBytes = file.size;
			}
		}

		this.editDocumentVisible = false;
		this.progressVisible = true;

		const promise: Promise<void> =
			new Promise<void>((resolve, reject) =>
			{
				this.fileService
					.updateFile(
						this.editedFileEntity,
						this.fileTypeGroup,
						file,
						false,
						this.changeFile)
					.subscribe({
						next:
							(progress: IFileProgress) =>
							{
								this.handleProgress(progress);

								if (progress.type === FileProgressType.Error)
								{
									reject(progress.value.message);
								}
							},
						error:
							(error: any) => {
								this.displayError(error);
								reject(error);
							},
						complete:
							() => resolve()
					});
			});

		this.activityService.handleActivity<void>(
			new Activity<void>(
				promise,
				'<strong>Updating file</strong> '
					+ this.editedFileEntity.data.name,
				'<strong>Updated file</strong> '
					+ this.editedFileEntity.data.name,
				`${this.editedFileEntity.data.name} was successfully updated.`,
				`Error updating ${this.originalFileEntity.data.name}.`));
	}

	/**
	 * Sets the extenion if it can find a valid match.
	 *
	 * @private
	 * @param {string} fileName
	 * The file name with an extension.
	 * @memberof EditFileComponent
	 */
	private setExtension(fileName: string): void
	{
		const extension = FileHelper
			.findKnownExtension(fileName);

		if (AnyHelper.isNull(extension))
		{
			this.extension = this.defaultFileExtension;
		}
		else
		{
			this.extension = extension;

			this.editedFileEntity
				.data
				.metadata
				.extension = extension;
		}
	}

	/**
	 * Handles the update progress events.
	 *
	 * @private
	 * @param {IFileProgress} progress
	 * The progress event report.
	 * @memberof EditFileComponent
	 */
	private handleProgress(progress: IFileProgress): void
	{
		const updating: string = this.progressMessage;
		const uploadingNewFile: string = 'Uploading new file...';

		switch (progress.type)
		{
			case FileProgressType.UpdatingEntity:
				this.progressMessage = updating;
				break;

			case FileProgressType.EntityUpdated:
				this.progressMessage = updating;
				this.updateSuccess = true;
				break;

			case FileProgressType.UploadStarted:
				this.progressMode = 'progressBar';
				this.progressMessage = uploadingNewFile;
				break;

			case FileProgressType.UploadProgress:
				this.progressMode = 'progressBar';
				this.progressMessage = uploadingNewFile;

				const progressEvent: HttpProgressEvent
					= progress.value;

				this.progressAmount = Math
					.round((progressEvent.loaded / progressEvent.total) * 100);

				if (progressEvent.loaded === progressEvent.total)
				{
					setTimeout(
						() =>
						{
							this.progressMode = 'spinner';
							this.progressMessage = 'Finishing up...';
						},
						500);
				}
				break;

			case FileProgressType.ResponseStatus:
				this.updateSuccess = (<HttpHeaderResponse>progress.value).ok;
				break;

			case FileProgressType.Response:
				if (!this.updateSuccess)
				{
					this.displayError(
						progress.value.message);
				}
				break;

			case FileProgressType.Error:
				this.displayError(
					progress.value.message);
				break;

			case FileProgressType.Complete:

				this.progressMessage = 'Done!';
				this.progressMode = 'done';
				this.updated.emit();

				setTimeout(
					() =>
					{
						this.changeDisplayMode.emit(
							this.viewModes.list);
					},
					AppConstants.time.twoSeconds);
				break;

			default:
				if (progress.type === FileProgressType.Unhandled)
				{
					throw new Error(
						'Unhandled file progress response: '
							+ progress.message);
				}
				break;
		}
	}

	/**
	 * Handles the progress component display of any errors
	 *
	 * @private
	 * @param {string} message
	 * The error message to didplay.
	 * @memberof EditFileComponent
	 */
	private displayError(message: string): void
	{
		setTimeout(() =>
		{
			this.progressMessage = null;
			this.errorMessage = message;
			this.progressMode = 'error';
		}, 600);
	}

	/**
	 * Determines if the file instance is in
	 * an edited state.
	 *
	 * @private
	 * @memberof EditFileComponent
	 * @returns {boolean}
	 * The result of the evaluation. True if in an edited state.
	 */
	private changed(): boolean
	{
		if (AnyHelper.isNull(this.editedFileEntity))
		{
			return false;
		}

		if (this.subTypeControl.enabled
			&& this.originalFileEntity
				.data
				.subType !== this.subTypeControl.value)
		{
			return true;
		}

		if (this.originalFileEntity
			.data
			.name !== this.nameControl.value)
		{
			return true;
		}

		if (!AnyHelper.isNullOrWhitespace(
			this.originalFileEntity.data
				.description)
			|| !AnyHelper.isNullOrWhitespace(
				this.descriptionControl.value))
		{
			if (this.originalFileEntity
				.data
				.description !== this.descriptionControl.value)
			{
				return true;
			}
		}

		if (this.storageType === StorageType.Referenced)
		{
			if (this.originalFileEntity
				.data
				.storage
				.location !== this.urlControl.value)
			{
				return true;
			}
		}

		return false;
	}
}