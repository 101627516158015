/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseStoredVariableService
} from '@shared/services/base/base-stored-variable.service';
import {
	EntityInstanceRuleViolation
} from '@shared/implementations/entities/entity-instance-rule-violation';
import {
	EntityInstanceRuleViolationApiService
} from '@api/services/entities/entity-instance-rule-violation.api.service';
import {
	EntityInstanceRuleViolationOverrideApiService
} from '@api/services/entities/entity-instance-rule-violation-override.api.service';
import {
	IEntityInstanceRuleViolation
} from '@shared/interfaces/entities/entity-instance-rule-violation.interface';
import {
	IEntityInstanceRuleViolationOverride
} from '@shared/interfaces/entities/entity-instance-rule-violation-override.interface';
import {
	Injectable
} from '@angular/core';
import {
	IRuleDefinition
} from '@shared/interfaces/rules/rule-definition.interface';
import {
	IRulePresentationDefinition
} from '@shared/interfaces/rules/rule-presentation-definition.interface';
import {
	IRulePresentationLogicDefinition
} from '@shared/interfaces/rules/rule-presentation-logic-definition.interface';
import {
	IRuleViolationWorkflowActionDefinition
} from '@shared/interfaces/rules/rule-violation-workflow-action-definition.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ISecurityGroupRuleDefinitionViolationOverride
} from '@shared/interfaces/security/security-group-rule-definition-violation-override.interface';
import {
	IStoredVariableDefinition
} from '@shared/interfaces/application-objects/stored-variable-definition';
import {
	IWorkflowActionDefinitions
} from '@shared/interfaces/workflow/workflow-action-definitions.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	RuleDefinitionApiService
} from '@api/services/rules/rule-definition.api.service';
import {
	RuleHelper
} from '@shared/helpers/rule.helper';
import {
	RulePresentationDefinitionApiService
} from '@api/services/rules/rule-presentation-definition.api.service';
import {
	RulePresentationLogicDefinitionApiService
} from '@api/services/rules/rule-presentation-logic-definition.api.service';
import {
	RuleViolationWorkflowActionDefinitionApiService
} from '@api/services/rules/rule-violation-workflow-action-definition.api.service';
import {
	SecurityGroupRuleDefinitionViolationOverrideApiService
} from '@api/services/security/security-group-rule-definition-violation-override.api.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	WorkflowActionDefinitionsApiService
} from '@api/services/workflow/workflow-action-definitions.api.service';

/* eslint-enable max-len */

/**
 * A singleton service representing shared interactions with the rule schema
 * api.
 *
 * @export
 * @extends {BaseStoredVariableService}
 * @class RuleService
 */
@Injectable({
	providedIn: 'root'
})
export class RuleService
	extends BaseStoredVariableService
{
	/**
	 * Creates an instance of a rule service.
	 *
	 * @param {SessionService} sessionService
	 * The session service used for authorizations in this service.
	 * @param {SecurityGroupRuleDefinitionViolationOverrideApiService}
	 * securityGroupRuleDefinitionViolationOverrideApiService
	 * The security group rule definition violation override service used
	 * for permissions checks in this service.
	 * @param {RuleDefinitionApiService} ruleDefinitionApiService
	 * The rule definition api service used in this service.
	 * @param {RulePresentationDefinitionApiService}
	 * rulePresentationDefinitionApiService
	 * The rule presentation definition api service used in this service.
	 * @param {RulePresentationLogicDefinitionApiService}
	 * rulePresentationDefinitionLogicApiService
	 * The rule presentation logic definition api service used in this service.
	 * @param {RuleViolationWorkflowActionDefinitionApiService}
	 * ruleViolationWorkflowActionDefinitionApiService
	 * The rule violation workflow action definition api service used in
	 * this service.
	 * @param {EntityInstanceRuleViolationApiService}
	 * entityInstanceRuleViolationApiService
	 * The entity instance rule violation api service used in
	 * this service.
	 * @param {EntityInstanceRuleViolationOverrideApiService}
	 * entityInstanceRuleViolationOverrideApiService
	 * The entity instance rule violation override api service used in
	 * this service.
	 * @param {WorkflowActionDefinitionsApiService}
	 * workflowActionDefinitionApiService
	 * The workflow action definition api service used in this service.
	 * @memberof RuleService
	 */
	public constructor(
		public sessionService: SessionService,
		public securityGroupRuleDefinitionViolationOverrideApiService:
			SecurityGroupRuleDefinitionViolationOverrideApiService,
		public ruleDefinitionApiService:
			RuleDefinitionApiService,
		public rulePresentationDefinitionApiService:
			RulePresentationDefinitionApiService,
		public rulePresentationLogicDefinitionApiService:
			RulePresentationLogicDefinitionApiService,
		public ruleViolationWorkflowActionDefinitionApiService:
			RuleViolationWorkflowActionDefinitionApiService,
		public entityInstanceRuleViolationApiService:
			EntityInstanceRuleViolationApiService,
		public entityInstanceRuleViolationOverrideApiService:
			EntityInstanceRuleViolationOverrideApiService,
		public workflowActionDefinitionApiService:
			WorkflowActionDefinitionsApiService)
	{
		super();
		this.storedVariables.forEach(
			(storedVariable: IStoredVariableDefinition) =>
			{
				storedVariable.apiService.ruleService = this;
			});
	}

	/**
	 * Gets or sets the local storage for rule definitions.
	 *
	 * @type {IRuleDefinition[]}
	 * @memberof RuleService
	 */
	public ruleDefinitions:
		IRuleDefinition[] = [];

	/**
	 * Gets or sets the local storage for rule presentation definitions.
	 *
	 * @type {IRulePresentationDefinition[]}
	 * @memberof RuleService
	 */
	public rulePresentationDefinitions:
		IRulePresentationDefinition[] = [];

	/**
	 * Gets or sets the local storage for rule presentation logic definitions.
	 *
	 * @type {IRulePresentationLogicDefinition[]}
	 * @memberof RuleService
	 */
	public rulePresentationLogicDefinitions:
		IRulePresentationLogicDefinition[] = [];

	/**
	 * Gets or sets the local storage for rule violation workflow action
	 * definitions.
	 *
	 * @type {IRuleViolationWorkflowActionDefinition[]}
	 * @memberof RuleService
	 */
	public ruleViolationWorkflowActionDefinitions:
		IRuleViolationWorkflowActionDefinition[] = [];

	/**
	 * Gets or sets the local storage for workflow action definitions.
	 *
	 * @type {IWorkflowActionDefinitions[]}
	 * @memberof RuleService
	 */
	public workflowActionDefinitions:
		IWorkflowActionDefinitions[] = [];

	/**
	 * Gets or sets the local storage for security group based rule violation
	 * override permissions.
	 *
	 * @type {ISecurityGroupRuleDefinitionViolationOverride[]}
	 * @memberof RuleService
	 */
	public securityGroupRuleDefinitionViolationOverrides:
		ISecurityGroupRuleDefinitionViolationOverride[] = [];

	/**
	 * Gets or sets the storage variables that will be stored in this
	 * singleton service.
	 *
	 * @type {IStoredVariableDefinition[]}
	 * @memberof RuleService
	 */
	public storedVariables: IStoredVariableDefinition[] =
	[
		{
			storageProperty:
				AppConstants.apiControllers.ruleDefinitions,
			apiService: this.ruleDefinitionApiService
		},
		{
			storageProperty:
				AppConstants.apiControllers.rulePresentationDefinitions,
			apiService: this.rulePresentationDefinitionApiService
		},
		{
			storageProperty:
				AppConstants.apiControllers.rulePresentationLogicDefinitions,
			apiService: this.rulePresentationLogicDefinitionApiService
		},
		{
			storageProperty:
				AppConstants.apiControllers
					.ruleViolationWorkflowActionDefinitions,
			apiService: this.ruleViolationWorkflowActionDefinitionApiService
		},
		{
			storageProperty:
				AppConstants.apiControllers.workflowActionDefinitions,
			apiService: this.workflowActionDefinitionApiService
		},
		{
			storageProperty:
				AppConstants.apiControllers
					.securityGroupRuleDefinitionViolationOverrides,
			apiService:
				this.securityGroupRuleDefinitionViolationOverrideApiService
		}
	];

	/**
	 * Populates and returns the set of rule violations matching the
	 * sent query parameters.
	 *
	 * @async
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @returns {Promise<EntityInstanceRuleViolation>}
	 * An awaitable promise that returns list of populated entity instance
	 * rule violations.
	 * @memberof RuleService
	 */
	public async getPopulatedRuleViolations(
		filter: string,
		orderBy: string,
		offset: number,
		limit: number): Promise<IEntityInstanceRuleViolation[]>
	{
		await this.setStoredVariables();

		const entityInstanceRuleViolations: IEntityInstanceRuleViolation[] =
			<IEntityInstanceRuleViolation[]>
			await this.entityInstanceRuleViolationApiService
				.query(
					filter,
					orderBy,
					offset,
					limit);
		const entityInstanceRuleViolationOverrides =
			<IEntityInstanceRuleViolationOverride[]>
			await this.entityInstanceRuleViolationOverrideApiService
				.query(
					filter,
					AppConstants.empty,
					0,
					AppConstants.dataLimits.maxResultSet);

		entityInstanceRuleViolations.forEach(
			(entityInstanceRuleViolation:
				IEntityInstanceRuleViolation) =>
			{
				entityInstanceRuleViolation.ruleDefinition =
					{
						...this.ruleDefinitions.find(
							(ruleDefinition: IRuleDefinition) =>
								ruleDefinition.id ===
									entityInstanceRuleViolation
										.ruleDefinitionId)
					};

				entityInstanceRuleViolation
					.ruleDefinition
					.ruleViolationWorkflowActionDefinitions =
						this.getRuleViolationWorkflowActions(
							entityInstanceRuleViolation,
							entityInstanceRuleViolationOverrides);
			});

		return entityInstanceRuleViolations;
	}

	/**
	 * Given a sent entity type and version, this will return the fully
	 * populated set of rule presentation definitions ready for presentation
	 * logic definition decorations.
	 *
	 * @async
	 * @param {number} entityTypeId
	 * The entity type id used to gather associated rule definitions.
	 * @param {number} entityVersionId
	 * The entity version id used to gather associated rule definitions.
	 * @returns {Promise<IRulePresentationDefinition>}
	 * An awaitable promise that returns a set of populated rule presentation
	 * definitions ready for the rule presentation logic decoration.
	 * @memberof RuleService
	 */
	public async getPopulatedRulePresentationDefinitions(
		entityTypeId: number,
		entityVersionId: number): Promise<IRulePresentationDefinition[]>
	{
		await this.setStoredVariables();

		const ruleDefinitions: IRuleDefinition[] =
			this.ruleDefinitions.filter(
				(ruleDefinition: IRuleDefinition) =>
					ruleDefinition.entityTypeId === entityTypeId
						&& ruleDefinition.entityVersionId === entityVersionId);

		if (ruleDefinitions.length === 0)
		{
			return [];
		}

		const ruleDefinitionIds: number[] =
			ruleDefinitions.map(
				(ruleDefinition: IRuleDefinition) =>
					ruleDefinition.id);

		const rulePresentationDefinitions: IRulePresentationDefinition[] =
			this.rulePresentationDefinitions.filter(
				(rulePresentationDefinition: IRulePresentationDefinition) =>
					ruleDefinitionIds.indexOf(
						rulePresentationDefinition.definitionId) !== -1)
				.map(
					(rulePresentationDefinition: IRulePresentationDefinition) =>
					{
						rulePresentationDefinition.ruleDefinition =
							ruleDefinitions.find(
								(ruleDefinition: IRuleDefinition) =>
									ruleDefinition.id ===
										rulePresentationDefinition
											.definitionId);
						rulePresentationDefinition
							.ruleDefinition
							.rulePresentationDefinitions =
								this.rulePresentationDefinitions.filter(
									(presentationDefinition:
										IRulePresentationDefinition) =>
										presentationDefinition
											.definitionId ===
												rulePresentationDefinition
													.definitionId);
						rulePresentationDefinition
							.rulePresentationLogicDefinition =
								this.rulePresentationLogicDefinitions.find(
									(presentationLogic:
										IRulePresentationLogicDefinition) =>
										presentationLogic.id ===
											rulePresentationDefinition
												.presentationLogicDefinitionId);

						return rulePresentationDefinition;
					})
				.sort(
					(itemOne: IRulePresentationDefinition,
						itemTwo: IRulePresentationDefinition) =>
						ObjectHelper.sortByPropertyValue(
							itemOne,
							itemTwo,
							AppConstants.commonProperties.order));

		return rulePresentationDefinitions;
	}

	/**
	 * Gets the set of unique data key based rule presentation definitions
	 * associated with the sent entity instance rule violation. This is used
	 * to locate the set of controls associated to the presentation definition.
	 *
	 * @async
	 * @param {EntityInstanceRuleViolation} entityInstanceRuleViolation
	 * The entity instance rule violation to get associated presentation
	 * definitions for.
	 * @returns {Promise<IRulePresentationDefinition[]>}
	 * An awaitable promise that returns the set of unique presentation
	 * definitions grouped by data key.
	 * @memberof RuleService
	 */
	public async getUniqueViolationPresentationDefinitions(
		entityInstanceRuleViolation: EntityInstanceRuleViolation):
		Promise<IRulePresentationDefinition[]>
	{
		await this.setStoredVariables();

		const rulePresentationDefinitions: IRulePresentationDefinition[] =
			this.rulePresentationDefinitions.filter(
				(rulePresentationDefinition:
					IRulePresentationDefinition) =>
					rulePresentationDefinition.definitionId ===
						entityInstanceRuleViolation.ruleDefinitionId);

		const uniqueKey: string = 'dataKey';

		return <IRulePresentationDefinition[]>
			[
				...new Map(
					rulePresentationDefinitions.map(
						(rulePresentationDefinition:
							IRulePresentationDefinition) =>
							[
								rulePresentationDefinition[uniqueKey],
								rulePresentationDefinition
							])).values()
			];
	}

	/**
	 * Given a set of populated rule violations loaded based on the sent entity
	 * instance id, this will filter down to find if any violations are
	 * blocking, not overridden, and a match for the sent workflow action name.
	 *
	 * @async
	 * @param {number} entityInstanceId
	 * The entity instance id used to gather a set of rule violations to
	 * check for a matching blocking violation.
	 * @param {string} workflowActionName
	 * The workflow action name to check for a blocking match.
	 * @returns {boolean}
	 * A value signifying whether or not the sent rule violations contain
	 * a matching blocked rule violation for this action.
	 * @memberof RuleService
	*/
	public async isActionBlocked(
		entityInstanceId: number,
		workflowActionName: string): Promise<boolean>
	{
		const populatedRuleViolations: IEntityInstanceRuleViolation[] =
			await this.getPopulatedRuleViolations(
				`InstanceId eq ${entityInstanceId}`,
				AppConstants.empty,
				0,
				AppConstants.dataLimits.maxResultSet);

		return RuleHelper.isActionBlocked(
			populatedRuleViolations,
			workflowActionName);
	}

	/**
	 * Gets the set of rule violation workflow action definitions associated
	 * with the sent entity instance rule violation.
	 *
	 * @async
	 * @param {EntityInstanceRuleViolation} entityInstanceRuleViolation
	 * The entity instance rule violation to get associated workflow action
	 * definitions for.
	 * @param {IEntityInstanceRuleViolationOverride[]}
	 * 	entityInstanceRuleViolationOverrides
	 * The entity instance rule violation overrides associated with this entity
	 * instance.
	 * @returns {Promise<IRuleViolationWorkflowActionDefinition[]>}
	 * An awaitable promise that returns the set of populated workflow action
	 * definitions associated with the sent entity instance rule violation.
	 * @memberof RuleService
	 */
	private getRuleViolationWorkflowActions(
		entityInstanceRuleViolation: IEntityInstanceRuleViolation,
		entityInstanceRuleViolationOverrides:
			IEntityInstanceRuleViolationOverride[]):
		IRuleViolationWorkflowActionDefinition[]
	{
		const matchingRuleViolationOverrides:
			IEntityInstanceRuleViolationOverride[] =
			entityInstanceRuleViolationOverrides
				.filter(
					(ruleViolationOverride:
						IEntityInstanceRuleViolationOverride) =>
						ruleViolationOverride.ruleDefinitionId ===
							entityInstanceRuleViolation.ruleDefinitionId
							&& ruleViolationOverride.resourceIdentifier ===
								entityInstanceRuleViolation.resourceIdentifier);

		return this.ruleViolationWorkflowActionDefinitions
			.filter(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
					ruleViolationWorkflowAction.definitionId ===
						entityInstanceRuleViolation.ruleDefinitionId)
			.map(
				(ruleViolationWorkflowAction:
					IRuleViolationWorkflowActionDefinition) =>
				{
					const mappedWorkflowAction:
						IRuleViolationWorkflowActionDefinition =
						<IRuleViolationWorkflowActionDefinition>
						{
							...ruleViolationWorkflowAction
						};

					mappedWorkflowAction.blocked =
						mappedWorkflowAction.actionTypeId ===
							AppConstants.ruleActionTypes.blocked;

					mappedWorkflowAction.overridden =
						matchingRuleViolationOverrides.filter(
							(entityInstanceRuleViolationOverride:
								IEntityInstanceRuleViolationOverride) =>
								(AnyHelper.isNull(
									entityInstanceRuleViolationOverride
										.workflowActionDefinitionId)
									|| entityInstanceRuleViolationOverride
										.workflowActionDefinitionId ===
											mappedWorkflowAction
												.workflowActionDefinitionId)
						).length > 0;

					mappedWorkflowAction.workflowActionDefinition =
						this.workflowActionDefinitions.find(
							(workflowActionDefinition:
								IWorkflowActionDefinitions) =>
								workflowActionDefinition.id ===
									mappedWorkflowAction
										.workflowActionDefinitionId);

					mappedWorkflowAction.overridable =
						this.isViolationOverridable(
							mappedWorkflowAction);

					return mappedWorkflowAction;
				});
	}

	/**
	 * Given a sent rule violation workflow action definition, this method
	 * will use the sent security group filter to return a truthy representation
	 * whether or not the violation is overridable for the security groups sent.
	 *
	 * @async
	 * @param {IRuleViolationWorkflowActionDefinition}
	 * ruleViolationWorkflowAction
	 * The rule violation workflow action definition to check for security group
	 * permissions to override.
	 * @returns {Promise<boolean}
	 * An awaitable promise that represents the sent security groups allowed
	 * ability to override a rule violation workflow action.
	 * @memberof RuleService
	 */
	private isViolationOverridable(
		ruleViolationWorkflowAction:
		IRuleViolationWorkflowActionDefinition): boolean
	{
		const allowedSecurityGroups: number[] =
			this.sessionService
				.user
				.membershipSecurityGroups
				.map((securityGroup: ISecurityGroup) =>
					securityGroup.id);

		const ruleViolationOverride:
			ISecurityGroupRuleDefinitionViolationOverride =
				this.securityGroupRuleDefinitionViolationOverrides
					.find(
						(securityRuleViolationOverride:
						ISecurityGroupRuleDefinitionViolationOverride) =>
							(securityRuleViolationOverride
								.workflowActionDefinitionId == null
								|| securityRuleViolationOverride
									.workflowActionDefinitionId ===
										ruleViolationWorkflowAction
											.workflowActionDefinitionId)
								&& securityRuleViolationOverride
									.ruleDefinitionId ===
										ruleViolationWorkflowAction.definitionId
								&& allowedSecurityGroups.indexOf(
									securityRuleViolationOverride
										.groupId) !== -1);

		return !AnyHelper.isNull(ruleViolationOverride);
	}
}