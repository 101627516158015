<div id="common-table-top-element">
</div>

<div clickOutside
	(clickOutside)="commonTableClickOutside($event)"
	[ignoreAppendedBodyPanelClicks]="true"
	[contentDisplayed]="displayCreate || displayDelete || displayUpdate || displayView"
	[ngClass]="{
		'hide-vertical-overflow': (scrollable == false)
	}"
	*ngIf="tableDefinitions?.objectSearch != null">
	<p-table
		#CommonTable
		[value]="virtualData"
		[columns]="columns"
		[scrollable]="scrollable"
		[rows]="tableDefinitions.objectSearch.virtualPageSize"
		dataKey="id"
		[scrollHeight]="tableHeight"
		[virtualScroll]="true"
		[virtualRowHeight]="rowHeight"
		[lazy]="true"
		[loading]="loadingListData || loadingTableDefinitions"
		[(selection)]="selectedRows"
		[selectionMode]="selectionMode"
		[expandedRowKeys]="expandedRows"
		[rowExpandMode]="'single'"
		[rowHover]="rowHover"
		sortMode="single"
		[sortField]="sortField"
		[sortOrder]="sortOrder"
		responsiveLayout="scroll"
		(onRowSelect)="rowSelect($event, true)"
		(onRowUnselect)="rowSelect($event, false)"
		(onLazyLoad)="loadNextDataSet($event)"
		(onRowExpand)="rowExpansion($event, true, CommonTable)"
		(onRowCollapse)="rowExpansion($event, false, CommonTable)">

		<ng-template pTemplate="caption"
			*ngIf="tableDefinitions.tableTitle != null
				|| tableDefinitions.actions != null">
			<div id= "tableActions"
				class="p-helper-clearfix table-caption">
				<div class="ui-g-12 no-vertical-padding panel-sub-title font table-title disabled">
					<div class="ui-g-6 no-padding ellipsis-text title-section">
						{{tableDefinitions.tableTitle}}
					</div>
					<div class="ui-g-6 no-padding table-actions-section"
						[ngClass]="{
						'highlight-opacity': loadingListData || loadingOperations
						}"
						*ngIf="tableDefinitions.actions != null">
						<a id="ellipsisTableAction"
							class="float-right table-ellipsis">
							<operation-button-menu
								*ngIf="tableDefinitions.actions.tableLevelEllipsis"
								useEllipsis="true"
								[operationGroup]="tableDefinitions.actions.tableLevelEllipsis"
								[fixedOverlayLocation]="(index + 5 === totalRecords ? 'TopLeft' : 'TopCenter')"
								[invertedColors]="true"
								[smallEllipsis]="true">
							</operation-button-menu>
						</a>
						<span class="table-action float-right"
							[ngClass]="{'filter-align': !siteLayoutService.displayTabletView}"
							*ngIf="tableDefinitions.actions.filter?.quickFilters?.length > 0">
							<app-quick-filters
								[filters]="tableDefinitions.actions.filter.quickFilters"
								[selectedFilterValue]="tableDefinitions.actions.filter.selectedFilterValue"
								(quickFilterSelectedEvent)="tableDefinitions.filterCriteriaChanged($event)">
							</app-quick-filters>
						</span>
						<span *ngIf="tableDefinitions.hideSettings !== true">
							<span>
								<a id="clickableSettings"
									(click)="toggleSettingsDisplay(CommonTable)">
									<i *ngIf="!displaySettings"
										class="table-action float-right fa fa-gear">
									</i>
								</a>
							</span>
							<a id="notClickableSettings">
								<i *ngIf="displaySettings"
									class="table-action float-right fa fa-gear border-selected-theme active-icon-color">
								</i>
							</a>
						</span>
						<a id="createAction"
							(click)="scrollTableToTop($event, CommonTable)">
							<i *ngIf="tableDefinitions.actions.create"
								class="table-action float-right fa fa-plus-circle"
								[ngClass]="{
									'border-selected-theme': displayCreate,
									'active-icon-color': displayCreate
								}">
							</i>
						</a>
					</div>
				</div>
				<div id="drawer"
					class="drawer-display"
					[ngStyle]="{
						'width': drawerWidth
					}"
					[ngClass]="{
						'drawer-expand': displaySettings,
						'overflow-visible': drawerOverflow
					}"
					clickOutside
					(clickOutside)="toggleSettingsDisplay(CommonTable)"
					[contentDisplayed]="displaySettings">
					<div class="drawer-title">
						<label class="panel-sub-title table-title font">
							Settings
						</label>
					</div>
					<div class="drawer-container overflow-visible">
						<div class="settings">
							<div class="ui-g-12">
								<div class="ui-g-12">
									<div>
										<label>
											Selected Columns
										</label>
									</div>
									<div>
										<p-multiSelect
											[ariaLabelledBy]="'selectColumnsControl'"
											[ariaFilterLabel]="'filtercolumnsControl'"
											inputId="selectColumnsControl"
											[options]="availableColumns"
											[(ngModel)]="selectedColumns"
											optionLabel="label"
											selectedItemsLabel="{0} columns selected"
											maxSelectedLabels="3"
											defaultLabel="Choose Columns"
											filterPlaceHolder="Filter"
											(onChange)="onMultiselectChange($event)">
										</p-multiSelect>
									</div>
								</div>
								<div class="ui-g-12">
									<div>
										<label>
											Results Set Count
										</label>
									</div>
									<div>
										<input #rowCount
											class="row-count ui-inputtext"
											type="number"
											id="resultSetCount"
											aria-label="resultSetCount"
											min="5"
											max="25"
											step="5"
											[value]="rowCount"
											(focusout)="rowCountFocusOut(rowCount.value)" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="setting-buttons">
						<p-button class="setting-button"
							id="setting-cancel"
							styleClass="p-button-secondary"
							label="Cancel"
							(click)="cancelSettings()">
						</p-button>
						<p-button (click)="applySettings(CommonTable)"
							id="apply"
							label="Apply">
						</p-button>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="colgroup"
			let-columns>
			<colgroup>
				<col *ngFor="let column of columns">
			</colgroup>
		</ng-template>

		<ng-template pTemplate="header"
			let-columns>
			<tr class="table-header-row border-bottom"
				[ngClass]="{
					'hide-view': (hideView)
				}">
				<ng-template [ngIf]="!tableDefinitions.allowSortColumns"
					[ngIfElse]="allowToSort">
					<th *ngFor="let column of columns"
						style="width:1px"
						scope="col"
						[ngClass]="{
							'action-column': (column.displayOrder > 99),
							'icon-action-column': ((column.displayOrder > 99) && useIconBar),
							'display-none': (column.displayOrder > 99 && noActionsExist),
							'expander-arrow-column': (column.dataKey == 'arrow' && column.displayOrder === 0)
						}">
						<div class="ellipsis-text font">
							{{column.columnHeader}}
						</div>
					</th>
				</ng-template>

				<ng-template #allowToSort>
					<th *ngFor="let column of columns"
						[pSortableColumn]="column.dataKey"
						style="width:1px"
						scope="col"
						[ngClass]="{
							'action-column': (column.displayOrder > 99),
							'icon-action-column': ((column.displayOrder > 99) && useIconBar),
							'display-none': (column.displayOrder > 99 && noActionsExist),
							'expander-arrow-column': (column.dataKey == 'arrow' && column.displayOrder === 0)
						}">
						<div class="ui-g-12 no-padding">
							<div class="ellipsis-text font content-container">
								{{column.columnHeader}}
							</div>
							<div class="float-right">
								<p-sortIcon
									*ngIf="column.displayOrder > 0
										&& column.displayOrder < 100"
									[field]="column.dataKey">
								</p-sortIcon>
							</div>
						</div>
					</th>
				</ng-template>
			</tr>
		</ng-template>

		<ng-template pTemplate="body"
			let-item
			let-rowData
			let-index="rowIndex"
			let-expanded="expanded"
			let-columns="columns">
			<ng-template [ngIf]="rowData?.id !== 0 && virtualData != null"
				[ngIfElse]="CreateDisplay">
				<tr [pRowToggler]="rowData"
					[pSelectableRow]="tableDefinitions.rowSelection != null
						? rowData
						: null"
					class="table-display"
					[ngClass]="{
						'byPass-hover' : tableDefinitions.actions == null
					}">
					<td *ngFor="let column of columns"
						style="width:1px"
						[ngClass]="{
							'table-data' : (!expanded),
							'action-column': (column.displayOrder > 99),
							'icon-action-column': ((column.displayOrder > 99) && useIconBar),
							'display-none': (column.displayOrder > 99 && noActionsExist),
							'row-action-hover': (column.displayOrder > 99),
							'expander-arrow-column': (column.dataKey == 'arrow' && column.displayOrder === 0)
						}"
						class="p-resizable-column table-column background"
						(click)="stopExpandEvent($event)">
						<div *ngIf="column.dataKey == 'arrow' && column.displayOrder === 0 && !expanded"
							class="text-center">
							<i class="pi pi-chevron-right">
							</i>
						</div>
						<div *ngIf="column.dataKey == 'arrow' && column.displayOrder === 0 && expanded"
							class="text-center">
							<a id="downArrow">
								<i class="pi pi-chevron-down">
								</i>
							</a>
						</div>
						<div *ngIf="column.displayOrder > 0 && column.displayOrder < 99"
							class="ellipsis-text table-column-text">
							<ng-template [ngIf]="column.dataFormatType === 'Date' || column.dataFormatType === 'ShortDate'">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item) | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'D'">
								</span>
							</ng-template>
							<ng-template [ngIf]="column.dataFormatType === 'DateTime' || column.dataFormatType === 'LongDate'">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item) | dateTimeFromIso | dateTimeToLocal | dateTimeToFormat:'D h:mm a'">
								</span>
							</ng-template>
							<ng-template [ngIf]="column.dataFormatType === 'Currency'">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item) | currency">
								</span>
							</ng-template>
							<ng-template [ngIf]="column.dataFormatType === 'Decimal'">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item) | number : '1.0-2'">
								</span>
							</ng-template>
							<ng-template [ngIf]="column.dataFormatType === 'Percent'">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item) | percent">
								</span>
							</ng-template>
							<ng-template [ngIf]="column.dataFormatType == null || column.dataFormatType === ''">
								<span *ngIf= "rowData != null"
									[innerHTML]="getTableColumnData(column.dataKey, item, column.dataFunction)">
								</span>
							</ng-template>
						</div>
						<div *ngIf="column.dataKey === 'update' && tableDefinitions.actions.update"
							class="text-center action-height"
							[ngClass]= "{
								'border-selected-theme': displayUpdate && expanded
							}">
							<a id="editAction">
								<i 	class="row-action fa fa-pencil icon-action-align"
									[ngClass]= "{
										'active-icon-color': displayUpdate && expanded
									}">
								</i>
							</a>
						</div>
						<div *ngIf="column.dataKey === 'delete' && tableDefinitions.actions.delete
								&& rowActionEnabled(tableDefinitions.actions.delete, rowData)"
							class="text-center action-height"
							[ngClass]= "{
								'border-selected-theme': displayDelete && expanded
							}">
							<i class="row-action fa fa-trash filter active-icon-color icon-action-align">
							</i>
						</div>
						<div *ngIf="column.dataKey === 'rowLevelEllipsis'
							&& tableDefinitions.actions.rowLevelEllipsis
							&& !useIconBar"
							class="text-center action-ellipsis action-height">
							<a id="ellipsisRowAction">
								<operation-button-menu
									[useEllipsis]="true"
									[operationGroup]="tableDefinitions.actions.rowLevelEllipsis"
									[fixedOverlayLocation]="(index + 1 === totalRecords ? 'TopLeft' : null)"
									[invertedColors]="true"
									[smallEllipsis]="true">
								</operation-button-menu>
							</a>
						</div>
						<div *ngIf="column.dataKey === 'rowIconActions'
							&& tableDefinitions.actions.rowIconActions
							&& useIconBar"
							class="text-center action-icons action-height">
							<operation-icon-bar
								[rowData]="addEnabledIconActions(rowData)"
								[context]="objectContext"
								[customContext]="customContext">
							</operation-icon-bar>
						</div>
						<div *ngIf="column.dataKey === 'drillIn' && tableDefinitions.actions?.drillIn"
							class="text-center action-height"
							[ngClass]= "{
								'border-selected-theme': drillIn
							}">
							<a id="drill-in">
								<i 	class="row-action fa fa-share-square-o icon-action-align drill-in"
									[ngClass]= "{
										'active-icon-color': drillIn && expanded
									}">
								</i>
							</a>
						</div>
						<div *ngIf="column.dataKey === 'updateIndex'
							&& tableDefinitions.actions?.updateIndex"
							class="text-center action-height"
							[ngClass]= "{
								'border-selected-theme': updateIndex,
								'update-index-container': tableDefinitions.actions
										.updateIndex[1]
										.visible(rowData, virtualData)
									&& tableDefinitions.actions
										.updateIndex[0]
										.visible(rowData, virtualData)
							}">
							<a id="updateIndexUp"
								*ngIf="tableDefinitions.actions.updateIndex[0]
									.visible(rowData, virtualData)"
								[ngClass]= "{
										'update-index-up': tableDefinitions.actions
											.updateIndex[1]
											.visible(rowData, virtualData)
									}">
								<i 	class="pi pi-chevron-up update-chevron-icon"
									[ngClass]= "{
										'active-icon-color': updateIndex,
										'single-arrow-align': !tableDefinitions.actions
											.updateIndex[1]
											.visible(rowData, virtualData)
									}">
								</i>
							</a>
							<a id="updateIndexDown"
								*ngIf="tableDefinitions.actions.updateIndex[1]
									.visible(rowData, virtualData)"
								[ngClass]= "{
										'update-index-down': tableDefinitions.actions
											.updateIndex[0]
											.visible(rowData, virtualData)
									}">
								<i class="pi pi-chevron-down update-chevron-icon"
									[ngClass]= "{
										'active-icon-color': updateIndex,
										'single-arrow-align': !tableDefinitions.actions
											.updateIndex[0]
											.visible(rowData, virtualData)
									}">
								</i>
							</a>
						</div>
						<div [ngClass]="{
								'highlight-opacity':expanded
							}"
							class="background-theme-highlight">
						</div>
					</td>
				</tr>
			</ng-template>

			<ng-template #CreateDisplay>
				<tr [pRowToggler]="rowData"
					class="hide-view"
					*ngIf="displayCreate"
					[ngClass]="{
						'display-none' : tableDefinitions.actions.create.displayCreateRow === false
					}">
					<td *ngFor="let column of columns"
						[ngClass]="{
							'table-data' : (!expanded),
							'action-column': (column.displayOrder > 99),
							'icon-action-column': ((column.displayOrder > 99) && useIconBar),
							'display-none': (column.displayOrder > 99 && noActionsExist),
							'expander-arrow-column': (column.dataKey == 'arrow' && column.displayOrder === 0)
						}"
						class="p-resizable-column table-column background">
						<div *ngIf="column.dataKey == 'arrow' && column.displayOrder === 0 && !expanded"
							class="text-center">
							<i class="pi pi-chevron-right">
							</i>
						</div>
						<div *ngIf="column.dataKey == 'arrow' && column.displayOrder === 0 && expanded"
							class="text-center">
							<a id="createDownArrow">
								<i class="pi pi-chevron-down">
								</i>
							</a>
						</div>
						<div *ngIf="column.displayOrder > 0 && column.displayOrder < 99"
							class="ellipsis-text table-column-text">
						</div>
						<div *ngIf="column.dataKey === 'update' && tableDefinitions.actions.update"
							class="  text-center row-action-align"
							[ngClass]= "{
								'border-selected-theme': displayUpdate && expanded
							}">
						</div>
						<div *ngIf="column.dataKey === 'delete' && tableDefinitions.actions.delete"
							class="text-center row-action-align"
							[ngClass]= "{
								'border-selected-theme': displayDelete && expanded
							}">
						</div>
						<div *ngIf="column.dataKey === 'ellipsis' && tableDefinitions.actions.rowLevelEllipsis"
							class="text-center action-ellipsis">
						</div>
						<div [ngClass]="{
								'highlight-opacity':expanded
							}"
							class="background-theme-highlight">
						</div>
					</td>
				</tr>
			</ng-template>
		</ng-template>

		<ng-template pTemplate="loadingbody"
			let-columns="columns">
			<tr class="table-row font">
				<td *ngFor="let column of columns"
					class="loading-table-column">
				</td>
			</tr>
		</ng-template>

		<ng-template pTemplate="summary">
			<div id="footer-summary-container"
				class="font">
				<div *ngIf="displayKeywordMessage === true
					&& columns.length === 0
					&& !loadingListData
					&& !loadingOperations
					&& !loadingTableDefinitions">
					<span>
						This entity type is not searchable, please add summary data to the definition.
					</span>
				</div>
				<div *ngIf="virtualData.length === 0
					&& !loadingListData
					&& !loadingOperations
					&& !(displayKeywordMessage === true
						&& columns.length === 0)">
					<span>
						No query results found matching the selected category and filter.
					</span>
				</div>
				<span *ngIf="!additionalRecordsExist
					&& !loadingListData
					&& !loadingOperations
					&& totalRecords > 0
					&& tableDefinitions.hideSummary !== true
					&& !(displayKeywordMessage === true
						&& columns.length === 0)">
					<span>
						Displaying all {{totalRecords}} {{totalRecords > 1 ? 'results' : 'result'}}.
					</span>
				</span>
				<div *ngIf="loadingListData">
					<span>
						Loading query results
					</span>
					<span class="theme-color fa fa-fw fa-spin fa-spinner">
					</span>
				</div>
				<div *ngIf="loadingTableDefinitions">
					<span>
						Updating table definitions
					</span>
					<span class="theme-color fa fa-fw fa-spin fa-spinner">
					</span>
				</div>
				<div class="ellipsis-text"
					*ngIf="virtualData.length > 0
						&& additionalRecordsExist
						&& !loadingListData
						&& !loadingOperations
						&& tableDefinitions.hideSummary !== true
						&& !(displayKeywordMessage === true
							&& columns.length === 0)">
					<span *ngIf="totalRecords < filterResultsSuggestionCount">
						Displaying top {{totalRecords > 1 ? totalRecords : totalRecords}} {{totalRecords > 1 ? 'results' : 'result'}}.
					</span>
					<span *ngIf="totalRecords >= filterResultsSuggestionCount">
						We have found more than {{totalRecords}} results.
						<ng-template [ngIf]="tableDefinitions.actions?.filter?.considerFilteringResults != null"
							[ngIfElse]="NotAnchor">
							<a id="considerFiltering"
								class="additional-results-link text-link"
								(click)="tableDefinitions.actions.filter.considerFilteringResults()">
									Consider filtering your search.
							</a>
						</ng-template>
						<ng-template #NotAnchor>
							Consider filtering your search.
						</ng-template>
					</span>
					<span *ngIf=" tableDefinitions.hideLoadMoreResultsLink !== true
						&& ((totalRecords >= tableDefinitions.objectSearch.limit
						|| (totalRecords / tableDefinitions.objectSearch.limit) % 1 === 0)
						&& totalRecords < filterResultsSuggestionCount)
						&& !(displayKeywordMessage === true
							&& columns.length === 0)">
						<a id="loadMoreLink"
							class="additional-results-link text-link"
							(click)="scrollTableToBottom(CommonTable)">
							Please scroll or click here for additional results.
						</a>
					</span>
				</div>
			</div>
		</ng-template>

		<ng-template pTemplate="rowexpansion"
			let-rowData
			let-columns="columns">
				<tr class="p-highlight row-expansion">
					<td #CommonTableExpandContainer
						[attr.colspan]="columns.length"
						class="expand-container">
						<span *ngIf="loadingExpansion === false">
							<div *ngIf="expandTitle != null"
								class="ui-g-12 panel-sub-title expand-title">
								{{expandTitle}}
							</div>
							<span *ngIf="objectContext == null"
								class="center-spinner loading-spinner theme-color fa fa-fw fa-spin fa-spinner center-spinner">
							</span>
							<div class="ui-g-12"
								*ngIf="objectContext != null">
								<app-dynamic-component
									[context]="objectContext"
									[displayComponent]="dynamicComponent">
								</app-dynamic-component>
							</div>
						</span>
					</td>
				</tr>
		</ng-template>
	</p-table>
</div>