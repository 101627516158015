/**
 * @copyright WaterStreet. All rights reserved.
*/

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	AppEventParameterConstants
} from '@shared/constants/app-event-parameter.constants';
import {
	BaseExpandComponent
} from '@shared/components/common-table/base-expand/base-expand.component';
import {
	BooleanFadeAnimation
} from '@shared/app-animations';
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	Type,
	ViewChild
} from '@angular/core';
import {
	cloneDeep,
	get
} from 'lodash-es';
import {
	concatAll,
	from,
	interval,
	map,
	Subscription,
	take
} from 'rxjs';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IActionTableConfiguration
} from '@shared/interfaces/application-objects/actions-table-configuration.interface';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IFormlyDefinitions
} from '@shared/interfaces/application-objects/formly-definitions.interface';
import {
	LazyLoadEvent
} from 'primeng/api/lazyloadevent';
import {
	MenuItem
} from 'primeng/api';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	Table
} from 'primeng/table';

/* eslint-enable max-len */

@Component({
	selector: 'app-common-table',
	templateUrl: './common-table.component.html',
	styleUrls: ['./common-table.component.scss'],
	animations: [
		BooleanFadeAnimation
	]
})

/**
 * A component representing an instance of the object list component.
 *
 * @export
 * @class CommonTableComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
export class CommonTableComponent
implements OnInit, OnChanges
{
	/**
	 * Initializes a new instance of the object list component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * Service utilized to catch any site layout change and
	 * site layout information
	 * @param {ChangeDetectorRef} changeDetectorRef
	 * The change detector reference for this component.
	 * @memberof CommonTableComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetectorRef: ChangeDetectorRef,
		public resolver: ResolverService)
	{
	}

	/**
	 * Gets or sets the table definitions data required to
	 * generate the table display within it's own functionality.
	 *
	 * @type {ITableConfiguration}
	 * @memberof CommonTableComponent
	 */
	@Input() public tableDefinitions: ICommonTable;

	/**
	 * Gets or sets the selected rows ids.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	@Input() public selectedRows?: object[] = [];

	/**
	 * Gets or sets the field context.
	 *
	 * @type {object[]}
	 * @memberof EntitySelectComponent
	 */
	 @Input() public fieldContext?: any = [];

	/**
	 * Gets or sets a value signifying whether or not the keyword based messsage
	 * should be displayed if zero keywords are found.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	@Input() public displayKeywordMessage: boolean = false;

	/**
	 * Gets or sets a value signifying whether or not to use the icon bar
	 * rather than the default ellipsis
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	@Input() public useIconBar: boolean = false;

	/**
	 * Gets or sets the finished loading event.
	 *
	 * @type {EventEmitter<boolean>}
	 * @memberof EntitySelectComponent
	 */
	@Output() public finishedLoading: EventEmitter<boolean>
		= new EventEmitter;

	/**
	 * Gets or sets the expand container input element.
	 *
	 * @type {ElementRef}
	 * @memberof CommonTableComponent
	 */
	@ViewChild('CommonTableExpandContainer')
	public commonTableExpandContainer: ElementRef;

	/**
	 * Gets or sets the definition if there are any additional
	 * records to display within the table data.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public additionalRecordsExist: boolean;

	/**
	 * Gets or sets columns object to be displayed within the table view.
	 *
	 * @type {object[]}
	 * @memberof CommonTableComponent
	 */
	public columns: ICommonTableColumn[];

	/**
	 * Gets or sets the data subscription class
	 *
	 * @type {Subscription}
	 * @memberof CommonTableComponent
	 */
	public dataSubscription: Subscription;

	/**
	 * Gets or sets the display create flag
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displayCreate: boolean = false;

	/**
	 * Gets or sets the delete view flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displayDelete: boolean = false;

	/**
	 * Gets or sets the settings view flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displaySettings: boolean = false;

	/**
	 * Gets or sets the update view flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displayUpdate: boolean = false;

	/**
	 * Gets or sets the view only flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public displayView: boolean = false;

	/**
	 * Gets or sets the drawer overflow view flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public drawerOverflow: boolean;

	/**
	 * Gets or sets the drawer width.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public drawerWidth: string;

	/**
	 * Gets or sets the dynamic component.
	 *
	 * @type {Type<any>}
	 * @memberof CommonTableComponent
	 */
	public dynamicComponent: Type<any>;

	/**
	 * Gets or sets the expand actions.
	 *
	 * @type {MenuItem[]}
	 * @memberof CommonTableComponent
	 */
	public expandActions: MenuItem[];

	/**
	 * Gets or sets the expand title.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public expandTitle: string;

	/**
	 * Gets or sets the expanded rows.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	public expandedRows: any = {};

	/**
	 * Gets or sets the list data.
	 *
	 * @type {any[]}
	 * @memberof CommonTableComponent
	 */
	public listData:  any[] = [];

	/**
	 * Gets or sets the loading list data flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingListData: boolean = true;

	/**
	 * Gets or sets the loading settings flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingSettings: boolean = false;

	/**
	 * Gets or sets the loading table definitions flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingTableDefinitions: boolean = false;

	/**
	 * Gets or sets the row data.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	public rowData: any;

	/**
	 * Gets or sets the expanded row.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public rowExpanded: boolean = false;

	/**
	 * Gets or sets the available columns for the multiselect.
	 *
	 * @type {object[]}
	 * @memberof CommonTableComponent
	 */
	public availableColumns: object[];

	/**
	 * Gets or sets the selected columns for the multiselect.
	 *
	 * @type {any[]}
	 * @memberof CommonTableComponent
	 */
	public selectedColumns: any[];

	/**
	 * Gets or sets the scrollable flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public scrollable: boolean;

	/**
	 * Gets or sets the table height.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public tableHeight: string;

	/**
	 * Gets or sets the total available colums.
	 *
	 * @type {string | any[]}
	 * @memberof CommonTableComponent
	 */
	public totalAvailableColumns: string | any[];

	/**
	 * Gets or sets the total records.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public totalRecords: number;

	/**
	 * Gets or sets the virtual data.
	 *
	 * @type {any[]}
	 * @memberof CommonTableComponent
	 */
	public virtualData: any[] = [];

	/**
	 * Gets or sets the formly definitions object.
	 *
	 * @type {IFormlyDefinitions}
	 * @memberof CommonTableComponent
	 */
	public formlyDefinitions: IFormlyDefinitions;

	/**
	 * Gets the height calculation of a table row.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public rowHeight: number = 46;

	/**
	 * Gets or sets the column display count.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public columnDisplayCount: number;

	/**
	 * Gets or sets the common table reference.
	 *
	 * @type {Table}
	 * @memberof CommonTableComponent
	 */
	public commonTable: Table;

	/**
	 * Gets or sets the number of items to display in this list before
	 * suggesting the user filters their search.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public filterResultsSuggestionCount: number = 500;

	/**
	 * Gets or sets the row count setting value used to define the amount
	 * of visible table rows.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public rowCount: string;

	/**
	 * Gets or sets the selected filter value.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public selectedFilterValue: string = AppConstants.empty;

	/**
	 * Gets or sets the data backup.
	 * This is used to restore the list and virtual data
	 * when required on a negative update scenario.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	public dataBackup: any;

	/**
	 * Gets or sets the action cancelled.
	 * This is flag when any time an action was cancelled
	 * by clicking on the cancel button.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public actionCancelled: boolean = false;

	/**
	 * Gets or sets the object context.
	 * This is used to determine this component context to be handed
	 * to the dynamic component displayed on every expanded row view.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof CommonTableComponent
	 */
	public objectContext: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the current Lazy Load Event
	 *
	 * @type {LazyLoadEvent}
	 * @memberof CommonTableComponent
	 */
	public currentLazyLoadEvent: LazyLoadEvent;

	/**
	 * Gets or sets the sort query string used
	 * to load list data.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public sortQueryString: string;

	/**
	 * Gets or sets the current sort active state.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public activeSort: boolean = false;

	/**
	 * Gets or sets the selection mode.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public selectionMode: string = null;

	/**
	 * Gets or sets the common table row hover.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public rowHover: boolean;

	/**
	 * Gets or sets the loading create display flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingCreateDisplay: boolean = false;

	/**
	 * Gets or sets the loading drawer flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingDrawer: boolean = false;

	/**
	 * Gets or sets the sort field.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	public sortField: string;

	/**
	 * Gets or sets the sort order.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public sortOrder: number;

	/**
	 * Gets or sets the additional records.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	public additionalRecords: number;

	/**
	 * Gets or sets the previous update view.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public previousUpdateView: boolean = false;

	/**
	 * Gets or sets the action button clicked flag.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public actionButtonClicked: boolean = false;

	/**
	 * Gets or sets the custom context.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	public customContext: any;

	/**
	 * Gets or sets an additional layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof CommonTableComponent
	 */
	public additionalLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the loading expansion.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public loadingExpansion: boolean = true;

	/**
	 * Gets or sets the object list component context.
	 * @returns {IDynamicComponentContext<CommonTableComponent, any>}
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof CommonTableComponent
	 */
	public get pageContext():
		IDynamicComponentContext<CommonTableComponent, any>
	{
		return <IDynamicComponentContext<CommonTableComponent, any>>
			{
				source: this,
				data: this.rowData
			};
	}

	/**
	 * Gets the class based identifiers used for form control overlays
	 * that will ensure these are handled accurately for display.
	 * @note p-calendar, p-dropdown, and p-multiselect cases are covered.
	 *
	 * @type {boolean}
	 * @memberof CommonTableComponent
	 */
	public get noActionsExist(): boolean
	{
		return this.tableDefinitions.actions == null
			|| (this.tableDefinitions.actions.update == null
				&& this.tableDefinitions.actions.delete == null
				&& this.tableDefinitions.actions.rowLevelEllipsis == null
				&& this.tableDefinitions.actions.rowIconActions == null
				&& this.tableDefinitions.actions.drillIn == null
				&& this.tableDefinitions.actions.updateIndex == null);
	}

	/**
	 * Gets or sets the api promise.
	 *
	 * @type {(objectSearch: IObjectSearch | IEntitySearch) => Promise<any[]>}
	 * @memberof CommonTableComponent
	 */
	private apiPromise: Promise<any>;

	/**
	 * Gets the class based identifiers used for form control overlays
	 * that will ensure these are handled accurately for display.
	 * @note p-calendar, p-dropdown, and p-multiselect cases are covered.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly toggleControls: string[] =
		[
			'p-calendar',
			'pi-calendar',
			'p-datepicker-calendar',
			'p-dropdown',
			'p-dropdown-item',
			'p-dropdown-trigger',
			'p-multiselect-item',
			'p-multiselect-label',
			'p-multiselect-trigger'
		];

	/**
	 * Gets the width required to begin including additional
	 * columns in the display. This uses math.ceiling() so a target size
	 * of 360 (Our minimum target size) will display two columns.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly additionalColumnRequiredWidth: number = 250;

	/**
	 * Gets the base component required to be display within the row expanded
	 * zone when there is no other specific Component provided.
	 *
	 * @type {any}
	 * @memberof CommonTableComponent
	 */
	private readonly baseExpandComponent: any = BaseExpandComponent;

	/**
	 * The standard timeout interval to wait between switching from
	 * one view to another to add loading flow to the user experience.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly switchViewsInterval: number =
		AppConstants.time.threeQuarterSecond;

	/**
	 * Gets the header row height used when calculating scrollable displays.
	 *
	 * @type {number}
	 * @memberof CommonTableComponent
	 */
	private readonly headerRowHeight: number = 49.5;

	/**
	 * The update button class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly displayUpdateButtonClass: string = 'fa-pencil';

	/**
	 * The delete button class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly displayDeleteButtonClass: string = 'fa-trash';

	/**
	 * The ellipsis button class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly ellipsisButtonClass: string = 'ellipsis-button';

	/**
	 * The drill in button class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly drillInButtonClass: string = 'fa-share-square-o';

	/**
	 * The update index up class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly updateIndexUpClass: string =
		'pi-chevron-up update-chevron-icon';

	/**
	 * The update index down class.
	 *
	 * @type {string}
	 * @memberof CommonTableComponent
	 */
	private readonly updateIndexDownClass: string =
		'pi-chevron-down update-chevron-icon';

	/**
	 * Handles the site layout change event which is called
	 * when the site layout service has altered it's variables
	 * or loading has completed.
	 *
	 * @memberof CommonTableComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.setSelectedColumns();
		this.addRowActions();
		this.setupSettings();

		if (this.siteLayoutService.displayTabletView === false)
		{
			if (this.displaySettings === true)
			{
				this.toggleSettingsDisplay();
			}

			if (this.displayCreate === true
				&& (this.loadingListData === false
				|| this.loadingCreateDisplay === false))
			{
				this.toggleCreateDisplay();
			}

			this.setExpandedRow(false);
			this.addExpanderArrow();
		}
	}

	/**
	 * Handles the hide associated menus event.
	 * This is used to close settings displayed drawe
	 * when another overlay is displayed.
	 *
	 * @memberof CustomImageInputComponent
	 */
	@HostListener(
		AppEventConstants.hideAssociatedMenusEvent,
		[AppEventParameterConstants.id])
	public hideAssociatedMenus(): void
	{
		if (this.displaySettings === true)
		{
			this.toggleSettingsDisplay();
		}

		if (this.displayCreate === true
			&& this.loadingCreateDisplay === false)
		{
			this.toggleCreateDisplay();
		}
	}

	/**
	 * Handles the loaded expansion panel event.
	 * This is used to calculate the table height when an expansion panel
	 * might need to redraw itself.
	 *
	 * @memberof CustomImageInputComponent
	 */
	@HostListener(
		AppEventConstants.tableExpansionPanelLoadedEvent)
	public handleLoadedExpansionPanel(): void
	{
		this.calculateTableHeight();
	}

	/**
	 * On initialization event.
	 * Sets the table and column and settings definitions
	 * for this object list view, as well as loading the data
	 * from the server to be displayed within the table.
	 *
	 * @memberof CommonTableComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.selectionMode = !AnyHelper.isNullOrEmpty(
			this.tableDefinitions.rowSelection?.selectionMode)
			? this.tableDefinitions.rowSelection.selectionMode
			: this.selectionMode;

		this.rowHover = !AnyHelper.isNullOrEmpty(
			this.tableDefinitions.actions);

		this.sortField =
			!AnyHelper.isNullOrEmpty(
				this.tableDefinitions.objectSearch.sortField)
				? this.tableDefinitions.objectSearch.sortField
				: 'id';

		this.sortOrder =
			!AnyHelper.isNullOrEmpty(
				this.tableDefinitions.objectSearch.sortOrder)
				? this.tableDefinitions.objectSearch.sortOrder
				: 1;

		if (!AnyHelper.isNullOrEmpty(this.tableDefinitions.dataSetup))
		{
			await this.tableDefinitions.dataSetup();
		}

		if (this.tableDefinitions.objectSearch.limit <
			(this.tableDefinitions.objectSearch.virtualPageSize * 2))
		{
			this.tableDefinitions.objectSearch.limit =
				this.tableDefinitions.objectSearch.virtualPageSize * 2;
		}

		this.setSelectedColumns();
		this.addRowActions();

		this.apiPromise =
			!AnyHelper.isNullOrEmpty(this.tableDefinitions.apiPromise)
				? this.tableDefinitions.apiPromise(
					this.tableDefinitions.objectSearch)
				: StringHelper
					.transformToDataPromise(
						StringHelper.interpolate(
							this.tableDefinitions.apiPromiseString,
							this.pageContext),
						this.pageContext);

		await this.loadListData(this.apiPromise);
		this.addExpanderArrow();
		this.setupSettings();
		this.rowCount = this.tableDefinitions.objectSearch.virtualPageSize
			.toString();
		this.setFilterResultsSuggestionCount();

		this.setCommonTableContext();

		if (!AnyHelper.isNullOrEmpty(
			this.tableDefinitions.rowSelection?.selectedRows))
		{
			this.selectedRows = this.tableDefinitions.rowSelection.selectedRows;
		}
	}

	/**
	 * On changes event.
	 * Fires the layout changed subject on load events so that the
	 * footer height can be calculated.
	 *
	 * @memberof CommonTableComponent
	 */
	public ngOnChanges(
		changes: SimpleChanges): void
	{
		if (changes.tableDefinitions?.previousValue
			=== changes.tableDefinitions?.currentValue)
		{
			return;
		}

		this.setAdditionalRecordsExist();
	}

	/**
	 * Calculates the table height for the common table on an interval.
	 *
	 * @memberof CommonTableComponent
	 */
	public calculateTableHeight(): void
	{
		from([
			AppConstants.time.oneHundredMilliseconds,
			AppConstants.time.quarterSecond,
			AppConstants.time.halfSecond])
			.pipe(
				map((intervalTime: number) =>
					interval(intervalTime)
						.pipe(take(1))),
				concatAll())
			.subscribe(() =>
			{
				this.setupCalculatedHeight();
			});
	}

	/**
	 * Applies the setting changes if any existing to update the displayed
	 * columns and rows.
	 *
	 * @memberof CommonTableComponent
	 */
	public async applySettings(): Promise<void>
	{
		if (this.loadingSettings !== false)
		{
			return;
		}
		this.loadingSettings = true;
		this.toggleSettingsDisplay();
		this.filteredRows();
		this.filteredColumns();
		this.loadingSettings = false;
	}

	/**
	 * Sets the filter results suggestion count to
	 * five times the object search limit.
	 *
	 * @memberof CommonTableComponent
	 */
	public setFilterResultsSuggestionCount(): void
	{
		if (AnyHelper.isNullOrEmpty(this.tableDefinitions.objectSearch.limit))
		{
			return;
		}

		this.filterResultsSuggestionCount =
			this.tableDefinitions.objectSearch.limit * 5;
	}

	/**
	 * Action to expand or collapse a row that is currently expanded.
	 *
	 * @param {boolean} expandedRowAction
	 * Defines if a row is expanded or collapsed.
	 * @memberof CommonTableComponent
	 */
	public setExpandedRow(
		expandedRowAction: boolean): void
	{
		this.expandedRows = {};

		if (!AnyHelper.isNull(this.rowData?.id)
			&& expandedRowAction === true)
		{
			this.expandedRows[this.rowData?.id] = expandedRowAction;
		}

		this.rowExpanded = expandedRowAction;
		this.calculateTableHeight();

		this.finishedLoading?.emit(true);
	}

	/**
	 * Setup to display the filtered rows based on the setting columns
	 * specified.
	 *
	 * @memberof CommonTableComponent
	 */
	public async filteredRows(): Promise<void>
	{
		if ((<HTMLInputElement>document
			.getElementById('resultSetCount')).value === this.rowCount)
		{
			return;
		}

		this.rowCount =
			(<HTMLInputElement>document
				.getElementById('resultSetCount')).value;

		this.tableDefinitions
			.rowCountChanged(parseInt(
				this.rowCount,
				AppConstants.parseRadix));
	}

	/**
	 * Setup to display the filtered columns based on the setting columns
	 * specified.
	 *
	 * @memberof CommonTableComponent
	 */
	public filteredColumns(): void
	{
		this.tableDefinitions.columnSelectionModeChanged(
			true);

		const newColumns = [];
		for (const selectedColumn of this.selectedColumns)
		{
			for (const availableColumn of this.totalAvailableColumns)
			{
				if (selectedColumn.value === availableColumn.dataKey)
				{
					newColumns.push(availableColumn);
				}
			}
		}

		newColumns.sort(
			(itemOne, itemTwo) =>
				itemOne.displayOrder - itemTwo.displayOrder);

		this.columns = newColumns;

		this.selectedColumns.splice(0, 100);

		for (const column of Object.keys(this.columns))
		{
			if (!AnyHelper.isNullOrEmpty(
				this.columns[column].dataKey)
				&& this.columns[column].displayOrder > 0
				&& this.columns[column].displayOrder < 100)
			{
				this.selectedColumns.push(
					{
						value: this.columns[column].dataKey,
						label: this.columns[column].columnHeader
					});
			}
		}

		this.tableDefinitions.selectedColumnsChanged(
			newColumns);
		this.addExpanderArrow();
		this.addRowActions();
	}

	/**
	 * Creates and returns a dynamic component context used to send data
	 * into the current row expand component.
	 *
	 * @async
	 * @returns {IDynamicComponentContext<Component, any>}
	 * The context of the dynamic row expand component sent into this object
	 * list.
	 * @param {any} data
	 * The specific data object from the selected or active row.
	 * @memberof CommonTableComponent
	 */
	public async getObjectContext(data: any):
		Promise<IDynamicComponentContext<Component, any>>
	{
		const contextData = {data: data};
		let contextViewOnly = false;

		const currentActiveDisplay: string =
			this.getActiveDisplayType();

		const contextLayout: FormlyFieldConfig[] =
			this.getNestedDefinitionByActiveActionDisplay(
				'layout',
				currentActiveDisplay,
				[]);

		const contextDefinition: object =
			this.getNestedDefinitionByActiveActionDisplay(
				'definition',
				currentActiveDisplay);

		const useAdditionalRowDataPromise: boolean =
			this.getNestedDefinitionByActiveActionDisplay(
				'useAdditionalRowDataPromise',
				currentActiveDisplay);

		const additionalRowDataPromise =
			this.getNestedDefinitionByActiveActionDisplay(
				'additionalRowData',
				currentActiveDisplay);

		if (!AnyHelper.isNullOrEmpty(additionalRowDataPromise))
		{
			contextData.data.additionalRowData =
				useAdditionalRowDataPromise === true
					? await StringHelper
						.transformToDataPromise(
							StringHelper.interpolate(
								additionalRowDataPromise,
								this.pageContext),
							this.pageContext)
					: await additionalRowDataPromise();
		}

		this.customContext =
			this.getNestedDefinitionByActiveActionDisplay(
				'customContext',
				currentActiveDisplay);

		this.additionalLayout =
			this.getNestedDefinitionByActiveActionDisplay(
				'additionalLayout',
				currentActiveDisplay);

		contextViewOnly = currentActiveDisplay ===
			AppConstants.commonTableActions.view;

		contextLayout?.forEach(
			(fieldConfig: FormlyFieldConfig) =>
			{
				fieldConfig.templateOptions.appendTo =
					FormlyConstants.appendToTargets.body;
			});

		this.formlyDefinitions =
			{
				definition: contextDefinition,
				layout: contextLayout,
				viewOnly: contextViewOnly,
				data: contextData
			};

		return <IDynamicComponentContext<Component, any>>
		{
			data: contextData,
			source: this
		};
	}

	/**
	 * Creates and returns a dynamic component context used to send data
	 * into the current row expand component.
	 *
	 * @returns {Promise<void>}
	 * The context of the dynamic row expand component sent into this object
	 * list.
	 * @param {Promise<any[]>} apiPromise
	 * The api service required to pull the data from and generate the
	 * table data.
	 * @memberof CommonTableComponent
	 */
	public async loadListData(apiPromise: Promise<any[]>): Promise<void>
	{
		// Handle dynamic keyword columns without keywords defined.
		if (this.displayKeywordMessage === true
			&& this.columns.length === 0)
		{
			this.loadingListData = false;
			this.changeDetectorRef.detectChanges();
			this.finishedLoading?.emit(!this.loadingListData);

			return new Promise((resolve) =>
				resolve());
		}

		this.loadingListData = true;

		if (this.dataSubscription != null)
		{
			this.dataSubscription.unsubscribe();
		}

		return new Promise((resolve) =>
		{
			this.dataSubscription =
				from(
					apiPromise)
					.subscribe((data: IBaseEntity[]) =>
					{
						this.listData =
							[
								...this.listData,
								...data
							];

						if (this.listData.length === 1
							&& this.tableDefinitions.actions?.updateIndex)
						{
							this.columns.splice(
								this.columns
									.findIndex((column) =>
										column.dataKey === 'updateIndex'),
								1);
						}

						this.setTotalRecords();
						this.setScrollable();
						this.setAdditionalRecordsExist();
						this.setTableHeight(
							this.tableDefinitions.objectSearch.virtualPageSize);

						if (this.listData.length > 0)
						{
							this.virtualData = this.loadVirtualData();

							this.feedVirtualData(
								{
									first: 0,
									rows:
										this.tableDefinitions
											.objectSearch.virtualPageSize
								},
								false);
						}
						else
						{
							this.tableHeight = `${1 + this.headerRowHeight}px`;
						}

						this.loadingListData = false;
						this.changeDetectorRef.detectChanges();
						this.finishedLoading?.emit(!this.loadingListData);

						resolve();
					});
		});
	}

	/**
	 * Loads a next data set on scrolling.
	 *
	 * @param {LazyLoadEvent} lazyLoadEvent
	 * The primeng function handler for the lazy load event.
	 * @memberof CommonTableComponent
	 */
	public async loadNextDataSet(lazyLoadEvent: LazyLoadEvent): Promise<void>
	{
		this.currentLazyLoadEvent = lazyLoadEvent;

		if (this.tableDefinitions.allowSortColumns === true
			&& !AnyHelper.isNullOrEmpty(lazyLoadEvent.sortField)
			&& (lazyLoadEvent.sortOrder !== this.sortOrder
				|| lazyLoadEvent.sortField !== this.sortField))
		{
			this.loadSortedData(lazyLoadEvent);
		}
		else
		{
			if (this.loadingListData === true)
			{
				return;
			}

			if (this.displayCreate === true
				&& this.loadingCreateDisplay === false
				&& lazyLoadEvent.first !== 0)
			{
				this.toggleCreateDisplay();
			}

			if ((lazyLoadEvent.first + lazyLoadEvent.rows
				>= (this.totalRecords))
				&& this.totalRecords >= this.tableDefinitions.objectSearch.limit
				&& this.additionalRecordsExist === true)
			{
				// avoid to display blank rows simulating loading the
				// data entirely.
				this.virtualData =
					[
						...this.listData
					];

				this.tableDefinitions.objectSearch.offset +=
					this.tableDefinitions.objectSearch.limit;

				this.apiPromise =
					!AnyHelper.isNullOrEmpty(this.tableDefinitions.apiPromise)
						? this.tableDefinitions.apiPromise(
							this.tableDefinitions.objectSearch)
						: StringHelper
							.transformToDataPromise(
								StringHelper.interpolate(
									this.tableDefinitions.apiPromiseString,
									this.pageContext),
								this.pageContext);

				await this.loadListData(this.apiPromise);
				this.virtualData = this.loadVirtualData();
				this.feedVirtualData(
					lazyLoadEvent,
					true);

				return;
			}

			this.feedVirtualData(
				lazyLoadEvent,
				false);
		}
	}

	/**
	 * Sets virtual data on load from list data.
	 *
	 * @returns {any[]}
	 * The virtual data array on .
	 * @memberof CommonTableComponent
	 */
	public loadVirtualData(): any[]
	{
		return [
			... this.listData
				.slice(
					0,
					this.tableDefinitions.objectSearch.virtualPageSize),
			... (this.listData.length -
				this.tableDefinitions.objectSearch.virtualPageSize * 2) < 0
				? []
				: Array.from(
					{
						length: this.listData.length -
							this.tableDefinitions.objectSearch.virtualPageSize
					})
		];
	}

	/**
	 * Feeds the virtual data based on the lazy load event.
	 *
	 * @memberof CommonTableComponent
	 */
	public feedVirtualData(
		lazyLoadEvent: LazyLoadEvent,
		loadingNextDataSet: boolean): void
	{
		// Mock virtual data while is loading for more data
		// this is to avoid displaying blank rows.
		this.virtualData = this.listData;

		if (loadingNextDataSet === true)
		{
			// load data of required page
			const loadedData: object[] =
				this.listData
					.slice(
						0,
						(lazyLoadEvent.first + lazyLoadEvent.rows));

			// populate page of virtual data
			this.virtualData
				.splice(
					0,
					lazyLoadEvent.first + lazyLoadEvent.rows,
					...loadedData);
		}
		else
		{
			// load data of required page
			const loadedData =
				this.listData
					.slice(
						lazyLoadEvent.first,
						(lazyLoadEvent.first + lazyLoadEvent.rows));

			// populate page of virtual data
			Array.prototype
				.splice
				.apply(
					this.virtualData,
					[
						...[
							lazyLoadEvent.first,
							lazyLoadEvent.rows
						],
						...loadedData
					]);
		}

		const sortReference =
			(this.tableDefinitions.objectSearch.orderBy)
				.split(AppConstants.characters.space);
		const sortProperty: string = sortReference[0];
		const sortDirection: number =
			sortReference.length === 2
				&& sortReference[1] === AppConstants.sortDirections.descending
				? -1
				: 1;

		this.virtualData.sort(
			(firstItem: any, secondItem: any) =>
			{
				const itemOne =
					get(
						firstItem,
						sortProperty);
				const itemTwo =
					get(
						secondItem,
						sortProperty);

				return (itemOne - itemTwo) * sortDirection;
			});

		// Handle less than full page responses.
		if (this.tableDefinitions.tableHeight?.virtualPageSizeBased !== false
			&& this.virtualData.length <
				this.tableDefinitions.objectSearch.virtualPageSize)
		{
			this.tableHeight =
				`${(this.rowHeight * this.virtualData.length)
					+ this.headerRowHeight}px`;
		}
		else
		{
			this.setTableHeight(
				this.tableDefinitions.objectSearch.virtualPageSize);
		}

		// Trigger change detection.
		this.virtualData =
			[
				...this.virtualData
			];
	}

	/**
	 * Sorts the list data ascending or descending based on the
	 * lazy load sort field and sort order.
	 *
	 * @memberof CommonTableComponent
	 */
	public loadSortedData(lazyLoadEvent: LazyLoadEvent): void
	{
		const sortFieldColumn: string =
			lazyLoadEvent.sortField.replace(
				AppConstants.nestedDataIdentifier,
				AppConstants.empty);

		const sortFieldOrder: string =
			lazyLoadEvent.sortOrder === 1
				? AppConstants.sortDirections.ascending
				: AppConstants.sortDirections.descending;

		const sortCriteria: string =
			sortFieldColumn.toLowerCase() === 'id'
				? `${sortFieldColumn} ${sortFieldOrder}`
				: `${sortFieldColumn} ${sortFieldOrder}, id ${AppConstants
					.sortDirections.descending}`;

		const sortOrderBy =
			(!AnyHelper.isNullOrEmpty(sortFieldColumn))
				? sortCriteria
				: this.tableDefinitions.objectSearch.orderBy;

		this.tableDefinitions.sortCriteriaChanged(
			sortOrderBy,
			lazyLoadEvent.sortField,
			lazyLoadEvent.sortOrder);
	}

	/**
	 * Adds an empty row data object for the create action.
	 *
	 * @param {object[]} data
	 * The data object used as a template for the create object.
	 * @returns {object[]}
	 * @memberof CommonTableComponent
	 */
	public addCreateRowDataObject(data: object[]): object[]
	{
		const createObject = [{id: 0}];

		if (!AnyHelper.isNullOrEmpty(data[0]))
		{
			for (const value of Object.keys(data[0]))
			{
				if (value !== 'id')
				{
					switch (typeof data[0][value])
					{
						case 'string':
							createObject[0][value] = AppConstants.empty;
							break;
						case 'boolean':
							createObject[0][value] = false;
							break;
						default:
							createObject[0][value] = null;
					}
				}
			}
		}
		else
		{
			this.columns
				.forEach((value: any) =>
				{
					const keyValue = value.dataKey;
					if (value.displayOrder < 100
						&& value.displayOrder > 0)
					{
						createObject[0][keyValue] = AppConstants.empty;
					}
				});
		}

		return createObject;
	}

	/**
	 * Overrides the prime ng table expansion event by ensuring that any
	 * clicks made on the row actions button will not expand or collapse
	 * the existing row, and handle the switching views for each row.
	 *
	 * @param {any} expandEvent
	 * The event sent from the prime ng table on expand or collapse.
	 * @param {boolean} expanded
	 * The truthy defining if this event would be a collapse or an
	 * expand event.
	 * @param {Table} commonTable
	 * The Common Table.
	 * @memberof CommonTableComponent
	 */
	public rowExpansion(
		expandEvent: any,
		expanded: boolean,
		commonTable: Table = null): Promise<void>
	{
		this.objectContext = null;
		this.loadingExpansion = true;
		this.finishedLoading?.emit(true);

		if (this.dataSubscription != null)
		{
			this.dataSubscription.unsubscribe();
		}

		return new Promise((resolve) =>
		{
			this.dataSubscription =
				from(
					this.handleRowExpansion(
						expandEvent,
						expanded
					))
					.subscribe(
						() =>
						{
							// Handle expand containers in non-scrollable views.
							if (this.tableDefinitions.tableHeight
								?.virtualPageSizeBased !== false
								&& this.scrollable === false)
							{
								this.calculateTableHeight();
							}

							const rowIndex = this.listData.findIndex(
								(data) => data.id === this.rowData.id);

							if (!AnyHelper.isNullOrEmpty(commonTable)
								&& rowIndex >= 0)
							{
								commonTable.scrollToVirtualIndex(rowIndex);
							}

							this.loadingExpansion = false;
							this.finishedLoading?.emit(true);
							resolve();
						});
		});
	}

	/**
	 * Handles the row expansion event when expanded or collapsed..
	 *
	 * @async
	 * @param {any} expandEvent
	 * The event sent from the prime ng table on expand or collapse.
	 * @param {boolean} expanded
	 * The truthy defining if this event would be a collapse or an
	 * expand event.
	 * @memberof CommonTableComponent
	 */
	public async handleRowExpansion(
		expandEvent: any,
		expanded: boolean): Promise<void>
	{
		const event: any = expandEvent?.originalEvent;

		this.displayUpdate = event?.target?.childNodes[0]?.childNodes[0]?.
			className?.indexOf(this.displayUpdateButtonClass) >= 0
			|| event?.target.className?.indexOf(
				this.displayUpdateButtonClass) >= 0;

		this.displayDelete = event?.target.childNodes[0]?.className?.
			indexOf(this.displayDeleteButtonClass) >= 0
			|| event?.target.className?.indexOf(
				this.displayDeleteButtonClass) >= 0;

		if (this.expandedRows[0] === true
			&& expandEvent.data?.id !== 0
			&& this.loadingCreateDisplay === false)
		{
			if (this.displayCreate === true)
			{
				this.toggleCreateDisplay();
			}
		}

		if (this.displayCreate === false
			&& this.displayUpdate === true
			&& this.tableDefinitions
				.actions?.update?.disabledExpandRow === true)
		{
			this.setExpandedRow(false);
			this.rowData = expandEvent.data;
			this.setCommonTableContext();
			this.tableDefinitions.actions.update.items[0]?.command();

			return;
		}

		if (this.displayCreate === false
			&& this.displayDelete === true
			&& this.tableDefinitions
				.actions?.delete?.disabledExpandRow === true)
		{
			this.setExpandedRow(false);
			this.rowData = expandEvent.data;
			this.setCommonTableContext();
			this.tableDefinitions.actions.delete.items[0]?.command();
			this.toggleCreateDisplay();

			return;
		}

		if ((expanded === false
			|| this.rowData?.id !== expandEvent.data.id)
			&& this.previousUpdateView === true)
		{
			this.virtualData = [
				...this.getVirtualData()
			];
		}

		this.dataBackup =
			this.rowData?.id === expandEvent.data.id
				&& this.previousUpdateView === true
				? this.dataBackup
				: cloneDeep(expandEvent.data);

		this.rowData = expandEvent.data;
		this.previousUpdateView = false;

		this.setCommonTableContext();

		const ellipsisButtonClicked: boolean =
			event?.target.className?.indexOf(this.ellipsisButtonClass) >= 0;

		const drillInButtonClicked: boolean =
			event?.target.className?.indexOf(this.drillInButtonClass) >= 0;

		const updateIndexUp: boolean =
			event?.target.className?.indexOf(this.updateIndexUpClass) >= 0;

		const updateIndexDown: boolean =
			event?.target.className?.indexOf(this.updateIndexDownClass) >= 0;

		if (this.displayCreate === true
			&& expanded === false
			&& this.loadingCreateDisplay === false)
		{
			this.displayCreate = false;
		}

		if (ellipsisButtonClicked === true
			|| drillInButtonClicked === true
			|| updateIndexUp === true
			|| updateIndexDown === true)
		{
			this.expandedRows = {};
			if (expanded === false)
			{
				const expandRow =
					this.displayUpdate
						|| this.displayDelete
						|| this.displayView;

				this.setExpandedRow(expandRow);
			}

			if (drillInButtonClicked === true)
			{
				const drillInCommand: void | Promise<any> =
					!AnyHelper.isNullOrEmpty(
						this.tableDefinitions.actions.drillIn.command)
						? this.tableDefinitions.actions.drillIn.command()
						: StringHelper
							.transformToDataPromise(
								StringHelper.interpolate(
									this.tableDefinitions.actions.drillIn
										.commandString,
									this.pageContext),
								this.pageContext);

				await drillInCommand;
			}

			if (updateIndexUp === true)
			{
				this.tableDefinitions.actions.updateIndex[0]
					.command(this.pageContext.source);
			}

			if (updateIndexDown === true)
			{
				this.tableDefinitions.actions.updateIndex[1]
					.command(this.pageContext.source);
			}
		}
		else
		{
			if (this.displayUpdate === true
				|| this.displayDelete === true)
			{
				this.previousUpdateView =
					this.displayUpdate === true;

				this.displayView = false;
				if (expanded === false)
				{
					this.setExpandedRow(false);
					setTimeout(() =>
					{
						this.setExpandedRow(true);
					},
					this.switchViewsInterval);
				}
			}
			else
			{
				if (this.displayCreate === false)
				{
					if (this.tableDefinitions.actions?.view)
					{
						this.displayView = expanded;
						if (this.tableDefinitions
							.actions.view.disabledExpandRow === true
							|| (this.tableDefinitions
								.actions?.view?.items.length === 0
								&& (AnyHelper.isNull(
									this.tableDefinitions.actions?.view?.layout)
									|| this.tableDefinitions.actions?.view
										?.layout.length === 0)
								&& AnyHelper.isNull(
									this.tableDefinitions
										.actions?.view?.component)))
						{
							this.setExpandedRow(false);
							this.setCommonTableContext();
							this.tableDefinitions
								.actions.view.items[0]?.command();

							return;
						}
					}
					else
					{
						this.setExpandedRow(false);
						this.displayView = false;

						return;
					}

					if (this.actionCancelled === true)
					{
						this.setExpandedRow(true);
						this.actionCancelled = false;
						this.displayView = true;
					}

				}
				else
				{
					this.setExpandedRow(true);
				}
			}
		}

		this.rowExpanded = expanded;

		this.dynamicComponent =
			this.getNestedDefinitionByActiveActionDisplay(
				'component',
				this.getActiveDisplayType(),
				this.baseExpandComponent);

		if (!AnyHelper.isNullOrEmpty(this.expandActions))
		{
			this.expandActions.shift();
		}

		this.expandActions =
			this.getNestedDefinitionByActiveActionDisplay(
				'items',
				this.getActiveDisplayType(),
				[]);

		if (this.expandActions[0]?.label !== 'Cancel'
			&& this.expandActions[0]?.label !== 'Close')
		{
			if (this.displayView === false)
			{
				this.expandActions.unshift({
					label: 'Cancel',
					icon: AppConstants.empty,
					id: 'cancelExpandAction',
					command: async() =>
					{
						this.actionCancelled = true;
						if (this.displayUpdate === true)
						{
							this.actionCancelled = false;
							this.rowExpansion(
								{data: this.rowData},
								false);
						}
						else if (this.displayCreate === true)
						{
							this.toggleCreateDisplay();
						}

						this.setExpandedRow(false);
						this.finishedLoading?.emit(true);
					}
				});
			}
			else if (this.tableDefinitions
				.actions.drillInOnRowSelection !== true)
			{
				this.expandActions.unshift({
					label: 'Close',
					icon: AppConstants.empty,
					id: 'closeExpandAction',
					command: async() =>
					{
						this.actionCancelled = true;
						this.setExpandedRow(false);

						this.finishedLoading?.emit(true);
					}
				});
			}
		}

		this.setCommonTableContext();
		this.expandTitle =
			(typeof this.tableDefinitions.expandTitle === 'function')
				? this.tableDefinitions.expandTitle()
				: this.tableDefinitions.expandTitle;

		this.objectContext = await this.getObjectContext(this.rowData);
	}

	/**
	 * Calculates and sets the display height of the table if this is in a
	 * non scrollable view.
	 *
	 * @memberof CommonTableComponent
	 */
	public setupCalculatedHeight(): void
	{
		if (this.tableDefinitions.tableHeight?.virtualPageSizeBased !== false
			&& this.virtualData.length <
				this.tableDefinitions.objectSearch.virtualPageSize)
		{
			const boundingBox: any =
				this.commonTableExpandContainer?.nativeElement
					.getBoundingClientRect();

			this.tableHeight =
				`${(this.rowHeight
					* (this.virtualData.length
						+ (this.tableDefinitions
							.actions?.create?.displayCreateRow === false
							&& this.displayCreate === true
							? -1
							: 0)))
					+ this.headerRowHeight
					+ (this.rowExpanded === true
						? (boundingBox?.height || 0)
						: 0)}px`;

			this.changeDetectorRef.detectChanges();
		}
		else
		{
			this.setTableHeight(
				this.tableDefinitions.objectSearch.virtualPageSize);
		}
	}

	/**
	 * Sets if there are any more additional results available.
	 *
	 * @memberof CommonTableComponent
	 */
	public setAdditionalRecordsExist(): void
	{
		if (this.additionalRecords === this.totalRecords)
		{
			this.additionalRecordsExist = false;

			return;
		}

		this.additionalRecords =
			this.additionalRecords !== this.totalRecords
				? this.totalRecords
				: this.additionalRecords;

		// Definition if there is any additional records to display
		this.additionalRecordsExist = (this.totalRecords /
			this.tableDefinitions.objectSearch.limit) % 1 === 0;
	}

	/**
	 * Sets the scrollable action to be enabled or disabled on the table body.
	 *
	 * @memberof CommonTableComponent
	 */
	public setScrollable(): void
	{
		// Make the table scrollable body enabled or disabled
		this.scrollable = this.totalRecords
			>= this.tableDefinitions.objectSearch.virtualPageSize;
	}

	/**
	 * Gets the set of columns defined and setups the columns
	 * that will be displayed currently in this object list table.
	 *
	 * @memberof CommonTableComponent
	 */
	public setSelectedColumns(): void
	{
		if (this.tableDefinitions.columnSelectionMode === true)
		{
			this.columns = this.tableDefinitions.selectedColumns;
			this.totalAvailableColumns = this.tableDefinitions.availableColumns;

			return;
		}

		this.columnDisplayCount = Math.ceil(
			(this.siteLayoutService.contentWidth)
				/ this.additionalColumnRequiredWidth);

		this.columns =
			this.tableDefinitions.availableColumns
				.slice(
					0,
					this.columnDisplayCount);

		this.totalAvailableColumns = this.tableDefinitions.availableColumns;
		this.tableDefinitions.selectedColumnsChanged(
			this.columns);
	}

	/**
	 * Gets the column display type.
	 *
	 * @memberof CommonTableComponent
	 */
	public getColumnDisplay(
		column: any): string
	{
		return ((column.dataKey === 'arrow' && column.displayOrder === 0)
			|| column.displayOrder > 99)
			? 'table-cell !important'
			: 'flex !important';
	}

	/**
	 * Adds the row actions if sent into this object list to each
	 * individual row. This will always be displayed as the last column
	 * in the object list.
	 *
	 * @memberof CommonTableComponent
	 */
	public addRowActions(): void
	{
		if (this.tableDefinitions.actions)
		{
			let rowActionsCounter = 100;
			for (const action of Object.keys(
				this.tableDefinitions.actions))
			{
				const hasColumn: boolean =
					this.columns
						.filter(column =>
							column.dataKey === action)
						.length > 0;

				if (hasColumn)
				{
					continue;
				}

				if (action !== AppConstants.commonTableActions.view
					&& action !== AppConstants.commonTableActions.create
					&& action !==
						AppConstants.commonTableActions.tableLevelEllipsis
					&& action !== AppConstants.commonTableActions.filter)
				{
					this.columns.push(
						<ICommonTableColumn>
						{
							dataKey: action,
							columnHeader: AppConstants.empty,
							displayOrder: rowActionsCounter++
						});
				}
			}
		}
	}

	/**
	 * Adds the expander arrow if sent into this object list to each
	 * individual row. This will always be displayed as the first column
	 * in the object list.
	 *
	 * @memberof CommonTableComponent
	 */
	public addExpanderArrow(): void
	{
		if (this.tableDefinitions.hideExpanderArrow !== true
			&& this.columns[0]?.dataKey !== 'arrow')
		{
			this.columns.unshift(
				<ICommonTableColumn>
				{
					dataKey: 'arrow',
					columnHeader: AppConstants.empty,
					displayOrder: 0
				});
		}
	}

	/**
	 * Sets the height of the table for display to equal the rowHeiht times
	 * provided virtualPageSize.
	 *
	 * @param {number} height
	 * The height number used to calculate the table height
	 * @memberof CommonTableComponent
	 */
	public setTableHeight(
		height: number): void
	{
		this.tableHeight =
			(this.tableDefinitions.tableHeight?.virtualPageSizeBased !== false)
				? `${(this.rowHeight * height) + this.headerRowHeight}px`
				: `${this.tableDefinitions.tableHeight.explicitHeight}px`;
	}

	/**
	 * Sets the total records amount to be displayed within the table.
	 *
	 * @memberof CommonTableComponent
	 */
	public async setTotalRecords(): Promise<void>
	{
		this.totalRecords = this.listData.length;
	}

	/**
	 * Sets the settings logic and view to handle the set columns and
	 * set rows withing the table settings drawer display.
	 *
	 * @memberof CommonTableComponent
	 */
	public setupSettings(): void
	{
		this.rowCount =
			this.tableDefinitions.objectSearch
				.virtualPageSize.toString();

		if (this.tableDefinitions.columnSelectionMode === true)
		{
			this.columns = this.tableDefinitions.selectedColumns;
		}

		this.availableColumns = [];
		this.selectedColumns =
			[
				{
					value: AppConstants.empty,
					label: AppConstants.empty
				}
			];
		this.selectedColumns.splice(0, 100);

		for (const column of Object.keys(this.columns))
		{
			if (!AnyHelper.isNullOrEmpty(
				this.columns[column].dataKey)
				&& this.columns[column].displayOrder > 0
				&& this.columns[column].displayOrder < 100)
			{
				this.selectedColumns.push(
					{
						value: this.columns[column].dataKey,
						label: this.columns[column].columnHeader
					});
			}
		}

		for (const column of Object.keys(this.totalAvailableColumns))
		{
			if (!AnyHelper.isNullOrEmpty(
				this.totalAvailableColumns[column].dataKey))
			{
				this.availableColumns.push(
					{
						value: this.totalAvailableColumns[column].dataKey,
						label: this.totalAvailableColumns[column].columnHeader
					});
			}
		}
	}

	/**
	 * Handles the on change event for the multiselet in order
	 * to set the selection to no less than 1.
	 *
	 * @param {{value: object[]}} event
	 * The value sent from the select change event
	 * @memberof CommonTableComponent
	 */
	public onMultiselectChange(event: {value: object[]}): void
	{
		const index: number =
			this.tableDefinitions.hideExpanderArrow === true
				? 0
				: 1;

		if (AnyHelper.isNullOrEmpty(event.value[0]))
		{
			this.selectedColumns.push(
				{
					value: this.columns[index].dataKey,
					label: this.columns[index].columnHeader
				});
		}
	}

	/**
	 * Scrolls the current table to the bottom of the in memory list, and
	 * if available loads the next data set into view.
	 *
	 * @param {Table} commonTable
	 * The table identifier.
	 * @memberof CommonTableComponent
	 */
	public scrollTableToBottom(commonTable: Table): void
	{
		commonTable.scrollToVirtualIndex(this.listData.length - 1);
	}

	/**
	 * Scrolls the current table to the top of the in memory list, and
	 * if available toggles the create display.
	 *
	 * @param {any} event
	 * The click event.
	 * @param {Table} commonTable
	 * The table identifier.
	 * @memberof CommonTableComponent
	 */
	public async scrollTableToTop(
		event?: any,
		commonTable?: Table): Promise<void>
	{
		commonTable?.scrollToVirtualIndex(0);

		if (event?.target?.className?.indexOf('fa-plus-circle') > 0)
		{
			this.toggleCreateDisplay();
		}
	}

	/**
	 * Toggles to display or hide the create view.
	 *
	 * @memberof CommonTableComponent
	 */
	public async toggleCreateDisplay(): Promise<void>
	{
		if (this.loadingListData === true
			|| this.loadingCreateDisplay === true)
		{
			return;
		}

		if (this.tableDefinitions
			.actions.create.disabledExpandRow === true)
		{
			this.setCommonTableContext();

			if (!AnyHelper.isNullOrEmpty(
				this.tableDefinitions.actions.create.commandString))
			{
				StringHelper
					.transformToDataPromise(
						StringHelper.interpolate(
							this.tableDefinitions.actions
								.create.commandString,
							this.pageContext),
						this.pageContext);

				return;
			}

			this.tableDefinitions.actions.create.items[0]?.command();

			return;
		}

		this.displayCreate = (this.displayCreate === false);
		this.finishedLoading?.emit(true);

		if (this.displayCreate === true)
		{
			this.loadingCreateDisplay = true;
			this.tableDefinitions.objectSearch.filter =
				this.selectedFilterValue;

			setTimeout(() =>
			{
				if (this.virtualData[0]?.id !== 0)
				{
					this.virtualData =
						[
							...this.addCreateRowDataObject(this.listData),
							...this.virtualData
						];
				}
				this.displayView = false;
				this.rowExpansion(
					{data: this.virtualData[0]},
					this.displayCreate);

				this.loadingCreateDisplay = false;
				this.finishedLoading?.emit(true);

				this.setExpandedRow(true);
				this.calculateTableHeight();
			},
			AnyHelper.isNullOrEmpty(this.currentLazyLoadEvent)
				|| this.currentLazyLoadEvent?.first === 0
				? AppConstants.time.threeHundredMilliSeconds
				: AppConstants.time.threeQuarterSecond);
		}
		else
		{
			this.setExpandedRow(false);

			this.virtualData =
				this.virtualData
					.filter(data =>
						!AnyHelper.isNullOrEmpty(data?.id)
							&& data.id !== 0);

			this.calculateTableHeight();
		}
	}

	/**
	 * Toogles the setting display to be displayed as a drawer with
	 * setting options.
	 *
	 * @memberof CommonTableComponent
	 */
	public async toggleSettingsDisplay(): Promise<void>
	{
		if (this.loadingDrawer === true)
		{
			return;
		}

		this.loadingDrawer = true;
		this.displaySettings =
			this.displaySettings === false;
		this.drawerWidth =
			`${(document.getElementById('tableActions').clientWidth)}px`;

		if (this.displayCreate === true)
		{
			this.toggleCreateDisplay();
		}

		if (this.displaySettings === true)
		{
			setTimeout(
				() =>
				{
					this.drawerOverflow = true;
					this.loadingDrawer = false;
				},
				this.switchViewsInterval);
		}
		else
		{
			this.drawerOverflow = false;
			this.loadingDrawer = false;
		}

		if (this.loadingSettings === false)
		{
			(<HTMLInputElement>document
				.getElementById('resultSetCount')).value = this.rowCount;
		}
	}

	/**
	 * Handles the focus out event for the row count setting
	 * input number in order to limit the input to its established
	 * boundaries and restric the value to be a multiple of 5.
	 *
	 * @param {string} value
	 * The row count input value.
	 * @memberof CommonTableComponent
	 */
	public rowCountFocusOut(value: string): void
	{
		const minRowCount: number = 5;
		const maxRowCount: number = 25;
		const rowCountInput = parseInt(value, AppConstants.parseRadix);
		const remainderMinMaxRowCount =
			rowCountInput % minRowCount !== 0
				? (Math.ceil(rowCountInput / minRowCount)
					* minRowCount).toString()
				: value;
		const inputGreater =
			rowCountInput > maxRowCount
				? maxRowCount.toString()
				: remainderMinMaxRowCount;

		(<HTMLInputElement>document.getElementById('resultSetCount')).value =
			(rowCountInput < minRowCount)
				? minRowCount.toString()
				: inputGreater;
	}

	/**
	 * Handles the on input event for the row count setting
	 * input number in order to limit the input to its established
	 * boundaries.
	 *
	 * @memberof CommonTableComponent
	 */
	public cancelSettings(): void
	{
		(<HTMLInputElement>document.getElementById('resultSetCount')).value =
			this.tableDefinitions.objectSearch.virtualPageSize
				.toString();

		this.toggleSettingsDisplay();
	}

	/**
	 * Handles the on operation button bar clicked event in order
	 * to manage any desired action upon button click action.
	 *
	 * @param {any} rowData
	 * The rowData on where the operation button was clicked.
	 * @memberof CommonTableComponent
	 */
	public async operationButtonClicked(event:
		{
			operationExecuted: boolean;
			exception: string;
		}): Promise<void>
	{
		if (event.operationExecuted === true)
		{
			this.loadingTableDefinitions = true;
			this.finishedLoading?.emit(!this.loadingTableDefinitions);

			return;
		}

		if (this.actionCancelled === false)
		{
			this.expandTitle =
				(typeof this.tableDefinitions.expandTitle === 'function')
					? this.tableDefinitions.expandTitle()
					: this.tableDefinitions.expandTitle;
			this.setExpandedRow(false);
			this.tableDefinitions.objectSearch.filter =
				this.selectedFilterValue;

			if (this.displayUpdate === true)
			{
				this.actionButtonClicked = true;
				if (AnyHelper.isNull(event.exception))
				{
					this.displayUpdate = false;
					this.virtualData = [...this.virtualData];
					this.dataBackup = cloneDeep(this.rowData);
				}
				else
				{
					this.rowExpansion(
						{
							data: this.dataBackup
						},
						false);
				}
			}
		}

		this.loadingTableDefinitions = false;
		this.changeDetectorRef.detectChanges();
		this.finishedLoading?.emit(!this.loadingTableDefinitions);
	}

	/**
	 * Stops the expand propagation event.
	 *
	 * @param {any} event
	 * @memberof CommonTableComponent
	 */
	public stopExpandEvent(event: any): void
	{
		if (this.loadingTableDefinitions === true)
		{
			event.stopPropagation();
		}
	}

	/**
	 * Validates the expand component changes.
	 *
	 * @param {boolean} isValid
	 * Determines when a expan component change is valid.
	 * @memberof CommonTableComponent
	*/
	public validExpandComponentChanged(isValid: boolean): void
	{
		if (this.displayDelete === true)
		{
			return;
		}

		this.expandActions?.forEach(
			(action: MenuItem,
				index: number) =>
			{
				if (action.label !== 'Cancel'
					&& action.label !== 'Close')
				{
					this.expandActions[index].disabled = !isValid;
				}
			});
	}

	/**
	 * Gets the data for each column from the existing instance row.
	 * This utilizes the mapped properties of the available data.
	 * @param {string} key
	 * The key for the column.
	 * @param {any} entityInstance
	 * The entity instance to get the data from.
	 * @memberof CommonTableComponent
	 * @returns {string}
	 * The value to be displayed.
	 */
	public getTableColumnData(
		key: string,
		item: object,
		dataFunction: string = null): string
	{
		const splitIdentifier = key.split('.');

		let dataValue: any = item[splitIdentifier[0]];
		if (splitIdentifier.length > 1)
		{
			for (let i: number = 1; i < splitIdentifier.length; i++)
			{
				if (!AnyHelper.isNullOrEmpty(dataValue))
				{
					dataValue = (splitIdentifier[i].indexOf('[0]') !== -1)
						? dataValue[splitIdentifier[i].replace('[0]', '')][0]
						: dataValue[splitIdentifier[i]];
				}
			}
		}

		if (!AnyHelper.isNullOrEmpty(dataFunction))
		{
			dataValue = StringHelper.transformToFunction(
				dataFunction,
				this.pageContext)(dataValue);
		}

		return dataValue;
	}

	/**
	 * Handles the row select event sent when clicking on a displayed table row
	 * which has the selectable properties enabled.
	 *
	 * @param {any} event
	 * The select event passed from the common view.
	 * @param {boolean} selected
	 * Whether this row is selected or not.
	 * @memberof CommonTableComponent
	 */
	public rowSelect(
		event: any,
		selected: boolean): void
	{
		if (AnyHelper.isNullOrEmpty(
			this.tableDefinitions.rowSelection?.selectedRowEvent))
		{
			return;
		}

		this.tableDefinitions.rowSelection
			.selectedRowEvent(
				event,
				selected);
	}

	/**
	 * Handles the common table click outside directive.
	 *
	 * @param {any} event
	 * The select event passed from the common view.
	 * @param {boolean} selected
	 * Whether this row is selected or not.
	 * @memberof CommonTableComponent
	 */
	public commonTableClickOutside(
		event: any): void
	{
		// Simulate a pointer event if a click event is sent.
		if (AnyHelper.isNull(event.path))
		{
			let parentElement: any = event.target;
			const clickEventPath: any[] = [];

			while (parentElement != null)
			{
				clickEventPath.push(parentElement);
				parentElement = parentElement.parentElement;
			}

			event.path = clickEventPath;
		}

		const toggleClick: boolean =
			[
				...event.path[0].classList,
				...event.path[1].classList,
				...event.path[2].classList,
				...event.path[3].classList,
				...event.path[4].classList
			].filter(
				(value: string) =>
					this.toggleControls
						.includes(value)).length > 0;

		// Do not close this container if the click outside event comes from
		// a formly dropdown, calendar, or multi-select panel.
		if (toggleClick === true)
		{
			return;
		}

		if (this.displayUpdate === true)
		{
			this.actionCancelled = false;
			this.rowExpansion(
				{data: this.rowData},
				false);
		}
		else if (this.displayCreate === true)
		{
			this.toggleCreateDisplay();
		}

		this.setExpandedRow(false);
		this.finishedLoading?.emit(true);
	}

	/**
	 * Adds enabled row action icons if enabled.
	 *
	 * @param {any} rowData
	 * the row data.
	 * @memberof CommonTableComponent
	 */
	public addEnabledIconActions(rowData: any): any
	{
		rowData.enabledIconActions = [];

		this.tableDefinitions.actions.rowIconActions
			.items.forEach((action: any) => {
				if (this.rowActionEnabled(action, rowData))
				{
					rowData.enabledIconActions.push(action);
				}
			});

		return rowData;
	}

	/**
	 * Determnine if the row action should be enabled.
	 *
	 * @param {any} action
	 * the row action.
	 * @param {any} rowData
	 * the row data.
	 * @memberof CommonTableComponent
	 */
	public rowActionEnabled(
		action: any,
		rowData: any): boolean
	{
		try
		{
			if (!action.disabled
				&& action.enabled)
			{
				return action.enabled(
					{
						...rowData
					});
			}

			return !action.disabled;
		}
		catch (error)
		{
			return false;
		}
	}

	/**
	 * Gets the current active display type.
	 *
	 * @returns {string}
	 * The active display type name.
	 * @memberof CommonTableComponent
	 */
	private getActiveDisplayType(): string
	{
		let activeDisplayType: string = AppConstants.empty;

		if (this.displayCreate === true)
		{
			activeDisplayType = AppConstants.commonTableActions.create;
		}
		else if (this.displayUpdate === true)
		{
			activeDisplayType = AppConstants.commonTableActions.update;
		}
		else if (this.displayDelete === true)
		{
			activeDisplayType = AppConstants.commonTableActions.delete;
		}
		else if (this.displayView === true)
		{
			activeDisplayType = AppConstants.commonTableActions.view;
		}

		return activeDisplayType;
	}

	/**
	 * Gets the Nested Deftinition By Action Display.
	 *
	 * @param {string} keyDefinition
	 * The definition key name.
	 * @param {string} activeDisplayType
	 * The active display name type.
	 * @param {any} fallbackReturn
	 * The fallBack return if no value is found.
	 * @returns {any}
	 * The nested object value or the default return..
	 * @memberof CommonTableComponent
	 */
	private getNestedDefinitionByActiveActionDisplay(
		keyDefinition: string,
		activeDisplayType: string,
		fallbackReturn: any = null): any
	{
		const tableDefinitionActions: IActionTableConfiguration =
			this.tableDefinitions.actions;

		if (!AnyHelper.isNullOrEmpty(tableDefinitionActions))
		{
			switch (activeDisplayType)
			{
				case AppConstants.commonTableActions.create:
				{
					return !AnyHelper
						.isNullOrEmpty(
							tableDefinitionActions.create[keyDefinition])
						? tableDefinitionActions.create[keyDefinition]
						: fallbackReturn;
				}
				case AppConstants.commonTableActions.update:
				{
					return !AnyHelper
						.isNullOrEmpty(
							tableDefinitionActions.update[keyDefinition])
						? tableDefinitionActions.update[keyDefinition]
						: fallbackReturn;
				}
				case AppConstants.commonTableActions.delete:
				{
					return !AnyHelper
						.isNullOrEmpty(
							tableDefinitionActions.delete[keyDefinition])
						? tableDefinitionActions.delete[keyDefinition]
						: fallbackReturn;
				}
				case AppConstants.commonTableActions.view:
				{
					return !AnyHelper
						.isNullOrEmpty(
							tableDefinitionActions.view[keyDefinition])
						? tableDefinitionActions.view[keyDefinition]
						: fallbackReturn;
				}
				default:
				{
					return fallbackReturn;
				}
			}
		}

		return fallbackReturn;
	}

	/**
	 * Gets the current virtual data.
	 *
	 * @memberof CommonTableComponent
	 */
	private getVirtualData(): any[]
	{
		return this.virtualData.map((data) =>
		{
			if (data?.id === this.rowData?.id)
			{
				return this.dataBackup;
			}
			else
			{
				return data;
			}
		});
	}

	/**
	 * Sets the common table context.
	 *
	 * @memberof CommonTableComponent
	 */
	private setCommonTableContext(): void
	{
		if (!AnyHelper.isNullOrEmpty(
			this.tableDefinitions.commonTableContext))
		{
			this.tableDefinitions.commonTableContext(this.pageContext);
		}
	}
}