/**
 * @copyright WaterStreet. All rights reserved.
 */

/**
 * An enum for designating work item status.
 */
export enum WorkItemStatus
{
	/**
	 * The work item is active.
	 *
	 * @type {string}
	 * @memberof WorkItemStatus
	 */
	Active = 'Active',

	 /**
	 * The work item is done.
	 *
	 * @type {string}
	 * @memberof WorkItemStatus
	*/
	Done = 'Done',

	/**
	 * The work item is overdue.
	 *
	 * @type {string}
	 * @memberof WorkItemStatus
	 */
	Overdue = 'Overdue',

	/**
	 * The work item is being ignored.
	 *
	 * @type {string}
	 * @memberof WorkItemStatus
	 */
	Ignored = 'Ignored'
}