/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	AppEventConstants
} from '@shared/constants/app-event.constants';
import {
	BaseOperationGroupDirective
} from '@operation/directives/base-operation-group.directive';
import {
	Component,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnDestroy,
	Output,
	SimpleChanges,
	ViewChild
} from '@angular/core';
import {
	debounceTime,
	Subject
} from 'rxjs';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	LoggerService
} from '@shared/services/logger.service';
import {
	MenuItem
} from 'primeng/api';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	Router
} from '@angular/router';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	SlideMenu
} from 'primeng/slidemenu';

@Component({
	selector: 'app-slide-menu',
	templateUrl: './slide-menu.component.html',
	styleUrls: [
		'./slide-menu.component.scss'
	]
})

/**
 * A component representing an instance of the context menu display.
 *
 * @export
 * @class SlideMenuComponent
 * @extends {BaseOperationGroupDirective}
 * @implements {OnDestroy}
 * @implements {OnChanges}
 */
export class SlideMenuComponent
	extends BaseOperationGroupDirective
	implements OnDestroy, OnChanges
{
	/**
	 * Initializes a new instance of the slide menu component.
	 *
	 * @param {LoggerService} loggerService
	 * The logger service to use for client and server side logging.
	 * @param {OperationService} operationService
	 * The operation service to use when loading operation group data.
	 * @param {OperationExecutionService} operationExecutionService
	 * The operation execution service to use when performing operation
	 * commands.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service used for create functionality.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service to use for responsive layouts.
	 * @param {ModuleService} moduleService
	 * The module service to use for module based slide menu displays.
	 * @param {Router} router
	 * The router used for navigation.
	 * @memberof SlideMenuComponent
	 */
	public constructor(
		public loggerService: LoggerService,
		public operationService: OperationService,
		public operationExecutionService: OperationExecutionService,
		public entityTypeApiService: EntityTypeApiService,
		public siteLayoutService: SiteLayoutService,
		public moduleService: ModuleService,
		public router: Router)
	{
		super(
			loggerService,
			operationService,
			operationExecutionService,
			siteLayoutService);

		if (AnyHelper.isNullOrWhitespace(this.moduleService.name))
		{
			this.moduleService.name = AppConstants.moduleNames.dashboard;
		}

		this.operationGroupName =
			this.moduleService.getContextMenuName();
		this.displayName = 'the context menu';
	}

	/**
	 * Gets or sets the page context operation group name. This will
	 * be displayed as the second level of the slide menu if send.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	@Input() public pageContextOperationGroupName: string;

	/**
	 * Gets or sets the filter change event emitter. This will notify
	 * listening components that the filter value needs to alter to
	 * the supplied string.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof SlideMenuComponent
	 */
	@Output() public filterChange: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets the step index change event emitter. This will notify
	 * listening components that a slide menu action to step into children
	 * or back into parents has occured.
	 *
	 * @type {EventEmitter<number>}
	 * @memberof SlideMenuComponent
	 */
	@Output() public stepIndexChange: EventEmitter<number> =
		new EventEmitter<number>();

	/**
	 * Gets the slide menu component used in this display as it's original
	 * prime ng defined component.
	 *
	 * @type {SlideMenu}
	 * @memberof SlideMenuComponent
	 */
	@ViewChild('SlideMenu')
	public slideMenuViewChild: SlideMenu;

	/**
	 * Gets or sets the context menu height which is used to create scrollable
	 * viewports for long lists in the slide menu.
	 *
	 * @type {number}
	 * @memberof SlideMenuComponent
	 */
	public stepIndex: number = 0;

	/**
	 * Gets the label to display during initial module menu loads.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	public readonly loadingLabel: string = 'Loading';

	/**
	 * Gets or sets the loading changed subscription.
	 *
	 * @type {Subject<void>}
	 * @memberof SlideMenuComponent
	 */
	public pageContextChanged: Subject<void> = new Subject<void>();

	/**
	 * Gets the menu item to display during loads.
	 *
	 * @type {MenuItem}
	 * @memberof SlideMenuComponent
	 */
	public readonly loadingMenuItem: MenuItem =
		{
			label: this.loadingLabel,
			icon: 'fa fa-fw fa-spin fa-spinner',
			disabled: true,
			visible: true,
			styleClass: 'loading-label'
		};

	/**
	 * Gets or sets the list of items displayed in this slide menu component.
	 *
	 * @type {MenuItem[]}
	 * @memberof SlideMenuComponent
	 */
	public items: MenuItem[] =
		[
			this.loadingMenuItem
		];

	/**
	 * Gets or sets the context menu height which is used to create scrollable
	 * viewports for long lists in the slide menu.
	 *
	 * @type {number}
	 * @memberof SlideMenuComponent
	 */
	public contextMenuHeight: number = 10000;

	/**
	 * Gets or sets the width of the expanded context menu which is used to
	 * define the horizontal bounds of the slide menu.
	 *
	 * @type {number}
	 * @memberof SlideMenuComponent
	 */
	public contextMenuWidth: number =
		AppConstants.staticLayoutSizes.expandedContextMenuWidth;

	/**
	 * Gets or sets the filter used to hide or show slide
	 * menu content.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	@Input() public get filter(): string
	{
		return this._filter;
	}
	public set filter(
		filter: string)
	{
		if (filter !== this.filter)
		{
			this.stepIndex =
					this.getCurrentStepIndex(
						this.slideMenuViewChild.left);

			this.items =
					this.filterMenuItems(
						this.items,
						filter);

			this.items =
				[
					...this.items,
				];
		}

		this._filter = filter;
	}

	 /**
	 * Gets or sets the value used to reset the
	 * operation menu items.
	 *
	 * @type {boolean}
	 * @memberof SlideMenuComponent
	 */
	@Input() public get reset(): boolean
	{
		return this._reset;
	}
	public set reset(
		reset: boolean)
	{
		if (reset === true)
		{
			if (!AnyHelper.isNull(this.slideMenuViewChild))
			{
				this.slideMenuViewChild.left = 0;
				this.stepIndex = this.getCurrentStepIndex(
					this.slideMenuViewChild.left);
				this.stepIndexChange.next(this.stepIndex);
			}
		}

		this._reset = reset;
	}

	/**
	 * Gets the loading value that specifies this component is loading
	 * the module menu.
	 *
	 * @type {boolean}
	 * @memberof SlideMenuComponent
	 */
	public get loadingModuleMenu(): boolean
	{
		return this.items.length === 1
			&& this.items[0].label === this.loadingLabel;
	}

	/**
	 * Gets or sets the local storage filter used
	 * to hide or show context menu content.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	private _filter: string;

	/**
	 * Gets or sets the local storage value used to reset the
	 * operation menu items.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	private _reset: boolean;

	/**
	 * Gets the style class based identifier for a section title.
	 *
	 * @type {boolean}
	 * @memberof SlideMenuComponent
	 */
	private readonly sectionTitleIdentifier: string = 'section-title';

	/**
	 * Gets the class based identifier for a section title icon.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	private readonly sectionTitleIconIdentifier: string = 'section-title-icon';

	/**
	 * Gets the class based identifier for a caret left icon,
	 * used to be displayed in a back button menu item.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	private readonly caretLeftIcon: string = 'caret-left';

	/**
	 * Gets the style class based identifier for no matching results.
	 *
	 * @type {string}
	 * @memberof SlideMenuComponent
	 */
	private readonly noResultsFoundIdentifier: string = 'no-results-found';

	/**
	 * Gets the debounce delay in milliseconds prior to loading the
	 * page level context menu.
	 *
	 * @type {number}
	 * @memberof SlideMenuComponent
	 */
	private readonly loadDebounceDelay: number = 100;

	/**
	 * Gets the common lookup identifiers to use when looking at a
	 * page context value.
	 *
	 * @type {{
		entityInstance: string;
		entityType: string;
		entityTypeGroup: string;
		id: string;
	}}
	 * @memberof SlideMenuComponent
	 */
	private readonly pageContextIdentifiers:
	{
		entityInstance: string;
		entityType: string;
		entityTypeGroup: string;
		id: string;
	} = {
		entityInstance: 'entityInstance',
		entityType: 'entityType',
		entityTypeGroup: 'entityTypeGroup',
		id: 'id'
	};

	/**
	 * Gets the measurements when calculating the display heights for scrolling.
	 *
	 * @type {{
		backButtonHeight: number;
		filterHeight: number;
		headerHeight: number;
	}}
	 * @memberof SlideMenuComponent
	 */
	private readonly componentSizes:
	{
		backButtonHeight: number;
		filterHeight: number;
		headerHeight: number;
	} = {
		backButtonHeight: 28,
		filterHeight: 44.5,
		headerHeight: 45
	};

	/**
	 * Watches for changes in the site layout and handles these events.
	 * This will recalculate the height of the context menu to ensure
	 * accurate scrollports based on the current site height.
	 *
	 * @memberof SlideMenuComponent
	 */
	@HostListener(
		AppEventConstants.siteLayoutChangedEvent)
	public siteLayoutChanged(): void
	{
		this.calculateContextMenuHeight();
	}

	/**
	 * Handles the on changes event.
	 * This is used to capture changes in the page level context menu
	 * identifier when being set async.
	 *
	 * @param {SimpleChanges} simpleChanges
	 * The altered values that fired this on change event.
	 * @memberof SlideMenuComponent
	 */
	public ngOnChanges(
		changes: SimpleChanges): void
	{
		if (!AnyHelper.isNullOrWhitespace(
			changes.pageContextOperationGroupName?.currentValue))
		{
			this.pageContextChanged.next();
		}
	}

	/**
	 * Handles the on destroy event.
	 * This is used to complete any subscriptions on the component.
	 *
	 * @memberof SlideMenuComponent
	 */
	public ngOnDestroy(): void
	{
		this.pageContextChanged.complete();
	}

	/**
	 * Calculates the available height for this slide menu in order to
	 * add proper scrollable viewports if required.
	 *
	 * @memberof SlideMenuComponent
	 */
	public calculateContextMenuHeight(): void
	{
		this.contextMenuHeight =
			this.siteLayoutService.windowHeight
				- (this.stepIndex !== 0
					? (this.componentSizes.filterHeight
						+ this.componentSizes.backButtonHeight)
					: 0);

		const contentMenuHeight: number =
			this.contextMenuHeight
				- this.componentSizes.headerHeight
				- AppConstants.staticLayoutSizes.nestedContentPadding;

		const contextMenuContent: HTMLDivElement =
			document.querySelector('.p-slidemenu-content');
		contextMenuContent.setAttribute(
			'style',
			`height: ${contentMenuHeight}px;`);
	}

	/**
	 * Handles the load of the operation group associated with this slide menu.
	 * This will load the module and page level operation menus via the
	 * IOperationGroupDirective interface.
	 *
	 * @memberof SlideMenuComponent
	 */
	public performPostOperationLoadActions(): void
	{
		this.pageContextChanged.pipe(
			debounceTime(this.loadDebounceDelay))
			.subscribe(
				() =>
				{
					if (this.loadingModuleMenu
						|| AnyHelper.isNullOrWhitespace(
							this.pageContextOperationGroupName))
					{
						return;
					}

					this.operationGroupName =
						this.pageContextOperationGroupName;

					if (this.items.length > 0)
					{
						this.items.push(
							{
								separator: true,
							});
					}

					this.items.push(
						this.loadingMenuItem);
					this.items =
						[
							...this.items
						];

					this.ngOnInit();
				});

		this.calculateContextMenuHeight();

		// Module menu initial load
		if (this.loadingModuleMenu === true)
		{
			if (this.model.length > 0)
			{
				const menuItems: MenuItem[] =
					this.populateMenuItems(
						this.model[0].items);

				menuItems.unshift(
					this.getMenuLabelItem(
						this.model[0]));

				this.items =
					[
						...menuItems
					];
			}
			else
			{
				this.items = [];
			}

			this.pageContextChanged.next();
		}
		else
		{
			// Complete the changes watcher
			this.pageContextChanged.complete();

			if (this.items[this.items.length - 1] ===
				this.loadingMenuItem)
			{
				this.items =
					this.items.slice(
						0,
						this.items.length - 1);
			}

			// Page menu secondary load
			const menuItems: MenuItem[] =
				this.populateMenuItems(
					this.model);

			this.items =
				[
					...this.items,
					...menuItems
				];
		}
	}

	/**
	 * Handles a click on the side menu to ensure the section title click
	 * will not be sent unless the section title action icon is clicked
	 * directly.
	 *
	 * @memberof SlideMenuComponent
	 */
	public slideMenuClicked(): void
	{
		if (this.stepIndex !==
			this.getCurrentStepIndex(
				this.slideMenuViewChild.left))
		{
			this.stepIndex =
				this.getCurrentStepIndex(
					this.slideMenuViewChild.left);

			this.calculateContextMenuHeight();

			this.filterChange.emit(
				AppConstants.empty);

			this.stepIndexChange.emit(
				this.stepIndex);
		}
	}

	/**
	 * Returns a formatted menu item used to acccurately format and configure
	 * an operation group display in this component.
	 *
	 * @param {MenuItem} menuItem
	 * The menu item to be configured and formatted for display in this slide
	 * menu.
	 * @returns {MenuItem}
	 * A labelled parent with a set of related menu items that represents an
	 * operation group in this slide menu.
	 * @memberof SlideMenuComponent
	 */
	private labelMenuItem(
		menuItem: MenuItem): MenuItem
	{
		const currentMenuItemIcon: string =
			AnyHelper.isNull(menuItem.icon)
				? AppConstants.empty
				: menuItem.icon;
		const relatedContextMenu: boolean =
			currentMenuItemIcon.indexOf(
				AppConstants.contextMenuIdentifiers
					.addRelatedContextMenuIdentifier) !== -1;
		const addCreateAction: boolean =
			currentMenuItemIcon.indexOf(
				AppConstants.contextMenuIdentifiers
					.addCreateActionIdentifier) !== -1;

		if (menuItem.items?.length > 0
			|| relatedContextMenu === true)
		{
			const menuItems: MenuItem[] = [];

			menuItem.items.unshift(
				this.getMenuLabelItem(
					menuItem,
					addCreateAction));
			menuItem.items?.forEach(
				(recursiveMenuItem: MenuItem) =>
				{
					menuItems.push(
						this.labelMenuItem(recursiveMenuItem));
				});

			menuItem.items = menuItems;
		}

		if (currentMenuItemIcon
			.indexOf(this.sectionTitleIconIdentifier) === -1
			&& currentMenuItemIcon.indexOf(this.caretLeftIcon) === -1)
		{
			menuItem.icon = null;
		}

		return menuItem;
	}

	/**
	 * Returns a label only menu item which is used to display a working title
	 * for a set of functions related to an operation group.
	 *
	 * @param {MenuItem} menuItem
	 * The menu item representing a group of operations to create a label
	 * for.
	 * @param {boolean} [addCreateAction]
	 * If sent, this will add a creation action icon to this group label
	 * menu item.
	 * @returns {MenuItem}
	 * A label menu item used to display operation group associations in this
	 * component.
	 * @memberof SlideMenuComponent
	 */
	private getMenuLabelItem(
		menuItem: MenuItem,
		addCreateAction: boolean = false): MenuItem
	{
		return {
			label: menuItem.label.toUpperCase(),
			styleClass: this.sectionTitleIdentifier,
			disabled: false,
			icon: (addCreateAction === true
				? 'section-title-icon float-right theme-color '
					+ 'fa fa-fw fa-plus-circle'
				: null),
			command: async() =>
			{
				// Entity Instance action
				const creationEntityType: IEntityType =
					await this.entityTypeApiService.getSingleQueryResult(
						`${AppConstants.commonProperties.group} eq `
							+ `'${menuItem.id}'`,
						AppConstants.empty);
				const parentEntityId: number =
					this.pageContext.data
						[this.pageContextIdentifiers.entityInstance]
						[this.pageContextIdentifiers.id];
				const parentEntityTypeGroup: string =
					this.pageContext.source
						[this.pageContextIdentifiers.entityTypeGroup];

				history.pushState(
					null,
					AppConstants.empty,
					this.router.url);

				this.router.navigate(
					[
						`${this.moduleService.name}`,
						AppConstants.route.entities,
						AppConstants.route.display,
						AppConstants.displayComponentTypes
							.basePageEntityCreate,
						AppConstants.viewTypes.create
					],
					{
						replaceUrl: true,
						queryParams: {
							routeData: ObjectHelper.mapRouteData(
								{
									data: {
										automateVerify: true,
										entityCreationType:
											creationEntityType,
										entityTypes:
											menuItem.id,
										parentSelections:
											[
												{
													typeGroup:
														parentEntityTypeGroup,
													parentIds:
														[parentEntityId]
												}
											]
									}
								})
						}
					});
			}
		};
	}

	/**
	 * Iterates through each menu item in the current collection and
	 * accurately formats and labels all children as a returned set
	 * of menu items.
	 *
	 * @param {MenuItem[]} menuItems
	 * The menu items requiring a transform to a slide menu based display.
	 * @returns {MenuItem[]}
	 * A set of fully labelled menu items for display in this slide menu.
	 * @memberof SlideMenuComponent
	 */
	private populateMenuItems(
		menuItems: MenuItem[]): MenuItem[]
	{
		const menuItemCollection: MenuItem[] = [];
		menuItems.forEach(
			(menuItem: MenuItem) =>
			{
				menuItemCollection.push(
					this.labelMenuItem(menuItem));
			});

		return menuItemCollection;
	}

	/**
	 * Iterates through each menu item in the current collection and
	 * returns only the matching menu items to the filter value.
	 *
	 * @param {MenuItem[]} menuItems
	 * The menu items requiring a transform to a filtered slide menu
	 * based display.
	 * @param {string} filter
	 * The filter value to search for a matching label.
	 * @param {number} [currentStepIndex]
	 * The step index being checked in this current filter pass. The
	 * default of zero will return a full matching set.
	 * @returns {MenuItem[]}
	 * A set of filtered menu items for display in this slide menu.
	 * @memberof SlideMenuComponent
	 */
	private filterMenuItems(
		menuItems: MenuItem[],
		filter: string,
		currentStepIndex: number = 0): MenuItem[]
	{
		menuItems.forEach(
			(menuItem: MenuItem) =>
			{
				if (menuItem.items?.length > 0)
				{
					menuItem.items =
						this.filterMenuItems(
							AnyHelper.isNull(menuItem.items)
								? []
								: menuItem.items,
							filter,
							currentStepIndex + 1);
				}

				const comparisonLabel: string =
					AnyHelper.isNull(menuItem.label)
						? AppConstants.empty
						: menuItem.label;

				menuItem.visible =
					currentStepIndex !== this.stepIndex
					|| (comparisonLabel.toLowerCase()
						.indexOf(filter.toLowerCase()) !== -1
						|| menuItem.styleClass ===
							this.sectionTitleIdentifier
						|| menuItem.styleClass ===
							this.noResultsFoundIdentifier);
			});

		if (currentStepIndex === 0)
		{
			return menuItems;
		}

		// Add a label for nested levels and handle no results found
		if (menuItems[menuItems.length - 1].styleClass !==
			this.noResultsFoundIdentifier)
		{
			menuItems.push(
				this.getNoResultsFoundMenuItem());
		}

		menuItems[menuItems.length - 1].visible =
			menuItems.filter(
				(menuItem: MenuItem) =>
					menuItem.visible === true).length === 2
						&& menuItems[0].styleClass
							=== this.sectionTitleIdentifier;

		return menuItems;
	}

	/**
	 * Returns a labelled menu item used to display no results found
	 * matching the filter.
	 *
	 * @returns {MenuItem}
	 * A display only no results found menu item.
	 * @memberof SlideMenuComponent
	 */
	private getNoResultsFoundMenuItem(): MenuItem
	{
		return <MenuItem>
		{
			label: AppConstants.noResultsFoundMessage,
			styleClass: this.noResultsFoundIdentifier,
			disabled: true,
			visible: true
		};
	}

	/**
	 * Returns the current slide menu step into value. This will
	 * return the level of the current nested menu item collection
	 * currently being viewed.
	 *
	 * @param {number} currentLeftSlideValue
	 * The left value of the slide menu component which is used
	 * to signify nested views.
	 * @returns {number}
	 * The current indent level viewed.
	 * @memberof SlideMenuComponent
	 */
	private getCurrentStepIndex(
		currentLeftSlideValue: number): number
	{
		return Math.abs(currentLeftSlideValue /
			AppConstants.staticLayoutSizes.expandedContextMenuWidth);
	}
}