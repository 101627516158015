<div class="form-group custom-field-wrapper ui-g-12 ui-md-{{to.gridColumns || 6}}">

	<div *ngIf="to.label"
		class="input-label">
		<label [attr.for]="id">
			<strong>
				{{to.label}}
			</strong>
			<ng-container
				*ngIf="(to.required === true || this.requiredViaRules === true)
					&& to.hideRequiredMarker !== true">
				*
			</ng-container>
			<ng-container
				*ngIf="to.tooltip != null && to.tooltip.length > 0">
				<i #Tooltip
					[pTooltip]="to.tooltip"
					tooltipPosition="top"
					tooltipStyleClass="label-tooltip"
					class="fa fa-info-circle theme-color label-info-icon"
					(click)="preventDefault($event)"
					(tap)="mobileTooltipToggle()">
				</i>
			</ng-container>
			<ng-container
				*ngIf="to.externalLink != null && to.externalLink.length > 0
					&& to.externalLinkTooltip != null">
				<i [pTooltip]="to.externalLinkTooltip"
					tooltipPosition="top"
					tooltipStyleClass="label-tooltip"
					class="fa fa-external-link theme-color external-link"
					(click)="accessExternalLink()">
				</i>
			</ng-container>
		</label>
	</div>

	<div class="p-input-div">
		<ng-template
			#fieldComponent>
		</ng-template>
		<span *ngIf="field.formControl.status === 'PENDING'">
			<i class="fa fa-fw fa-spin fa-spinner validation-spinner">
			</i>
		</span>
	</div>
</div>