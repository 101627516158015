/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	AdjustmentWriteOffAmountComponent
} from '@insurance/components/wizard-steps/policy/adjustment-write-off-amount/adjustment-write-off-amount.component';
import {
	AdjustmentWriteOffTypeComponent
} from '@insurance/components/wizard-steps/policy/adjustment-write-off-type/adjustment-write-off-type.component';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	AssociatedEntityListComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-entity-list.component';
import {
	AssociatedKeyContactsComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-key-contacts/associated-key-contacts.component';
import {
	AssociatedOrganizationComponent
} from '@insurance/dynamic-components/associated-entity-list/associated-organization/associated-organization.component';
import {
	BindTransactionComponent
} from '@insurance/components/wizard-steps/policy/bind-transaction/bind-transaction.component';
import {
	CommonModule
} from '@angular/common';
import {
	CreateTransactionComponent
} from '@insurance/components/wizard-steps/policy/create-transaction/create-transaction.component';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	InsuranceComponent
} from '@insurance/insurance.component';
import {
	InsurancePolicySearchComponent
} from '@insurance/components/insurance-policy-search/insurance-policy-search.component';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	InsuranceTokenLookup
} from '@insurance/insurance.token-lookup';
import {
	MakePaymentComponent
} from '@insurance/components/wizard-steps/policy/make-payment/make-payment.component';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	NgModule
} from '@angular/core';
import {
	NonRenewPolicyComponent
} from '@insurance/components/wizard-steps/policy/non-renew-policy/non-renew-policy.component';
import {
	PaymentDetailsComponent
} from '@insurance/components/wizard-steps/policy/make-payment/payment-details/payment-details.component';
import {
	RescindNonRenewPolicyComponent
} from '@insurance/components/wizard-steps/policy/rescind-non-renew-policy/rescind-non-renew-policy.component';
import {
	RouterModule
} from '@angular/router';
import {
	SharedModule
} from '@shared/shared.module';
import {
	StatusReasonsComponent
} from '@insurance/components/status-reasons/status-reasons.component';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';
import {
	TransactionAssetsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-assets/transaction-assets.component';
import {
	TransactionCancelEffectiveDateComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-effective-date/transaction-cancel-effective-date.component';
import {
	TransactionCancelReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-reasons/transaction-cancel-reasons.component';
import {
	TransactionCancelReinstateCommentsComponent
} from '@insurance/components/wizard-steps/policy/transaction-cancel-reinstate-comments/transaction-cancel-reinstate-comments.component';
import {
	TransactionCoveragesDeductiblesComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-coverages-deductibles/transaction-coverages-deductibles.component';
import {
	TransactionDeclineReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-decline-reasons/transaction-decline-reasons.component';
import {
	TransactionEndorseIssueDifferencesComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-issue-differences/transaction-endorse-issue-differences.component';
import {
	TransactionEndorseIssueSummaryComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-issue-summary/transaction-endorse-issue-summary.component';
import {
	TransactionEndorseReasonsComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-reasons/transaction-endorse-reasons.component';
import {
	TransactionEndorseStatusEffectiveDateComponent
} from '@insurance/components/wizard-steps/policy/transaction-endorse-status-effective-date/transaction-endorse-status-effective-date.component';
import {
	TransactionFormsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-forms/transaction-forms.component';
import {
	TransactionInterestsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-interests/transaction-interests.component';
import {
	TransactionReasonsComponent
} from '@insurance/dynamic-components/associated-entity-list/transaction-reasons/transaction-reasons.component';
import {
	TransactionRescindCancelComponent
} from '@insurance/components/wizard-steps/policy/transaction-rescind-cancel/transaction-rescind-cancel.component';
import {
	TransactionRescindEndorseComponent
} from '@insurance/components/wizard-steps/policy/transaction-rescind-endorse/transaction-rescind-endorse.component';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffTypeComponent,
		AssociatedEntityListComponent,
		AssociatedKeyContactsComponent,
		AssociatedOrganizationComponent,
		InsuranceComponent,
		BindTransactionComponent,
		CreateTransactionComponent,
		InsurancePolicySearchComponent,
		MakePaymentComponent,
		NonRenewPolicyComponent,
		PaymentDetailsComponent,
		RescindNonRenewPolicyComponent,
		StatusReasonsComponent,
		StatusReasonsDirective,
		TransactionAssetsComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionCoveragesDeductiblesComponent,
		TransactionFormsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionInterestsComponent,
		TransactionReasonsComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionDeclineReasonsComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'insurance',
				component: InsuranceComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						'Administrators',
						'Support Users',
						'Users'
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										'Administrators',
										'Support Users'
									]
								}
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffTypeComponent,
		BindTransactionComponent,
		CreateTransactionComponent,
		InsurancePolicySearchComponent,
		MakePaymentComponent,
		NonRenewPolicyComponent,
		PaymentDetailsComponent,
		RescindNonRenewPolicyComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionDeclineReasonsComponent
	],
	providers: [
		AdjustmentWriteOffAmountComponent,
		AdjustmentWriteOffTypeComponent,
		AssociatedEntityListComponent,
		AssociatedKeyContactsComponent,
		AssociatedOrganizationComponent,
		BindTransactionComponent,
		CreateTransactionComponent,
		InsurancePolicySearchComponent,
		InsuranceService,
		{
			provide: InsuranceTokenLookup.tokens.InsuranceService,
			useClass: InsuranceService
		},
		MakePaymentComponent,
		NonRenewPolicyComponent,
		PaymentDetailsComponent,
		RescindNonRenewPolicyComponent,
		StatusReasonsComponent,
		StatusReasonsDirective,
		TransactionAssetsComponent,
		TransactionCancelEffectiveDateComponent,
		TransactionCancelReasonsComponent,
		TransactionCancelReinstateCommentsComponent,
		TransactionCoveragesDeductiblesComponent,
		TransactionFormsComponent,
		TransactionEndorseIssueDifferencesComponent,
		TransactionEndorseIssueSummaryComponent,
		TransactionEndorseReasonsComponent,
		TransactionInterestsComponent,
		TransactionReasonsComponent,
		TransactionEndorseStatusEffectiveDateComponent,
		TransactionRescindCancelComponent,
		TransactionRescindEndorseComponent,
		TransactionDeclineReasonsComponent
	]
})

/**
 * A module used to hold insurance route specific
 * logic and display resources.
 *
 * @export
 * @class InsuranceModule
 */
export class InsuranceModule
{
}