/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	Directive,
	EventEmitter,
	Input,
	Output
} from '@angular/core';
import {
	FileCategory
} from '@dynamicComponents/files/file-category/file-category';
import {
	FileHelper
} from '@shared/helpers/file.helper';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	StorageType
} from '@shared/constants/enums/storage-type.enum';

/* eslint-enable max-len */

@Directive({
	selector: '[File]'
})

/**
 * A directive representing shared logic for a component interacting
 * with files.
 *
 * @export
 * @class FileDirective
 */
export class FileDirective
{
	/**
	 * Gets or sets the selected file entity.
	 *
	 * @type {IFileEntity}
	 * @memberof FileDirective
	 */
	@Input() public fileEntity: IFileEntity;

	/**
	 * Gets or sets the categories.
	 *
	 * @type {FileCategory[]}
	 * @memberof FileDirective
	 */
	@Input() public categories: FileCategory[];

	/**
	 * Gets or sets the categories.
	 *
	 * @type {string[]}
	 * @memberof FileDirective
	 */
	 @Input() public subTypes: string[] = [];

	/**
	 * Gets or sets the navigate event
	 *
	 * @type {EventEmitter<string>}
	 * @memberof FileDirective
	 */
	@Output() public changeDisplayMode: EventEmitter<string> =
		new EventEmitter<string>();

	/**
	 * Gets or sets a value indictaing whether to show
	 * the progress component.
	 *
	 * @type {boolean}
	 * @memberof FileDirective
	 */
	public progressVisible: boolean = false;

	/**
	 * Gets or sets the display mode of the progress component.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public progressMode: string = 'spinner';

	/**
	 * Gets or sets the progress amount.
	 *
	 * @type {number}
	 * @memberof FileDirective
	 */
	public progressAmount: number = 0;

	/**
	 * Gets or sets the display message for the progress component errors.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public errorMessage: string;

	/**
	 * Gets the set of available item view modes.
	 *
	 * @type {object}
	 * @memberof FileDirective
	 */
	public readonly viewModes: {
		add: string;
		download: string;
		details: string;
		edit: string;
		list: string;
		remove: string;
	} =
	{
		add: 'add',
		download: 'download',
		details: 'details',
		edit: 'edit',
		remove: 'remove',
		list: 'list'
	};

	/**
	 * Gets the set of available actions for nested displays.
	 *
	 * @type
	 * @memberof FileDirective
	 */
	public itemActions: MenuItem[] =
		[
			<MenuItem> {
				icon: 'fa-cloud-download',
				id: this.viewModes.download,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						this.viewModes.download);
					event.stopPropagation();
				}
			},
			<MenuItem> {
				icon: 'fa-info-circle',
				id: this.viewModes.details,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						this.viewModes.details);
					event.stopPropagation();
				}
			},
			<MenuItem> {
				icon: 'fa-pencil',
				id: this.viewModes.edit,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						this.viewModes.edit);
					event.stopPropagation();
				}
			},
			<MenuItem> {
				icon: 'fa-trash',
				id: this.viewModes.remove,
				command: (event: any) => {
					this.changeDisplayMode.emit(
						this.viewModes.remove);
					event.stopPropagation();
				}
			}
		];

	/**
	 * Gets the default file extension.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public readonly defaultFileExtension: string = 'ext';

	/**
	 * Gets the default required value message.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public readonly requiredValueMessage: string =
		AppConstants.messages.requiredValueMessage;

	/**
	 * Gets the default required value custom metadata.
	 *
	 * @type {any}
	 * @memberof FileDirective
	 */
	public customMetadata: any[];

	/**
	 * Gets the storage type
	 *
	 * @type {StorageType}
	 * @memberof FileDirective
	 */
	public get storageType(): StorageType
	{
		return this.fileEntity
			.data
			.storage
			.storageType;
	}

	/**
	 * Gets the file category.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	 public get category(): string
	 {
		 return FileHelper
			 .getCategoryFromType(this.fileEntity.entityType);
	 }

	/**
	 * Gets the file extension.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get fileExtension(): string
	{
		return this.fileEntity
			.data?.metadata?.extension
				|| this.defaultFileExtension;
	}

	/**
	 * Gets the file entity type group.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get fileTypeGroup(): string
	{
		return this.categories
			.find((element: FileCategory) =>
				element.value === this.fileEntity
					.entityType)
			.entityType
			.group;
	}

	/**
	 * Gets the file category.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	 public get fileCategory(): string
	 {
		 return this.categories
			 .find((element: FileCategory) =>
				 element.value === this.fileEntity
					 .entityType)
			 .label;
	 }

	/**
	 * Gets the file sub type.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get subType(): string
	{
		return AnyHelper.isNullOrWhitespace(this.fileEntity?.data.subType)
			? 'None'
			: this.fileEntity.data.subType;
	}

	/**
	 * Gets the file size in bytes.
	 *
	 * @type {number}
	 * @memberof FileDirective
	 */
	public get sizeInBytes(): number
	{
		return this.fileEntity
			.data
			.metadata
			.sizeInBytes;
	}

	/**
	 * Gets thesize in bytes formatted to Bytes,
	 * Kilobytes, or Megabytes.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get sizeFormatted(): string
	{
		if (AnyHelper.isNullOrEmpty(this.sizeInBytes))
		{
			return '0 Bytes';
		}
		if (this.sizeInBytes < FileHelper.oneKilobyte)
		{
			return `${this.sizeInBytes} Bytes`;
		}

		if (this.sizeInBytes < FileHelper.oneMegabyte)
		{
			return `${(this.sizeInBytes / FileHelper.oneKilobyte)
				.toFixed(1)} KB`;
		}

		return `${(this.sizeInBytes / FileHelper.oneMegabyte)
			.toFixed(2)} MB`;
	}

	/**
	 * Gets a the create date.
	 *
	 * @type {string}
	 * @memberof FileDirective
	 */
	public get createDate(): string
	{
		const date: DateTime =
			DateTime.fromISO(this.fileEntity.createDate);

		return DateHelper.formatDate(
			date,
			'ccc MMM dd yyyy');
	}

	/**
	 * Handles initialization event.
	 * Sets the custom metadata.
	 *
	 * @memberof FileDirective
	 */
	public ngOnInit(): void
	{
		this.setCustomMetadata();
	}

	/**
	 * Sets the custom metadata.
	 *
	 * @memberof FileDirective
	 */
	private setCustomMetadata(): void
	{
		this.customMetadata = FileHelper
			.getCustomMetadata(
				this.fileEntity,
				null);
	}
}