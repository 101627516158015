/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	debounceTime,
	distinctUntilChanged,
	Subject
} from 'rxjs';
import {
	each
} from 'lodash-es';
import {
	FieldWrapper
} from '@ngx-formly/core';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	WindowEventConstants
} from '@shared/constants/window-event.constants';

@Component({
	selector: 'custom-field-wrapper',
	templateUrl: './custom-field-wrapper.component.html',
	styleUrls: [
		'./custom-field-wrapper.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Field Wrapper.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomFieldWrapperComponent
 * @extends {FieldWrapper}
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 * @implements {AfterViewChecked}
 */
export class CustomFieldWrapperComponent
	extends FieldWrapper
	implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
	/**
	 * Initializes a new instance of the CustomFieldWrapperComponent class.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomFieldWrapperComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService,
		public changeDetector: ChangeDetectorRef)
	{
		super();
	}

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof CustomFieldWrapperComponent
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/**
	 * Gets or sets a value defining whether or not the field is required based
	 * on presentation rules.
	 *
	 * @type {boolean}
	 * @memberof CustomFieldWrapperComponent
	 */
	public requiredViaRules: boolean = false;

	/**
	 * Gets or sets the observer of scroll height changes.
	 *
	 * @type {Subject<void>}
	 * @memberof CustomFieldWrapperComponent
	 */
	public scrollHeightChangedSubject:
		Subject<void> = new Subject<void>();

	/**
	 * Handles the scroll event sent from the window.
	 * This will hide any currently displayed tooltips.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	@HostListener(
		WindowEventConstants.scrollEvent)
	public scroll(): void
	{
		this.scrollHeightChangedSubject.next();
	}

	/**
	 * On initialization event.
	 * Checks for the existence of a required rule validator on this field if
	 * it is set via rules.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNull(this.field.validators))
		{
			each(
				Object.keys(this.field.validators),
				(key: string) =>
				{
					if (key.indexOf(
						'Required_') === 0)
					{
						this.requiredViaRules = true;
					}
				});
		}

		this.scrollHeightChangedSubject.pipe(
			debounceTime(AppConstants.time.twentyFiveMilliseconds),
			distinctUntilChanged())
			.subscribe(() =>
			{
				this.tooltip?.nativeElement?.dispatchEvent(
					new Event(MouseEventConstants.mouseLeave));
			});
	}

	/**
	 * On after view initialization event.
	 * Triggers any change events that exist in the field on the initial
	 * display. This ensures that loaded values and client entered values
	 * remain consistent with business rules.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngAfterViewInit(): void
	{
		if (!AnyHelper.isNullOrEmpty(this.field.templateOptions.change))
		{
			this.field.templateOptions
				.change(this.field);
		}
	}

	/**
	 * On after view checked event.
	 * This will fire a change detector for validation events outside of the
	 * main client thread.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngAfterViewChecked(): void
	{
		this.changeDetector.detectChanges();
	}

	/**
	 * Handles the on destroy event.
	 * This will complete any current observables.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public ngOnDestroy(): void
	{
		this.scrollHeightChangedSubject.complete();
	}

	/**
	 * This will redirect and load in a new tab the external
	 * link specified on the template options externalLink property.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public accessExternalLink(): void
	{
		const externalLink =
			window.open(
				this.field.templateOptions.externalLink,
				AppConstants.windowTargets.blank);
		externalLink?.focus();
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @memberof CustomFieldWrapperComponent
	 */
	public mobileTooltipToggle(): void
	{
		this.tooltip.nativeElement.dispatchEvent(
			new Event(MouseEventConstants.mouseEnter));
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof CustomFieldWrapperComponent
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}
}