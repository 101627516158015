<p-scrollPanel styleClass="rule-container">
	<div class="ui-g-12 text-left">

		<div class="ui-g-12 no-horizontal-padding description-container"
			*ngIf="selectedItem?.ruleDefinition?.description != null">
			<markdown
				[data]="selectedItem.ruleDefinition.description">
			</markdown>
		</div>

		<div *ngIf="ruleViolationWorkflowActions.length === 0"
			class="text-center ui-g-12 no-horizontal-padding">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>
		<div *ngIf="ruleViolationWorkflowActions.length > 0"
			class="ui-g-12 table-container no-horizontal-padding">

			<p-table [value]="ruleViolationWorkflowActions"
				responsiveLayout="scroll">
				<ng-template pTemplate="caption">
					<div class="panel-sub-title">
						<span class="table-title">
							Related Actions
						</span>
					</div>
				</ng-template>
				<ng-template pTemplate="header">
					<tr>
						<th scope="col"
							class="text-left">
							Name
						</th>
						<th scope="col"
							class="text-center"
							[style]="{'width':'70px'}">
							Blocked
						</th>
						<th scope="col"
							class="text-center"
							[style]="{'width':'90px'}">
							<span>
								Override
							</span>
							<span *ngIf="overrideAllowed === true"
								class="override-column-action">
								<label class="visually-hidden"
									[attr.for]="AriaInputOverrideAll">
								</label>
								<ng-template [ngIf]="overrideAllDisplayMode === true"
									[ngIfElse]="OverrideAll">
									<i #UndoTooltip
										[pTooltip]="undoTooltipMessage"
										tooltipPosition="left"
										class="fa fa-fw fa-undo theme-color"
										(click)="resetValues($event)"
										(tap)="mobileTooltipToggle(UndoTooltip)">
									</i>
								</ng-template>
								<ng-template #OverrideAll>
									<p-checkbox
										binary="true"
										[inputId]="AriaInputOverrideAll"
										(onChange)="overrideAll()">
									</p-checkbox>
								</ng-template>
							</span>
						</th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-ruleViolationWorkflowAction>
					<tr>
						<td class="text-left">
							<div class="ellipsis-text">
								{{ruleViolationWorkflowAction.workflowActionDefinition?.name}}
							</div>
						</td>
						<td class="text-center"
							[style]="{'width':'70px'}">
							<span>
								<ng-template [ngIf]="ruleViolationWorkflowAction.blocked === true"
									[ngIfElse]="WarningDisplay">
									<i #BlockedTooltip
										[pTooltip]="blockedTooltipMessage"
										tooltipPosition="left"
										class="fa fa-fw fa-flag column-icon"
										(click)="preventDefault($event)"
										(tap)="mobileTooltipToggle(BlockedTooltip)">
									</i>
								</ng-template>
								<ng-template #WarningDisplay>
									<i #WarningTooltip
										[pTooltip]="warningTooltipMessage"
										tooltipPosition="left"
										class="fa fa-fw fa-flag-checkered column-icon"
										(click)="preventDefault($event)"
										(tap)="mobileTooltipToggle(WarningTooltip)">
									</i>
								</ng-template>
							</span>
						</td>
						<td class="text-center"
							[style]="{'width':'90px'}">
							<div class="column-checkbox-container"
								*ngIf="ruleViolationWorkflowAction.blocked === true">
								<label class="visually-hidden"
									[attr.for]="'AriaInputOverride' + ruleViolationWorkflowAction.workflowActionDefinition?.name">
								</label>
								<p-checkbox
									binary="true"
									[disabled]="ruleViolationWorkflowAction.overridable !== true"
									[(ngModel)]="ruleViolationWorkflowAction.overridden"
									[inputId]="'AriaInputOverride' + ruleViolationWorkflowAction.workflowActionDefinition?.name"
									(onChange)="overrideSelectionChanged()">
								</p-checkbox>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-table>

			<div class="ui-g-12 no-horizontal-padding">
				<app-dynamic-formly
					[dataSet]="overrideFormlyData"
					[layoutSchema]="overrideFormlyLayout">
				</app-dynamic-formly>
			</div>

			<div class="ui-g-12 no-padding text-right button-set">
				<a class="text-link theme-color secondary-action-button"
					(click)="cancel()">
					Cancel
				</a>
				<button
					pButton
					type="button"
					label="Save"
					class="no-margin-right"
					(click)="syncViolations()"
					[disabled]="isChanged === false || saving === true">
				</button>
			</div>
		</div>

	</div>
</p-scrollPanel>