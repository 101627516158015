/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AbstractControl,
	FormControl
} from '@angular/forms';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IInputGroupItem
} from '@shared/interfaces/application-objects/input-group-item.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

// tslint:enable: max-line-length

@Component({
	selector: 'payment-details',
	templateUrl: './payment-details.component.html',
	styleUrls: [
		'./payment-details.component.scss'
	]
})

/**
 * A component representing a wizard step to display the payment details for a
 * policy term.
 *
 * @export
 * @class PaymentDetailsComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class PaymentDetailsComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the payment details component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof PaymentDetailsComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public insuranceService: InsuranceService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof PaymentDetailsComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof PaymentDetailsComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof PaymentDetailsComponent
	 */
	public currentData: any;

	/**
	 * Gets or sets the policy term accounting payment methods data.
	 *
	 * @type {any}
	 * @memberof PaymentDetailsComponent
	*/
	public paymentMethodsData: any;

	/**
	 * Gets or sets the PolicyTerm entity instance associated to the current
	 * data.
	 *
	 * @type {IEntityInstance}
	 * @memberof PaymentDetailsComponent
	 */
	public policyTermEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the ledger entity instance associated to the current
	 * policy term.
	 *
	 * @type {IEntityInstance}
	 * @memberof PaymentDetailsComponent
	 */
	public ledgerEntityInstance: IEntityInstance;

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof PaymentDetailsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		this.currentData.paymentOption = 'minimumDue';
		this.currentData.paymentAmount = 0;

		const policyTermId = this.currentData.id;

		this.policyTermEntityInstance =
			await this.insuranceService.getPolicyTerm(
				policyTermId);

		this.ledgerEntityInstance =
			await this.insuranceService.getLedger(
				policyTermId);

		const ledgerTransactionInstances: IEntityInstance[] =
			await this.insuranceService.getLedgerTransactions(
				this.ledgerEntityInstance.id);

		this.paymentMethodsData =
			this.policyTermEntityInstance.data.preferences.paymentMethods
				.filter(
					(paymentMethod: any) =>
						paymentMethod.type !== 'Check');

		const priorTermTransfer: number =
			InsuranceHelper.getPriorTermTransferBalance(
				ledgerTransactionInstances);

		const policyTermWrittenOffBalance: number =
			InsuranceHelper.getPolicyTermWrittenOffBalance(
				ledgerTransactionInstances);

		const payments: number =
			InsuranceHelper.getPaymentsBalance(
				ledgerTransactionInstances);

		let accountingData: any;
		let paymentDetailsData: any;

		const minimumDue: number = 0;

		if (this.policyTermEntityInstance.data.status ===
			InsuranceConstants.policyTermStatusTypes.active)
		{
			accountingData =
				this.policyTermEntityInstance.data.accounting;

			paymentDetailsData =
				{
					premium: accountingData.writtenPremium,
					fees: accountingData.writtenFees,
					taxes: accountingData.writtenTaxes,
					total: accountingData.writtenTotal,
					priorTermTransfer: priorTermTransfer,
					policyTermWrittenOffBalance: policyTermWrittenOffBalance,
					payments: payments,
					remainingBalance:
						accountingData.writtenTotal
							+ priorTermTransfer
							+ payments
							+ policyTermWrittenOffBalance,
					minimumDue: minimumDue
				};
		}
		else
		{
			const policyTermTransactionInstance: IEntityInstance =
				await this.insuranceService
					.getLatestPolicyTermTransactionByPolicyTerm(
						this.policyTermEntityInstance.id);

			accountingData =
				policyTermTransactionInstance.data.accounting;

			paymentDetailsData =
			{
				premium: accountingData.directWrittenPremium,
				fees: accountingData.directWrittenFees,
				taxes: accountingData.directWrittenTaxes,
				total: accountingData.directWrittenTotal,
				priorTermTransfer: priorTermTransfer,
				policyTermWrittenOffBalance: policyTermWrittenOffBalance,
				payments: payments,
				remainingBalance:
					accountingData.directWrittenTotal
						+ priorTermTransfer
						+ payments
						+ policyTermWrittenOffBalance,
				minimumDue: minimumDue
			};
		}

		this.currentData.paymentDetails =
			paymentDetailsData;

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof PaymentDetailsComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Validates the payment amount is a correct input.
	 *
	 * @param {FormControl} _control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private paymentAmountValidator(
		_control: FormControl,
		field: FormlyFieldConfig): boolean
	{
		const valueField: AbstractControl =
			field.form.get(
				AppConstants.nestedDataIdentifier
					+ field.templateOptions.valueKey);

		return !AnyHelper.isNull(valueField) && valueField.value > 0;
	}

	/**
	 * Validates the payment methods available.
	 *
	 * @param {FormControl} control
	 * The form control to get the input value.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof EntityLayoutDefinitionComponent
	 */
	private paymentMethodsValidator(
		control: FormControl): boolean
	{
		const paymentMethods: IDropdownOption[] =
			this.getPaymentMethods();

		if (control.value === null)
		{
			return false;
		}

		return paymentMethods.length > 0;
	}

	/**
	 * Gets the available payment methods for the current policy term instance.
	 *
	 * @return {IDropdownOption[]}
	 * The payment methods converted to a IDropdownOption array.
	 * @memberof PaymentDetailsComponent
	 */
	private getPaymentMethods(): IDropdownOption[]
	{
		return this.paymentMethodsData.map(
			(paymentMethod: any) =>
				({
					label: this.getPaymentMethodName(paymentMethod),
					value: paymentMethod
				}));
	}

	/**
	 * Gets the default payment method.
	 *
	 * @return {any}
	 * The default payment method.
	 * @memberof PaymentDetailsComponent
	 */
	private getDefaultPaymentMethod(): any
	{
		return this.paymentMethodsData.find(paymentMethod =>
			paymentMethod.isDefault === true);
	}

	/**
	 * Gets the payment method label for the payment methods dropdown.
	 *
	 * @return {string}
	 * The formatted payment method type plus the payment method name.
	 * @memberof PaymentDetailsComponent
	 */
	private getPaymentMethodName(
		paymentMethod: any): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.toProperCase(paymentMethod.type))
				+ ' - '
				+ paymentMethod.name;
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof PaymentDetailsComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		const paymentMethods: IDropdownOption[] =
			this.getPaymentMethods();

		const defaultPaymentMethod: any =
			this.getDefaultPaymentMethod();

		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.paymentMethod',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Payment Method',
						placeholder: 'Select an Option',
						options: paymentMethods,
						default: defaultPaymentMethod
					},
					validators: {
						validDefinition: {
							expression: ((
								control: FormControl) =>
								this.paymentMethodsValidator(
									control)),
							message:
								'Payment method is required.'
						}
					}
				},
				{
					key: 'data.paymentOption',
					type: FormlyConstants.customControls.customInputRadioButton,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Payment Amount',
						formatType: 'currency',
						valueKey: 'paymentAmount',
						inputGroupItems:
							<IInputGroupItem[]>
							[
								{
									name: 'Minimum Due',
									key: 'minimumDue',
									optionValue: this.currentData.paymentDetails.minimumDue,
									disabledInput: true
								},
								{
									name: 'Remaining Balance',
									key: 'remainingBalance',
									optionValue: this.currentData.paymentDetails.remainingBalance,
									disabledInput: true
								},
								{
									name: 'Other',
									key: 'other',
									optionValue: null,
									placeholder: '$0.00',
									disabledInput: true,
									allowEntry: true
								}
							]
					},
					validators: {
						validDefinition: {
							expression: ((
								control: FormControl,
								field: FormlyFieldConfig) =>
								this.paymentAmountValidator(
									control,
									field)),
							message:
								'A value above zero dollars must be paid.'
						}
					}
				},
				{
					key: 'data.paymentAmount',
					type: FormlyConstants.customControls.input,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					className: 'visually-hidden',
					templateOptions: {
					}
				},
				{
					key: 'data.termsAndConditions',
					type: FormlyConstants.customControls.customCheckbox,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					className: 'ellipsis-text',
					templateOptions: {
						checkboxText: 'I agree to the terms and conditions of the Payment Authorization Agreement'
					},
					validators: {
						validDefinition: {
							expression: ((
								control: FormControl) =>
								control.value === true),
							message:
								'You must agree to the terms before proceeding.'
						}
					}
				}
			];
	}
}