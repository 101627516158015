<div class="ui-g-12 section-title"
	[attr.data-key]="field.key"
	[ngClass]="{
		'repeater-title': (field.fieldGroup)
	}"
	[id]="cleanLabelIdentifier">
	<span class="panel-sub-title section-title-text">
		{{to.label}}
		<span *ngIf="field.fieldGroup">
			({{getRepeaterItemCount()}})
		</span>
		<ng-container
			*ngIf="to.tooltip != null && to.tooltip.length > 0">
			<i #Tooltip
				[pTooltip]="to.tooltip"
				tooltipPosition="top"
				tooltipStyleClass="label-tooltip"
				class="fa fa-info-circle theme-color"
				(click)="preventDefault($event)"
				(tap)="mobileTooltipToggle()">
			</i>
		</ng-container>
	</span>

	<span *ngIf="field.fieldGroup && !field.templateOptions.disabled"
		(click)="addItemAction()"
		class="text-link float-right section-title-action">
		<a>
			<i class="fa fa-plus-circle double-font-size">
			</i>
			<span class="section-title-action-text">
				Add {{to.singular}}
			</span>
		</a>
	</span>
</div>