/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	ActivatedRoute,
	Router
} from '@angular/router';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	ApiHelper
} from '@shared/helpers/api.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	ContentAnimation
} from '@shared/app-animations';
import {
	DisplayComponentDefinitionApiService
} from '@api/services/display-components/display-component-definition.api.service';
import {
	DisplayComponentInstance
} from '@shared/implementations/display-components/display-component-instance';
import {
	DisplayComponentInstanceApiService
} from '@api/services/display-components/display-component-instance.api.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	DisplayComponentTypeApiService
} from '@api/services/display-components/display-component-type.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDisplayComponentDefinition
} from '@shared/interfaces/display-components/display-component-definition.interface';
import {
	IDisplayComponentInstance
} from '@shared/interfaces/display-components/display-component-instance.interface';
import {
	IDisplayComponentType
} from '@shared/interfaces/display-components/display-component-type.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IObjectSearch
} from '@shared/interfaces/application-objects/object-search.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	IPowerBiReportDefinition
} from '@shared/interfaces/reports/power-bi/power-bi-report-definition.interface';
import {
	IReportListItem
} from '@bi/interfaces/report-list-item';
import {
	isEqual
} from 'lodash';
import {
	Location
} from '@angular/common';
import {
	MenuItem
} from 'primeng/api';
import {
	OperationButtonTypeConstants
} from '@operation/shared/operation-button-type.constants';
import {
	PermissionConstants
} from '@shared/constants/permission.constants';
import {
	PowerBiApiService
} from '@shared/services/power-bi-api.service';
import {
	ReportConstants
} from '@shared/constants/report.constants';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';
import {
	User
} from '@shared/implementations/users/user';

/* eslint-enable max-len */

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	animations: [
		ContentAnimation
	]
})

/**
 * A component representing a list of display component
 * defined reports.
 *
 * @export
 * @class ReportsComponent
 * @extends {CommonTablePageDirective}
 * @implements {OnInit}
 * @implements {IOwnershipGuardComponent}
 */
export class ReportsComponent
	extends CommonTablePageDirective
	implements OnInit, IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the reports component.
	 *
	 * @param {DisplayComponentService} displayComponentService
	 * The display component service used to populate full data sets.
	 * @param {DisplayComponentInstanceApiService}
	 * displayComponentInstanceApiService
	 * The display component instance api service used for list lookups.
	 * @param {DisplayComponentTypeApiService} displayComponentTypeApiService
	 * The display component type api service used for list lookups.
	 * @param {DisplayComponentDefinitionApiService}
	 * displayComponentDefinitionApiService
	 * The display component definition api service used for list lookups.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service user for created by lookups.
	 * @param {Router} router
	 * The router used in this component for site navigation.
	 * @param {ActivatedRoute} route
	 * The site layout service to use for responsive layouts.
	 * @param {SessionService} sessionService
	 * The session service used in this component.
	 * @param {ActivityService} activityService
	 * The activity service used in this component.
	 * @param {PowerBiApiService} powerBiApiService
	 * The power bi api service used in this component.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @param {Location} location
	 * The location used for route mapping.
	 * @memberof ActionDefinitionsComponent
	 */
	public constructor(
		public displayComponentService: DisplayComponentService,
		public displayComponentInstanceApiService:
			DisplayComponentInstanceApiService,
		public displayComponentTypeApiService:
			DisplayComponentTypeApiService,
		public displayComponentDefinitionApiService:
			DisplayComponentDefinitionApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public router: Router,
		public route: ActivatedRoute,
		public sessionService: SessionService,
		public activityService: ActivityService,
		public powerBiApiService: PowerBiApiService,
		public resolver: ResolverService,
		public location: Location)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the loading value of this table display.
	 *
	 * @type {boolean}
	 * @memberof ReportsComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the table definitions for the standard table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ReportsComponent
	 */
	public tableDefinition: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ReportsComponent
	 */
	public availableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ReportsComponent
	 */
	public selectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ReportsComponent
	 */
	public columnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the selected delete row action item.
	 *
	 * @type {IReportListItem[]}
	 * @memberof ReportsComponent
	 */
	public itemsInDeleteMode: IReportListItem[] = [];

	/**
	 * Gets or sets the selected filter criteria.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public selectedFilterCriteria: string = AppConstants.empty;

	/**
	 * Gets or sets the selected filter criteria.
	 *
	 * @type {number}
	 * @memberof ReportsComponent
	 */
	public selectedRowCount: number = 15;

	/**
	 * Gets or sets the all Types Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public allTypesFilter: string;

	/**
	 * Gets or sets the custom Type Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public customTypeFilter: string;

	/**
	 * Gets or sets the standard Type Filter.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public standardTypeFilter: string;

	/**
	 * Gets the base page display component name for the current
	 * selected row.
	 *
	 * @type {string}
	 * @memberof ReportsComponent
	 */
	public get basePageReportDisplayComponentName(): string
	{
		return `${AppConstants.displayComponentTypes.basePage}.`
			+ this.commonTableContext.source
				.rowData.displayComponentInstanceName;
	}

	/**
	 * icon action id constants.
	 *
	 * @readonly
	 * @type {any}
	 * @memberof ReportsComponent
	 */
	private readonly iconActionIds: {
		navigateToEditReport: string;
		navigateToViewReport: string;
		deleteReport: string;
		} = {
			navigateToEditReport: 'NavigateToEditReport',
			navigateToViewReport: 'NavigateToViewReport',
			deleteReport: 'DeleteReport'
		};

	/**
	 * Handles the on initialization event.
	 * This will setup the table definitions for the reports search page.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			EventHelper.dispatchNavigateToAccessDeniedEvent(
				this.location.path(),
				[
					AppConstants.displayComponentTypes.reportPowerBiStandard,
					AppConstants.displayComponentTypes.reportPowerBiCustom
				]);

			return;
		}

		await this.setFilterTypes();
		this.setupTableDefinitions();
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate page ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this page.
	 * @memberof ReportsComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const displayTypes: IDisplayComponentType[] =
			await this.displayComponentTypeApiService
				.query(
					'Name eq \''
						+ AppConstants.displayComponentTypes
							.reportPowerBiStandard
						+ '\' or Name eq \''
						+ AppConstants.displayComponentTypes
							.reportPowerBiCustom
						+ '\'',
					AppConstants.empty);

		if (displayTypes.length === 0)
		{
			return false;
		}

		const displayDefinitions: IDisplayComponentDefinition[] =
			await this.displayComponentDefinitionApiService.query(
				this.getTypeFilter(
					displayTypes),
				AppConstants.empty);

		return displayDefinitions.length !== 0;
	}

	/**
	 * Sets the filter types.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async setFilterTypes(): Promise<void>
	{
		const displayTypes: IDisplayComponentType[] =
			await this.displayComponentTypeApiService
				.query(
					'Name.StartsWith(\'Report\') eq true',
					AppConstants.empty);
		this.allTypesFilter =
			this.getTypeFilter(
				displayTypes);
		this.customTypeFilter =
			this.getTypeFilter(
				displayTypes,
				AppConstants.displayComponentTypes.reportPowerBiCustom);
		this.standardTypeFilter =
			this.getTypeFilter(
				displayTypes,
				AppConstants.displayComponentTypes.reportPowerBiStandard);

		this.selectedFilterCriteria = this.allTypesFilter;

		let displayOrder: number = 1;
		this.availableColumns =
			[
				{
					dataKey: 'reportName',
					columnHeader: 'Report Name',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'reportType',
					columnHeader: 'Report Type',
					displayOrder: displayOrder++
				},
				{
					dataKey: 'workspaceType',
					columnHeader: 'Workspace',
					displayOrder: displayOrder
				},
				{
					dataKey: 'createdByName',
					columnHeader: 'Created By',
					displayOrder: displayOrder
				}
			];
		this.selectedColumns =
			this.availableColumns;
	}

	/**
	 * Sets up the list column definitions for the current reports object
	 * list.
	 *
	 * @memberof ReportsComponent
	 */
	public setupTableDefinitions(): void
	{
		const isEditAllowed: boolean =
			new User(this.sessionService.user)
				.hasMembership(
					PermissionConstants.editPowerBiReportRoles);

		this.tableDefinition = {
			hideExpanderArrow: true,
			actions: {
				filter: {
					quickFilters:
						[
							{
								label: 'All Reports',
								value: this.allTypesFilter
							},
							{
								label: 'Custom PowerBI Reports',
								value: this.customTypeFilter
							},
							{
								label: 'Standard PowerBI Reports',
								value: this.standardTypeFilter
							}
						],
					selectedFilterValue: this.selectedFilterCriteria
				},
				rowIconActions: {
					label: 'Report Row Operations',
					disabled: false,
					id: OperationButtonTypeConstants.iconActions,
					items:
						<any[]>
						[
							{
								label: 'Edit Report',
								icon: 'fa fa-fw fa-edit',
								id: this.iconActionIds.navigateToEditReport,
								visible: isEditAllowed,
								command: (commandArguments: any) =>
								{
									this.navigateToDisplay(commandArguments);
								},
								enabled: (commandArguments: any) =>
									this.rowActionEnabled(
										commandArguments,
										AppConstants.viewTypes.edit)
							},
							{
								label: 'View Report',
								icon: 'fa fa-fw fa-share',
								id: this.iconActionIds.navigateToViewReport,
								visible: true,
								command: (commandArguments: any) => {
									this.navigateToDisplay(commandArguments);
								},
								enabled: (commandArguments: any) =>
									this.rowActionEnabled(
										commandArguments,
										AppConstants.viewTypes.view)
							}
						]
				},
				delete: {
					items: [
						{
							label: 'Confirm',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							disabled: false,
							command: () => this.deleteReport()
						}],
					deleteStatement: () => this.getDeleteStatement(),
					enabled: (args: any) =>
						this.rowActionEnabled(
							args,
							AppConstants.viewTypes.delete)
				}
			},
			tableTitle: 'Reports',
			objectSearch: {
				filter: this.selectedFilterCriteria,
				orderBy: `${AppConstants.commonProperties.name} `
					+ `${AppConstants.sortDirections.ascending}`,
				offset: 0,
				limit: 50,
				virtualIndex: 0,
				virtualPageSize: this.selectedRowCount
			},
			apiPromise: async(objectSearch: IObjectSearch) =>
			{
				const data: IDisplayComponentInstance[] =
						<IDisplayComponentInstance[]>
						await this.displayComponentInstanceApiService
							.query(
								objectSearch.filter,
								objectSearch.orderBy,
								objectSearch.offset,
								objectSearch.limit);

				if (AnyHelper.isNullOrEmpty(data)
					|| data.length === 0)
				{
					return [];
				}
				const initialPromiseArray: Promise<any>[] = [];
				data.forEach(
					(iDisplayComponentInstance:
								IDisplayComponentInstance) =>
					{
						initialPromiseArray.push(
							this.displayComponentService
								.populateDisplayComponentInstance(
									iDisplayComponentInstance.name,
									this.pageContext,
									null,
									iDisplayComponentInstance,
									true));
					});
				const displayComponentInstances:
					DisplayComponentInstance[] =
						await Promise.all(
							initialPromiseArray);

				const mappedData: IReportListItem[] =
					await this.mapDisplayComponents(
						displayComponentInstances);

				return mappedData;
			},
			availableColumns: this.availableColumns,
			selectedColumns: this.selectedColumns,
			columnSelectionMode: this.columnSelectionMode,
			filterCriteriaChanged: (filterCriteria: string) =>
			{
				this.selectedFilterCriteria = filterCriteria;
				this.restoreTableDefinition();
			},
			rowCountChanged: (rowCount: number) =>
			{
				this.selectedRowCount = rowCount;
				this.restoreTableDefinition();
			},
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;

				this.tableDefinition.actions.rowIconActions
					.items.forEach(
						(menuItem: MenuItem) =>
						{
							menuItem.disabled =
									this.itemsInDeleteMode.length > 0
										&& this.itemsInDeleteMode.filter(
											(reportListItem: IReportListItem) =>
												isEqual(
													reportListItem,
													this.commonTableContext
														.source
														.rowData)).length > 0;
						});
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.tableDefinition.selectedColumns =
					selectedColumns;
				this.selectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.tableDefinition.columnSelectionMode =
					columnSelectionMode;
				this.columnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Maps a list of display component instances into the expected
	 * set of report list items.
	 *
	 * @async
	 * @param {DisplayComponentInstance[]} displayComponentInstances
	 * The set of display component instances to map into the expected
	 * list item type.
	 * @returns {IReportListItem[]}
	 * The mapped set of list items to display in this common table.
	 * @memberof ReportsComponent
	 */
	public async mapDisplayComponents(
		displayComponentInstances:
			DisplayComponentInstance[]): Promise<IReportListItem[]>
	{
		const creatorIds: string[] =
			[...new Set(
				displayComponentInstances
					.map(
						(displayComponent: IDisplayComponentInstance) =>
							displayComponent.createdById.toString()))];

		const createdByFilter: string =
			AppConstants.commonProperties.id
				+ ' in ('
				+ ApiFilterHelper.commaSeparatedStringValues(
					creatorIds,
					AppConstants.empty)
				+ ')';

		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;
		const creators: IEntityInstance[] =
			await ApiHelper.getFullDataSet(
				this.entityInstanceApiService,
				createdByFilter,
				AppConstants.empty);

		const mappedData: IReportListItem[] = [];
		for (const displayComponent of displayComponentInstances)
		{
			const creator: IEntityInstance =
				creators.filter(
					(user: IEntityInstance) =>
						user.id === displayComponent.createdById)[0];

			const reportDefinition: any =
				displayComponent.displayComponentDefinition.jsonDefinition;
			mappedData.push(
				<IReportListItem>
				{
					id: displayComponent.id,
					displayComponentDefinition:
						displayComponent.displayComponentDefinition,
					displayComponentInstance: displayComponent,
					displayComponentInstanceName: displayComponent.name,
					reportDefinition: reportDefinition,
					reportName: reportDefinition.reportName,
					reportType: reportDefinition.reportType,
					workspaceType: reportDefinition.workspaceType,
					createdByName:
						StringHelper.toNameString(
							creator.data.firstName,
							creator.data.lastName)
				});
		}

		return mappedData;
	}

	/**
	 * Navigates to the generic base page component holding this
	 * report.
	 *
	 * @param {any} commandArguments
	 * the argumenst sent by the icon item command.
	 * @memberof ReportsComponent
	 */
	public navigateToDisplay(
		commandArguments: any): void
	{
		const basePageReportDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ commandArguments.rowData.displayComponentInstanceName;

		const displayMode: string =
			commandArguments.item.id === this.iconActionIds.navigateToViewReport
				? AppConstants.viewTypes.view
				: AppConstants.viewTypes.edit;

		// Store this value for back functionality.
		history.pushState(
			null,
			AppConstants.empty,
			this.router.url);

		this.router.navigate(
			[
				AppConstants.moduleNames.bi,
				AppConstants.route.display,
				basePageReportDisplayComponentName,
				displayMode
			],
			{
				replaceUrl: true
			});
	}

	/**
	 * Deletes a report from this list of available reports.
	 *
	 * @async
	 * @memberof ReportsComponent
	 */
	public async deleteReport(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;

		const basePageReportDisplayComponentName: string =
			`${AppConstants.displayComponentTypes.basePage}.`
				+ rowData.displayComponentInstanceName;

		this.itemsInDeleteMode.push(rowData);

		const reportDisplayComponentInstance: IDisplayComponentInstance =
		rowData.displayComponentInstance;
		const reportDefinition: IPowerBiReportDefinition =
			{
				...rowData.reportDefinition
			};

		const basePageReportDisplayComponentInstance:
			IDisplayComponentInstance =
				<IDisplayComponentInstance>
				await this.displayComponentInstanceApiService
					.getSingleQueryResult(
						`Name Eq '${basePageReportDisplayComponentName}'`,
						`${AppConstants.commonProperties.id}`);

		const basePageReportDisplayComponentInstanceId: number =
			basePageReportDisplayComponentInstance.id;
		const reportDisplayComponentInstanceId: number =
			reportDisplayComponentInstance.id;

		const basePageSecurityGroupIdentifiers: number[] =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(basePageReportDisplayComponentInstanceId);
		const reportSecurityGroupIdentifiers: number[] =
			await this.displayComponentInstanceApiService
				.getSecurityGroups(reportDisplayComponentInstanceId);

		const initialPromiseArray: Promise<any>[] = [];
		basePageSecurityGroupIdentifiers.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.deleteSecurityGroupMap(
							basePageReportDisplayComponentInstanceId,
							securityGroupId));
			});
		reportSecurityGroupIdentifiers.forEach(
			(securityGroupId: number) =>
			{
				initialPromiseArray.push(
					this.displayComponentInstanceApiService
						.deleteSecurityGroupMap(
							reportDisplayComponentInstanceId,
							securityGroupId));
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise<void>(
					async(resolve) =>
					{
						await Promise.all(
							initialPromiseArray);

						await this.displayComponentInstanceApiService
							.delete(reportDisplayComponentInstanceId);
						await this.displayComponentInstanceApiService
							.delete(basePageReportDisplayComponentInstanceId);

						const tableContextSource: CommonTableComponent =
								this.commonTableContext.source;
						await this.powerBiApiService
							.deleteReport(
								reportDefinition);

						const persistedData =
								await tableContextSource.tableDefinitions
									.apiPromise(
										tableContextSource
											.tableDefinitions.objectSearch);

						const lastItem: IReportListItem =
								persistedData[
									tableContextSource.listData.length - 1];
						const lastItemExists: boolean =
								tableContextSource.listData.filter(
									(reportListItem: IReportListItem) =>
										!isEqual(
											reportListItem,
											lastItem)).length > 0;

						tableContextSource.listData =
								[
									...this.filterListItems(
										tableContextSource.listData,
										reportDefinition)
								];
						tableContextSource.virtualData =
								[
									...this.filterListItems(
										tableContextSource.virtualData,
										reportDefinition)
								];

						if (!lastItemExists)
						{
							tableContextSource.virtualData
								.push(lastItem);
							tableContextSource.listData
								.push(lastItem);
						}

						tableContextSource.setTotalRecords();
						tableContextSource.scrollTableToTop();

						this.itemsInDeleteMode =
								[
									...this.filterListItems(
										this.itemsInDeleteMode,
										reportDefinition)
								];
						resolve();
					}),
				'<strong>Deleting Report</strong>',
				'<strong>Deleted A Report</strong>',
				`${reportDefinition.reportName} was deleted.`,
				`${reportDefinition.reportName} was not deleted.`));
	}

	/**
	 * Get a value indicating ifthe row action should be enabled.
	 *
	 * @param {any} commandArguments
	 * the agruments sent by the command
	 * @param {string} actionType
	 * The action type.
	 * @returns {boolean}
	 * a value of true if it should be enabled.
	 * @memberof ReportsComponent
	 */
	public rowActionEnabled(
		commandArguments: any,
		actionType: string): boolean
	{
		let isEnabled: boolean;

		const isCustomWorkspace: boolean =
			commandArguments.workspaceType === ReportConstants
				.powerBiWorkspaceTypes.custom;

		switch (actionType){
			case AppConstants.viewTypes.delete:
				isEnabled = isCustomWorkspace;
				break;
			case AppConstants.viewTypes.edit:
				isEnabled = isCustomWorkspace;
				break;
			case AppConstants.viewTypes.view:
				isEnabled = true;
				break;
			default:
				isEnabled = false;
		}

		return isEnabled;
	}

	/**
	 * Creates a filter for use in api calls using specific type ids. If a
	 * specific display type is sent, this will only include matches of display
	 * component types with that same name.
	 *
	 * @param {} displayTypes
	 * The array of available display types to create filter for.
	 * @param {string} displayType
	 * If sent this will return a filter for matching only the sent specific
	 * display type. This defaults to null and will return a filter for the
	 * full display types array.
	 * @returns {string}
	 * A string for use as a display type filter when querying display
	 * component instances.
	 * @memberof ReportsComponent
	 */
	private getTypeFilter(
		displayTypes: IDisplayComponentType[],
		displayType: string = null): string
	{
		return ApiFilterHelper.getEnumerationFilter(
			AppConstants.commonProperties.typeId,
			displayTypes
				.filter((displayComponentType: IDisplayComponentType) =>
					AnyHelper.isNullOrWhitespace(displayType)
						|| displayComponentType.name === displayType)
				.map((displayComponentType: IDisplayComponentType) =>
					`${displayComponentType.id}`));
	}

	/**
	 * Returns a list of filtered list items with the sent report list item
	 * with a matching report definition removed.
	 *
	 * @param {IReportListItem[]} arrayToClean
	 * The array of report list items that will be filtered.
	 * @param {IPowerBiReportDefinition} reportDefinitionToRemove
	 * The report definition to match for list item removal.
	 * @returns {IReportListItem[]}
	 * The list of items with the filtered list item removed.
	 * @memberof ReportsComponent
	 */
	private filterListItems(
		arrayToClean: IReportListItem[],
		reportDefinitionToRemove: IPowerBiReportDefinition): IReportListItem[]
	{
		return arrayToClean.filter(
			(reportListItem: IReportListItem) =>
				reportListItem.reportDefinition.reportName !==
					reportDefinitionToRemove.reportName
					|| reportListItem.reportDefinition.groupId !==
						reportDefinitionToRemove.groupId);
	}

	/**
	 * Gets the delete statement.
	 *
	 * @returns {string}
	 * The delete statement string.
	 * @memberof ReportsComponent
	 */
	private getDeleteStatement(): string
	{
		return `Confirm you want to delete Report
			${this.commonTableContext.source.rowData.reportName}.`;
	}
}