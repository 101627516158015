<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i
		class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template
	#Content>
	<div
		class="ui-g-12 text-left no-padding">
		<div
			class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				View: Note
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
						<i
							class="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							[ngClass]="{
								'selected-bottom-border': (action.id === 'View')
							}"
							(click)="action.command($event)"
							pTooltip="{{action.label}}"
							tooltipPosition="left"
							tooltipStyleClass="utility-menu-tooltip">
						</i>
				</ng-template>
			</span>
		</div>

		<app-note
			[context]="context"
			[selectedItem]="selectedItem"
			(changeDisplayMode)="changeDisplayMode($event)">
		</app-note>
	</div>
</ng-template>