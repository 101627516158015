<app-base-page pageTitle="Search Reports" [pageContext]="pageContext" utilityMenuOperationGroupName="">

	<div class="ui-g-12 no-horizontal-padding text-center" *ngIf="loadingTableDefinitions === true"
		[@contentAnimation]="state">
		<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
		</i>
	</div>

	<div class="ui-g-12 report-search" *ngIf="loadingTableDefinitions === false" [@contentAnimation]="state">
		<app-common-table [tableDefinitions]="tableDefinition" [useIconBar]="true">
		</app-common-table>
	</div>

</app-base-page>