/**
 * @copyright WaterStreet. All rights reserved.
 */

// tslint:disable: no-string-literal
/* eslint-disable max-len */

import {
	AddDrawerAction
} from '@operation/actions/add-drawer-action';
import {
	AddRelatedContextMenuAction
} from '@operation/actions/add-related-context-menu-action';
import {
	ButtonModule
} from 'primeng/button';
import {
	ChangeLayoutColorAction
} from '@operation/actions/change-layout-color-action';
import {
	ChangeThemeAction
} from '@operation/actions/change-theme-action';
import {
	ClickOutsideDirective
} from '@shared/directives/click-outside.directive';
import {
	CommonModule
} from '@angular/common';
import {
	ContextOperationAction
} from '@operation/actions/context-operation-action';
import {
	DeleteEntityAction
} from '@operation/actions/delete-entity-action';
import {
	EmitEventAction
} from '@operation/actions/emit-event-action';
import {
	ExecuteOperationPromiseAction
} from '@operation//actions/execute-operation-promise-action';
import {
	ExecuteWorkflowAction
} from '@operation/actions/execute-workflow-action';
import {
	GenerateDocumentAction
} from '@operation/actions/generate-document-action';
import {
	GenerateFilesAction
} from '@operation/actions/generate-files-action';
import {
	LogOutAction
} from '@operation/actions/logout-action';
import {
	NavigationAction
} from '@operation/actions/navigation-action';
import {
	NgModule
} from '@angular/core';
import {
	OperationButtonBarComponent
} from '@operation/components/operation-button-bar/operation-button-bar.component';
import {
	OperationButtonMenuComponent
} from '@operation/components/operation-button-menu/operation-button-menu.component';
import {
	OperationExecutionService
} from '@operation/services/operation-execution.service';
import {
	OperationFactory
} from '@operation/factories/operation-factory';
import {
	OperationIconBarComponent
} from './components/operation-icon-bar/operation-icon-bar.component';
import {
	OperationMenuComponent
} from '@operation/components/operation-menu/operation-menu.component';
import {
	OperationMenuSubGroupComponent
} from '@operation/components/operation-menu/operation-menu-subgroup/operation-menu-subgroup.component';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	OperationTokenLookup
} from '@operation/operation-token.lookup';
import {
	PanelModule
} from 'primeng/panel';
import {
	RouterModule
} from '@angular/router';
import {
	SaveEntityAction
} from '@operation/actions/save-entity-action';
import {
	ScrollPanelModule
} from 'primeng/scrollpanel';
import {
	SplitButtonModule
} from 'primeng/splitbutton';
import {
	TooltipModule
} from 'primeng/tooltip';

/* eslint-enable max-len */

@NgModule({
	imports: [
		ButtonModule,
		CommonModule,
		PanelModule,
		RouterModule,
		ScrollPanelModule,
		TooltipModule,
		SplitButtonModule
	],
	exports: [
		ClickOutsideDirective,
		OperationButtonBarComponent,
		OperationButtonMenuComponent,
		OperationIconBarComponent,
		OperationMenuComponent,
		OperationMenuSubGroupComponent
	],
	declarations: [
		ClickOutsideDirective,
		OperationButtonBarComponent,
		OperationButtonMenuComponent,
		OperationIconBarComponent,
		OperationMenuComponent,
		OperationMenuSubGroupComponent
	],
	providers: [
		OperationService,
		OperationExecutionService,
		OperationFactory,
		AddDrawerAction,
		{
			provide: OperationTokenLookup.tokens.AddDrawer,
			useClass: AddDrawerAction
		},
		AddRelatedContextMenuAction,
		{
			provide: OperationTokenLookup.tokens.AddRelatedContextMenu,
			useClass: AddRelatedContextMenuAction
		},
		ChangeLayoutColorAction,
		{
			provide: OperationTokenLookup.tokens.ChangeLayoutColor,
			useClass: ChangeLayoutColorAction
		},
		ChangeThemeAction,
		{
			provide: OperationTokenLookup.tokens.ChangeTheme,
			useClass: ChangeThemeAction
		},
		ContextOperationAction,
		{
			provide: OperationTokenLookup.tokens.ContextOperation,
			useClass: ContextOperationAction
		},
		DeleteEntityAction,
		{
			provide: OperationTokenLookup.tokens.DeleteEntity,
			useClass: DeleteEntityAction
		},
		EmitEventAction,
		{
			provide: OperationTokenLookup.tokens.EmitEvent,
			useClass: EmitEventAction
		},
		ExecuteOperationPromiseAction,
		{
			provide: OperationTokenLookup.tokens.ExecuteOperationPromise,
			useClass: ExecuteOperationPromiseAction
		},
		ExecuteWorkflowAction,
		{
			provide: OperationTokenLookup.tokens.ExecuteWorkflow,
			useClass: ExecuteWorkflowAction
		},
		GenerateDocumentAction,
		{
			provide: OperationTokenLookup.tokens.GenerateDocument,
			useClass: GenerateDocumentAction
		},
		GenerateFilesAction,
		{
			provide: OperationTokenLookup.tokens.GenerateFiles,
			useClass: GenerateFilesAction
		},
		LogOutAction,
		{
			provide: OperationTokenLookup.tokens.LogOut,
			useClass: LogOutAction
		},
		NavigationAction,
		{
			provide: OperationTokenLookup.tokens.Navigation,
			useClass: NavigationAction
		},
		SaveEntityAction,
		{
			provide: OperationTokenLookup.tokens.SaveEntity,
			useClass: SaveEntityAction
		}
	]
})

/**
 * A module to be shared accross the application used
 * for operation based logic and actions.
 *
 * @export
 * @class OperationModule
 */
export class OperationModule
{
}