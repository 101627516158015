/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IIntervalService
} from '@shared/interfaces/application-objects/interval-service.interface';
import {
	Injectable,
	OnDestroy,
	RendererFactory2
} from '@angular/core';
import {
	interval,
	Observable,
	Subject,
	Subscription,
	throttleTime
} from 'rxjs';
import {
	MenuItem
} from 'primeng/api';
import {
	SessionService
} from '@shared/services/session.service';

/**
 * A singleton class that handles session refresh interactions with a logged
 * in user.
 *
 * @export
 * @class SessionRefreshService
 */
@Injectable({
	providedIn: 'root'
})
export class SessionRefreshService implements IIntervalService, OnDestroy
{
	/**
	 * Creates an instance of a session refresh service.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service used to refresh a server connection.
	 * @param {SessionService} sessionService
	 * The session service used to track server settings for timeouts.
	 * @param {RendererFactory2} rendererFactory
	 * The renderer factory used to insert an inline script.
	 */
	public constructor(
		private entityInstanceApiService: EntityInstanceApiService,
		private readonly sessionService: SessionService,
		private readonly rendererFactory: RendererFactory2)
	{
	}

	/**
	 * Gets the final url characters used for a session refresh query.
	 *
	 * @type {string}
	 * @memberof SessionRefreshService
	 */
	public static readonly refreshApiEndpoint: string =
		'/entityInstances/Systems/1';

	/**
	 * Gets or sets the subject that will watch for debounced and throttled
	 * reset calls to limit API queries.
	 *
	 * @type {Subject<void>}
	 * @memberof SessionRefreshService
	 */
	private resetIntervalsCalled: Subject<void> = new Subject<void>();

	/**
	 * Gets or sets the server refresh session interval.
	 *
	 * @type {Observable<number>}
	 * @memberof SessionRefreshService
	 */
	private serverRefreshInterval: Observable<number>;

	/**
	 * Gets or sets the client refresh interval.
	 *
	 * @type {Observable<number>}
	 * @memberof SessionRefreshService
	 */
	private clientLogoutInterval: Observable<number>;

	/**
	 * Gets or sets the client notification interval.
	 *
	 * @type {Observable<number>}
	 * @memberof SessionRefreshService
	 */
	private clientNotificationInterval: Observable<number>;

	/**
	 * Gets or sets the server refresh session subscriber.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private serverRefreshSubscription: Subscription;

	/**
	 * Gets or sets the client refresh session subscriber.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private clientLogoutSubscription: Subscription;

	/**
	 * Gets or sets the client notification subscription that will send
	 * the client session refresh message.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private clientNotificationSubscription: Subscription;

	/**
	 * Gets or sets the length of a server session before a timeout in
	 * milliseconds.
	 *
	 * @type {number}
	 * @memberof SessionRefreshService
	 */
	private serverExpirationInterval: number;

	/**
	 * Gets or sets the length of a clent session before a timeout in
	 * milliseconds.
	 *
	 * @type {number}
	 * @memberof SessionRefreshService
	 */
	private clientExpirationInterval: number;

	/**
	 * Gets the number of milliseconds before the server refresh timer
	 * will fire a refresh API call.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	 private readonly serverRefreshTime: number =
		60 * AppConstants.time.oneSecond;

	/**
	 * Gets the number of seconds before the client refresh timer
	 * will expire to show an idle notification.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private readonly clientNotificationTime: number =
		120 * AppConstants.time.oneSecond;

	/**
	 * Gets the number of seconds to throttle between reset interval
	 * actions to limit api queries.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private readonly resetThrottleTime: number =
		30 * AppConstants.time.oneSecond;

	/**
	 * Gets an inline javascript function that will handle the timer portion
	 * of the notification message.
	 * See: https://www.w3schools.com/howto/howto_js_countdown.asp
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private readonly inlineTimerScript: string =
		`
		var offset = 2 * 60000;
		var countDownDate = new Date(new Date().getTime() + offset);

		var interval =
			setInterval(
				() =>
				{
					var now = new Date().getTime();
					var distance = countDownDate - now;

					var minutes = Math.floor(
						(distance % (1000 * 60 * 60)) / (1000 * 60));
					var seconds = Math.floor(
						(distance % (1000 * 60)) / 1000);

					var timerElement = document.getElementById("timer");
					if (timerElement != null)
					{
						timerElement.innerHTML =
							minutes + " minute"
								+ ((minutes > 1)
									? "s"
									: "")
								+ ((seconds > 0)
									? " and " + seconds + " seconds"
									: "")
					}
				},
				1000);

			setTimeout(
				() =>
				{
					clearInterval(interval);
				},
				offset);
		`;

	/**
	 * Gets the parsed system id used when querying the entity instance
	 * api service.
	 *
	 * @type {Subscription}
	 * @memberof SessionRefreshService
	 */
	private get systemId(): number
	{
		return parseInt(
			AppConstants.systemId,
			AppConstants.parseRadix);
	}

	/**
	 * Handles the on destroy interface.
	 * This completes any intervals and subscriptions.
	 *
	 * @memberof AppComponent
	 */
	public ngOnDestroy(): void
	{
		this.resetIntervalsCalled.complete();
		this.stop();
	}

	/**
	 * Starts the session refresh service.
	 *
	 * @param {number} serviceInterval
	 * The interval in milliseconds to wait between checking for a client
	 * side session refresh.
	 * @memberof SessionRefreshService
	 */
	public start(
		serviceInterval: number): void
	{
		this.stop();

		this.serverExpirationInterval =
			this.sessionService.expiry.totalMilliSeconds;
		this.clientExpirationInterval = serviceInterval;

		if (this.clientExpirationInterval <= this.clientNotificationTime)
		{
			throw new Error(
				'The value of the \'clientExirationInterval\' in settings '
					+ 'is less than the interval for refresh notifications. '
					+ 'Please increase the client expiration interval to a '
					+ 'value greater than two minutes.');
		}

		this.setupRefreshConditions();

		// Handle calls from external sources signifying that the interval
		// timers can be reset.
		this.resetIntervalsCalled?.complete();
		this.resetIntervalsCalled = new Subject<void>();
		this.resetIntervalsCalled.pipe(
			throttleTime(this.resetThrottleTime))
			.subscribe(
				() =>
				{
					this.stop();
					this.setupRefreshConditions();
				});

		// Force a clean interval timer on start.
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;
		this.entityInstanceApiService
			.get(this.systemId)
			.then(
				() =>
				{
					this.resetIntervalsCalled.next();
				});
	}

	/**
	 * Stops the service.
	 *
	 * @memberof SessionRefreshService
	 */
	public stop(): void
	{
		this.serverRefreshSubscription?.unsubscribe();
		this.clientLogoutSubscription?.unsubscribe();
		this.clientNotificationSubscription?.unsubscribe();
		this.serverRefreshInterval = null;
		this.clientLogoutInterval = null;
		this.clientNotificationInterval = null;
	}

	/**
	 * Handles a call to reset intervals from outside sources that update
	 * server and client timeout intervals.
	 *
	 * @memberof SessionRefreshService
	 */
	public resetIntervals(): void
	{
		this.resetIntervalsCalled.next();
	}

	/**
	 * Queries the API service to refresh the current expiration token.
	 * @note This particular API request will not call reset intervals as
	 * the selected refresh query call. See: Http Interceptor.
	 *
	 * @memberof SessionRefreshService
	 */
	private refreshServerConnection(): void
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.systems;

		this.entityInstanceApiService.get(
			this.systemId);
	}

	/**
	 * Handles setting up refresh conditions based on the server, client, and
	 * notification timers.
	 *
	 * @memberof SessionRefreshService
	 */
	private setupRefreshConditions(): void
	{
		this.serverRefreshInterval =
			interval(
				this.serverExpirationInterval - this.serverRefreshTime);
		this.serverRefreshSubscription =
			this.serverRefreshInterval
				.subscribe(
					() =>
					{
						this.handleServerRefresh();
					});

		this.clientNotificationInterval =
			interval(
				this.clientExpirationInterval - this.clientNotificationTime);
		this.clientNotificationSubscription =
			this.clientNotificationInterval
				.subscribe(
					() =>
					{
						this.handleClientNotification();
					});

		this.clientLogoutInterval =
			interval(this.clientExpirationInterval);
		this.clientLogoutSubscription =
			this.clientLogoutInterval
				.subscribe(
					() =>
					{
						this.handleClientExpiration();
					});
	}

	/**
	 * Checks conditional logic to determine if a server refresh is required.
	 *
	 * @memberof SessionRefreshService
	 */
	private handleServerRefresh(): void
	{
		this.refreshServerConnection();
	}

	/**
	 * Checks conditional logic to determine actions if the client keep alive
	 * has expired.
	 *
	 * @memberof SessionRefreshService
	 */
	private handleClientExpiration(): void
	{
		this.stop();
		this.sessionService.logOut();

		EventHelper.dispatchLoginMessageEvent(
			AppConstants.messages.sessionExpired,
			AppConstants.messages.pleaseEnterCredentials,
			AppConstants.messageLevel.error);
	}

	/**
	 * Checks conditional logic to determine if a client notification
	 * is required due to an approaching timeout.
	 *
	 * @memberof SessionRefreshService
	 */
	private handleClientNotification(): void
	{
		EventHelper.dispatchFullScreenBannerEvent(
			'Session Expiring',
			'Please <a class="full-screen-text-link">click here</a> '
				+ 'to confirm you are still '
				+ 'active. This session will expire in '
				+ '<span id="timer">two minutes</span>.',
			AppConstants.activityStatus.error,
			[
				<MenuItem>
				{
					label: 'Session Refreshed.',
					queryParams: {
						details: 'Thank you for confirming your activity.'
					},
					command:
						() =>
						{
							this.handleServerRefresh();
							this.resetIntervals();
						}
				}
			]);

		const renderer =
			this.rendererFactory
				.createRenderer(
					null,
					null);
		const inlineScript =
			renderer.createElement(
				'script');
		inlineScript.type = 'text/javascript';
		inlineScript.text = this.inlineTimerScript;
		renderer.appendChild(
			document.body,
			inlineScript);
	}
}