/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-input-switch',
	templateUrl: './custom-input-switch.component.html',
	styleUrls: [
		'./custom-input-switch.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Input Switch.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomInputSwitchComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomInputSwitchComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/** Initializes a new instance of the CustomInputSwitchComponent.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof CustomInputSwitchComponent
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets inputgroup items.
	 *
	 * @type {any[]}
	 * @memberof CustomInputSwitchComponent
	 */
	public inputGroupItems: any[];

	/**
	 * Handles the on initialization interface.
	 * This will capture the saved input switch boolean, and pass
	 * it to the displayInput value. Also saves the model into a
	 * separate data model constant.
	 *
	 * @memberof CustomInputSwitchComponent
	 */
	public ngOnInit(): void
	{
		if (!AnyHelper.isNullOrEmpty(
			this.field.templateOptions.inputGroupItems))
		{
			this.inputGroupItems =
				this.field.templateOptions.useInputGroupFunction
					!== true
					? this.field.templateOptions.inputGroupItems
					: StringHelper
						.transformToFunction(
							this.field.templateOptions.inputGroupItems,
							this.field.templateOptions.context)(this.model);

			if (AnyHelper.isNull(this.formControl.value))
			{
				this.formControl.setValue([]);
			}
		}

		super.ngOnInit();
	}

	/**
	 * Handles input group input switch changes
	 * by adding or removing values from the formControl
	 *
	 * @param {any} event.
	 * The change event.
	 * @param {any} changedValue
	 * The changed value key.
	 *
	 * @memberof CustomInputSwitchComponent
	 */
	public handleInputGroupChanges(
		event: any,
		changedValue: any): void
	{
		const inputGroupKey =
			this.field.templateOptions.inputGroupKey;

		this.formControl.setValue(
			event.checked === true
				? [
					...this.formControl.value,
					changedValue
				]
				: this.formControl.value
					.filter((currentValue: any) =>
						currentValue[inputGroupKey]
							!== changedValue[inputGroupKey]));
	}
}