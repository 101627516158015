/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

// tslint:enable: max-line-length

@Component({
	selector: 'make-payment',
	templateUrl: './make-payment.component.html',
	styleUrls: [
		'./make-payment.component.scss'
	]
})

/**
 * A component representing a wizard step for making a policy term payment.
 *
 * @export
 * @class MakePaymentComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class MakePaymentComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the make payment component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof MakePaymentComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<DynamicWizardComponent, IWizardContext>}
	 * @memberof MakePaymentComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof MakePaymentComponent
	 */
	public currentData: any;

	/**
	 * Gets or sets the PolicyTerm entity type to be updated.
	 *
	 * @type {IEntityType}
	 * @memberof MakePaymentComponent
	 */
	public policyTermEntityType: IEntityType;

	/**
	 * Gets or sets the PolicyTerm entity instance associated to the current
	 * data.
	 *
	 * @type {IEntityInstance}
	 * @memberof MakePaymentComponent
	 */
	public policyTermEntityInstance: IEntityInstance;

	/**
	 * Gets the workflow action being executed to update the PolicyTerm
	 * data.
	 *
	 * @type {string}
	 * @memberof MakePaymentComponent
	 */
	private readonly workflowActionName: string =
		'PaymentCreateLedgerTransaction';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof MakePaymentComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		const policyTermId = this.currentData.id;

		this.policyTermEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name eq \'${this.currentData.entityType}\'`,
					AppConstants.empty);

		this.policyTermEntityInstance =
			await this.getPolicyTerm(
				this.policyTermEntityType.group,
				policyTermId);

		this.context.source.addToNext(this.makePayment.bind(this));

		this.context.source.validStepChanged(true);

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * This will send the make payment event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof MakePaymentComponent
	 */
	public async makePayment(): Promise<void>
	{
		const queryString: string =
			`?paymentMethod=${this.currentData.paymentMethod.type}`
				+ `&amount=${this.currentData.paymentAmount}`;

		await this.makePaymentExecution(
			this.policyTermEntityType,
			this.policyTermEntityInstance,
			queryString);

		await this.navigateToPolicyTermDashboard(
			this.policyTermEntityInstance.id,
			this.policyTermEntityType.group);
	}

	/**
	 * Gets the payment method type.
	 *
	 * @return {string}
	 * The formatted payment method type.
	 * @memberof PaymentDetailsComponent
	 */
	public getPaymentMethodType(
		paymentMethod: any): string
	{
		return StringHelper.beforeCapitalSpaces(
			StringHelper.toProperCase(paymentMethod.type));
	}

	/**
	 * Gets the PolicyTerm instance data associated to the current id from the
	 * Payment Wizard data.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityTypeGroupName
	 * The PolicyTerm entity type group name.
	 * @param {number} policyTermId
	 * The PolicyTerm entity instance id.
	 * @memberof MakePaymentComponent
	 */
	public async getPolicyTerm(
		policyTermEntityTypeGroupName: string,
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			policyTermEntityTypeGroupName;

		const policyTermInstance: IEntityInstance =
			await this.entityInstanceApiService
				.get(policyTermId);

		return policyTermInstance;
	}

	/**
	 * Executes the make payment process, which executes the MakePayment
	 * workflow service with the collected data from the make payment wizard,
	 * then creates the payment information.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityType
	 * The PolicyTerm entity type.
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data to be updated.
	 * @param {string} queryString
	 * The extra parameters sent as query string parameters.
	 * @memberof MakePaymentComponent
	 */
	private async makePaymentExecution(
		policyTermEntityType: IEntityType,
		policyTermInstanceData: IEntityInstance,
		queryString: string): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					await this.executeMakePaymentWorkflow(
						policyTermEntityType.group,
						policyTermInstanceData,
						queryString);

					resolve();
				}),
				'<strong>Processing payment</strong>',
				'<strong>Payment processed</strong>',
				'Payment has been applied to the policy.',
				'Payment has not been applied to the policy.'));
	}

	/**
	 * Executes the PaymentCreateLedgerTransaction workflow action.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityTypeGroupName
	 * The PolicyTerm entity type group name.
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data.
	 * @param {string} queryString
	 * The extra parameters sent as query string parameters.
	 * @returns {Promise<number>}
	 * The IActionResponse response from the PaymentCreateLedgerTransaction
	 * execution.
	 * @memberof MakePaymentComponent
	 */
	private async executeMakePaymentWorkflow(
		policyTermEntityTypeGroupName: string,
		policyTermInstanceData: IEntityInstance,
		queryString: string): Promise<IActionResponse>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			policyTermEntityTypeGroupName;

		const actionResponse: IActionResponse =
			await this.entityInstanceApiService
				.executeAction(
					policyTermInstanceData.id,
					this.workflowActionName,
					policyTermInstanceData,
					queryString);

		return actionResponse;
	}

	/**
	 * This will navigate to the policy term dashboard using the policy term id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The policy term entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof MakePaymentComponent
	 */
	private async navigateToPolicyTermDashboard(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}