/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChartConfiguration,
	ChartData,
	ChartDataset,
	ChartOptions,
	PieControllerChartOptions,
	TimeUnit
} from 'chart.js';
import {
	ChartConstants
} from '@shared/constants/chart-constants';
import {
	ChartHelper
} from '@shared/helpers/chart.helper';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	DateTime
} from 'luxon';
import {
	get,
	orderBy
} from 'lodash-es';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	Injectable
} from '@angular/core';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing a chart transform. This is used to alter
 * existing chart configurations on the fly for multiple possible
 * displays and data inputs.
 *
 * @export
 * @class ChartTransform
 */
@Injectable()
export class ChartTransform
{
	/**
	 * Initializes a new instance of a chart transform class.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used to define and handle
	 * site layout changes.
	 * @memberof ChartTransform
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Creates and returns a clone of an existing chart configuration.
	 *
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone.
	 * @returns {ChartConfiguration}
	 * A cloned chart configuration ready for customization.
	 * @memberof ChartTransform
	 */
	public cloneChartConfiguration(
		chartConfiguration: ChartConfiguration): ChartConfiguration
	{
		return <ChartConfiguration>
			{
				...chartConfiguration.options
			};
	}

	/**
	 * Creates and returns a clone of an existing chart configuration
	 * options object.
	 *
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the options value of.
	 * @returns {ChartOptions}
	 * A cloned chart configuration options object ready for customization.
	 * @memberof ChartTransform
	 */
	public cloneChartOptions(
		chartConfiguration: ChartConfiguration): ChartOptions
	{
		return <ChartOptions>
			{
				...chartConfiguration.options
			};
	}

	/**
	 * Creates and returns a clone of an existing chart configuration
	 * data object.
	 *
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the data value of.
	 * @returns {ChartData}
	 * A cloned chart configuration data object ready for customization.
	 * @memberof ChartTransform
	 */
	public cloneChartData(
		chartConfiguration: ChartConfiguration): ChartData
	{
		return <ChartData>
			{
				...chartConfiguration.data
			};
	}

	/**
	 * Clones an existing chart configuration options object and alters
	 * this to be the desired option set for displaying this chart in
	 * a summary card.
	 *
	 * @async
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the options value of.
	 * @returns {Promise<ChartOptions>}
	 * A cloned chart configuration options object ready for display
	 * in a summary card.
	 * @memberof ChartTransform
	 */
	public async toRadialGaugeChart(
		chartConfiguration: ChartConfiguration): Promise<ChartOptions>
	{
		return new Promise(
			(resolve) =>
			{
				const currentOptions: ChartOptions =
				this.cloneChartOptions(chartConfiguration);

				currentOptions.plugins.title.display = false;
				currentOptions.plugins.legend.display = false;
				currentOptions.plugins.tooltip.enabled = false;
				currentOptions.maintainAspectRatio = false;
				currentOptions.plugins.tooltip =
					{
						enabled: true
					};
				currentOptions.hover =
					{
						mode: null
					};
				currentOptions.layout.padding =
					{
						top: AppConstants.staticLayoutSizes
							.radialGaugeChartTopPadding,
						bottom: AppConstants.staticLayoutSizes
							.radialGaugeChartBottomPadding,
					};

				const pieChartOptions: PieControllerChartOptions =
					<PieControllerChartOptions>currentOptions;
				pieChartOptions.circumference =
					AppConstants.staticLayoutSizes
						.radialGaugeChartCircumference;
				pieChartOptions.rotation =
					AppConstants.staticLayoutSizes
						.radialGaugeChartRotation;
				pieChartOptions.cutout = AppConstants.staticLayoutSizes
					.radialGaugeChartStandardCutOut;

				resolve(currentOptions);
			});
	}

	/**
	 * Clones an existing chart configuration options object and alters
	 * this to be the desired option set for displaying this chart in
	 * a summary card.
	 *
	 * @async
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the options value of.
	 * @param {boolean} radialGaugeChart
	 * The definition to set the radial gauge configuration options
	 * @returns {Promise<ChartOptions>}
	 * A cloned chart configuration options object ready for display
	 * in a summary card.
	 * @memberof ChartTransform
	 */
	public async toSummaryChart(
		chartConfiguration: ChartConfiguration,
		radialGaugeChart: boolean = false): Promise<ChartOptions>
	{
		return new Promise(
			(resolve) =>
			{
				const currentOptions: ChartOptions =
				this.cloneChartOptions(chartConfiguration);

				currentOptions.responsive = true;
				currentOptions.plugins.title.display = false;
				currentOptions.plugins.legend.display = false;
				currentOptions.plugins.tooltip.enabled = false;
				currentOptions.maintainAspectRatio = false;
				currentOptions.responsive = false;
				currentOptions.plugins.tooltip =
					{
						enabled: false
					};
				currentOptions.hover =
					{
						mode: null
					};

				currentOptions.layout.padding =
					{
						top: AppConstants.staticLayoutSizes.standardPadding,
						left: AppConstants.staticLayoutSizes.standardPadding,
						right: AppConstants.staticLayoutSizes.standardPadding,
						/* See: chart-component.scss .summary-card-chart */
						bottom: AppConstants.staticLayoutSizes
							.summaryCardChartBottomMargin
					};

				if (radialGaugeChart === true)
				{
					currentOptions.responsive = true;
					const pieChartOptions: PieControllerChartOptions =
						<PieControllerChartOptions>currentOptions;
					pieChartOptions.circumference =
						AppConstants.staticLayoutSizes
							.radialGaugeChartCircumference;
					pieChartOptions.rotation =
						AppConstants.staticLayoutSizes
							.radialGaugeChartRotation;
					pieChartOptions.cutout = AppConstants.staticLayoutSizes
						.radialGaugeChartStandardCutOut;
					currentOptions.layout.padding.top =
						AppConstants.staticLayoutSizes
							.circularChartInformationCardTopMargin;
					currentOptions.layout.padding.bottom =
						AppConstants.staticLayoutSizes
							.radialGaugeChartInformationCardBottomMargin;

				}
				else
				{
					if (chartConfiguration.type ===
						ChartConstants.chartTypes.line)
					{
						currentOptions.elements.point.radius = 0;
						currentOptions.scales.x.display = false;
						currentOptions.scales.y.display = false;
					}
					else if (chartConfiguration.type ===
						ChartConstants.chartTypes.pie
						|| chartConfiguration.type ===
							ChartConstants.chartTypes.doughnut)
					{
						currentOptions.layout.padding.top =
							AppConstants.staticLayoutSizes
								.circularChartInformationCardTopMargin;
						currentOptions.layout.padding.bottom =
							AppConstants.staticLayoutSizes
								.circularChartInformationBottomMargin;
					}
				}

				resolve(currentOptions);
			});
	}

	/**
	 * Clones an existing chart configuration options object and alters
	 * this to be the desired option set for displaying this chart in
	 * a square chart.
	 *
	 * @async
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the options value of.
	 * @returns {Promise<ChartOptions>}
	 * A cloned chart configuration options object ready for display
	 * in a summary card.
	 * @memberof ChartTransform
	 */
	public async toSquareChart(
		chartConfiguration: ChartConfiguration): Promise<ChartOptions>
	{
		return new Promise((resolve) =>
		{
			const currentOptions: ChartOptions =
				this.cloneChartOptions(chartConfiguration);

			currentOptions.plugins.title.display = false;
			currentOptions.plugins.legend.display = false;
			currentOptions.maintainAspectRatio = false;
			currentOptions.responsive = false;

			currentOptions.layout.padding =
				{
					/* See: chart-component.scss .summary-card-chart */
					top: 7.5,
					bottom: AppConstants.staticLayoutSizes
						.squareCardChartBottomMargin
				};

			if (chartConfiguration.type === ChartConstants.chartTypes.pie
				|| chartConfiguration.type ===
					ChartConstants.chartTypes.doughnut)
			{
				currentOptions.layout.padding.bottom =
					AppConstants.staticLayoutSizes
						.circularChartSquareCardBottomMargin;
			}

			resolve(currentOptions);
		});
	}

	/**
	 * Clones an existing chart configuration data object and alters
	 * this to define the color set's of all charts to match the defined
	 * available ChartConstants.themeColors. If no data set index is sent,
	 * this will apply the array of colors against the primary dataset
	 * else each dataset will receive it's associated index color value.
	 *
	 * @async
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the data value of.
	 * @param {string[]} chartColors
	 * The array of chart colors to gather theme values of and display
	 * in this chart.
	 * @param {number?} dataSetIndex
	 * The specific data set index to set theme colors against, if not
	 * sent this will match color indexes to data set index for colors.
	 * if sent, this will set the data set color value as an array matching
	 * this themed color set.
	 * @returns {Promise<ChartData>}
	 * A cloned chart configuration data object ready for display
	 * using the defined chart colors matching the current theme.
	 * @memberof ChartTransform
	 */
	public async toThemeColor(
		chartConfiguration: ChartConfiguration,
		chartColors: string[],
		dataSetIndex: number = null): Promise<ChartData>
	{
		return new Promise(
			(resolve) =>
			{
				const currentData: ChartData =
					this.cloneChartData(chartConfiguration);

				// Handle single dataset, multi color views
				if (!AnyHelper.isNull(dataSetIndex)
					&& chartColors.length > 0)
				{
					const backgroundColors: string[] = [];

					chartColors.forEach(
						(color: string) =>
						{
							const themeColor: string =
								this.getThemeColor(color);
							backgroundColors
								.push(themeColor);
						});

					// Add a default for secondary data.
					backgroundColors.push('rgba(0, 0, 0, 0.1)');
					const fillColors: string[] =
						backgroundColors.map((color) =>
							this.getFillColor(color));

					currentData
						.datasets[dataSetIndex]
						.hoverBackgroundColor = backgroundColors;

					currentData
						.datasets[dataSetIndex]
						.backgroundColor = fillColors;

					resolve(currentData);

					return;
				}

				// Handle multiple datasets, each a specific color
				// Default if not specified to primary
				if (!AnyHelper.isNull(chartColors))
				{
					chartColors.forEach(
						(color: string, index: number) =>
						{
							this.setThemeColor(
								currentData,
								color,
								index);
						});
				}
				else
				{
					this.setThemeColor(
						currentData,
						ChartConstants.themeColors.primary,
						0);
				}

				resolve(currentData);
			});
	}

	/**
	 * Clones an existing chart configuration data object and creates
	 * and maps data values for a chart display according to the
	 * defined groupByCount and fillMissDataSets values.
	 *
	 * @async
	 * @param {ChartConfiguration} chartConfiguration
	 * The chart configuration to clone the data value of.
	 * @param {IAggregate[]} chartData
	 * The chart's data values to translate into a chart data set for display.
	 * @param {string?} pivotProperty
	 * The aggregate value to pivot on, if not sent this will be autogenerated
	 * such as cases of time based outputs.
	 * @param {boolean?} groupByCount
	 * If true, this will group the y based values as the count of data
	 * returned in each aggregate data set as opposed to the value.
	 * This can be used to display unique counts via an api group by.
	 * @param {boolean?} fillMissingDataSets
	 * If true, this will fill in missing label or y values with a zero x
	 * value.
	 * This is used to differentiate between trendline and data value point
	 * based charts.
	 * @returns {Promise<ChartData>}
	 * A cloned chart configuration data object ready for display with mapped
	 * chart axis values matching the sent data.
	 * @memberof ChartTransform
	 */
	public async handleAggregateData(
		chartConfiguration: ChartConfiguration,
		chartData: IAggregate[],
		pivotProperty: string = null,
		pivotProperties: string[] = [],
		radialGaugeChart: boolean = false,
		groupByCount: boolean = false,
		fillMissingDataSets: boolean = true): Promise<ChartData>
	{
		return new Promise(
			async(resolve) =>
			{
				const chartDataSet: ChartData =
					this.cloneChartData(chartConfiguration);

				const chartType: string =
					chartConfiguration.type;
				const xAxes: any =
					chartConfiguration.options.scales?.x;
				const xAxesType: string =
					xAxes?.type;

				switch (`${chartType}|${xAxesType}`)
				{
					case 'line|time':
						resolve(
							await this.handleTimeLineAggregate(
								chartDataSet,
								chartData,
								xAxes.time.unit,
								xAxes.time.unitStepSize,
								groupByCount,
								fillMissingDataSets));
						break;
					case 'pie|undefined':
					case 'doughnut|undefined':
						if (radialGaugeChart === true)
						{
							resolve(
								await this.handleCircularData(
									chartDataSet,
									chartData,
									pivotProperties));
						}
						else
						{
							resolve(
								await this.handleCommonAggregate(
									chartDataSet,
									chartData,
									pivotProperty));
						}

						break;
					case 'bar|undefined':
						if (!AnyHelper.isNullOrEmpty(xAxes)
							&& xAxes.stacked === true)
						{
							resolve (await this.handleStackedBarAggregate(
								chartDataSet,
								chartData,
								pivotProperty,
								xAxes.dateRange,
								xAxes.timeUnit,
								xAxes.dateFormat,
								xAxes.stackedTimeFormat,
								xAxes.dataSetLabels));
						}
						else
						{
							resolve (await this.handleCommonAggregate(
								chartDataSet,
								chartData,
								pivotProperty));
						}

						break;
				}
			});
	}

	/**
	 * Maps pie based aggregate data into an expected x and y value set ready
	 * for a chart display.
	 *
	 * @async
	 * @param {ChartData} currentChartDataSet
	 * The chart data set which will have it's data value defined and mapped.
	 * @param {IAggregate[]} chartData
	 * The data values to map into the current chart dataset.
	 * @param {string} pivotProperty
	 * The aggregate key value to pivot on for this circular chart.
	 * @returns {Promise<ChartData>}
	 * A dataset ready for a circular chart based display.
	 * @memberof ChartTransform
	 */
	public async handleCircularData(
		currentChartDataSet: ChartData,
		chartData: object,
		pivotProperties: string[]): Promise<ChartData>
	{
		return new Promise(
			async(resolve) =>
			{
				currentChartDataSet.datasets.forEach(
					(dataSet: ChartDataset) =>
					{
						const sortedData: number[] = [];

						currentChartDataSet.labels.forEach(
							(label: string) =>
							{
								pivotProperties.forEach(
									(pivotProperty: string) =>
									{
										const lastSplitProperty: string =
											StringHelper.getLastSplitValue(
												pivotProperty.toLowerCase(),
												AppConstants.characters.period);

										if (lastSplitProperty ===
											label.toLowerCase())
										{
											sortedData.push(
												parseInt(
													get(
														chartData,
														pivotProperty),
													10));
										}
									});
							});

						dataSet.data = sortedData;
					});

				resolve(currentChartDataSet);
			});
	}

	/**
	 * Maps common based aggregate data into an expected x and y value set ready
	 * for a chart display.
	 *
	 * @async
	 * @param {ChartData} currentChartDataSet
	 * The chart data set which will have it's data value defined and mapped.
	 * @param {IAggregate[]} chartData
	 * The data values to map into the current chart dataset.
	 * @param {string} pivotProperty
	 * The aggregate key value to pivot on for this chart.
	 * @returns {Promise<ChartData>}
	 * A dataset ready for common aggregate chart based display.
	 * @memberof ChartTransform
	 */
	 public async handleCommonAggregate(
		currentChartDataSet: ChartData,
		chartData: IAggregate[],
		pivotProperty: string): Promise<ChartData>
	{
		return new Promise(
			async(resolve) =>
			{
				currentChartDataSet.datasets.forEach(
					(dataSet: ChartDataset) =>
					{
						const sortedData: number[] = [];
						let useDataLabels: boolean = false;

						if (AnyHelper.isNullOrEmpty(currentChartDataSet.labels))
						{
							useDataLabels = true;
							currentChartDataSet.labels = [];

							for (const data of chartData)
							{
								currentChartDataSet.labels
									.push(data.key[pivotProperty]);
							}
						}

						currentChartDataSet.labels.forEach(
							(label: string) =>
							{
								const property =
									pivotProperty[0].toLowerCase()
										+ pivotProperty.slice(1);

								const matchingData: IAggregate =
									(chartData || []).find(
										(aggregate: IAggregate) =>
										{
											const aggregateKey =
												(useDataLabels === false)
													? StringHelper.toProperCase(
														aggregate
															.key[property]
															.toLowerCase())
													: aggregate
														.key[property];

											return label === aggregateKey;
										});

								sortedData.push(
									AnyHelper.isNull(matchingData)
										? 0
										: matchingData.value);
							});
						dataSet.data = sortedData;
					});
				resolve(currentChartDataSet);
			});
	}

	/**
	 * Maps bar based aggregate data
	 * into an expected stacked x and y values by a date range
	 * set ready for a chart display.
	 *
	 * @async
	 * @param {ChartData} currentChartDataSet
	 * The chart data set which will have it's data value defined and mapped.
	 * @param {IAggregate[]} chartData
	 * The data values to map into the current chart dataset.
	 * @param {string} pivotProperty
	 * The aggregate key value to pivot on for this chart.
	 * @param {DateTime[]} dateRange
	 * The date range that will be splitting the data into.
	 * @param {TimeUnit} timeUnit
	 * The time unit that the chart will be based on.
	 * @param {string} dateFormat
	 * The date format to be displayed within the chart.
	 * @param {string} stackedTimeFormat
	 * The date format to be displayed within the chart.
	 * @param {string[]} dataSetLabels
	 * The date format to be displayed within the chart.
	 * @returns {Promise<ChartData>}
	 * A dataset ready for a circular chart based display.
	 * @memberof ChartTransform
	 */
	 public async handleStackedBarAggregate(
		currentChartDataSet: ChartData,
		chartData: IAggregate[],
		pivotProperty: string,
		dateRange: DateTime[],
		timeUnit: TimeUnit,
		dateFormat: string,
		stackedTimeFormat: string,
		dataSetLabels: string[]): Promise<ChartData>
	{
		const newDataSet: ChartDataset[] = [];
		let dataSetIndex: number;

		if (!AnyHelper.isNull(dataSetLabels))
		{
			dataSetLabels.forEach((dataSetLabel) =>
			{
				newDataSet.push(
					<ChartDataset>
					{
						label: dataSetLabel,
						data: [
							...Array.from(
								{
									length: dateRange.length
								})
						]
					});
			});
		}

		chartData.forEach(
			(data) =>
			{
				dataSetIndex =
					newDataSet.findIndex((dataValue) =>
						data.key[pivotProperty] === dataValue.label);

				const dateRangeIndex =
					dateRange.findIndex((dateValue) =>
						data.key[timeUnit].toString()
							=== dateValue.toFormat(stackedTimeFormat));

				if (dataSetIndex >= 0)
				{
					newDataSet[dataSetIndex]
						.data[dateRangeIndex] =
						data.value;
				}
				else
				{
					newDataSet.push(
						<ChartDataset>
						{
							label: data.key[pivotProperty],
							data: [
								...Array.from(
									{
										length: dateRange.length
									})
							]
						});

					newDataSet[newDataSet.length - 1]
						.data[dateRangeIndex] = data.value;
				}

				dataSetIndex = null;
			});

		currentChartDataSet.datasets = newDataSet;
		currentChartDataSet.labels =
			dateRange.map(
				(date) =>
					date.toFormat(dateFormat));

		return Promise.resolve(currentChartDataSet);
	}

	/**
	 * Maps line based, time pivoted aggregate data into an expected
	 * x and y value set ready for a time line chart display.
	 *
	 * @async
	 * @param {ChartData} currentChartDataSet
	 * The chart data set which will have it's data value defined and
	 * mapped.
	 * @param {IAggregate[]} chartData
	 * The data values to map into the current chart dataset.
	 * @param {TimeUnit} timeUnit
	 * The unit of time that each label should split down to.
	 * @see ChartConstants.timeUnits
	 * @param {number?} timeUnitStepSize
	 * The step size per unit of time to increment for each label.
	 * Ie: '1' 'day' or '24' 'hour's.
	 * @param {boolean?} groupByCount
	 * If true, this will group the y based values as the count of data
	 * returned in each aggregate data set as opposed to the value.
	 * This can be used to display unique counts via an api group by.
	 * @param {boolean?} fillMissingDataSets
	 * If true, this will fill in missing label or y values with a zero x
	 * value. This is used to differentiate between trendline and data
	 * value point based charts.
	 * @returns {Promise<ChartData>}
	 * A dataset ready for a line based, time pivoted chart display.
	 * @memberof ChartTransform
	 */
	public async handleTimeLineAggregate(
		currentChartDataSet: ChartData,
		chartData: IAggregate[],
		timeUnit: TimeUnit,
		timeUnitStepSize: number = 1,
		groupByCount: boolean = false,
		fillMissingDataSets: boolean = true): Promise<ChartData>
	{
		return new Promise(
			async(resolve) =>
			{
				const sortedData: IAggregate[] =
					orderBy(
						chartData,
						(aggregate: IAggregate) =>
							this.getTimeBasedAggregateKey(aggregate));

				if (fillMissingDataSets === true)
				{
					if (currentChartDataSet.labels.length === 0)
					{
						const initialTime: DateTime =
							this.getTimeBasedAggregateKey(
								sortedData[0]);
						const finalTime: DateTime =
							this.getTimeBasedAggregateKey(
								sortedData[sortedData.length - 1]);

						currentChartDataSet.labels =
							ChartHelper.getTimeBasedLabels(
								initialTime,
								finalTime,
								timeUnit,
								timeUnitStepSize);
					}

					let newDataSet: any[] = [];
					currentChartDataSet.datasets.forEach(
						(dataSet: ChartDataset) =>
						{
							currentChartDataSet.labels.forEach(
								(time: DateTime) =>
								{
									if (groupByCount === false)
									{
										const matchingAggregate: IAggregate =
											<IAggregate>
											this.getsMatchingTimeBasedAggregate(
												'find',
												sortedData,
												time);

										newDataSet.push(
											{
												x: time,
												y: AnyHelper.isNull(
													matchingAggregate)
													? 0
													: matchingAggregate.value
											});
									}
									else
									{
										const matchingAggregates: IAggregate[] =
											<IAggregate[]>
											this.getsMatchingTimeBasedAggregate(
												'filter',
												sortedData,
												time);

										newDataSet.push(
											{
												x: time,
												y: matchingAggregates.length
											});
									}
								});

							dataSet.data = newDataSet;
							newDataSet = [];
						});
				}
				else
				{
					currentChartDataSet.labels =
						sortedData
							.map((aggregate: IAggregate) =>
								this.getTimeBasedAggregateKey(
									aggregate));
					currentChartDataSet.datasets.forEach(
						(dataSet: ChartDataset) =>
						{
							dataSet.data = sortedData
								.map(
									(aggregate: IAggregate) =>
										<any>
										{
											x: this.getTimeBasedAggregateKey(
												aggregate),
											y: aggregate.value || 0
										});
						});
				}

				resolve(currentChartDataSet);
			});
	}

	/**
	 * Returns the matching aggregate time based values
	 * based on the sent aggregate.
	 *
	 * @param {any} objectMethod
	 * The object method to make the matching comparission.
	 * @param {any[]} sortedData
	 * The object data.
	 * @param {DateTime} time
	 * The time to compare data.
	 * @returns {IAggregate | IAggregate[]}
	 * The matching comparison time based aggregate.
	 * @memberof ChartTransform
	 */
	private getsMatchingTimeBasedAggregate(
		objectMethod: string,
		sortedData: any[],
		time: DateTime): IAggregate | IAggregate[]
	{
		return sortedData[objectMethod](
			(aggregate:
				IAggregate) =>
				this.getTimeBasedAggregateKey(
					aggregate)
					.toFormat(
						DateHelper.presetFormats.dateAndHourFormat) ===
						time.toFormat(
							DateHelper.presetFormats.dateAndHourFormat));
	}

	/**
	 * Returns time based values based on the sent aggregate. This is returned
	 * with the correct utc valued datetime for use in y labels and data
	 * grouping.
	 *
	 * @param {IAggregate} aggregate
	 * The aggregate data value to map a time based datetime from.
	 * @returns {DateTime}
	 * A utc valued datettime matching the existing aggregate value returned
	 * from an api aggregate query.
	 * @memberof ChartTransform
	 */
	private getTimeBasedAggregateKey(
		aggregate: IAggregate): DateTime
	{
		const hour: number =
			aggregate.key[ChartConstants.timeUnits.hour];

		const dateTimeObjectInput: object =
			{
				year: aggregate.key[ChartConstants.timeUnits.year],
				month: aggregate.key[ChartConstants.timeUnits.month],
				day: aggregate.key[ChartConstants.timeUnits.day],
				hour: AnyHelper.isNull(hour)
					? 0
					: hour,
				minute: aggregate.key[ChartConstants.timeUnits.minute] || 0,
				second: aggregate.key[ChartConstants.timeUnits.second] || 0
			};

		return DateTime.fromObject(
			dateTimeObjectInput);
	}

	/**
	 * Sets the supplied index based data set's color values to match the sent
	 * color value.
	 *
	 * @param {ChartData} currentData
	 * The chart data set array to alter colors for.
	 * @param {string} color
	 * The theme based color value to be set for the indexed data set.
	 * @see ChartConstants.themeColors
	 * @param {number} index
	 * The dataset index to set theme color values for.
	 * @memberof ChartTransform
	 */
	private setThemeColor(
		currentData: ChartData,
		color: string,
		index: number): void
	{
		if ((currentData.datasets?.length || 0) === 0
			|| AnyHelper.isNull(currentData.datasets[index]))
		{
			return;
		}

		const themeColor: string =
			this.getThemeColor(color);
		const fillColor: string =
			this.getFillColor(themeColor);

		const dataSet: ChartDataset =
			currentData.datasets[index];
		dataSet.borderColor = themeColor;
		dataSet.backgroundColor = fillColor;
		dataSet.hoverBackgroundColor = themeColor;
	}

	/**
	 * Gets a themed color value computed from the color swatch based
	 * on the current theme colors.
	 *
	 * @param {string} color
	 * The theme based color value to get the computed rgba based
	 * color value of. See: ChartConstants.themeColors.
	 * @param {string} retryAttempts
	 * Used for race cases in which this is called prior to the display
	 * of the app-color-swatch component.
	 * @returns {string}
	 * The rgba theme color computed from the supplied theme color.
	 * @memberof ChartTransform
	 */
	private getThemeColor(
		color: string,
		retryAttempts: number = 0): string
	{
		const retryCount: number = 25;

		if (AnyHelper.isNull(
			document.querySelector(
				`.theme-color-${color}`)))
		{
			if (retryAttempts >= retryCount)
			{
				throw new Error(
					'Unable to access the theme color element. Please '
						+ 'ensure you have added an app-color-swatch '
						+ 'to the component using this method.' );
			}

			return this.getThemeColor(
				color,
				retryAttempts + 1);
		}

		return getComputedStyle(
			document.querySelector(
				`.theme-color-${color}`))
			.color;
	}

	/**
	 * Gets a themed fill color value computed from the color swatch based
	 * on the current theme colors.
	 *
	 * @param {string} color
	 * The theme based color value to get the computed rgba based
	 * color value of. See: ChartConstants.themeColors.
	 * @param {number?} opacity
	 * The opacity value to use for this fill color.
	 * @returns {string}
	 * The rgba fill color computed from the supplied theme color.
	 * @memberof ChartTransform
	 */
	private getFillColor(
		themeColor: string,
		opacity: number = .7): string
	{
		return themeColor
			.replace('rgb(', 'rgba(')
			.replace(')', `, ${opacity})`);
	}
}