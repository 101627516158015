/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output
} from '@angular/core';
import {
	FieldType,
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'custom-section-title',
	templateUrl: './custom-section-title.component.html',
	styleUrls: ['./custom-section-title.component.scss']
})

/**
 * A component representing an instance of a Custom Section Title.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomSectionTitleComponent
 * @extends {FieldType}
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
export class CustomSectionTitleComponent
	extends FieldType
	implements OnInit, OnDestroy
{
	/**
	 * Sets the event emitter action to communicate to
	 * implementing components that the add button was
	 * clicked.
	 *
	 * @type {EventEmitter}
	 * @memberof CustomSectionTitleComponent
	 */
	@Output() public addAction: EventEmitter<void> =
		new EventEmitter();

	/**
	 * Gets or sets the interval that is used to limit the number of times
	 * an item can be added per second. This allows repeater displays and
	 * defaults sufficient time to complete.
	 *
	 * @type {NodeJS.Timeout}
	 * @memberof CustomSectionTitleComponent
	 */
	public addItemInterval: NodeJS.Timeout;

	/**
	 * Cleans and reutrns the current section title label
	 * value. This value is set as an id which is used
	 * for functions such as scrollTo throughout the site.
	 *
	 * @returns {string}
	 * The cleaned identifier to be used as an id for this
	 * section title.
	 * @memberof CustomSectionTitleComponent
	 */
	public get cleanLabelIdentifier(): string
	{
		return StringHelper.getCleanedValue(
			this.field.templateOptions.label) + this.sectionIdentifier;
	}

	/**
	 * Gets or sets the array that represents the existence of an item to be
	 * added.
	 *
	 * @type {number[]}
	 * @memberof CustomSectionTitleComponent
	 */
	private readonly addItemTasks: number[] = [];

	/**
	 * Gets or sets the name added to section title ids for tab
	 * interaction.
	 *
	 * @type {string}
	 * @memberof CustomSectionTitleComponent
	 */
	private readonly sectionIdentifier: string = 'Section';

	/**
	 * On initialization event.
	 * Adds a delayed interval watcher for requests to add an item.
	 *
	 * @memberof CustomSectionTitleComponent
	 */
	public ngOnInit(): void
	{
		if (AnyHelper.isNull(this.field.fieldGroup)
			|| this.field.templateOptions.disabled === true)
		{
			return;
		}

		this.setAddItemInterval();
	}

	/**
	 * On destroy event.
	 * Clears the add item subscription and the interval.
	 *
	 * @memberof CustomSectionTitleComponent
	 */
	public ngOnDestroy(): void
	{
		clearInterval(this.addItemInterval);
	}

	/**
	 * Calculates and returns the count of displayed items in this repeater.
	 *
	 * @returns {number}
	 * The count of all items in a repeater section that have a display value
	 * of true.
	 * @memberof CustomSectionTitleComponent
	 */
	 public getRepeaterItemCount(): number
	 {
		 return this.field.fieldGroup.filter(
			 (field: FormlyFieldConfig) =>
				 field.templateOptions.display === true).length;
	 }

	/**
	 * Emits the add action event to all listening components.
	 * This signifies that the add button has been clicked.
	 *
	 * @memberof CustomSectionTitleComponent
	 */
	public addItemAction(): void
	{
		this.addItemTasks.push(1);
	}

	/**
	 * Sets the add item interval.
	 *
	 * @memberof CustomSectionTitleComponent
	 */
	public setAddItemInterval(): void
	{
		this.addItemInterval =
			setInterval(
				() =>
				{
					if (this.addItemTasks.length > 0)
					{
						this.addItemTasks.pop();
						this.addAction.emit();
					}
				},
				AppConstants.time.quarterSecond);
	}
}