<div class="ui-g-12 text-left no-padding">
	<app-file-progress
		*ngIf="progressVisible"
		[message]="progressMessage"
		[mode]="progressMode"
		[progress] = "progressAmount">
	</app-file-progress>
	
	<div *ngIf="showDownload"
		class="ui-g-12 nested-display-subtitle text-left">
		<span class="nested-display-actions">
			<ng-template
				ngFor
				let-action
				[ngForOf]="itemActions">
					<i
						class="fa fa-fw {{action.icon}} theme-color cursor-pointer"
						[ngClass]="{
							'selected-bottom-border': (action.id === 'download')
						}"
						(click)="action.command($event)"
						pTooltip="{{action.label}}"
						tooltipPosition="left"
						tooltipStyleClass="utility-menu-tooltip">
					</i>
			</ng-template>
		</span>

		<div class="ellipsis-text-2-line"
			[pTooltip]="fileEntity.data.name"
			tooltipPosition="left"
			tooltipStyleClass="utility-menu-tooltip">
			Download:
			<strong style="font-size: smaller">
				&nbsp;{{fileEntity.data.name}}
			</strong>
		</div>
	</div>

	<div class="ui-g-12 no-padding text-right button-set">
		<button
			pButton
			label="Open"
			class="pi-button-secondary"
			aria-hidden="true"
			(click)="onOpenClicked()">
		</button>
		<button
			pButton
			label="Download"
			aria-hidden="true"
			(click)="onSaveClicked()">
		</button>
		<button
			pButton
			label="Print"
			class="no-margin-right"
			aria-hidden="true"
			(click)="onPrintClicked()">
		</button>
	</div>
</div>