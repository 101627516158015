<app-login
	*ngIf="!this.sessionService.isValidLoggedInAndReady">
</app-login>

<div class="layout-wrapper"
	[ngClass]="{
		'menu-layout-static': !isOverlay(),
		'menu-layout-overlay': isOverlay(),
		'layout-menu-overlay-active': overlayMenuActive,
		'menu-layout-slim': isSlim(),
		'layout-menu-static-inactive': staticMenuDesktopInactive,
		'layout-menu-static-active': staticMenuMobileActive
	}"
	*ngIf="this.sessionService.isValidLoggedInAndReady">

	<app-full-screen-banner>
	</app-full-screen-banner>

	<app-banner>
	</app-banner>

	<div (click)="hideOverlayMenu()">
		<app-topbar
			[rotateMenuButton]="rotateMenuButton"
			(overlayMenuButtonClick)="overlayMenuButtonClicked($event)">
		</app-topbar>
	</div>

	<div class="layout-menu-container">
		<p-scrollPanel #layoutMenuScroller
			styleClass="layout-menu-scroll-container">
			<div class="menu-scroll-content">
				<app-menu (closeOverlays)="hideOverlayMenu()">
				</app-menu>
				<app-inline-profile
					(closeOverlays)="hideOverlayMenu()"
					(activeProfileChanged)="activeProfileChanged($event)">
				</app-inline-profile>
			</div>
		</p-scrollPanel>
	</div>

	<div class="layout-main"
		[ngClass]="{
			'drawer-one-open': (pinnedDrawerCount === 1),
			'drawer-two-open': (pinnedDrawerCount === 2),
			'drawer-three-open': (pinnedDrawerCount === 3),
			'drawer-four-open': (pinnedDrawerCount === 4),
			'drawer-five-open': (pinnedDrawerCount === 5)
		}">
		<router-outlet>
		</router-outlet>
	</div>

	<div class="utility-menu-container">
		<app-utility-menu
			[pageContext]="contentContext"
			(pinnedDrawerCountChange)="pinnedDrawerCountChanged($event)">
		</app-utility-menu>
	</div>

	<div class="profile-menu-container"
		[ngClass]="{
			'active-profile-menu': activeProfileMenu === true
		}">
		<operation-menu
			[operationGroupName]="profileGroupName"
			displayName="user actions"
			class="layout-menu"
			id="profile-navigation"
			[initialModel]="initialProfileModel"
			[reset]="resetProfileMenu"
			(closeOverlays)="hideOverlayMenu()">
		</operation-menu>
	</div>

	<div class="layout-mask"
		(click)="hideOverlayMenu()">
	</div>

	<app-footer>
	</app-footer>
</div>