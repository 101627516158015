/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	EntityDefinition
} from '@shared/implementations/entities/entity-definition';
import {
	EntityDefinitionApiService
} from '@api/services/entities/entity-definition.api.service';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityLayout
} from '@shared/implementations/entities/entity-layout';
import {
	EntityLayoutApiService
} from '@api/services/entities/entity-layout.api.service';
import {
	EntityLayoutTypeApiService
} from '@api/services/entities/entity-layout-type.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	FormlyHelper
} from '@shared/helpers/formly.helper';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityLayoutDto
} from '@api/interfaces/entities/entity-layout.dto.interface';
import {
	IEntityLayoutTypeDto
} from '@api/interfaces/entities/entity-layout-type.dto.interface';
import {
	NoteDirective
} from '@shared/directives/note.directive';
import {
	SelectItem
} from 'primeng/api';

/* eslint-enable max-len */

@Component({
	selector: 'app-note-create',
	templateUrl: './note-create.component.html',
	styleUrls: [
		'./note-create.component.scss'
	]
})

/**
 * A component representing context level notes create display mode.
 *
 * @export
 * @class NoteCreateComponent
 * @extends {NoteDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class NoteCreateComponent
	extends NoteDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {EntityLayoutApiService} entityLayoutApiService
	 * The entity layout API service.
	 * @param {EntityLayoutTypeApiService} entityLayoutTypeApiService
	 * The entity layout type API service.
	 * @param {EntityDefinitionApiService} entityDefinitionApiService
	 * The entity definition API service.
	 * @param {ActivityService} activityService
	 * The activity service.
	 * @memberof NoteCreateComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public entityLayoutApiService: EntityLayoutApiService,
		public entityLayoutTypeApiService: EntityLayoutTypeApiService,
		public entityDefinitionApiService: EntityDefinitionApiService,
		public activityService: ActivityService,
		public entityService: EntityService)
	{
		super(entityTypeApiService);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NoteCreateComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets a string representing the parent type group.
	 *
	 * @type {string}
	 * @memberof NoteCreateComponent
	 */
	@Input() public parentTypeGroup: string;

	/**
	 * Gets or sets a number representing the parent id.
	 *
	 * @type {number}
	 * @memberof NoteCreateComponent
	 */
	@Input() public parentId: number;

	/**
	 * Gets or sets a value indicating whether the component is loading.
	 *
	 * @type {boolean}
	 * @memberof NoteCreateComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a value indicating whether the component is saving.
	 *
	 * @type {boolean}
	 * @memberof NoteCreateComponent
	 */
	public saving: boolean = false;

	/**
	 * Gets or sets a EntityDefinition representing the parent's entity
	 * definition.
	 *
	 * @type {EntityDefinition}
	 * @memberof NoteCreateComponent
	 */
	public parentEntityDefinition: EntityDefinition;

	/**
	 * Gets or sets a collection of EntityType representing the supported
	 * note types.
	 *
	 * @type {EntityType[]}
	 * @memberof NoteCreateComponent
	 */
	public supportedNoteTypes: EntityType[];

	/**
	 * Gets or sets a collection of SelectItem representing note types to
	 * load within the selector.
	 *
	 * @type {SelectItem[]}
	 * @memberof NoteCreateComponent
	 */
	public noteTypes: SelectItem[] = [];

	/**
	 * Gets or sets an EntityType representing the selected note type to create.
	 *
	 * @type {EntityType}
	 * @memberof NoteCreateComponent
	 */
	public selectedNoteType: EntityType;

	/**
	 * Gets or sets an EntityLayout representing the selected note type
	 * layout.
	 *
	 * @type {EntityLayout}
	 * @memberof NoteCreateComponent
	 */
	public noteLayout: EntityLayout;

	/**
	 * Gets or sets a collection of FormlyFieldConfig representing the
	 * formly note layout of the selected note type layout.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof NoteCreateComponent
	 */
	public formlyNoteLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets an EntityInstance representing the note to create data.
	 *
	 * @type {IEntityInstance}
	 * @memberof NoteCreateComponent
	 */
	public noteData: IEntityInstance;

	/**
	 * Gets or sets the current validity of the displayed dynamic form.
	 *
	 * @type {boolean}
	 * @memberof NoteCreateComponent
	 */
	public isValid: boolean = false;

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof NoteCreateComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const source: EntityInstanceComponent
			= (<EntityInstanceComponent>this.context.source);
		this.parentTypeGroup = this.parentTypeGroup || source.entityType.group;
		this.parentId = this.parentId || source.id;
		this.parentEntityDefinition = source.entityDefinition;

		const childNoteTypes: string[]
			= this.getSupportedNoteTypes(
				this.parentEntityDefinition
					.supportedChildTypes);

		this.supportedNoteTypes
			= await this.getEntityTypesFromNameList(childNoteTypes);

		this.mapEntityTypesToNoteTypes(this.supportedNoteTypes);

		this.loading = false;
	}

	/**
	 * Handles the validity changed event of the contained dynamic form.
	 *
	 * @param {boolean} isValid
	 * The sent validity value of the dynamic form.
	 * @memberof NoteCreateComponent
	 */
	public validDataChanged(
		isValid: boolean): void
	{
		this.isValid = isValid;
	}

	/**
	 * A method containing the activity of creating a note.
	 *
	 * @returns {Promise<void>}
	 * @memberof NoteCreateComponent
	 */
	public async createNote(): Promise<void>
	{
		this.saving = true;

		this.entityInstanceApiService.entityInstanceTypeGroup
			= this.selectedNoteType.group;

		let createDisplayName: string
			= this.getDisplayName(this.selectedNoteType.name);

		createDisplayName = createDisplayName === 'Note'
			? createDisplayName
			: createDisplayName + ' note';

		const newNoteId: number
			= await this.activityService.handleActivity<number>(
				new Activity<number>(
					this.entityInstanceApiService.createEntityInstance(
						this.noteData,
						this.parentTypeGroup,
						this.parentId),
					`<strong>Creating</strong> ${createDisplayName}`,
					`<strong>Created</strong> ${createDisplayName}`,
					`${createDisplayName} was added.`,
					`${createDisplayName} was not added.`));

		this.saving = false;

		if (AnyHelper.isNull(newNoteId) !== true)
		{
			this.changeDisplayMode.emit(
				AppConstants.displayMode.list);
		}
	}

	/**
	 * A method that handles a note type change event.
	 *
	 * @param {*} event
	 * A value representing the event parameters.
	 * @returns {Promise<void>}
	 * @memberof NoteCreateComponent
	 */
	public async handleNoteTypeChange(
		event: any): Promise<void>
	{
		this.noteLayout = null;
		this.formlyNoteLayout = null;
		this.selectedNoteType = event?.value;
		this.noteData = <IEntityInstance>{
			id: 0,
			entityType: this.selectedNoteType?.name,
			versionNumber: null // this causes latest to be used
		};

		if (AnyHelper.isNullOrWhitespace(this.selectedNoteType))
		{
			return;
		}

		const entityLayoutTypes: IEntityLayoutTypeDto[]
			= await this.entityLayoutTypeApiService
				.query(
					'Name eq "Drawer"',
					'Id DESC',
					0,
					1);

		const entityLayouts: EntityLayout[]
			= await this.entityLayoutApiService.query(
				`TypeId eq ${this.selectedNoteType.id}
					AND LayoutTypeId eq ${entityLayoutTypes[0].id}`,
				'VersionId DESC',
				0,
				1)
				.then((layouts: IEntityLayoutDto[]) =>
					layouts.map((entityLayout: IEntityLayoutDto) =>
						new EntityLayout(entityLayout)));

		this.noteLayout = entityLayouts[0];
		this.formlyNoteLayout = FormlyHelper.getFormlyLayout(
			JSON.parse(this.noteLayout.jsonData),
			this.context);
	}

	/**
	 * A method that maps a collection of EntityType to SelectItem's and
	 * populates the note types selector.
	 *
	 * @param {EntityType[]} entityTypes
	 * A collection of EntityType to map and populate NoteTypes select.
	 * @memberof NoteCreateComponent
	 */
	public mapEntityTypesToNoteTypes(
		entityTypes: EntityType[]): void
	{
		// Map types to select items
		entityTypes.forEach(
			(entityType: EntityType) =>
			{
				if (AnyHelper.isNull(
					this.noteTypes.find((existingNoteType: SelectItem) =>
						existingNoteType.value === entityType)))
				{
					this.noteTypes.push(<SelectItem>{
						label: this.getDisplayName(entityType.name),
						value: entityType
					});
				}
			});

		// Default to the only available type
		if (this.supportedNoteTypes.length === 1)
		{
			this.handleNoteTypeChange({
				value: this.supportedNoteTypes[0]
			});
		}
	}
}