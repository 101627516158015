/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	ChangeDetectorRef,
	Component,
	OnInit
} from '@angular/core';
import {
	debounceTime,
	distinctUntilChanged,
	of,
	Subject,
	Subscription
} from 'rxjs';
import {
	ExtendedCustomControlDirective
} from '@entity/directives/extended-custom-control.directive';
import {
	get
} from 'lodash-es';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IGroupedDropdownOption
} from '@shared/interfaces/application-objects/grouped-dropdown-option.interface';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'custom-data-select',
	templateUrl: './custom-data-select.component.html',
	styleUrls: [
		'./custom-data-select.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Data Select.
 * https://ngx-formly.github.io/ngx-formly/guide
 *
 * @export
 * @class CustomDataSelectComponent
 * @extends {ExtendedCustomControlDirective}
 * @implements {OnInit}
 */
export class CustomDataSelectComponent
	extends ExtendedCustomControlDirective
	implements OnInit
{
	/**
	 * Initializes a new instance of a custom data select component.
	 *
	 * @param {ResolverService} resolver
	 * The resolver service used for data providers.
	 * @param {ChangeDetectorRef} changeDetector
	 * The base class used to detect tree changes.
	 * @memberof CustomDataSelectComponent
	 */
	public constructor(
		public resolver: ResolverService,
		public changeDetector: ChangeDetectorRef)
	{
		super(changeDetector);
	}

	/**
	 * Gets or sets the loading value of this select.
	 *
	 * @type {boolean}
	 * @memberof CustomDataSelectComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets the loading filtered options value of this select.
	 *
	 * @type {boolean}
	 * @memberof CustomDataSelectComponent
	 */
	public filterApiLoading: boolean = false;

	/**
	 * Gets or sets the data options for this select.
	 *
	 * @type {IDropdownOption[] | IGroupedDropdownOption[]}
	 * @memberof CustomDataSelectComponent
	 */
	public dataOptions: IDropdownOption[] | IGroupedDropdownOption[] = [];

	/**
	 * Gets or sets the debounce subject to delay filter events until the user
	 * has delayed typing.
	 *
	 * @type {Subject<string>}
	 * @memberof CustomDataSelectComponent
	 */
	private filterChanged: Subject<string> = new Subject<string>();

	/**
	 * Gets or sets the data subscription class
	 *
	 * @type {Subscription}
	 * @memberof CustomDataSelectComponent
	 */
	private dataSubscription: Subscription;

	/**
	 * Gets or sets the debounce delay used for calling a new api promise
	 * using the filter input and data promise.
	 *
	 * @type {number}
	 * @memberof OperationButtonMenuComponent
	 */
	private readonly filterDebounceDelay: number =
		AppConstants.time.quarterSecond;

	/**
	 * Gets or sets the string that will be displayed if the template option
	 * is set to use an api filter.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	private readonly loadingFilterMessage: string =
		'Loading results';

	/**
	 * Gets or sets the interpolatio value base identifier.
	 *
	 * @type {string}
	 * @memberof OperationButtonMenuComponent
	 */
	private readonly interpolationValueBaseIdentifier: string =
		'#{item}';

	/**
	 * On component initialization event.
	 * This method is used to load the sent data promise values and map these
	 * into an options array.
	 *
	 * @memberof CustomDataSelectComponent
	 */
	public ngOnInit()
	{
		this.field.templateOptions.initializeDataOptions =
			this.initializeDataOptions.bind(this);
		this.initializeDataOptions();
	}

	/**
	 * Loads the data promise for this select and maps these values into the
	 * sent templates.
	 *
	 * @async
	 * @memberof CustomDataSelectComponent
	 */
	public async initializeDataOptions(): Promise<void>
	{
		if (this.field.templateOptions.useApiFilter === true)
		{
			this.field.templateOptions.emptyFilterMessage =
				this.loadingFilterMessage;
		}

		this.dataSubscription?.unsubscribe();
		this.dataOptions = [];
		this.fireChanges();

		const useLabelFunction: boolean =
			!AnyHelper.isNullOrWhitespace(
				this.field.templateOptions.labelFunction);
		const useObjectValue: boolean =
			this.field.templateOptions.valueTemplate.indexOf(
				AppConstants.interpolationTypes.object) === 0;
		const useGroupedDropdown: boolean =
			!AnyHelper.isNull(this.field.templateOptions.groupItemDataPromise);

		this.dataSubscription =
			of(
				AnyHelper.isFunction(this.field.templateOptions.dataPromise)
					? await this.field.templateOptions.dataPromise
					: await this.loadDataPromise(
						this.field.templateOptions.dataPromise,
						this.field.templateOptions.context))
				.subscribe(
					async(data: any[]) =>
					{
						if (useGroupedDropdown === false)
						{
							this.dataOptions =
								this.mapDropdownData(
									data,
									useLabelFunction,
									useObjectValue);
						}
						else
						{
							const groupData: any[] =
								await this.populateGroupData(data);

							const groupedOptions: IGroupedDropdownOption[] = [];
							for (const groupItem of groupData)
							{
								groupedOptions.push(
									this.mapGroupItem(
										groupItem,
										useLabelFunction,
										useObjectValue));
							}

							this.dataOptions = groupedOptions;
						}

						this.initializeFilter();

						this.field.templateOptions.options = this.dataOptions;
						this.loading = false;
						super.ngOnInit();
						this.fireChanges();
					});
	}

	/**
	 * On component destroy event.
	 * This method is used to complete any subscriptions or observables when
	 * destroyed.
	 *
	 * @memberof CustomDataSelectComponent
	 */
	public ngOnDestroy(): void
	{
		// Reset the filter value in the page context.
		if (!AnyHelper.isNull(this.field))
		{
			this.field.templateOptions
				.context.data.dataSelectOptions = null;
		}

		this.filterChanged.complete();
	}

	/**
	 * This event handler method is used to handle filter based changes if
	 * this control is set to reload api data on filter.
	 *
	 * @param {any} event
	 * The filter string sent to this event handler.
	 * @memberof CustomDataSelectComponent
	 */
	public onFilter(
		filter: string): void
	{
		if (this.field.templateOptions.useApiFilter !== true)
		{
			return;
		}

		this.filterChanged.next(filter);
	}

	/**
	 * Loads the data promise.
	 *
	 * @async
	 * @param {string} promiseAsString
	 * The promise value as a string as defined in template options.
	 * @param {object} dataContext
	 * The context to run this data promise against.
	 * @returns {object[]}
	 * The set of loaded items as defined in the data promise.
	 * @memberof CustomDataSelectComponent
	 */
	public async loadDataPromise(
		promiseAsString: string,
		dataContext: object): Promise<object[]>
	{
		return StringHelper
			.transformToDataPromise(
				promiseAsString,
				dataContext);
	}

	/**
	 * Populates the group level data promise if a group data promise
	 * is sent.
	 *
	 * @async
	 * @param {object} data
	 * The result of the data promise call.
	 * @returns {object[]}
	 * The set of items sent in with populated grouped items as defined
	 * in the group data promise.
	 * @memberof CustomDataSelectComponent
	 */
	public async populateGroupData(
		data: object[]): Promise<object[]>
	{
		this.field.templateOptions.context =
		{
			...this.field.templateOptions.context,
			groupData: data
		};

		for (let index: number = 0;
			index < data.length;
			index++)
		{
			this.field.templateOptions.context.groupItem = data[index];
			const groupData: object[] =
				await this.loadDataPromise(
					this.field.templateOptions.groupItemDataPromise,
					this.field.templateOptions.context);
			data[index] =
				{
					...data[index],
					items: groupData
				};
		}

		return <object[]>
			[
				...data
			];
	}

	/**
	 * Maps a dropdown option from a sent item.
	 *
	 * @param {object} item
	 * The object to be mapped into a dropdown option.
	 * @param {boolean} useLabelFunction
	 * If true, this will create the dropdown option using the label function
	 * as defined in template options.
	 * If false, this will use the label template.
	 * @param {boolean} useObjectValue
	 * If true, this will assign the value of the dropdown option as the item.
	 * If false, this will assign the value as the mapped value template.
	 * @returns {IDropdownOption}
	 * A mapped dropdown option ready for a client display.
	 * @memberof CustomDataSelectComponent
	 */
	public mapDropdownOption(
		item: object,
		useLabelFunction: boolean,
		useObjectValue: boolean): IDropdownOption
	{
		const interpolationDataName: string =
			this.field.templateOptions.valueTemplate
				.replace(
					/\#{item.(.*?)\}/gm,
					'$1');

		const itemValue: any =
			interpolationDataName === this.interpolationValueBaseIdentifier
				? item
				: get(
					item,
					interpolationDataName);

		return <IDropdownOption>
			{
				label: useLabelFunction === true
					? StringHelper.transformToFunction(
						this.field.templateOptions.labelFunction,
						this.field.templateOptions.context)(item)
					: StringHelper.interpolate(
						this.field.templateOptions.labelTemplate,
						item),
				value: useObjectValue === true
					? itemValue
					: StringHelper.interpolate(
						this.field.templateOptions.valueTemplate,
						item)
			};
	}

	/**
	 * Maps an object array into a consumable set of dropdown options and
	 * returns these values as a label sorted array.
	 *
	 * @param {object[]} data
	 * The object array to be mapped into a dropdown option set.
	 * @param {boolean} useLabelFunction
	 * If true, this will create the dropdown option set using the label
	 * function as defined in template options. If false, this will use
	 * the label template.
	 * @param {boolean} useObjectValue
	 * If true, this will assign the value of the dropdown option set as the
	 * item. If false, this will assign the value as the mapped value template.
	 * @returns {IDropdownOption[]}
	 * A mapped dropdown option set ready for a client display.
	 * @memberof CustomDataSelectComponent
	 */
	public mapDropdownData(
		data: object[],
		useLabelFunction: boolean,
		useObjectValue: boolean): IDropdownOption[]
	{
		const options: IDropdownOption[] = [];
		for (const item of data as any[])
		{
			options.push(
				this.mapDropdownOption(
					item,
					useLabelFunction,
					useObjectValue));
		}

		if (this.field.templateOptions.dataPromiseSort === true)
		{
			return options;
		}

		return options.sort((
			firstItem: IDropdownOption,
			secondItem: IDropdownOption) =>
			ObjectHelper.sortByPropertyValue(
				firstItem,
				secondItem,
				AppConstants.commonProperties.label));
	}

	/**
	 * Maps a group item into a dropdown option holding multiple child options.
	 *
	 * @param {any} data
	 * The object to be mapped into a group item holding a dropdown option set.
	 * @param {boolean} useLabelFunction
	 * If true, this will create the dropdown option set using the label
	 * function as defined in template options. If false, this will use
	 * the label template.
	 * @param {boolean} useObjectValue
	 * If true, this will assign the value of the dropdown option set as the
	 * item. If false, this will assign the value as the mapped value template.
	 * @returns {IGroupedDropdownOption}
	 * A mapped dropdown option holding grouped items ready for a client
	 * display.
	 * @memberof CustomDataSelectComponent
	 */
	public mapGroupItem(
		groupItem: any,
		useLabelFunction: boolean,
		useObjectValue: boolean): IGroupedDropdownOption
	{
		groupItem.items =
			this.mapDropdownData(
				groupItem.items,
				useLabelFunction,
				useObjectValue);

		return <IGroupedDropdownOption>
			{
				label: StringHelper.interpolate(
					this.field.templateOptions.groupLabelTemplate,
					groupItem),
				value: groupItem.id,
				items: groupItem.items
			};
	}

	/**
	 * Initializes filter handling for this data select.
	 *
	 * @memberof CustomDataSelectComponent
	 */
	public initializeFilter(): void
	{
		this.filterChanged.complete();
		this.filterChanged = new Subject<string>();
		this.filterChanged.pipe(
			debounceTime(this.filterDebounceDelay),
			distinctUntilChanged())
			.subscribe(
				async (newValue: string) =>
				{
					this.filterApiLoading = true;
					this.field.templateOptions
						.context.data.dataSelectOptions =
							<any>
							{
								filterValue: newValue
							};
					this.fireChanges();

					await this.initializeDataOptions();

					this.field.templateOptions.emptyFilterMessage = null;
					this.filterApiLoading = false;
					this.fireChanges();
				});
	}
}