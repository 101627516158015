/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */

import {
	AccessDeniedComponent
} from '@appComponents/access-denied/access-denied.component';
import {
	AppAuthGuard
} from '@shared/guards/app-auth.guard';
import {
	CommonModule
} from '@angular/common';
import {
	GenericBasePageComponent
} from '@appComponents/generic-base-page/generic-base-page.component';
import {
	GenericDashboardComponent
} from '@appComponents/generic-dashboard/generic-dashboard.component';
import {
	ManagementComponent
} from '@workItems//components/management/management.component';
import {
	ModuleGuard
} from '@shared/guards/module.guard';
import {
	NgModule
} from '@angular/core';
import {
	RouterModule
} from '@angular/router';
import {
	SharedModule
} from '@shared/shared.module';
import {
	WorkbenchComponent
} from '@workItems//components/workbench/workbench.component';
import {
	WorkItemExpandComponent
} from '@workItems/components/workbench/work-item-expand/work-item-expand.component';
import {
	WorkItemsComponent
} from '@workItems/work-items.component';

/* eslint-enable max-len */

@NgModule({
	declarations: [
		ManagementComponent,
		WorkbenchComponent,
		WorkItemsComponent,
		WorkItemExpandComponent
	],
	imports: [
		CommonModule,
		SharedModule,
		RouterModule.forChild([
			{
				path: 'workItems',
				component: WorkItemsComponent,
				canActivate: [
					AppAuthGuard,
					ModuleGuard
				],
				data:
				{
					roles: [
						'Administrators',
						'Support Users'
					]
				},
				children: [
					{
						path: 'accessDenied',
						component: AccessDeniedComponent
					},
					{
						path: 'dashboard',
						component: GenericDashboardComponent
					},
					{
						path: 'workbench',
						component: WorkbenchComponent
					},
					{
						path: 'management',
						component: ManagementComponent
					},
					{
						path: 'dashboard/:dashboardType',
						component: GenericDashboardComponent
					},
					{
						path: 'display',
						children: [
							{
								path: ':displayComponent/view',
								component: GenericBasePageComponent
							},
							{
								path: ':displayComponent/edit',
								component: GenericBasePageComponent,
								canActivate: [
									AppAuthGuard
								],
								data:
								{
									roles: [
										'Administrators',
										'Support Users'
									]
								}
							}
						]
					},
					{
						path: '',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					},
					{
						path: '**',
						redirectTo: 'dashboard',
						pathMatch: 'full'
					}
				]
			}])
	],
	exports: [
		ManagementComponent,
		WorkbenchComponent
	],
	providers: [
		ManagementComponent,
		WorkbenchComponent
	]
})

/**
 * A module used to hold work item route specific logic and display resources.
 *
 * @export
 * @class WorkItemsModule
 */
export class WorkItemsModule
{
}