/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import {
	IAggregate
} from '@shared/interfaces/application-objects/aggregate.interface';
import {
	IChartDefinition
} from '@shared/interfaces/application-objects/chart-definition.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/* eslint-enable max-len */

@Component({
	selector: 'app-summary-card',
	templateUrl: './summary-card.component.html',
	styleUrls: [
		'./summary-card.component.scss'
	]
})

/**
 * A component representing an instance of the summary card
 * component.
 *
 * @export
 * @class SummaryCardComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @typeparam TDataType
 * The data type returned in the data promise.
 */
export class SummaryCardComponent<TDataType>
implements OnInit, OnChanges
{
	/** Initializes a new instance of the ExtendedCustomControlDirective.
	 *
	 * @param {ChangeDetectorRef} changeDetector
	 * The change detector reference for this component.
	 * @memberof ExtendedCustomControlDirective
	 */
	public constructor(
		public changeDetector: ChangeDetectorRef)
	{
	}

	/**
	 * Gets or sets the overlay dynamic component to display
	 * if a popout is desired.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	@Input() public overlayDynamicComponent: string;

	/**
	 * Gets or sets the data promise of the overlay's component
	 * data context.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof SummaryCardComponent
	 */
	@Input() public overlayDynamicContext:
		IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the summary template.
	 * This is used for a single value display.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	@Input() public summaryTemplate: string;

	/**
	 * Gets or sets the summary function.
	 * This is used for a single value display.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	@Input() public summaryFunction: string;

	/**
	 * Gets or sets the summary promise.
	 * This is used for a single value display.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	@Input() public summaryPromise: string;

	/**
	 * Gets or sets the summary template.
	 * This is used for a single value display.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	@Input() public summaryCardDisplay: boolean;

	/**
	 * Gets or sets the square card template.
	 * This is used for a single value display.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	@Input() public squareCardDisplay: boolean;

	/**
	 * Gets or sets the chart defintion. This is used to
	 * display a summary based chart if set.
	 *
	 * @type {IChartDefinition<IAggregate[]}
	 * @memberof SummaryCardComponent
	 */
	@Input() public chartDefinition: IChartDefinition<IAggregate[]>;

	/**
	 * Gets or sets the group by count value.
	 * This will alter how the data is interpolated in charts
	 * via a count of data entries as opposed to a value
	 * lookup.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	@Input() public groupByCount: boolean = false;

	/**
	 * Gets or sets the title template.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	@Input() public titleTemplate: string;

	/**
	 * Gets or sets the data promise.
	 *
	 * @type {Promise<TDataType>}
	 * @memberof SummaryCardComponent
	 */
	@Input() public dataPromise: Promise<TDataType>;

	/**
	 * Gets or sets the event to emit when a summary card is clicked.
	 *
	 * @type {{
			dynamicComponent: string,
			summaryCardComponent: HTMLDivElement
		}}
	 * @memberof SummaryCardComponent
	 */
	@Output() public summaryCardClick:
		EventEmitter<{
			dynamicComponent: string;
			summaryCardElement: HTMLDivElement;
			dynamicComponentContext: any;
		}> = new EventEmitter<{
			dynamicComponent: string;
			summaryCardElement: HTMLDivElement;
			dynamicComponentContext: any;
		}>();

	/**
	 * Gets or sets the active value of this summary card.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	public active: boolean = false;

	/**
	 * Gets or sets the data returned from the data promise.
	 *
	 * @type {IAggregate[]}
	 * @memberof SummaryCardComponent
	 */
	public data: IAggregate[];

	/**
	 * Gets or sets a value indicating if the
	 * data is loading.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	public summaryCardLoading: boolean = true;

	/**
	 * Gets or sets the main display line.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	public summaryDisplay: string;

	/**
	 * Gets or sets the title display line.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	public titleDisplay: string;

	/**
	 * Gets or sets the large description.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	public largeSummary: boolean = false;

	/**
	 * Gets or sets the value defining if the overlay
	 * menu is currently active for this component.
	 * When set as false in the parent component,
	 * this will reset all cards to a non-active value.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	@Input() public get overlayMenuActive(): boolean
	{
		return this._overlayMenuActive;
	}
	public set overlayMenuActive(
		overlayMenuActive: boolean)
	{
		if (overlayMenuActive === false)
		{
			this.active = false;
		}

		this._overlayMenuActive = overlayMenuActive;
	}

	/**
	 * Gets or sets the local overlay menu active value.
	 * This can only be interacted with via the getter/setter.
	 *
	 * @type {boolean}
	 * @memberof SummaryCardComponent
	 */
	private _overlayMenuActive: boolean;

	/**
	 * Gets the debounce delay to use after emitting an event
	 * to set the card as active.
	 *
	 * @type {string}
	 * @memberof SummaryCardComponent
	 */
	private readonly eventEmitDebounceDelay: number = 25;

	/**
	 * Handles the on initilazation event.
	 * This calls any data promises and populates the summary
	 * card.
	 *
	 * @memberof SummaryCardComponent
	 */
	public ngOnInit(): void
	{
		this.populateCard();
		this.changeDetector.detectChanges();
	}

	/**
	 * Fires when there are changes and looks to see if
	 * those changes require a refreshing of the card.
	 *
	 * @param {SimpleChanges} changes
	 * The simple change value to check for business logic.
	 * @memberof SummaryCardComponent
	 */
	public ngOnChanges(changes: SimpleChanges): void
	{
		if (changes.dataPromise?.currentValue !==
				changes.dataPromise?.previousValue
			|| (changes.summaryTemplate?.currentValue !==
				changes.summaryTemplate?.previousValue)
			|| changes.titleTemplate?.currentValue !==
				changes.titleTemplate?.previousValue)
		{
			this.populateCard();
		}
	}

	/**
	 * Parses the data and populates the card.
	 *
	 * @memberof SummaryCardComponent
	 */
	public populateCard(): void
	{
		this.summaryCardLoading = true;

		this.dataPromise
			.then(async data =>
			{
				if ((!AnyHelper.isNull(this.summaryTemplate)
					|| ((!AnyHelper.isNull(this.summaryFunction)
							|| !AnyHelper.isNull(this.summaryPromise))
						&& AnyHelper.isNull(this.chartDefinition)))
					&& this.groupByCount !== true)
				{
					if (!AnyHelper.isNullOrEmpty(this.summaryTemplate))
					{
						this.summaryDisplay = this.interpolate(
							this.summaryTemplate,
							data);
					}
					else if (!AnyHelper.isNullOrEmpty(this.summaryFunction))
					{
						this.summaryDisplay = StringHelper.transformToFunction(
							this.summaryFunction,
							data)();
					}
					else if (!AnyHelper.isNullOrEmpty(this.summaryPromise))
					{
						this.summaryDisplay =
							await StringHelper.transformToDataPromise(
								this.summaryPromise,
								data);
					}

					if (AnyHelper.isNullOrWhitespace(this.summaryDisplay)
						|| this.summaryDisplay === '0')
					{
						this.overlayDynamicComponent = null;
						this.summaryDisplay =
							AnyHelper.isNullOrWhitespace(this.summaryDisplay)
								? 'No Data'
								: this.summaryDisplay;
					}
				}
				else if (!AnyHelper.isNull(this.chartDefinition)
					|| this.groupByCount === true)
				{
					this.data =
						data as unknown as [];

					this.summaryDisplay = !AnyHelper.isNull(
						this.summaryFunction)
						? StringHelper
							.transformToFunction(
								this.summaryFunction,
								data)()
						: `${this.data.length}`;

					if ((this.data.length === 0
						|| AnyHelper.isNullOrEmpty(this.data))
						|| (AnyHelper.isNullOrWhitespace(this.summaryDisplay)
							|| this.summaryDisplay === '0'
							|| this.summaryDisplay === 'No Data'))

					{
						this.data = null;
						this.summaryDisplay = 'No Data';
						this.overlayDynamicComponent = null;
					}

					if (this.summaryDisplay === '$0')
					{
						this.data = null;
						this.overlayDynamicComponent = null;
					}
				}
				this.titleDisplay =
					this.interpolate(
						this.titleTemplate,
						data);

				this.summaryCardLoading = false;
				this.changeDetector.detectChanges();
			})
			.catch((error) =>
			{
				this.summaryDisplay = '!';
				throw error;
			});
	}

	/**
	 * Handles the summary card click event.
	 * This will emit the click event to the parent component
	 * and set this card as active or not based on existing
	 * conditions.
	 *
	 * @memberof SummaryCardComponent
	 */
	public summaryCardClicked(
		summaryCardElement: HTMLDivElement): void
	{
		const currentlyActive: boolean = this.active;
		this.active =
			!AnyHelper.isNull(this.overlayDynamicComponent)
				&& !this.active;

		this.summaryCardClick
			.emit({
				dynamicComponent:
					currentlyActive
						? null
						: this.overlayDynamicComponent,
				dynamicComponentContext:
					this.overlayDynamicContext,
				summaryCardElement:
					summaryCardElement
			});

		if (this.active === true)
		{
			this.setActiveCard();
		}
	}

	/**
	 * Maps data to a string template
	 * that uses interpolation syntax.
	 *
	 * @param template
	 * the template.
	 * @param data
	 * the data to map to the template.
	 * @returns {string}
	 * @memberof SummaryCardComponent
	 */
	public interpolate(
		template: string,
		data: any): string
	{
		return StringHelper
			.interpolate(
				template,
				data);
	}

	/**
	 * Handles seetting the active card value with a small debounce
	 * delay to handle a parent component reset.
	 *
	 * @memberof SummaryCardComponent
	 */
	private setActiveCard(): void
	{
		this.active = false;

		setTimeout(() =>
		{
			this.active = true;
		},
		this.eventEmitDebounceDelay);
	}
}