/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';

/* eslint-enable max-len */

@Component({
	selector: 'transaction-rescind-endorse',
	templateUrl: './transaction-rescind-endorse.component.html'
})

/**
 * A component representing a wizard step to rescind an endorsed transaction.
 *
 * @export
 * @class TransactionRescindEndorseComponent
 * @extends {StatusReasonsDirective}
 */
export class TransactionRescindEndorseComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionRescindEndorseComponent
	 */
	public clientMessage: string =
		'Rescind endorsement is not allowed on a non-active policy term, '
			+ 'a policy term that is not the most recent, or when '
			+ 'the latest is not a endorsement transaction.';

	/**
	 * Gets or sets true/false if the current policy term is allowing the
	 * rescind endorsement process.
	 *
	 * @type {boolean}
	 * @memberof TransactionRescindEndorseComponent
	 */
	public allowedRescindEndorsement: boolean;

	/**
	 * Gets or sets the policy term transaction entity type.
	 *
	 * @type {IEntityType}
	 * @memberof TransactionRescindEndorseComponent
	 */
	public policyTermTransactionEntityType: IEntityType;

	/**
	 * Gets or sets the policy term transaction entity instance associated to
	 * the current policy term data.
	 *
	 * @type {IEntityInstance}
	 * @memberof TransactionEndorseReasonsComponent
	 */
	public policyTermTransactionInstance: IEntityInstance;

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @async
	 * @memberof TransactionRescindEndorseComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.allowedRescindEndorsement =
			await this.isWizardStepValidForDisplay();

		this.workflowActionName = 'TransactionRescindEndorse';

		this.context.source.addOrUpdateStepData(
			{
				comments: AppConstants.empty
			});

		this.context.source.addToNext(this.rescindEndorse.bind(this));
	}

	/**
	 * This will send the transaction rescind endorse event and navigate to the
	 * policy term dashboard.
	 *
	 * @async
	 * @memberof TransactionRescindEndorseComponent
	 */
	public async rescindEndorse(): Promise<void>
	{
		await this.transactionRescindEndorse();
		this.navigateToPolicyTermDashboard();
	}

	/**
	 * Executes the transaction rescind endorsement process by executing the
	 * transactionRescindEndorse workflow action to update the associated Policy
	 * entity data with the collected data from the Transaction Rescind Endorse
	 * Wizard.
	 *
	 * @async
	 * @memberof TransactionRescindEndorseComponent
	 */
	public async transactionRescindEndorse(): Promise<void>
	{
		const displayName: string =
			new EntityType(this.policyTermTransactionEntityType)
				.displayName;
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					const queryString: string =
						`?comments=${currentData.comments}`;

					await this.executeWorkflowAction(
						this.policyTermTransactionEntityType,
						this.policyTermTransactionInstance,
						queryString);

					resolve();
				}),
				'<strong>Rescinding endorsement</strong>',
				'<strong>Endorsement rescinded</strong>',
				`Endorsement has been rescinded for ${displayName}.`,
				`Endorsement has not been rescinded for ${displayName}.`));
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionRescindEndorseComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		const policy: IEntityInstance =
			await this.insuranceService.getPolicyByPolicyTermId(
				this.policyTermEntityInstance.id);

		if (AnyHelper.isNull(policy))
		{
			return false;
		}

		const activePolicyTerm: IEntityInstance =
			await this.insuranceService.getActivePolicyTerm(
				policy.id);

		const policyTermTransactionTypeGroup =
			'PolicyTermTransaction.'
				+ `${this.policyTermEntityInstance.data.productName}`;

		this.policyTermTransactionEntityType =
			await this.insuranceService.getPolicyTermTransactionEntityType(
				policyTermTransactionTypeGroup);

		this.entityInstanceApiService.entityInstanceTypeGroup =
			this.insuranceEntityTypes.policyTermEntityType.group;

		const latestTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				this.policyTermEntityInstance.id,
				`${AppConstants.commonProperties.status} eq `
					+ `'${InsuranceConstants.transactionStatusTypes.issued}' `
					+ `and ${AppConstants.commonProperties.type} eq `
					+ `'${InsuranceConstants.transactionTypes.endorsement}'`,
				`${InsuranceConstants.commonProperties.effectiveDate} `
					+ `${AppConstants.sortDirections.descending}, `
					+ `${AppConstants.commonProperties.id} `
					+ `${AppConstants.sortDirections.descending}`,
				0,
				AppConstants.dataLimits.large,
				policyTermTransactionTypeGroup);

		if (latestTransactions.length > 0)
		{
			this.policyTermTransactionInstance = latestTransactions[0];
		}

		const result: boolean =
			!(this.policyTermEntityInstance.data.status !==
				InsuranceConstants.policyTermStatusTypes.active
					|| activePolicyTerm.data.renewalTerm >
						this.policyTermEntityInstance.data.renewalTerm
					|| latestTransactions.length === 0);

		return result;
	}

	/**
	 * This will navigate to the parent policy term dashboard.
	 *
	 * @memberof TransactionRescindEndorseComponent
	 */
	private navigateToPolicyTermDashboard(): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.router.navigate(
			[
				`${AppConstants.moduleNames.policy}/entities`,
				this.insuranceEntityTypes.policyTermEntityType.group,
				AppConstants.viewTypes.edit,
				currentData.id
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}