<div class="ui-g-12 no-horizontal-padding text-left list-item-container"
	[ngClass]="{
		'non-override-item': (ruleDefinition?.overridable !== true || overrideAllowed !== true)
	}">

	<div class="ui-g-12 no-horizontal-padding description-container"
		*ngIf="ruleDefinition?.description != null">
		<markdown
			[data]="ruleDefinition.description">
		</markdown>
	</div>
	<div class="top-padding-element"
		*ngIf="ruleDefinition?.description == null">
	</div>

	<div *ngIf="loadingTables === true"
		class="text-center ui-g-12 no-horizontal-padding">
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>
	<div *ngIf="loadingTables === false	&& ruleViolationWorkflowActions.length > 0"
		class="ui-g-12 table-container no-horizontal-padding">
		<p-table [value]="ruleViolationWorkflowActions"
			responsiveLayout="scroll">
			<ng-template pTemplate="caption">
				<div class="panel-sub-title">
					<span class="table-title">
						Actions
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th scope="col"
						class="text-left">
						Name
					</th>
					<th scope="col"
						class="text-center"
						[style]="{'width':'70px'}">
						Blocked
					</th>
					<th scope="col"
						class="text-center"
						[style]="{'width':'85px'}">
						Overridden
					</th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-ruleViolationWorkflowAction>
				<tr>
					<td class="text-left name-column">
						<div class="ellipsis-text">
							{{ruleViolationWorkflowAction.workflowActionDefinition?.name}}
						</div>
					</td>
					<td class="text-center"
						[style]="{'width':'70px'}">
						<span>
							<ng-template [ngIf]="ruleViolationWorkflowAction.blocked === true"
								[ngIfElse]="WarningDisplay">
								<i #BlockedTooltip
									[pTooltip]="blockedTooltipMessage"
									tooltipPosition="left"
									class="fa fa-fw fa-flag column-icon"
									(click)="preventDefault($event)"
									(tap)="mobileTooltipToggle(BlockedTooltip)">
								</i>
							</ng-template>
							<ng-template #WarningDisplay>
								<i #WarningTooltip
									[pTooltip]="warningTooltipMessage"
									tooltipPosition="left"
									class="fa fa-fw fa-flag-checkered column-icon"
									(click)="preventDefault($event)"
									(tap)="mobileTooltipToggle(WarningTooltip)">
								</i>
							</ng-template>
						</span>
					</td>
					<td class="text-center"
						[style]="{'width':'85px'}">
						<span *ngIf="ruleViolationWorkflowAction.overridden === true">
							<i class="fa fa-fw fa-check column-icon">
							</i>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

	<div *ngIf="(loadingTables === false && ruleViolationWorkflowActions.length > 0)">
		<br/>
	</div>

	<div *ngIf="loadingTables === true"
		class="text-center ui-g-12 no-horizontal-padding">
		<br/>
		<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
		</span>
	</div>
	<div *ngIf="loadingTables === false	&& rulePresentationDefinitions.length > 0"
		class="ui-g-12 table-container no-horizontal-padding">
		<p-table [value]="rulePresentationDefinitions"
			responsiveLayout="scroll">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-ai-center p-jc-between panel-sub-title">
					<span class="table-title">
						Areas and Fields
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="body" let-rulePresentationDefinition>
				<tr>
					<td class="text-left">
						<div class="markdown-container ellipsis-text">
							<markdown
								[data]="getFormattedDataKey(rulePresentationDefinition)">
							</markdown>
						</div>
					</td>
					<td class="text-center"
						[style]="{'width':'85px'}">
						<span (click)="navigateToControl(rulePresentationDefinition)"
							*ngIf="rulePresentationDefinition.dataKey !== 'form'">
							<i class="fa fa-fw fa-edit column-icon theme-color">
							</i>
						</span>
					</td>
				</tr>
			</ng-template>
		</p-table>
	</div>

	<div class="ui-g-12 no-padding text-right">
		<span *ngIf="ruleDefinition?.overridable === true && overrideAllowed === true">
			<p-button class="override-button no-margin-right"
				(click)="displayOverride()">
				Override
			</p-button>
		</span>
	</div>
</div>