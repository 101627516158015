/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IActionResponse
} from '@shared/interfaces/workflow/action-response.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'bind-transaction',
	templateUrl: './bind-transaction.component.html',
	styleUrls: []
})

/**
 * A component representing a wizard step for binding a policy term transaction.
 *
 * @export
 * @class BindTransactionComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class BindTransactionComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the bind transaction component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes on entity creation.
	 * @param {EntityService} entityService
	 * The entity service used to lookup entity modules upon creation.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service used in this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @memberof BindTransactionComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public entityInstanceApiService: EntityInstanceApiService,
		public sessionService: SessionService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof BindTransactionComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof BindTransactionComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the PolicyTerm entity type to be updated.
	 *
	 * @type {IEntityType}
	 * @memberof BindTransactionComponent
	 */
	public policyTermEntityType: IEntityType;

	/**
	 * Gets or sets the PolicyTermTransaction entity type to be updated.
	 *
	 * @type {IEntityType}
	 * @memberof BindTransactionComponent
	 */
	public policyTermTransactionEntityType: IEntityType;

	/**
	 * Gets or sets the PolicyTerm entity instance associated to the current
	 * data.
	 *
	 * @type {string}
	 * @memberof BindTransactionComponent
	 */
	private policyTermEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the PolicyTermTransaction entity instance associated to the
	 * current data.
	 *
	 * @type {string}
	 * @memberof BindTransactionComponent
	 */
	private policyTermTransactionEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the context active menu item current data collected on the
	 * dynamic wizard steps.
	 *
	 * @type {any}
	 * @memberof BindTransactionComponent
	 */
	private currentData: any;

	/**
	 * Gets or sets the workflow action being executed to update the PolicyTerm
	 * data.
	 *
	 * @type {string}
	 * @memberof BindTransactionComponent
	 */
	private readonly workflowActionName: string = 'TransactionBind';

	/**
	 * Implements the on initialization interface.
	 *
	 * @memberof BindTransactionComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.currentData =
			this.context.source.activeMenuItem.currentData;

		const policyTermTransactionId = this.currentData.data.id;

		this.policyTermEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					'Group eq \'PolicyTerms\' AND Name eq \'PolicyTerm\'',
					AppConstants.empty);

		this.policyTermTransactionEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Group eq '${this.currentData.data.entityType}' `
						+ `AND Name eq '${this.currentData.data.entityType}'`,
					AppConstants.empty);

		this.policyTermEntityInstance =
			await this.getPolicyTerm(
				this.policyTermEntityType,
				this.policyTermTransactionEntityType,
				policyTermTransactionId);

		this.policyTermTransactionEntityInstance =
			await this.getPolicyTermTransaction(
				this.policyTermTransactionEntityType,
				policyTermTransactionId);

		this.context.source.addToNext(this.bind.bind(this));

		await this.performPostInitActions();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof BindTransactionComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * Gets the PolicyTerm parent instance data associated to the current
	 * PolicyTermTransaction id collected from the Bind Wizard data.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityType
	 * The PolicyTerm entity type.
	 * @param {IEntityType} policyTermTransactionEntityType
	 * The PolicyTermTransaction entity type.
	 * @param {number} policyTermTransactionId
	 * The PolicyTermTransaction entity instance id.
	 * @memberof BindTransactionComponent
	 */
	public async getPolicyTerm(
		policyTermEntityType: IEntityType,
		policyTermTransactionEntityType: IEntityType,
		policyTermTransactionId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = policyTermTransactionEntityType.group;

		const entityParents: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					policyTermTransactionId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					policyTermEntityType.group);

		return entityParents[0];
	}

	/**
	 * Gets the PolicyTermTransaction instance data associated to the current
	 * PolicyTermTransaction id collected from the Bind Wizard data.
	 *
	 * @async
	 * @param {IEntityType} policyTermTransactionEntityType
	 * The PolicyTermTransaction entity type.
	 * @param {number} policyTermTransactionId
	 * The PolicyTermTransaction entity instance id.
	 * @memberof BindTransactionComponent
	 */
	public async getPolicyTermTransaction(
		policyTermTransactionEntityType: IEntityType,
		policyTermTransactionId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = policyTermTransactionEntityType.group;

		const policyTermTransactionData: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`id eq ${policyTermTransactionId}`,
					AppConstants.empty);

		return policyTermTransactionData;
	}

	/**
	 * This will send the transaction bind event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof BindTransactionComponent
	 */
	public async bind(): Promise<void>
	{
		await this.transactionBind(
			this.policyTermEntityType,
			this.policyTermEntityInstance,
			this.policyTermTransactionEntityType,
			this.policyTermTransactionEntityInstance);

		await this.navigateToPolicyTermDashboard(
			this.policyTermEntityInstance.id,
			this.policyTermEntityType.group);
	}

	/**
	 * Executes the transaction bind process, which updates the PolicyTerm
	 * entity data with the collected data from the Bind Winzard and then
	 * executes the TransacionBind workflow action to update the associated
	 * PolicyTermTransaction data.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityType
	 * The PolicyTerm entity type.
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data to be updated.
	 * @param {IEntityType} policyTermTransactionEntityType
	 * The PolicyTermTransaction entity type.
	 * @param {IEntityInstance} policyTermTransactionInstanceData
	 * The PolicyTermTransaction entity instance data to be updated.
	 * @memberof BindTransactionComponent
	 */
	public async transactionBind(
		policyTermEntityType: IEntityType,
		policyTermInstanceData: IEntityInstance,
		policyTermTransactionEntityType: IEntityType,
		policyTermTransactionInstanceData: IEntityInstance): Promise<void>
	{
		const displayName: string =
			new EntityType(policyTermTransactionEntityType)
				.displayName;

		setTimeout(() =>
		{
			this.context.source.wizardStepLoading = true;
		});

		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, reject: any) =>
				{
					try
					{
						await this.updatePolicyTermEntity(
							policyTermEntityType,
							policyTermInstanceData,
							this.currentData.data.invoiceTo.resourceIdentifier,
							this.currentData.data.invoicingMethod,
							this.currentData.data.paymentPlan,
							this.currentData.data
								.enableAutomaticInstallmentPayments,
							this.currentData.data.electronicDocuments,
							this.currentData.data.paymentMethods);

						await this.executeTransactionBindWorkflow(
							policyTermTransactionEntityType,
							policyTermTransactionInstanceData);

						resolve();
					}
					catch (exception)
					{
						reject(exception);
					}
				}),
				'<strong>Binding transaction</strong>',
				'<strong>Transaction bound</strong>',
				`${displayName} has been bound.`,
				`${displayName} has not been bound.`));
	}

	/**
	 * This will send the PolicyTerm entity update event.
	 *
	 * @async
	 * @param {IEntityType} policyTermEntityType
	 * The PolicyTerm entity type.
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data to be updated.
	 * @param {string} invoiceTo
	 * The invoice to reference.
	 * @param {string} invoicingMethod
	 * The invoicing method selected (Email, Paper and SMS).
	 * @param {string} paymentPlan
	 * The payment plan to set on the policy term.
	 * @param {boolean} enableAutomaticInstallmentPayments
	 * Enables or disables the automatic installment payments.
	 * @param {boolean} electronicDocuments
	 * Enabled or disables the electronic documents.
	 * @param {any} paymentMethods
	 * The payment methods selected.
	 * @memberof BindTransactionComponent
	 */
	private async updatePolicyTermEntity(
		policyTermEntityType: IEntityType,
		policyTermInstanceData: IEntityInstance,
		invoiceTo: string,
		invoicingMethod: string,
		paymentPlan: string,
		enableAutomaticInstallmentPayments: boolean,
		electronicDocuments: boolean,
		paymentMethods: any): Promise<object>
	{
		const preferences: any = policyTermInstanceData.data.preferences;

		preferences.invoiceTo =
			invoiceTo;
		preferences.invoicingMethod =
			invoicingMethod;
		preferences.paymentPlan = paymentPlan;
		preferences.enableAutomaticInstallmentPayments =
			enableAutomaticInstallmentPayments;
		preferences.electronicDocuments =
			electronicDocuments;
		preferences.paymentMethods =
			paymentMethods;

		this.entityInstanceApiService.entityInstanceTypeGroup =
			policyTermEntityType.group;

		return this.entityInstanceApiService.update(
			policyTermInstanceData.id,
			policyTermInstanceData);
	}

	/**
	 * Executes the TransactionBind workflow action.
	 *
	 * @param {IEntityType} policyTermTransactionEntityType
	 * The PolicyTermTransaction entity type.
	 * @param {IEntityInstance} policyTermTransactionInstanceData
	 * The PolicyTermTransaction entity instance data to be updated.
	 * @returns {Promise<number>}
	 * The IActionResponse response from the PolicyTermTransaction update.
	 * @memberof BindTransactionComponent
	 */
	private async executeTransactionBindWorkflow(
		policyTermTransactionEntityType: IEntityType,
		policyTermTransactionInstanceData:
			IEntityInstance): Promise<IActionResponse>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			policyTermTransactionEntityType.group;

		const actionResponse: IActionResponse =
			await this.entityInstanceApiService
				.executeAction(
					policyTermTransactionInstanceData.id,
					this.workflowActionName,
					policyTermTransactionInstanceData);

		return actionResponse;
	}

	/**
	 * This will navigate to the policy term dashboard using the policy term id
	 * provided.
	 *
	 * @async
	 * @param {number} entityId
	 * The policy term entity id to navigate.
	 * @param {string} group
	 * The entity group associated to the navigation.
	 * @memberof BindTransactionComponent
	 */
	private async navigateToPolicyTermDashboard(
		entityId: number,
		group: string)
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				group,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @memberof BindTransactionComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				<FormlyFieldConfig>
				{
					key: 'data.electronicDocuments',
					type: FormlyConstants.customControls.customInputSwitch,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'E-Documents',
						description: 'The e-documents'
					}
				}
			];
	}
}