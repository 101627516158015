<div class="ui-g-12 no-padding">
	<div class="ui-g-12 no-horizontal-padding wizard-content-title">
	</div>
	<ng-template
		[ngIf]="context.source.wizardStepLoading"
		[ngIfElse]="Content">
		<div class="ui-g-12 no-horizontal-padding text-center">
			<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
			</i>
		</div>
	</ng-template>

	<ng-template #Content>
		<div class="ui-g-12 content-section">
			<div class="payment-details-box"
				[ngClass]="{'payment-details-box-tablet-view': siteLayoutService.displayTabletView}">
				<div class="payment-details-label"
					[ngClass]="{'payment-details-label-tablet-view': siteLayoutService.displayTabletView}">
					<div class="field-label ellipsis-text">
						Payor Name:
					</div>
					<div class="field-label ellipsis-text">
						Payment Method:
					</div>
					<div class="field-label ellipsis-text">
						Account:
					</div>
					<div class="field-label ellipsis-text">
						Payment Amount:
					</div>
				</div>
				<div class="payment-details"
					[ngClass]="{'payment-details-tablet-view': siteLayoutService.displayTabletView}">
					<div class="ellipsis-text">
						{{currentData.paymentMethod.name}}
					</div>
					<div class="ellipsis-text">
						{{getPaymentMethodType(currentData.paymentMethod)}}
					</div>
					<div class="ellipsis-text">
						{{currentData.paymentMethod.accountNumber}}
					</div>
					<div class="ellipsis-text">
						{{currentData.paymentAmount | currency}}
					</div>
				</div>
			</div>
		</div>
	</ng-template>
</div>