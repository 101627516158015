<p-scrollPanel styleClass="file-container">
	<div class="ui-g-12 text-left">
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Type:
			</strong>
			{{category}}
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Sub Type:
			</strong>
			{{subType}}
		</div>
		<div
			*ngFor="let property of customMetadata"
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				{{property.key}}:
			</strong>
			{{property.value}}
		</div>
		<div
			class="ui-g-12 no-horizontal-padding">
			<strong style="font-size: smaller">
				Description:
			</strong>

			<div
				class="ui-g-12 no-padding panel-text">
				{{fileEntity.data.description}}
			</div>
		</div>
		<div
			class="ui-g-12 no-horizontal-padding theme-color-gray font-italic">
			{{sizeFormatted}}
			<br />
			Created on {{createDate}}
			<br />
			Modified on {{ fileEntity.changeDate | dateTimeFromIso | dateTimeToFormat:'ccc MMM dd yyyy' }} by {{fileEntity.data.userName}}
			<br />
			({{ fileEntity.changeDate | dateTimeFromIso | dateTimeToRelative:null:'long' }})
		</div>
	</div>
</p-scrollPanel>