/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';

/* eslint-enable max-len */

@Component({
	selector: 'app-transaction-cancel-reinstate-comments',
	templateUrl: './transaction-cancel-reinstate-comments.component.html'
})

/**
 * A component representing a wizard step for performing a reinstate on a
 * cancelled transaction.
 *
 * @export
 * @class TransactionCancelReinstateCommentsComponent
 * @extends {StatusReasonsDirective}
 */
export class TransactionCancelReinstateCommentsComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets a client message to display if this wizard is not valid
	 * to be completed.
	 *
	 * @type {string}
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public clientMessage: string = AppConstants.empty;

	/**
	 * Gets or sets the active menu item data object.
	 *
	 * @type {any}
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public currentData: any;

	/**
	 * Gets the noi cancelled message if this wizard can not complete.
	 *
	 * @type {string}
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	private readonly policyTermIsNotCancelledMessage: string =
		'This policy term is not currently in a cancelled state.';

	/**
	 * Implements the on initialization interface.
	 * This method will lookup and initialize data for a status reasons
	 * display.
	 *
	 * @async
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		this.context.source.wizardStepLoading = true;
		this.currentData =
			this.context.source.activeMenuItem.currentData.data;

		if (await this.isWizardStepValidForDisplay() === false)
		{
			this.clientMessage = this.policyTermIsNotCancelledMessage;
			this.context.source.wizardStepLoading = false;

			return;
		}

		const transactionEntityType: string =
			InsuranceConstants.policyTermTransactionPrefix
				+ this.currentData.productName;
		this.entityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.group} `
						+ `eq '${transactionEntityType}'`,
					AppConstants.empty);
		this.entityInstanceId =
			this.entityInstance.id;
		this.entityInstanceTypeGroup =
			this.entityType.group;
		this.insuranceEntityTypes =
			await this.insuranceService.populateInsuranceEntityTypes();
		this.policyTermEntityInstance =
			await this.getEntityInstance(
				this.currentData.statusEntityTypeGroup,
				this.currentData.statusEntityId);
		this.productEntityInstance =
			await this.getProduct(
				this.insuranceEntityTypes.productEntityType,
				this.policyTermEntityInstance.data.productName);
		await this.performPostInitActions();
		this.context.source.updateGuardComparisonData();

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Allows additional actions following on init.
	 *
	 * @async
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		this.workflowActionName =
			InsuranceConstants.workflowActions.transactionCancelReinstate;

		this.context.source.addOrUpdateStepData(
			{
				id: this.entityInstance.id,
				typeGroup: this.entityType.group,
				comments: AppConstants.empty
			});

		this.context.source.addToNext(this.reinstateCancel.bind(this));
	}

	/**
	 * This will fire the cancel reinstate action and navigate to the policy
	 * term.
	 *
	 * @async
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public async reinstateCancel(): Promise<void>
	{
		await this.transactionCancelReinstate();
		await this.navigateToPolicyTermDashboard();
	}

	/**
	 * Executes the transaction reinstate cancel process by executing the
	 * TransactionCancelReinstate workflow action to update the associated
	 * Policy term transaction entity data with the collected data from the
	 * reinstate cancel Wizard.
	 *
	 * @async
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public async transactionCancelReinstate(): Promise<void>
	{
		const displayName: string =
			new EntityType(this.entityType)
				.displayName;
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;
		const queryString: string =
			`?parentId=${currentData.statusEntityId}`
				+ `&comments=${currentData.comments}`;

		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		return this.activityService.handleActivity(
			new Activity(
				new Promise(
					async(resolve: any) =>
					{
						await this.executeWorkflowAction(
							this.entityType,
							this.entityInstance,
							queryString);

						resolve();
					}),
				'<strong>Processing reinstatement</strong>',
				'<strong>Reinstatement processed</strong>',
				`${displayName} reinstatement has been issued.`,
				`${displayName} reinstatement has not been issued.`));
	}

	/**
	 * Validates the wizard step based on the component logic to
	 * confirm if this should be displayed or not.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * An awaitable promise that returns a value signifying whether or not
	 * the wizard step is valid for display.
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	public async isWizardStepValidForDisplay(): Promise<boolean>
	{
		this.entityInstance =
			await this.insuranceService
				.getLatestPolicyTermTransactionByPolicyTerm(
					this.currentData.statusEntityId);

		return this.entityInstance.data.type ===
			InsuranceConstants.transactionTypes.cancellation
			&& this.entityInstance.data.status ===
				InsuranceConstants.transactionStatusTypes.issued;
	}

	/**
	 * This will navigate to the policy term dashboard.
	 *
	 * @async
	 * @memberof TransactionCancelReinstateCommentsComponent
	 */
	private async navigateToPolicyTermDashboard()
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${AppConstants.moduleNames.policy}/entities`,
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms,
				AppConstants.viewTypes.edit,
				this.context.source.activeMenuItem
					.currentData.data.statusEntityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}