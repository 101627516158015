/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConfig
} from 'src/app/app.config';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	BaseApiService
} from '@api/services/base/base.api.service';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	DisplayComponentService
} from '@shared/services/display-component.service';
import {
	HttpClient,
	HttpRequest
} from '@angular/common/http';
import {
	IAggregateDto
} from '@api/interfaces/common/aggregatedto.interface';
import {
	IBaseEntity
} from '@api/interfaces/base/base-entity.interface';
import {
	Injectable
} from '@angular/core';
import {
	lastValueFrom
} from 'rxjs';
import {
	OperationService
} from '@operation/services/operation.service';
import {
	RuleService
} from '@shared/services/rule.service';

/**
 * A class representing a common API Service.
 * This class implements the standardized
 * crud operations for simple controller operations.
 *
 * @export
 * @class BaseEntityApiService
 * @extends {BaseApiService}
 * @typeparam TEntity The generic type that implements common crud
 * operations.
 */
@Injectable()
export abstract class BaseEntityApiService<TEntity>
	extends BaseApiService
{
	/**
	 * Gets or sets the cache service of the base entity api.
	 *
	 * @type {CacheService}
	 * @memberof BaseEntityApiService
	 */
	public cacheService: CacheService;

	/**
	 * Gets or sets the display component service of the base entity api.
	 *
	 * @type {displayComponentService}
	 * @memberof BaseEntityApiService
	 */
	public displayComponentService: DisplayComponentService;

	/**
	 * Gets or sets the operation service of the base entity api.
	 *
	 * @type {OperationService}
	 * @memberof BaseEntityApiService
	 */
	public operationService: OperationService;

	/**
	 * Gets or sets the rule service of the base entity api.
	 *
	 * @type {RuleService}
	 * @memberof BaseEntityApiService
	 */
	public ruleService: RuleService;

	/*
	* Gets or sets the typeGroup parameter of the entity
	* instance.
	*/
	protected typeGroup: string;

	/**
	 * Gets or sets the url endpoint of the base entity.
	 *
	 * @type {string}
	 * @memberof BaseEntityApiService
	 */
	protected endpoint: string;

	/**
	 * Gets or sets the http client of the base entity.
	 *
	 * @type {HttpClient}
	 * @memberof BaseEntityApiService
	 */
	protected httpClient: HttpClient;

	/**
	 * Gets or sets the url index for the controller url part.
	 *
	 * @type {number}
	 * @memberof BaseEntityApiService
	 */
	private readonly controllerUrlIndex: number = 4;

	/**
	 * Gets a type TEntity with the specified identifier.
	 *
	 * @param {number} id
	 * The identifier of the type T to get.
	 * @returns {Promise<TEntity>}
	 * The item found via this get method.
	 * @memberof BaseEntityApiService
	 */
	public async get(
		id: number): Promise<TEntity>
	{
		const url =
			this.getBaseUrl()
			+ `/${id}`;

		return lastValueFrom(
			this.httpClient.get<TEntity>(url));
	}

	/**
	 * Gets a collection of type TEntity with the specified filters.
	 *
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {number} [offset]
	 * A number representing the skip offset.
	 * @param {number} [limit]
	 * A number representing the top limit count.
	 * @param {number} [last]
	 * A number representing the last count.
	 * @returns {Promise<TEntity[]>}
	 * The array of items found via this query method.
	 * @memberof BaseEntityApiService
	 */
	public async query(
		filter: string,
		orderBy: string,
		offset?: number,
		limit?: number,
		last?: number): Promise<TEntity[]>
	{
		const url =
			this.formUrlParam(
				this.getBaseUrl(),
				{
					filter: filter,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					last: last
				});

		return lastValueFrom(this.httpClient.get<TEntity[]>(
			url));
	}

	/**
	 * Gets the first returned value returned from a query
	 * response matching the sent parameters.
	 *
	 * @param {string} filter
	 * A string representing the filters for the query.
	 * @param {string} orderBy
	 * A string representing the order by for the query.
	 * @param {boolean} [allowNullReturn]
	 * If sent, this will not throw an error if a matching
	 * value is found. Default is false.
	 * @returns {Promise<TEntity>}
	 * The first match of the query sent of type TEntity.
	 * @throws {Error}
	 * If no match was found for the query an error will be thrown describing
	 * the service and query used.
	 * @memberof BaseEntityApiService
	 */
	public async getSingleQueryResult(
		queryString: string,
		orderBy: string,
		allowNullReturn: boolean = false): Promise<TEntity>
	{
		const queryMatches: IBaseEntity[] =
			await this.query(
				queryString,
				orderBy,
				0,
				1);

		if (!allowNullReturn
			&& (queryMatches == null
				|| queryMatches.length === 0))
		{
			throw new Error(
				`Unable to find a matching value from the ${typeof this}. `
					+ `Query: '${queryString}' found zero matching values.`);
		}

		return queryMatches[0] as TEntity;
	}

	/**
	 * Gets an aggregate of TEntity.
	 * Count, sum, average, min, or max.
	 *
	 * @param {string} method
	 * The aggregate method name to use.
	 * @param {string} property
	 * The property to aggregate. Pass null if calling count.
	 * @param {string} filter
	 * The filter or "where clause."
	 * @param {string} groupBy
	 * The properties to group by.
	 * @returns {Promise<IAggregateDto[]>}
	 * An array of aggregate results.
	 * @memberof BaseEntityApiService
	 */
	public async aggregate(
		method: string,
		property?: string,
		filter?: string,
		groupBy?: string): Promise<IAggregateDto[]>
	{
		const url =
			this.formUrlParam(
				this.getBaseUrl()
				+ `/${AppConstants.apiMethods.aggregate}`,
				{
					method: method,
					property: property,
					filter: filter,
					groupBy: groupBy
				});

		return lastValueFrom(this.httpClient
			.get<IAggregateDto[]>(url));
	}

	/**
	 * Creates a type TEntity with the specified information.
	 *
	 * @param {IBaseEntity} entity
	 * The entity of type T to create.
	 * @returns {Promise<number>}
	 * The newly created item identifier.
	 * @memberof BaseEntityApiService
	 */
	public async create(
		entity: IBaseEntity): Promise<number>
	{
		const url =
			this.getBaseUrl();
		await this.resetAssociatedCache(url);

		const response = await lastValueFrom(this.httpClient.post(
			url,
			entity,
			{ observe: 'response' }));

		const createdItemId: number =
			this.getCreatedAtRouteIdentifier(
				response.headers.get('location'));

		return Promise.resolve(createdItemId);
	}

	/**
	 * Updates a type TEntity with the specified identifier
	 * and information.
	 *
	 * @param {number} id
	 * The identifier of the type T to update.
	 * @param {IBaseEntity} entity
	 * The entity of type T to update.
	 * @returns {Promise<object>}
	 * An observable of the put no-content response.
	 * @memberof BaseEntityApiService
	 */
	public async update(
		id: number,
		entity: IBaseEntity): Promise<object>
	{
		const baseUrl: string =
			this.getBaseUrl();
		const url =
			`${baseUrl}/${id}`;
		await this.resetAssociatedCache(baseUrl);

		return lastValueFrom(this.httpClient.put(
			url,
			entity));
	}

	/**
	 * Deletes a type TEntity with the specified identifier.
	 *
	 * @param {number} id
	 * The identifier of the type T to delete.
	 * @returns {Promise<object>}
	 * An observable of the delete no-content response.
	 * @memberof BaseEntityApiService
	 */
	public async delete(
		id: number): Promise<object>
	{
		const baseUrl: string =
			this.getBaseUrl();
		const url =
			`${baseUrl}/${id}`;
		await this.resetAssociatedCache(baseUrl);

		return lastValueFrom(this.httpClient.delete(url));
	}

	/**
	 * Gets a collection of type TEntity with the specified
	 * values that intersects the named colum value
	 * with the associated item set ids.
	 *
	 * @param {string} columnName foreign key column name.
	 * @param {BaseEntity[]} associatedItems An array representing
	 * the set of id based associated items.
	 * @param {string} orderBy dependency column to order by.
	 * @param {number} offset the number of rows to offset the query.
	 * @param {number} limit the number of rows to limit the query.
	 * @returns {Promise<TEntity[]>} The array of items found via this
	 * find where colum name value in array of ids method.
	 * @memberof BaseEntityApiService
	 */
	public async getForeignKeyMatches(
		columnName: string,
		associatedItems: IBaseEntity[],
		orderBy: string = null,
		offset: number = null,
		limit: number = null): Promise<TEntity[]>
	{
		const commaSeparatedIdArray: string =
			associatedItems
				.map((item: IBaseEntity) => item.id)
				.join(',');

		const url =
			this.formUrlParam(
				this.getBaseUrl(),
				{
					filter:
						`(${columnName} IN (${commaSeparatedIdArray}))`,
					orderBy: orderBy,
					offset: offset,
					limit: limit,
					last: null
				});

		return lastValueFrom(this.httpClient.get<TEntity[]>(url));
	}

	/**
	 * Gets the base url for all api calls of this type T.
	 * If typeGroup is set, also appends it to the url.
	 *
	 * @returns {string} concatenated base url
	 * @memberof BaseEntityApiService
	 */
	public getBaseUrl(): string
	{
		let baseUrl =
			AppConfig.settings.webApi.rootUrl
				+ AppConfig.settings.webApi.version
				+ this.endpoint;

		if (this.typeGroup)
		{
			baseUrl += '/' + this.typeGroup;
		}

		return baseUrl;
	}

	/**
	 * Gets the base url for all nested api calls of this type T.
	 *
	 * @param {number} id The id of the object to sat as nested
	 * route information.
	 * @param {string} routeIdentifier The identifier
	 * representing the nested route.
	 * @returns {string} Concatenated url.
	 * @memberof BaseEntityApiService
	 */
	public getNestedUrl(
		id: number,
		routeIdentifier: string): string
	{
		return this.getBaseUrl()
			+ `/${id}/${routeIdentifier}`;
	}

	/**
	 * Gets the identifier returned from a created at route result
	 * location header.
	 *
	 * @param {string} createdAtRouteLocation
	 * The created at route location result.
	 * @returns {number}
	 * The identifier of the created item.
	 * @memberof BaseEntityApiService
	 */
	public getCreatedAtRouteIdentifier(
		createdAtRouteLocation: string): number
	{
		return parseInt(
			createdAtRouteLocation.substring(
				createdAtRouteLocation.lastIndexOf('/') + 1),
			10);
	}

	/**
	 * Resets associated caches and to the sent url value and resets singleton
	 * storage if implemented for this endpoint. This is used to
	 * remove query and similar result sets prior to a modify to ensure
	 * we force a resync of the cached result sets.
	 *
	 * @async
	 * @param {string} url
	 * The url being used that will require a partial cache clear.
	 * @memberof BaseEntityApiService
	 */
	public async resetAssociatedCache(
		url: string): Promise<void>
	{
		this.resetSingletonStorageVariables(
			url.split(AppConstants.characters.forwardSlash)
				[this.controllerUrlIndex]);

		if (this.cacheService.cachesAreNullOrInsecure() === true)
		{
			return;
		}

		const requestClone: HttpRequest<void> =
			new HttpRequest(
				<any>AppConstants.httpRequestTypes.get,
				url);

		const matchingPerformanceCacheIdentifier: string =
			await this.cacheService
				.getRequestConfigurationIdentifier(
					requestClone);

		// If this is a performance modify this is handled
		// by the http cache interceptor.
		if (AnyHelper.isNullOrWhitespace(
			matchingPerformanceCacheIdentifier))
		{
			// Clear partials matching the sent url.
			await this.cacheService
				.clearExistingResponse(
					requestClone,
					true);
		}
	}

	/**
	 * Resets singleton storage variable based values based on the targetted
	 * controller.
	 *
	 * @param {string} targetController
	 * The controller being called with a modify that will require a
	 * partial singleton storage clear.
	 * @memberof BaseEntityApiService
	 */
	public resetSingletonStorageVariables(
		targetController: string): void
	{
		if (!AnyHelper.isNull(this.displayComponentService))
		{
			switch (targetController)
			{
				case AppConstants.apiControllers.displayComponentDefinitions:
					this.displayComponentService
						.displayComponentDefinitions = [];
					break;
				case AppConstants.apiControllers.displayComponentInstances:
					this.displayComponentService.displayComponentInstances = [];
					break;
				case AppConstants.apiControllers.displayComponentTypes:
					this.displayComponentService.displayComponentTypes = [];
					break;
			}
		}

		if (!AnyHelper.isNull(this.operationService))
		{
			switch (targetController)
			{
				case AppConstants.apiControllers.operationDefinitions:
					this.operationService.operationDefinitions = [];
					break;
				case AppConstants.apiControllers.operationDefinitionParameters:
					this.operationService.operationDefinitionParameters = [];
					break;
				case AppConstants.apiControllers.operationGroups:
					this.operationService.operationGroups = [];
					this.operationService.operationDefinitions = [];
					this.operationService.operationGroupHierarchies = [];
					break;
				case AppConstants.apiControllers.operationTypes:
					this.operationService.operationTypes = [];
					break;
				case AppConstants.apiControllers.operationTypeParameters:
					this.operationService.operationTypeParameters = [];
					break;
			}
		}

		if (!AnyHelper.isNull(this.ruleService))
		{
			switch (targetController)
			{
				case AppConstants.apiControllers.ruleDefinitions:
					this.ruleService.ruleDefinitions = [];
					break;
				case AppConstants.apiControllers.rulePresentationDefinitions:
					this.ruleService.rulePresentationDefinitions = [];
					break;
				case AppConstants.apiControllers
					.rulePresentationLogicDefinitions:
					this.ruleService.rulePresentationLogicDefinitions = [];
					break;
				case AppConstants.apiControllers
					.ruleViolationWorkflowActionDefinitions:
					this.ruleService
						.ruleViolationWorkflowActionDefinitions = [];
					break;
				case AppConstants.apiControllers.workflowActionDefinitions:
					this.ruleService.workflowActionDefinitions = [];
					break;
				case AppConstants.apiControllers
					.securityGroupRuleDefinitionViolationOverrides:
					this.ruleService
						.securityGroupRuleDefinitionViolationOverrides = [];
					break;
			}
		}
	}

	/**
	 * Gets the message to be displayed for non implemented
	 * interfaces.
	 *
	 * @param {string} methodName
	 * The name of the not supported method.
	 * @param {string[]} supportedMethods
	 * The set of supported methods to be offered as alternatives if applicable.
	 * This value defaults to null.
	 * @returns {string}
	 * The message to be used when displaying a non-implemented
	 * interface.
	 * @memberof BaseEntityApiService
	 */
	public getNotImplementedMessage(
		methodName: string,
		supportedMethods: string[] = null): string
	{
		const standardMessage: string =
			`${methodName} is not supported for this api service.`;

		if (AnyHelper.isNull(supportedMethods)
			|| supportedMethods.length === 0)
		{
			return standardMessage;
		}

		return standardMessage + ' Please use the supported '
			+ (supportedMethods.length > 1
				? `${supportedMethods.join('/')} interfaces.`
				: `${supportedMethods[0]} interface.`);
	}

	/**
	 * Gets the message to be displayed for interfaces that
	 * are handled by other services.
	 *
	 * @param {string} methodName
	 * The name of the not supported method.
	 * @param {string} otherService
	 * Name or phrase of other service.
	 * @returns {string}
	 * The message displayed.
	 * @memberof BaseEntityApiService
	 */
	public getHandledByOthersMessage(
		methodName: string,
		otherService: string): string
	{
		return `${methodName} is not supported for this api service. `
			+ `These entities are handled by the ${otherService} service.`;
	}
}