/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AccountDirective
} from '@account/directives/account.directive';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IInputGroupItem
} from '@shared/interfaces/application-objects/input-group-item.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-security',
	templateUrl: './security.component.html'
})

/**
 * A component representing the sercurity page.
 *
 * @export
 * @class SecurityComponent
 */
export class SecurityComponent
	extends AccountDirective
{
	/**
	 * Initializes a new instance of the sercurity component.
	 *
	 * @param {SessionService} sessionService
	 * The session service use to gather user data data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {ActivityService} activityService
	 * The activityservice user to handle action notifications.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof SecurityComponent
	 */
	public constructor(
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public activityService: ActivityService,
		public resolver: ResolverService)
	{
		super();
		this.entityInstanceApiService.entityInstanceTypeGroup = 'Users';
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof SecurityComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.setUserEntityInstance();
		this.updateLastSavedData();
		this.setFormlyDefinitions();
	}

	/**
	 * Sets up the user entity instance data.
	 *
	 * @async
	 * @memberof SecurityComponent
	 */
	public async setUserEntityInstance(): Promise<void>
	{
		this.userEntityInstance =
			await this.entityInstanceApiService
				.get(this.sessionService.user.id);

		this.userEntityInstance.data.settings.multiFactorAuthenticationEnabled =
			JSON.parse(
				this.userEntityInstance.data
					.settings.multiFactorAuthenticationEnabled);
	}

	/**
	 * Sets up the formly field definitions.
	 *
	 * @memberof SecurityComponent
	 */
	public setFormlyDefinitions(): void
	{
		this.formlyLayoutDefinitions =
		[
			{
				key: 'data.userName',
				type: FormlyConstants.customControls.input,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'User Name',
					disabled: true
				}
			},
			{
				key: 'data.settings.multiFactorAuthenticationEnabled',
				type: FormlyConstants.customControls.customInputSwitch,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Enable Multi-Factor Authentication',
				}
			},
			{
				key: 'data.password',
				type: 'custom-input-password',
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'New Password',
					keyup: (field: FormlyFieldConfig) =>
					{
						if (field.formControl.value === AppConstants.empty)
						{
							delete this.userEntityInstance.data.password;
							this.formValidityChange(false);
						}
					}
				}
			},
			{
				key: 'data.settings.multiFactorAuthenticationMethod',
				type: FormlyConstants.customControls.customInputRadioButton,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Multi-Factor Authentication Method',
					tooltip: 'To change the email or phone, '
						+ 'navigate to the Profile page. '
						+ 'Currently, SMS is not allowed.',
					disabled: true,
					inputGroupItems:
					<IInputGroupItem[]>
					[
						{
							name: 'Email',
							key: 'email',
							disabledInput: true
						},
						{
							name: 'SMS',
							key: 'sms',
							disabledInput: true
						}
					],
				},
			}
		];
	}

	/**
	 * Save activity action.
	 *
	 * @async
	 * @memberof SecurityComponent
	 */
	public async saveActivityAction(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, reject: any) =>
				{
					try
					{
						const newEntityInstance: any = {
							id: this.userEntityInstance.id,
							data: {
								email: this.userEntityInstance.data.email,
								userName: this.userEntityInstance.data.userName,
								settings: {
									multiFactorAuthenticationEnabled:
										`${this.userEntityInstance.data.settings
											.multiFactorAuthenticationEnabled}`,
									multiFactorAuthenticationMethod:
										this.userEntityInstance.data.settings
											.multiFactorAuthenticationMethod
								}
							},
							entityType: this.userEntityInstance.entityType,
							versionNumber: this.userEntityInstance.versionNumber
						};

						const isNewPassword: boolean =
							!AnyHelper.isNull(
								this.userEntityInstance.data.password);

						const updateEntityInstance: Function = async () =>
						{
							await this.entityInstanceApiService
								.update(
									this.userEntityInstance.id,
									newEntityInstance);
						};

						if (isNewPassword)
						{
							newEntityInstance.data.password =
								this.userEntityInstance.data.password;
							await updateEntityInstance();
							this.sessionService.logOut();
						}
						else
						{
							await updateEntityInstance();
						}

						await this.setupPageVariables();

						resolve();
					}
					catch (exception)
					{
						this.saving = false;
						reject(exception);
					}
				}),
				'<strong>Saving</strong> Security Settings',
				'<strong>Saved</strong> Security Settings',
				'Security Settings were saved.',
				'Security Settings were not saved.'));
	}
}