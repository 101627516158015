/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AccountDirective
} from '@account/directives/account.directive';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	ChangeThemeAction
} from '@operation/actions/change-theme-action';
import {
	Component
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SessionService
} from '@shared/services/session.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

@Component({
	selector: 'app-site-settings',
	templateUrl: './site-settings.component.html'
})

/**
 * A component representing the site settings page.
 *
 * @export
 * @class SiteSettingsComponent
 */
export class SiteSettingsComponent
	extends AccountDirective
{
	/**
	 * Initializes a new instance of the site settings page component.
	 *
	 * @param {SessionService} sessionService
	 * The session service use to gather user data.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The api service used to get entity instance data.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The api service used to get entity type data.
	 * @param {ActivityService} activityService
	 * The activityservice user to handle action notifications.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof SiteSettingsComponent
	 */
	public constructor(
		public sessionService: SessionService,
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public activityService: ActivityService,
		public changethemeAction: ChangeThemeAction,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
		super();
		this.entityInstanceApiService.entityInstanceTypeGroup = 'Users';
	}

	/**
	 * Sets up the page variables.
	 *
	 * @async
	 * @memberof SiteSettingsComponent
	 */
	public async setupPageVariables(): Promise<void>
	{
		await this.setUserEntityInstance();
		this.updateLastSavedData();
		this.setFormlyDefinitions();
	}

	/**
	 * Sets up the user entity instance data.
	 *
	 * @async
	 * @memberof SiteSettingsComponent
	 */
	public async setUserEntityInstance(): Promise<void>
	{
		this.userEntityInstance =
			await this.entityInstanceApiService
				.get(this.sessionService.user.id);
	}

	/**
	 * Gets the available theme options.
	 *
	 * @return {IDropdownOption[]}
	 * @memberof SiteSettingsComponent
	 */
	public getThemeOptions(): IDropdownOption[]
	{
		return this.changethemeAction.allowedEntries
			.map((theme: string) =>
				({
					label: StringHelper.toProperCase(theme),
					value: theme
				}));
	}

	/**
	 * Sets up the formly field definitions.
	 *
	 * @memberof SiteSettingsComponent
	 */
	public setFormlyDefinitions(): void
	{
		this.formlyLayoutDefinitions =
		[
			{
				key: 'data.settings.themeColor',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Theme Color',
					options: this.getThemeOptions()
				}
			}
		];
	}

	/**
	 * Save activity action.
	 *
	 * @async
	 * @memberof SiteSettingsComponent
	 */
	public async saveActivityAction(): Promise<void>
	{
		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, reject: any) =>
				{
					try
					{
						await this.entityInstanceApiService.update(
							this.userEntityInstance.id,
							this.userEntityInstance);

						await this.excecuteChangeThemeAction();
						await this.setupPageVariables();

						resolve();
					}
					catch (exception)
					{
						this.saving = false;
						reject(exception);
					}
				}),
				'<strong>Saving</strong> Site Settings',
				'<strong>Saved</strong> Site Settings',
				'Site Settings were saved.',
				'Site Settings were not saved.'));
	}

	/**
	 * Change theme action.
	 *
	 * @async
	 * @memberof SiteSettingsComponent
	 */
	public async excecuteChangeThemeAction(): Promise<void>
	{
		if (this.databaseEntityInstance.data.settings.themeColor ===
			this.userEntityInstance.data.settings.themeColor)
		{
			return;
		}

		const changeThemeAction: Function =
			async() =>
			{
				const user: IUser =
					{
						...this.sessionService.user
					};

				user.data.settings.themeColor =
					this.userEntityInstance.data.settings.themeColor;

				this.sessionService.user = user;

				this.changethemeAction.theme =
					this.userEntityInstance.data.settings.themeColor;

				await this.changethemeAction.execute();
			};

		await this.activityService.handleActivity(
			new Activity(
				changeThemeAction(),
				'<strong>Changing</strong> Theme Color',
				'<strong>Changed</strong> Theme Color',
				this.changethemeAction.operationSuccessMessage,
				this.changethemeAction.operationFailureMessage));
	}
}