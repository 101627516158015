<div
	class="ui-g-12 text-left no-padding">
	<div
		class="ui-g-12 nested-display-subtitle text-left">
		<span class="nested-display-actions">
			<ng-template
				ngFor
				let-action
				[ngForOf]="itemActions">
					<i
						class="fa fa-fw {{action.icon}} theme-color cursor-pointer"
						[ngClass]="{
							'selected-bottom-border': (action.id === 'details')
						}"
						(click)="action.command($event)"
						pTooltip="{{action.label}}"
						tooltipPosition="left"
						tooltipStyleClass="utility-menu-tooltip">
					</i>
			</ng-template>
		</span>

		<div class="ellipsis-text-2-line"
			[pTooltip]="fileEntity.data.name"
			tooltipPosition="left"
			tooltipStyleClass="utility-menu-tooltip">
			Details:
			<strong style="font-size: smaller">
				&nbsp;{{fileEntity.data.name}}
			</strong>
		</div>
	</div>

	<app-file
		[fileEntity] = "fileEntity">
	</app-file>
</div>