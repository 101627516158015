
/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/member-ordering */

import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	Injectable
} from '@angular/core';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	IUser
} from '@shared/interfaces/users/user.interface';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';

/**
 * A class representing a User Service.
 *
 * @export
 * @class UserService
 */
@Injectable({
	providedIn: 'root'
})
export class UserService
{
	/**
	 * Creates an instance of UserService.
	 *
	 * @param {SecurityGroupApiService} securityGroupApiService
	 * The security group api service.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service.
	 * @memberof UserService
	 */
	public constructor(
		private readonly securityGroupApiService: SecurityGroupApiService,
		private readonly entityInstanceApiService: EntityInstanceApiService)
	{
	}

	/**
	 * Decorates the security groups that the user has access to
	 * into the session user object.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @param {ISecurityGroup[]} securityGroups
	 * The security groups to decorate the user with
	 * specific ones.
	 * @returns {Promise<IUser>}
	 * The user with user security group values set and ready for use.
	 * @memberof UserService
	 */
	public async setUserSecurityGroups(
		user: IUser,
		securityGroups: ISecurityGroup[] = []): Promise<IUser>
	{
		const accessibleSecurityGroups: ISecurityGroup[] =
			securityGroups.length > 0
				? securityGroups
				: await this.securityGroupApiService.query(
					AppConstants.empty,
					AppConstants.empty);

		const commaDelimitedSecurityGroups: string =
			ApiFilterHelper.commaSeparatedStringValues(
				accessibleSecurityGroups
					.map((securityGroup: ISecurityGroup) =>
						securityGroup.id.toString()),
				AppConstants.empty);

		const membershipFilter: string =
			`Id IN (${commaDelimitedSecurityGroups}) `
				+ 'AND SecurityGroupEntityInstances.Any('
					+ `EntityInstanceId eq ${user.id})`;

		const membershipSecurityGroups: ISecurityGroup[] =
			await this.securityGroupApiService.query(
				membershipFilter,
				AppConstants.empty);

		user.accessibleSecurityGroups = accessibleSecurityGroups;
		user.membershipSecurityGroups = membershipSecurityGroups;

		return user;
	}

	/**
	 * Updates the User Instance and its security groups.
	 *
	 * @async
	 * @param {IUser} user
	 * The logged in user.
	 * @param {ISecurityGroup[]} initialSecurityGroups
	 * The initial security groups.
	 * @param {ISecurityGroup[]} changedSecurityGroups
	 * The changed security groups.
	 * @memberof UserService
	 */
	 public async updateUserSecurityGroups(
		user: IEntityInstance,
		initialSecurityGroups: ISecurityGroup[],
		changedSecurityGroups: ISecurityGroup[]): Promise<void>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			AppConstants.typeGroups.users;

		await this.entityInstanceApiService
			.update(
				user.id,
				user);

		for (const initialSecurityGroup of initialSecurityGroups)
		{
			if (!changedSecurityGroups.some((changedSecurityGroup) =>
				initialSecurityGroup.id === changedSecurityGroup.id))
			{
				await this.securityGroupApiService
					.deleteSecurityGroupUser(
						initialSecurityGroup.id,
						user.id);
			}
		}

		for (const changedSecurityGroup of changedSecurityGroups)
		{
			if (!initialSecurityGroups.some((initialSecurityGroup) =>
				initialSecurityGroup.id === changedSecurityGroup.id))
			{
				await this.securityGroupApiService
					.createSecurityGroupUser(
						changedSecurityGroup.id,
						user.id);
			}
		}
	}
}