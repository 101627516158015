/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	DateHelper
} from '@shared/helpers/date.helper';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	IActionResponseDto
} from '@api/interfaces/workflow/action-response.dto.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IFileEntity
} from '@shared/interfaces/files/file-entity.interface';
import {
	IInsuranceEntityTypes
} from '@insurance/interfaces/insurance-entity-types.interface';
import {
	IInsuranceKeyContacts
} from '@insurance/interfaces/insurance-key-contacts.interface';
import {
	IInsuranceServiceProvider
} from '@insurance/interfaces/insurance-service-provider.interface';
import {
	Injectable
} from '@angular/core';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	IWorkItem
} from '@shared/interfaces/workItems/work-item.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	Transaction
} from '@insurance/business-logic-entities/transactions/transaction';
import {
	WorkItem
} from '@shared/business-logic-entities/work-item';

/**
 * A class representing a common interface to gather fully populated
 * insurance information.
 *
 * @export
 * @class InsuranceService
 */
@Injectable()
export class InsuranceService
{
	/**
	 * Creates an instance of an InsuranceService.
	 *
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type service for this component.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity type service for this component.
	 * @param {ResolverService} resolverService
	 * The resolver service for this component.
	 * @memberof InsuranceService
	 */
	public constructor(
		public readonly entityTypeApiService: EntityTypeApiService,
		public readonly entityInstanceApiService: EntityInstanceApiService,
		public readonly resolverService: ResolverService)
	{
	}

	/**
	 * Creates and populates an insurance entity type lookup object.
	 *
	 * @async
	 * @returns {Promise<IInsuranceEntityTypes>}
	 * An awaitable insurance entity type object used for lookups.
	 * @memberof InsuranceService
	 */
	public async populateInsuranceEntityTypes(): Promise<IInsuranceEntityTypes>
	{
		const insuranceEntityTypes: IInsuranceEntityTypes =
			<IInsuranceEntityTypes> {};

		const promiseArray: Promise<void>[] =
			[
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.policyEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									'Group eq \'Policies\' '
										+ 'AND Name eq \'Policy\'',
									AppConstants.empty);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.policyTermEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									'Group eq \'PolicyTerms\' '
										+ 'AND Name eq \'PolicyTerm\'',
									AppConstants.empty);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.productEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									'Group eq \'Products\' '
										+ 'AND Name eq \'Product\'',
									AppConstants.empty);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.ledgerEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									'Group eq \'Ledgers\' '
										+ 'AND Name eq \'Ledger\'',
									AppConstants.empty);
						resolve();
					}),
				new Promise(
					async(resolve) =>
					{
						insuranceEntityTypes.ledgerTransactionEntityType =
							await this.entityTypeApiService
								.getSingleQueryResult(
									'Group eq \'LedgerTransactions\' '
										+ 'AND Name eq \'LedgerTransaction\'',
									AppConstants.empty);
						resolve();
					})
			];

		await Promise.all(promiseArray);

		return insuranceEntityTypes;
	}

	/**
	 * Gets the active policy term entity instance from the policy.
	 *
	 * @async
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the active policy term.
	 * @memberof InsuranceService
	 */
	public async getActivePolicyTerm(
		policyId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policies;
		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		let activePolicyTermInstance: IEntityInstance =
			entityRelationships.find((instance: IEntityInstance) =>
				instance.data.status ===
					InsuranceConstants.policyTermStatusTypes.active);

		if (!AnyHelper.isNull(activePolicyTermInstance))
		{
			return activePolicyTermInstance;
		}

		activePolicyTermInstance =
			entityRelationships.find((instance: IEntityInstance) =>
				instance.data.status ===
					InsuranceConstants.policyTermStatusTypes.pending);

		return activePolicyTermInstance;
	}

	/**
	 * Gets the policy instance by policy term id.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy.
	 * @memberof InsuranceService
	 */
	public async getPolicyByPolicyTermId(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					policyTermId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policies);

		return entityRelationships[0];
	}

	/**
	 * Gets the policy term instance by policy term transaction id.
	 *
	 * @async
	 * @param {number} transactionId
	 * The policy term transaction id.
	 * @param {string} transactionEntityTypeGroup
	 * The policy term transaction entity type group.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy term transaction.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermByTransaction(
		transactionId: number,
		transactionEntityTypeGroup: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup = transactionEntityTypeGroup;

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getParents(
					transactionId,
					null,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		return entityRelationships[0];
	}

	/**
	 * Gets the policy term instance by id.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy term.
	 * @memberof InsuranceService
	 */
	 public async getPolicyTerm(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const policyTermInstance: IEntityInstance =
			await this.entityInstanceApiService.get(
				policyTermId);

		return policyTermInstance;
	}

	/**
	 * Gets all policy term instances by policy id that are not archived
	 * or obsolete.
	 *
	 * @async
	 * @param {number} policyId
	 * The policy id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy term transaction.
	 * @memberof InsuranceService
	 */
	public async getPolicyTermsByPolicyId(
		policyId: number): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService.entityInstanceTypeGroup =
			InsuranceConstants.insuranceEntityTypeGroups.policies;

		const entityRelationships: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyId,
					`${AppConstants.commonProperties.status} ne `
						+ `'${InsuranceConstants
							.transactionStatusTypes.obsolete}' `
						+ `and ${AppConstants.commonProperties.status} ne `
						+ `'${InsuranceConstants
							.transactionStatusTypes.archived}'`,
					null,
					null,
					AppConstants.dataLimits.large,
					InsuranceConstants.insuranceEntityTypeGroups.policyTerms);

		return entityRelationships;
	}

	/**
	 * Gets the Product instance by name.
	 *
	 * @async
	 * @param {number} productName
	 * The product name.
	 * @return {Promise<IEntityInstance>}
	 * The product instance that matches the product name.
	 * @memberof InsuranceService
	 */
	public async getProductByName(
		productName: string): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.products;

		const productInstance: IEntityInstance =
			await this.entityInstanceApiService
				.getSingleQueryResult(
					`${AppConstants.commonProperties.name} eq '${productName}'`,
					AppConstants.empty);

		return productInstance;
	}

	/**
	 * Gets the Product of a transaction.
	 *
	 * @async
	 * @param {IEntityInstance} transaction
	 * The transaction.
	 * @return {Promise<IEntityInstance>}
	 * The product instance to which the transaction belongs.
	 * @memberof InsuranceService
	 */
	public async getProductByTransaction(
		transaction: IEntityInstance): Promise<IEntityInstance>
	{
		const transactionType: IEntityType =
			await this.entityTypeApiService
				.getSingleQueryResult(
					`Name eq '${transaction.entityType}'`,
					AppConstants.empty);

		const policyTerm: IEntityInstance =
			await this.getPolicyTermByTransaction(
				transaction.id,
				transactionType.group);

		return this.getProductByName(
			policyTerm.data.productName);
	}

	/**
	 * Gets the policy term transaction entity type by group name.
	 *
	 * @async
	 * @param {number} group
	 * The group name.
	 * @return {Promise<IEntityType>}
	 * The policy term transaction entity type that matches the group name.
	 * @memberof InsuranceService
	 */
	 public async getPolicyTermTransactionEntityType(
		group: string): Promise<IEntityType>
	{
		const policyTermTransactionEntityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				`Group eq \'${group}\' AND Name eq \'${group}\'`,
				AppConstants.empty);

		return policyTermTransactionEntityType;
	}

	/**
	 * Gets the latest child transaction of the sent policy term id be effective
	 * date. If status or type is sent, this will filter those results to that
	 * matching status, type, or both.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id to find a child policy term transaction for.
	 * @param {string} transactionEntityTypeGroup
	 * The policy term transaction type to get latest for.
	 * @param {string} transactionType
	 * If sent, this will filter the result set to only those with a matching
	 * transaction type. The status filter will also be applied if sent.
	 * @param {string} transactionStatus
	 * If sent, this will filter the result set to only those with a matching
	 * transaction status. The type filter will also be applied if sent.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance that signifies the latest policy term
	 * transaction.
	 * @memberof InsuranceService
	 */
	public async getLatestPolicyTermTransaction(
		policyTermId: number,
		transactionEntityTypeGroup: string,
		transactionType: string = AppConstants.empty,
		transactionStatus: string = AppConstants.empty):
			Promise<IEntityInstance>
	{
		const typeFilter: string =
			!AnyHelper.isNullOrWhitespace(transactionType)
				? `${AppConstants.commonProperties.type} eq `
					+ `'${transactionType}'`
				: AppConstants.empty;
		const statusFilter: string =
			!AnyHelper.isNullOrWhitespace(transactionStatus)
				? `${AppConstants.commonProperties.status} eq `
					+ `'${transactionStatus}'`
				: AppConstants.empty;

		let combinedFilter: string = AppConstants.empty;
		switch (true)
		{
			case !AnyHelper.isNullOrWhitespace(typeFilter)
				&& AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = typeFilter;
				break;
			case AnyHelper.isNullOrWhitespace(typeFilter)
				&& !AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = statusFilter;
				break;
			case !AnyHelper.isNullOrWhitespace(typeFilter)
				&& !AnyHelper.isNullOrWhitespace(statusFilter):
				combinedFilter = `${typeFilter} AND ${statusFilter}`;
				break;
		}

		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const entityTransactions: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyTermId,
					combinedFilter,
					`${InsuranceConstants.commonProperties.effectiveDate} `
						+ AppConstants.sortDirections.descending,
					0,
					1,
					transactionEntityTypeGroup);

		return entityTransactions.length > 0
			? entityTransactions[0]
			: null;
	}

	/**
	 * Gets the latest policy term transaction associated to the policy term id.
	 * This method will filter obsolete and archived transactions.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy.
	 * @memberof InsuranceService
	 */
	 public async getLatestPolicyTermTransactionByPolicyTerm(
		policyTermId: number): Promise<IEntityInstance>
	{
		const policyTermInstance: IEntityInstance =
			await this.getPolicyTerm(policyTermId);

		const policyTermTransactionTypeGroup =
			InsuranceConstants.policyTermTransactionPrefix
				+ `${policyTermInstance.data.productName}`;

		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const policyTermTransactions: IEntityInstance[] =
			await this.entityInstanceApiService.getChildren(
				policyTermId,
				`${AppConstants.commonProperties.status} ne `
					+ `'${InsuranceConstants.transactionStatusTypes.obsolete}' `
					+ `and ${AppConstants.commonProperties.status} ne `
					+ `'${InsuranceConstants.transactionStatusTypes.archived}'`,
				`${AppConstants.commonProperties.id} `
					+ `${AppConstants.sortDirections.descending}`,
				null,
				AppConstants.dataLimits.large,
				policyTermTransactionTypeGroup);

		return policyTermTransactions[0];
	}

	/**
	 * Gets the latest policy term transaction associated to the policy id.
	 * This method will filter obsolete and archived transactions and will use
	 * the latest active policy term.
	 *
	 * @async
	 * @param {number} policyId
	 * The policy term id.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable IEntityInstance related to the policy.
	 * @memberof InsuranceService
	 */
	public async getLatestPolicyTermTransactionByPolicy(
		policyId: number): Promise<IEntityInstance>
	{
		const policyTerm: IEntityInstance =
			await this.getActivePolicyTerm(policyId);

		return this.getLatestPolicyTermTransactionByPolicyTerm(
			policyTerm.id);
	}

	/**
	 * Adds policy forms to the policy term passed.
	 *
	 * @async
	 * @param {IEntityInstance} policyTerm
	 * The policy term for which to get the forms.
	 * @returns {Promise<any>}
	 * An awaitable object with the forms.
	 * @memberof InsuranceService
	 */
	public async getForms(
		policyTerm: IEntityInstance): Promise<any>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const response: any
			= await this.entityInstanceApiService
				.executeAction(
					policyTerm.id,
					'GetFormFiles',
					null,
					'generatorFilter=Tags.Contains("PolicyForm")');

		const actionResponse: IActionResponseDto = response.body;

		const forms: IFileEntity[] = actionResponse.value;

		const loadedForms: any =
			<any>
			{
				count: forms.length,
				forms: forms
			};

		return loadedForms;
	}

	/**
	 * Gets key contacts data from a transaction, term, or policy instance.
	 *
	 * @async
	 * @param {IEntityInstance} entity
	 * The entity to get key contacts for.
	 * @param {string} entityTypeGroup
	 * The entity type group to get key contacts for.
	 * @returns {Promise<IInsuranceKeyContacts>}
	 * An awaitable key contacts object holding product, agency, and
	 * primary insured information.
	 * @memberof InsuranceService
	 */
	public async getKeyContacts(
		entity: IEntityInstance,
		entityTypeGroup: string): Promise<IInsuranceKeyContacts>
	{
		let policy: IEntityInstance;
		let policyTerm: IEntityInstance;
		let policyTermTransaction: IEntityInstance;

		switch (entityTypeGroup)
		{
			case InsuranceConstants.insuranceEntityTypeGroups.policies:
				policy = entity;
				policyTerm =
					await this.getActivePolicyTerm(
						policy.id);
				policyTermTransaction =
					await this.getLatestPolicyTermTransactionByPolicyTerm(
						policyTerm.id);
				break;
			case InsuranceConstants.insuranceEntityTypeGroups.policyTerms:
				policyTerm = entity;
				policy =
					await this.getPolicyByPolicyTermId(
						policyTerm.id);
				policyTermTransaction =
					await this.getLatestPolicyTermTransactionByPolicyTerm(
						policyTerm.id);
				break;
			default:
				policyTermTransaction = entity;
				policyTerm =
					await this.getPolicyTermByTransaction(
						policyTermTransaction.id,
						entityTypeGroup);
				policy =
					await this.getPolicyByPolicyTermId(
						policyTerm.id);
				break;
		}

		const insuranceKeyContacts: IInsuranceKeyContacts =
			{
				agency:
					await this.getActiveServiceProviderInstance(
						policy,
						InsuranceConstants.serviceProviderTypes.agency,
						InsuranceConstants.serviceProviderEntityTypeGroups
							.agency),
				producer:
					await this.getActiveServiceProviderInstance(
						policy,
						InsuranceConstants.serviceProviderTypes.producer,
						InsuranceConstants.serviceProviderEntityTypeGroups
							.producer),
				primaryInsured:
					policyTermTransaction.data.interests[0]
			};

		return insuranceKeyContacts;
	}

	/**
	 * Gets a work item business logic entity.
	 *
	 * @param instance
	 * An entity instance of a work itew.
	 * @returns {WorkItem}
	 * The work item business logic entity.
	 * @memberof InsuranceService
	 */
	public getWorkItem(instance: IWorkItem): WorkItem
	{
		return new WorkItem(
			instance,
			this.resolverService);
	}

	/**
	 * Gets a transaction business logic entity.
	 *
	 * @param instance
	 * An entity instance of a work itew.
	 * @returns {Transaction}
	 * The transaction business logic entity.
	 * @memberof InsuranceService
	 */
	public getTransaction(instance: IEntityInstance): Transaction
	{
		return new Transaction(
			instance,
			this.resolverService);
	}

	/**
	 * refreshes components.
	 *
	 * @param {string} component
	 * the component name.
	 *  @param {string} targetType
	 * @memberof InsuranceService
	 */
	public refreshComponents(
		components: string[],
		targetType: string,
		refreshBadges: boolean = true)
	{
		EventHelper.refreshComponents(
			components,
			targetType);

		if (refreshBadges)
		{
			this.refreshBadges(
				components,
				targetType);
		}
	}

	/**
	 * refreshes badges.
	 *
	 * @param {string} component
	 * the component name.
	 *  @param {string} targetType
	 * @memberof InsuranceService
	 */
	public refreshBadges(
		components: string[],
		targetType: string) {
		EventHelper.dispatchRefreshBadgePromiseEvents(
			components,
			targetType);
	}

	/**
	 * Gets the Ledger instance data associated to the current PolicyTerm
	 * instance.
	 *
	 * @async
	 * @param {number} policyTermId
	 * The PolicyTerm entity instance id.
	 * @return {Promise<IEntityInstance>}
	 * The Ledger instance.
	 * @memberof InsuranceService
	 */
	public async getLedger(
		policyTermId: number): Promise<IEntityInstance>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

		const ledgerInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					policyTermId,
					null,
					null,
					null,
					null,
					InsuranceConstants.insuranceEntityTypeGroups
						.ledger);

		return ledgerInstances[0];
	}

	/**
	 * Gets the LedgerTransacion entity instances associated to the current
	 * Ledger instance.
	 *
	 * @async
	 * @param {number} ledgerId
	 * The Ledger entity instance id.
	 * @return {Promise<IEntityInstance[]>}
	 * The LedgerTransaction instances array.
	 * @memberof InsuranceService
	 */
	public async getLedgerTransactions(
		ledgerId: number): Promise<IEntityInstance[]>
	{
		this.entityInstanceApiService
			.entityInstanceTypeGroup =
				InsuranceConstants.insuranceEntityTypeGroups.ledger;

		const ledgerTransactionInstances: IEntityInstance[] =
			await this.entityInstanceApiService
				.getChildren(
					ledgerId,
					null,
					null,
					null,
					null,
					InsuranceConstants.insuranceEntityTypeGroups
						.ledgerTransaction);

		return ledgerTransactionInstances;
	}

	/**
	 * Get a value indicating true if submission is enabled on the product.
	 *
	 * @async
	 * @returns {boolean}
	 * The value indicating true if it is enabled, false if not.
	 * @memberof InsuranceService
	 */
	public async productSubmissionEnabled(
		transaction: IEntityInstance): Promise<boolean>
	{
		const product: IEntityInstance =
			await this.getProductByTransaction(transaction);

		return product
			.data
			.submit
			.enabled;
	}

	/**
	 * Given a policy entity instance, service provider type, and a service
	 * provider type group this will load and return the associated entity
	 * instance matching the provider type and id.
	 *
	 *
	 * @async
	 * @param {IEntityInstance} policy
	 * The policy data holding service providers.
	 * @param {string} serviceProviderType
	 * The service provider type to load an entity instance for.
	 * @param {string} serviceProviderEntityTypeGroup
	 * The service provider entity type to be loaded.
	 * @returns {Promise<IEntityInstance>}
	 * An awaitable entity instance that represents the currently active
	 * service provider.
	 * @memberof InsuranceService
	 */
	private async getActiveServiceProviderInstance(
		policy: IEntityInstance,
		serviceProviderType: string,
		serviceProviderEntityTypeGroup: string): Promise<IEntityInstance>
	{
		const matchingServiceProviders: IInsuranceServiceProvider[] =
			policy.data.serviceProviders
				.filter(
					(serviceProvider: IInsuranceServiceProvider) =>
						serviceProvider.type === serviceProviderType
							&& DateHelper.fromUtcIso(
								serviceProvider.effectiveDate)
								<= DateHelper.getSystemDateTime());

		matchingServiceProviders.sort(
			(itemOne: IInsuranceServiceProvider,
				itemTwo: IInsuranceServiceProvider) =>
			{
				if (DateHelper.fromUtcIso(itemOne.effectiveDate)
					> DateHelper.fromUtcIso(itemTwo.effectiveDate))
				{
					return 1;
				}

				if (DateHelper.fromUtcIso(itemOne.effectiveDate)
					< DateHelper.fromUtcIso(itemTwo.effectiveDate))
				{
					return -1;
				}

				return 0;
			});

		if (matchingServiceProviders.length === 0)
		{
			return null;
		}

		this.entityInstanceApiService.entityInstanceTypeGroup =
			serviceProviderEntityTypeGroup;

		return this.entityInstanceApiService.getSingleQueryResult(
			`${AppConstants.commonProperties.resourceIdentifier} eq `
				+ `'${matchingServiceProviders[0].id}'`,
			AppConstants.empty);
	}
}