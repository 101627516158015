<div class="ui-g-12 no-padding">
	<app-file-progress
		*ngIf="progressVisible"
		[message]="progressMessage"
		[mode]="progressMode">
	</app-file-progress>

	<div *ngIf="removeFormVisible"
		class="ui-g-12 no-padding">
		<div class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				Delete: {{fileEntity.data.name}}
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
						<i
							class="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							[ngClass]="{
								'selected-bottom-border': (action.id === 'remove')
							}"
							(click)="action.command($event)"
							pTooltip="{{action.label}}"
							tooltipPosition="left"
							tooltipStyleClass="utility-menu-tooltip">
						</i>
				</ng-template>
			</span>
		</div>

		<p-scrollPanel styleClass="file-container">
			<div class="ui-g-12 no-padding">
				<div class="ui-g-12 delete-confirmation">
					Are you sure you want to delete this file?
				</div>

				<div class="ui-g-12 no-vertical-padding">
					<strong>
						Reason
					</strong>
					<textarea class="reason-text"
						name="reason"
						pInputTextarea
						[formControl]="reasonControl">
					</textarea>
				</div>
				<div *ngIf="reasonControl.invalid"
					class="ui-g-12 no-padding validation-message-container">
					<div
						class="ui-g-12 no-vertical-padding validation-message theme-color-red">
						No more than 1024 characters allowed.
					</div>
				</div>
			</div>
		</p-scrollPanel>

		<div class="ui-g-12 no-padding text-right button-set">
			<a class="text-link theme-color secondary-action-button"
				(click)="changeDisplayMode.emit('list')">
				Cancel
			</a>
			<button
				pButton
				label="Confirm Delete"
				class="background-color-red no-margin-right"
				(click)="removeClicked()"
				[disabled]="reasonControl.invalid">
			</button>
		</div>
	</div>
</div>