<app-summary-card
	[chartDefinition]="context.data.chartDefinition"
	[groupByCount]="context.data.chartDefinition?.groupByCount || context.data.groupByCount"
	[summaryTemplate]="context.data.summaryTemplate"
	[summaryPromise]="context.data.summaryPromise"
	[summaryFunction]="context.data.chartDefinition?.chartSummaryFunction || context.data.summaryFunction"
	[titleTemplate]="context.data.titleTemplate"
	[overlayDynamicComponent]="context.data.overlayDynamicComponent"
	[overlayDynamicContext]="context.data.overlayDynamicContext"
	[overlayMenuActive]="context.source['expansionActive'] === true"
	[dataPromise]="context.data.chartDefinition?.dataPromise || context.data.dataPromise">
</app-summary-card>