<div [id]="entityTopElementIdentifier">
</div>

<app-base-page [loading]="loading"
	[pageContext]="pageContext"
	[loadingPageTitle]="entityTypeGroupDisplay"
	[pageTitle]="entityIdentifier"
	operationGroupDisplayName="actions"
	[operationGroupName]="operationGroupName"
	[pageContextOperationGroupName]="pageContextOperationGroupName"
	[utilityMenuOperationGroupName]="utilityMenuOperationGroupName"
	[activeDrawerComponent]="activeDrawerComponent"
	[activeDrawerItemDescription]="activeDrawerItemDescription"
	[operationGroupRedraw]="operationGroupRedraw">

	<div #AdditionalHeaderContent
		additional-header-content
		*ngIf="informationMenuItems.length > 0">
		<div *ngIf="!loading"
			[@contentAnimation]="state">
			<app-information-menu
				[initialLoadComplete]="informationMenuItemsLoaded"
				[informationMenuItems]="informationMenuItems">
			</app-information-menu>
		</div>
	</div>

	<div class="entity-header"
		#HeaderContextContent
		header-context-content
		*ngIf="!loading
			&& (tabItems != null && tabItems.length > 1
				|| sectionItems != null && sectionItems.length > 1)">
		<div class="multi-level-tab-shadow ui-g-12 no-padding"
			*ngIf="tabItems != null && tabItems.length > 1 && displayNavigationDropdown === false"
			[ngClass]="{
				'information-menu-offset' : (informationMenuItems?.length > 0)
			}">
		</div>
		<div [@contentAnimation]="state">
			<ng-template [ngIf]="displayNavigationDropdown === true"
				[ngIfElse]="BasePageTabMenuContainer">
				<div class="button-menu-navigation">
					<operation-button-menu
						[operationGroup]="buttonMenuNavigationGroup">
					</operation-button-menu>
				</div>
			</ng-template>
			<ng-template #BasePageTabMenuContainer>
				<div #BasePageTabMenu
					class="tabmenu-container">
					<app-base-page-tab-menu
						[sectionItems]="sectionItems"
						[tabItems]="tabItems"
						[activeSectionItem]="activeSectionItem"
						[activeTabItem]="activeTabItem"
						(tabSelectedEvent)="tabSelected($event)">
					</app-base-page-tab-menu>
				</div>
			</ng-template>
		</div>
	</div>

	<div>
		<div class="entity-panel">
			<div *ngIf="loading"
				class="text-center"
				[@contentAnimation]="state">
				<br/>
				<span class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
				</span>
				<br/>
				<br/>
			</div>

			<div *ngIf="!loading"
				[@contentAnimation]="state">
				<div class="ui-g entity-content">
					<entity-form
						[entityType]="entityType"
						[entityVersion]="entityVersion"
						[entityTypeGroup]="entityTypeGroup"
						[entityIdentifier]="entityIdentifier"
						[formlyOptions]="formlyOptions"
						[entityLayout]="entityLayout"
						[entityInstance]="entityInstance"
						[entityDefinition]="entityDefinition"
						[pageContext]="pageContext"
						[formlyEntityLayout]="formlyEntityLayout"
						[entityInstanceRuleViolations]="entityInstanceRuleViolations"
						[databaseEntityInstance]="databaseEntityInstance"
						(componentInstance)="updateFormInstance($event)">
					</entity-form>
				</div>
			</div>
		</div>
	</div>

	<div class="entity-layout-mask">
		<p-progressBar mode="indeterminate">
		</p-progressBar>
	</div>

</app-base-page>
