/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component
} from '@angular/core';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDropdownOption
} from '@shared/interfaces/application-objects/dropdown-option.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	StatusReasonsDirective
} from '@insurance/directives/status-reasons.directive';

@Component({
	selector: 'adjustment-write-off-type',
	templateUrl: './adjustment-write-off-type.component.html'
})

/**
 * A component representing a wizard step to select a type and a note for
 * a term based adjustment write off.
 *
 * @export
 * @class AdjustmentWriteOffTypeComponent
 * @extends {StatusReasonsDirective}
 */
export class AdjustmentWriteOffTypeComponent
	extends StatusReasonsDirective
{
	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AdjustmentWriteOffTypeComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[] =
		<FormlyFieldConfig[]>
		[
			{
				key: 'data.type',
				type: FormlyConstants.customControls.customSelect,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Write Off Type',
					required: true,
					options:
						<IDropdownOption[]>
						[
							{
								label:
									InsuranceConstants.accountingTypes.premium,
								value:
									InsuranceConstants.accountingTypes.premium,
							},
							{
								label:
									InsuranceConstants.accountingTypes.fee,
								value:
									InsuranceConstants.accountingTypes.fee,
							},
							{
								label:
									InsuranceConstants.accountingTypes.tax,
								value:
									InsuranceConstants.accountingTypes.tax,
							}
						]
				}
			},
			{
				key: 'data.comments',
				type: FormlyConstants.customControls.customTextArea,
				wrappers: [
					FormlyConstants.customControls.customFieldWrapper
				],
				templateOptions: {
					label: 'Comments',
					required: true,
					rows: FormlyConstants.textAreaRowSizes.small
				}
			}
		];

	/**
	 * Allows additional actions following the shared status reasons directive
	 * on init.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffTypeComponent
	 */
	public async performPostInitActions(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		this.context.source.addOrUpdateStepData(
			{
				type: currentData.type
					|| InsuranceConstants.accountingTypes.premium,
				comments: currentData.comments
					|| AppConstants.empty
			});

		this.context.source.wizardStepLoading = false;
	}
}