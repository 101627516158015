/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';
import {
	DynamicFormlyComponent
} from '@shared/components/dynamic-formly/dynamic-formly.component';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	MouseEventConstants
} from '@shared/constants/mouse-event.constants';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-search-filter',
	templateUrl: './search-filter.component.html',
	styleUrls: ['./search-filter.component.scss']
})

/**
 * A component representing an instance of the session component.
 *
 * @export
 * @class SearchFilterComponent
 * @implements {OnInit}
 */
export class SearchFilterComponent
implements OnInit
{
	/**
	 * Initializes a new instance of the search filter component.
	 *
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service.
	 * @memberof SiteSettingsComponent
	 */
	public constructor(
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the dynamic formly component.
	 *
	 * @type {DynamicFormlyComponent}
	 * @memberof SearchFilterComponent
	 */
	@ViewChild('DynamicFormlyComponent')
	public dynamicFormlyComponent: DynamicFormlyComponent;

	/**
	 * Gets or sets the search available options.
	 *
	 * @type {object[]}
	 * @memberof SearchFilterComponent
	 */
	@Input() public searchOptions: object[];

	/**
	 * Gets or sets the loaded category.
	 *
	 * @type {string}
	 * @memberof SearchFilterComponent
	 */
	@Input() public loadedCategoryOption: string;

	/**
	 * Gets or sets the loaded filter criteria.
	 *
	 * @type {string}
	 * @memberof SearchFilterComponent
	 */
	@Input() public loadedFilterCriteria: string;

	/**
	 * Whether to cented the search filter container or not.
	 *
	 * @type {boolean}
	 * @memberof SearchFilterComponent
	 */
	@Input() public alignCentered?: boolean = false;

	/**
	 * Emits an event with the updated filter criteria.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof SearchFilterComponent
	 */
	@Output() public filterCriteriaChanged: EventEmitter<string> =
		new EventEmitter();

	/**
	 * Emits an event with the updated category value.
	 *
	 * @type {EventEmitter<string>}
	 * @memberof SearchFilterComponent
	 */
	@Output() public categoryChanged: EventEmitter<string> =
		new EventEmitter();

	/**
	 * Gets or sets the tooltip element reference.
	 *
	 * @type {ElementRef}
	 * @memberof SearchFilterComponent
	 */
	@ViewChild('Tooltip')
	public tooltip: ElementRef;

	/**
	 * Gets or sets the filter value used when filtering the data.
	 *
	 * @type {string}
	 * @memberof SearchFilterComponent
	 */
	public filter: string = AppConstants.empty;

	/**
	 * Gets or sets the data schema object to be used in the
	 * dynamic formly form fields.
	 *
	 * @type {object}
	 * @memberof SearchFilterComponent
	 */
	public dataSet: object;

	/**
	 * Gets or sets the layout schema object to be used in the
	 * dynamic formly form fields.
	 *
	 * @type {object}
	 * @memberof SearchFilterComponent
	 */
	public layoutSchema: FormlyFieldConfig[];

	/**
	 * Implements the on initialization interface.
	 * This will lset the layout schema definition.
	 *
	 * @async
	 * @memberof SearchFilterComponent
	*/
	public async ngOnInit(): Promise<void>
	{
		this.filter = this.loadedFilterCriteria;

		this.setDynamicFormlyDefinitions();
	}

	/**
	 * This will handle the tap mobile only event on the tooltip icon
	 * and toggle the display of the tooltip.
	 *
	 * @memberof SearchFilterComponent
	 */
	public mobileTooltipToggle(): void
	{
		this.tooltip.nativeElement.dispatchEvent(
			new Event(MouseEventConstants.mouseEnter));
	}

	/**
	 * This method will remove the auto focus click event attached to
	 * primeNg tooltips.
	 *
	 * @param {MouseEvent} event
	 * The click event to be captured and halted.
	 * @memberof SearchFilterComponent
	 */
	public preventDefault(
		event: MouseEvent): void
	{
		event.preventDefault();
		event.stopImmediatePropagation();
	}

	/**
	 * Sorts the search options alphabetically
	 * based on the label value.
	 *
	 * @memberof SearchFilterComponent
	 */
	public sortSearchOptions(): void
	{
		this.searchOptions.sort((
			searchOptionOne: any,
			searchOptionTwo: any) =>
			ObjectHelper.sortByPropertyValue(
				searchOptionOne,
				searchOptionTwo,
				AppConstants.commonProperties.label));
	}

	/**
	 * Searches for the input criteria value from the
	 * dynamic form search field.
	 *
	 * @memberof SearchFilterComponent
	*/
	public searchCriteria(): void
	{
		const searchValue: string =
			this.dynamicFormlyComponent.layoutSchema[1].formControl.value;

		this.filter = searchValue;
		this.loadedFilterCriteria = searchValue;
		this.filterCriteriaChanged.emit(searchValue);
	}

	/**
	 * Defines the dynamic formly properties.
	 *
	 * @memberof SearchFilterComponent
	*/
	private setDynamicFormlyDefinitions(): void
	{
		this.sortSearchOptions();
		this.layoutSchema =
			<FormlyFieldConfig[]>
			[
				{
					key: 'data.search',
					type: FormlyConstants.customControls.customSelect,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						placeholder: 'Select a Category',
						showClear: true,
						disabled: this.searchOptions.length < 2,
						options: this.searchOptions,
						change: (field: FormlyFieldConfig) =>
						{
							if (field.formControl.value !==
								AppConstants.undefined
								&& field.formControl.value !==
									this.loadedCategoryOption)
							{
								this.categoryChanged.emit(
									field.formControl.value);
								this.layoutSchema[1]
									.templateOptions.disabled = false;

								setTimeout(
									() =>
									{
										document.getElementById(
											AppConstants.commonTableActions
												.filterInput)
											.focus();
									});
							}

							if (AnyHelper.isNull(field.formControl.value))
							{
								this.layoutSchema[1]
									.templateOptions.disabled = true;
							}
						},
						attributes: {
							label: 'searchCategories'
						}
					}
				},
				{
					key: 'data.filter',
					type: FormlyConstants.customControls.input,
					id: AppConstants.commonTableActions.filterInput,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						disabled: AnyHelper
							.isNullOrEmpty(this.loadedCategoryOption),
						placeholder: 'Filter',
						attributes: {
							title: AppConstants.commonTableActions.filterInput
						},
						keypress:
							(_field: FormlyFieldConfig,
								event: KeyboardEvent) =>
							{
								if (event.key ===
									AppConstants.keyBoardKeyConstants.enter)
								{
									this.searchCriteria();
								}
							}
					}
				}
			];

		this.dataSet = {
			data: {
				filter: this.loadedFilterCriteria,
				search: this.loadedCategoryOption
			}
		};
	}
}