<app-base-page pageTitle="Search Policies"
	[pageContext]="pageContext"
	utilityMenuOperationGroupName="">

	<div class="search-filter-container"
		*ngIf="!loadingTransactionTypes">
		<div class="ui-g-6 search-filter"
			[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
			<div>
				<app-dynamic-formly
					#DynamicFormlyComponent
					[dataSet]="dataSet"
					[layoutSchema]="dynamicFormly"
					(validityChanged)="formValidityChange($event)">
				</app-dynamic-formly>
			</div>
			<button type="button"
				pButton
				icon="fa fa-search"
				styleClass="p-button-primary"
				[disabled]="!this.validForm"
				(click)="search()">
			</button>
			<div class="search-tooltip-container">
				<i #Tooltip
					pTooltip="<div>Search terms entered here will filter down to only the items that hold all of the search terms.<ul><li>In order to search for dates, please use the following format 'YYYY-MM-DD'.</li><li>In order to search for numerical values, please enter the number without commas or format characters.</li></ul></div>"
					tooltipPosition="left"
					tooltipStyleClass="label-tooltip"
					class="fa fa-info-circle theme-color label-info-icon"
					[escape]="false"
					(click)="preventDefault($event)"
					(tap)="mobileTooltipToggle()">
				</i>
			</div>
		</div>
	</div>

	<div class="ui-g">
		<div *ngIf="loadingTableDefinitions"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

		<div class="ui-g-12 table-container"
			*ngIf="!loadingTableDefinitions"
			[@contentAnimation]="state">
			<app-common-table
				[tableDefinitions]="policySearchTableDefinitions">
			</app-common-table>
		</div>
	</div>

</app-base-page>