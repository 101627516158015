<div class="ui-g-12 no-padding"
	*ngIf="context.source.activeMenuItem.currentData.data.availableReasons != null">
	<p-pickList
		sourceHeader="Available"
		targetHeader="Selected"
		[source]="context.source.activeMenuItem.currentData.data.availableReasons"
		[target]="context.source.activeMenuItem.currentData.data.selectedReasons"
		[dragdrop]="false"
		[responsive]="true"
		[sourceStyle]="{'height':'30rem'}"
		[targetStyle]="{'height':'30rem'}"
		[showSourceControls]="false"
		[showTargetControls]="false"
		filterBy="name,detailedDescription"
		sourceFilterPlaceholder="Filter"
		targetFilterPlaceholder="Filter"
		(onMoveToTarget)="validityChanged()"
		(onMoveToSource)="validityChanged()">
		<ng-template let-reason pTemplate="item">
			<div class="reason-item">
				<div
					*ngIf="detailedView === false"
					class="reason-list-detail">
					<span class="panel-sub-title reason-title"
						[ngClass]="{
							'reason-list-item-primary-text': (reason.name == null)
						}">
						{{reason.name || reason.description}}
					</span>
					<br/>
					<span class="reason-description"
						*ngIf="reason.name != null">
						{{reason.description}}
					</span>
				</div>
				<div
					*ngIf="detailedView === true && reason.type === 'Rejection'"
					class="reason-list-detail">
					<span class="panel-sub-title reason-title"
						[ngClass]="{
							'reason-list-item-primary-text': (reason.name == null)
						}">
						{{reason.name || reason.description}}
					</span>
					<br/>
					<span class="reason-description"
						*ngIf="reason.name != null">
						{{reason.detailedDescription}}
					</span>
				</div>
			</div>
		</ng-template>
	</p-pickList>
</div>
<div class="ui-g-12 no-horizontal-padding dynamic-form-content">
	<app-dynamic-formly
		[dataSet]="context.source.activeMenuItem.currentData"
		[layoutSchema]="dynamicFormlyLayout"
		[context]="context"
		(validityChanged)="validityChanged($event)">
	</app-dynamic-formly>
</div>