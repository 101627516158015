/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	Component
} from '@angular/core';
import {
	FieldWrapper
} from '@ngx-formly/core';

@Component({
	selector: 'custom-empty-wrapper',
	templateUrl: './custom-empty-wrapper.component.html',
	styleUrls: [
		'./custom-empty-wrapper.component.scss'
	]
})

/**
 * A component representing an instance of a Custom Empty Wrapper.
 *
 * @export
 * @class CustomEmptyWrapperComponent
 * @extends {FieldWrapper}
 */
export class CustomEmptyWrapperComponent
	extends FieldWrapper
{
}