<div class="ui-g-12 access-denied-content">

	<div class="ui-g-12 no-vertical-padding panel-title-container">
		<span class="panel-title">
			{{drawerDisplay === true ? 'No Access' : 'Summary'}}
			<i class="pi pi-copy theme-color cursor-pointer"
				pTooltip="Copy details"
				tooltipStyleClass="label-tooltip"
				(click)="copyAccessDeniedToClipboard()">
			</i>
		</span>
	</div>

	<div class="ui-g-12">
		<div class="panel-sub-title">
			Details
		</div>
		<div>
			You may not have sufficient privileges to access this {{drawerDisplay === true ? 'drawer' : 'page'}}. If the problem persists, please contact support.
		</div>
	</div>

	<div class="ui-g-12">
		<div class="panel-sub-title">
			Session
		</div>
		<div class="ellipsis-text">
			{{sessionIdentifier}}
		</div>
	</div>

	<div class="ui-g-12">
		<div class="panel-sub-title">
			Url
		</div>
		<div class="ellipsis-text">
			{{accessDeniedUrl}}
		</div>
	</div>

	<div class="ui-g-12">
		<div class="panel-sub-title">
			Resources
		</div>
		<div>
			<ul class="resource-list">
				<li *ngFor="let resource of resources">
					<div class="ellipsis-text">
						{{resource}}
					</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="ui-g-12"
		*ngIf="clientMessage != ''">
		<div class="panel-sub-title">
			Message
		</div>
		<div>
			{{clientMessage}}
		</div>
	</div>
</div>