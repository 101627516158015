<div class="ui-g-12 no-vertical-padding">
	<div class="ui-g-6 content-section"
		[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
		<p-card>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					{{userLabel || 'User'}}
				</strong>
				{{userName || 'loading...'}}
			</div>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					Application
				</strong>
				{{application || 'loading...'}}
			</div>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					Token
				</strong>
				{{token}}
			</div>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					Create Date
				</strong>
				{{createDate}}
			</div>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					Last Active Date
				</strong>
				{{lastActiveDate}}
			</div>
			<div class="information-section ellipsis-text">
				<strong class="information-header">
					Expire Date
				</strong>
				{{expireDate}}
			</div>
		</p-card>
	</div>

	<div class="ui-g-6 no-horizontal-padding graph-container"
		[ngClass]="{'ui-g-12': siteLayoutService.displayTabletView}">
		<div class="ui-g-6 information-menu-item no-padding"
			*ngFor="let item of summaryCardItems">
			<app-summary-card
				[chartDefinition]="item.chartDefinition"
				[groupByCount]="item.chartDefinition?.groupByCount || item.groupByCount"
				[summaryTemplate]="item.summaryTemplate"
				[titleTemplate]="item.titleTemplate"
				[summaryCardDisplay]="item.summaryCardDisplay"
				[squareCardDisplay]="item.squareCardDisplay"
				[dataPromise]="item.chartDefinition?.dataPromise || item.dataPromise">
			</app-summary-card>
		</div>
	</div>

	<div *ngIf="loadingTableDefinition"
			class="text-center ui-g-12">
			<span class="fa fa-fw fa-spin fa-spinner theme-color double-font-size">
			</span>
		</div>

	<div *ngIf="!loadingTableDefinition"
		class="ui-g-12 no-padding table-container">
		<div>
			<app-common-table
				[tableDefinitions]="securitySessionEventTableDefinitions">
			</app-common-table>
		</div>
	</div>

	<div class="ui-g-12 no-horizontal-padding">
		<operation-button-bar
			[initialModel]="context.source.expandActions"
			(loadingOperations)="context.source.operationButtonClicked(context.source.rowData)">
		</operation-button-bar>
	</div>
</div>
