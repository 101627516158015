/* !
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AbstractControl,
	FormControl
} from '@angular/forms';
import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	Input,
	OnInit
} from '@angular/core';
import {
	DynamicWizardComponent
} from '@dynamicComponents/dynamic-wizard/dynamic-wizard.component';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';
import {
	InsuranceHelper
} from '@insurance/helpers/insurance.helper';
import {
	InsuranceService
} from '@insurance/services/insurance.service';
import {
	IWizardContext
} from '@shared/interfaces/dynamic-interfaces/wizard-context.interface';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	ObjectHelper
} from '@shared/helpers/object.helper';
import {
	Router
} from '@angular/router';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

// tslint:enable: max-line-length

@Component({
	selector: 'adjustment-write-off-amount',
	templateUrl: './adjustment-write-off-amount.component.html',
	styleUrls: [
		'./adjustment-write-off-amount.component.scss'
	]
})

/**
 * A component representing a wizard step to display the payment details and
 * collect an amount for a policy term write off.
 *
 * @export
 * @class AdjustmentWriteOffAmountComponent
 * @implements {OnInit}
 * @implements {IDynamicComponent<DynamicWizardComponent, IWizardContext>}
 */
export class AdjustmentWriteOffAmountComponent
implements OnInit, IDynamicComponent<DynamicWizardComponent, IWizardContext>
{
	/**
	 * Initializes an instance of the adjustment write off component.
	 *
	 * @param {Router} router
	 * The router used for navigation and url query parameter storage.
	 * @param {ActivityService} activityService
	 * The activity message service used to notify the user.
	 * @param {ModuleService} moduleService
	 * The module service used to set module changes.
	 * @param {InsuranceService} insuranceService
	 * The insurance service used to lookup insurance modules.
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance api service used in this component.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public constructor(
		public router: Router,
		public activityService: ActivityService,
		public moduleService: ModuleService,
		public insuranceService: InsuranceService,
		public entityInstanceApiService: EntityInstanceApiService,
		public siteLayoutService: SiteLayoutService)
	{
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<
	 * 	DynamicWizardComponent,
	 * 	IWizardContext>}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	@Input() public context: IDynamicComponentContext<
		DynamicWizardComponent,
		IWizardContext>;

	/**
	 * Gets or sets the formly layout used in implementing components.
	 *
	 * @type {FormlyFieldConfig[]}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public dynamicFormlyLayout: FormlyFieldConfig[];

	/**
	 * Gets or sets the PolicyTerm entity instance associated to the current
	 * data.
	 *
	 * @type {IEntityInstance}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public policyTermEntityInstance: IEntityInstance;

	/**
	 * Gets or sets the ledger entity instance associated to the current
	 * policy term.
	 *
	 * @type {IEntityInstance}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public ledgerEntityInstance: IEntityInstance;

	/**
	 * Gets the workflow action being executed to update the PolicyTerm
	 * data.
	 *
	 * @type {string}
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private readonly workflowActionName: string =
		'WriteOffCreateLedgerTransaction';

	/**
	 * Implements the on initialization interface.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const policyTermId: number = currentData.id;

		this.policyTermEntityInstance =
			await this.insuranceService.getPolicyTerm(
				policyTermId);

		this.ledgerEntityInstance =
			await this.insuranceService.getLedger(
				policyTermId);

		const ledgerTransactionInstances: IEntityInstance[] =
			await this.insuranceService.getLedgerTransactions(
				this.ledgerEntityInstance.id);

		const priorTermTransfer: number =
			InsuranceHelper.getPriorTermTransferBalance(
				ledgerTransactionInstances);

		const policyTermWrittenOffBalance: number =
			InsuranceHelper.getPolicyTermWrittenOffBalance(
				ledgerTransactionInstances);

		const payments: number =
			InsuranceHelper.getPaymentsBalance(
				ledgerTransactionInstances);

		let accountingData: any;
		let paymentDetailsData: any;
		const minimumDue: number = 0;

		if (this.policyTermEntityInstance.data.status ===
			InsuranceConstants.policyTermStatusTypes.active)
		{
			accountingData =
				this.policyTermEntityInstance.data.accounting;

			paymentDetailsData =
				{
					premium: accountingData.writtenPremium,
					fees: accountingData.writtenFees,
					taxes: accountingData.writtenTaxes,
					total: accountingData.writtenTotal,
					priorTermTransfer: priorTermTransfer,
					policyTermWrittenOffBalance: policyTermWrittenOffBalance,
					payments: payments,
					remainingBalance:
						accountingData.writtenTotal
							+ priorTermTransfer
							+ payments
							+ policyTermWrittenOffBalance,
					minimumDue: minimumDue
				};
		}
		else
		{
			const policyTermTransactionInstance: IEntityInstance =
				await this.insuranceService
					.getLatestPolicyTermTransactionByPolicyTerm(
						this.policyTermEntityInstance.id);

			accountingData =
				policyTermTransactionInstance.data.accounting;

			paymentDetailsData =
				{
					premium: accountingData.directWrittenPremium,
					fees: accountingData.directWrittenFees,
					taxes: accountingData.directWrittenTaxes,
					total: accountingData.directWrittenTotal,
					priorTermTransfer: priorTermTransfer,
					policyTermWrittenOffBalance: policyTermWrittenOffBalance,
					payments: payments,
					remainingBalance:
						accountingData.directWrittenTotal
							+ priorTermTransfer
							+ payments
							+ policyTermWrittenOffBalance,
					minimumDue: minimumDue
				};
		}

		this.context.source.addOrUpdateStepData(
			{
				newBalance: paymentDetailsData.remainingBalance,
				paymentDetails: paymentDetailsData,
				amount: currentData.amount
			});

		await this.performPostInitActions();

		this.context.source.addToNext(this.writeOff.bind(this));

		this.context.source.wizardStepLoading = false;
	}

	/**
	 * Handles the validity changed event sent from the child dynamic
	 * formly component. This will update the validity of the form for
	 * action buttons.
	 *
	 * @async
	 * @param {boolean} isValid
	 * The validity of the current displayed step data set.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public async validityChanged(
		isValid: boolean): Promise<void>
	{
		this.context.source.validStepChanged(isValid);
	}

	/**
	 * This will send the make payment event and navigate to the new
	 * entity.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	public async writeOff(): Promise<void>
	{
		const currentData: any =
			this.context.source.activeMenuItem.currentData.data;

		const queryString: string =
			`?writeOffType=${currentData.type}`
				+ `&amount=${currentData.amount}`
				+ `&comments=${currentData.comments}`;

		await this.writeOffExecution(
			this.policyTermEntityInstance,
			queryString);

		this.navigateToPolicyTermDashboard(
			this.policyTermEntityInstance.id);
	}

	/**
	 * Handles the post initialization action.
	 * This will create the dynamic formly layout for display component creation
	 * and permissions.
	 *
	 * @async
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private async performPostInitActions(): Promise<void>
	{
		this.dynamicFormlyLayout =
			<FormlyFieldConfig[]>
			[
				{
					key: 'amount',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'Write Off Amount',
						useCurrency: true,
						change:
							(field: FormlyFieldConfig,
								_event: any) =>
							{
								const newBalance: number =
									this.context.source
										.activeMenuItem.currentData.data
										.paymentDetails.remainingBalance
										- (field.formControl.value || 0);

								this.dynamicFormlyLayout[1]
									.formControl.setValue(newBalance);
							}
					},
					validators: {
						positivePaymentAmount: {
							expression: ((
								control: FormControl,
								field: FormlyFieldConfig) =>
								this.positivePaymentAmountValidator(
									control,
									field)),
							message:
								'A value above zero dollars must be written off.'
						},
						allowedTotalWritten: {
							expression: ((
								control: FormControl,
								field: FormlyFieldConfig) =>
								this.allowedTotalRemainingBalanceValidator(
									control,
									field)),
							message:
								'A value less than the total remaining balance is'
									+ ' required.'
						}
					}
				},
				{
					key: 'newBalance',
					type: FormlyConstants.customControls.customInputNumber,
					wrappers: [
						FormlyConstants.customControls.customFieldWrapper
					],
					templateOptions: {
						label: 'New Balance',
						disabled: true,
						useCurrency: true
					}
				}
			];
	}

	/**
	 * Validates the payment amount is positive
	 *
	 * @param {AbstractControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} _field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private positivePaymentAmountValidator(
		control: AbstractControl,
		_field: FormlyFieldConfig): boolean
	{
		return !AnyHelper.isNullOrWhitespace(control.value)
			&& control.value > 0;
	}

	/**
	 * Validates the payment amount is less than the total remaining balance.
	 *
	 * @param {AbstractControl} control
	 * The form control to get the input value.
	 * @param {FormlyFieldConfig} _field
	 * The formly field configuration.
	 * @returns {boolean}
	 * The validation passed or failed.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private allowedTotalRemainingBalanceValidator(
		control: AbstractControl,
		_field: FormlyFieldConfig): boolean
	{
		const paymentDetails: any =
			this.context.source
				.activeMenuItem.currentData.data
				.paymentDetails;

		return control.value <= paymentDetails.remainingBalance;
	}

	/**
	 * Executes the make payment process, which executes the MakePayment
	 * workflow service with the collected data from the make payment wizard,
	 * then creates the payment information.
	 *
	 * @async
	 * @param {IEntityInstance} policyTermInstanceData
	 * The PolicyTerm entity instance data to be updated.
	 * @param {string} queryString
	 * The extra parameters sent as query string parameters.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private async writeOffExecution(
		policyTermInstanceData: IEntityInstance,
		queryString: string): Promise<void>
	{
		setTimeout(
			() =>
			{
				this.context.source.wizardStepLoading = true;
			});

		await this.activityService.handleActivity(
			new Activity(
				new Promise(async(resolve: any, _reject: any) =>
				{
					this.entityInstanceApiService.entityInstanceTypeGroup =
						InsuranceConstants.insuranceEntityTypeGroups.policyTerms;

					await this.entityInstanceApiService
						.executeAction(
							policyTermInstanceData.id,
							this.workflowActionName,
							policyTermInstanceData,
							queryString);

					resolve();
				}),
				'<strong>Processing write off</strong>',
				'<strong>Write off processed</strong>',
				'A write off has been applied to the policy.',
				'A write off has not been applied to the policy.'));
	}

	/**
	 * This will navigate to the policy term dashboard using the policy term id
	 * provided.
	 *
	 * @param {number} entityId
	 * The policy term entity id to navigate to.
	 * @memberof AdjustmentWriteOffAmountComponent
	 */
	private navigateToPolicyTermDashboard(
		entityId: number): void
	{
		this.context.source.addOrUpdateStepData(
			<object>
			{
				automateVerify: false
			});

		this.router.navigate(
			[
				`${this.moduleService.name}/entities`,
				InsuranceConstants.insuranceEntityTypeGroups.policyTerms,
				AppConstants.viewTypes.edit,
				entityId
			],
			{
				queryParams: {
					routeData:
						ObjectHelper.mapRouteData(
							{
								layoutType:
									AppConstants.layoutTypes.full
							})
				}
			});
	}
}