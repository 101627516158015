<label class="visually-hidden"
	[attr.for]="'AriaInput' + id">
	{{to.label}}
</label>

<ng-template
	[ngIf]="to.actionMenuItems == null && to.addonMenuItems == null"
	[ngIfElse]="InputGroup">
	<p-dropdown
		[options]="dataOptions"
		[formControl]="formControl"
		[formlyAttributes]="field"
		[styleClass]="inputStyleClass"
		[placeholder]="to.placeholder"
		[ariaLabelledBy]="'Filter ' + to.label"
		[selectId]="'AriaInput' + id"
		[appendTo]="to.appendTo"
		[showClear]="to.showClear"
		[group]="to.group"
		[emptyFilterMessage]="to.emptyFilterMessage || 'No results found'"
		filter="true"
		filterPlaceholder="Filter"
		(onChange)="change($event)"
		(onFilter)="onFilter($event)">
	</p-dropdown>
</ng-template>

<ng-template #InputGroup>
	<div class="p-grid p-fluid">
		<div class="p-inputgroup">
			<span *ngFor="let addonMenuItem of to.addonMenuItems"
				class="p-inputgroup-addon">
				<i [class]="addonMenuItem.getCustomIcon(formControl.value)"
					[style]="addonMenuItem.getCustomStyle(formControl.value)">
				</i>
			</span>
			<span class="dropdown-group">
				<p-dropdown
					[options]="dataOptions"
					[formControl]="formControl"
					[formlyAttributes]="field"
					[styleClass]="inputStyleClass"
					[placeholder]="to.placeholder"
					[ariaLabelledBy]="'Filter ' + to.label"
					[selectId]="'AriaInput' + id"
					[appendTo]="to.appendTo"
					[showClear]="to.showClear"
					[group]="to.group"
					[emptyFilterMessage]="to.emptyFilterMessage || 'No results found'"
					filter="true"
					filterPlaceholder="Filter"
					(onChange)="change($event)"
					(onFilter)="onFilter($event)">
				</p-dropdown>
			</span>
			<span *ngFor="let actionMenuItem of to.actionMenuItems">
				<button
					type="button"
					pButton pRipple
					[icon]="actionMenuItem.icon"
					styleClass="p-button-warn"
					[disabled]="actionMenuItem.disabled"
					(click)="actionMenuItem.command()">
				</button>
			</span>
		</div>
	</div>
</ng-template>

<field-validation-message
	[field]="field">
</field-validation-message>

