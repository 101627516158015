/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot
} from '@angular/router';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CacheService
} from '@shared/services/cache.service';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	Injectable
} from '@angular/core';
import {
	ModuleService
} from '@shared/services/module.service';
import {
	OperationGroupApiService
} from '@api/services/operations/operation-group.api.service';
import {
	StringHelper
} from '@shared/helpers/string.helper';

/**
 * A class representing an instance of a module guard.
 * This is used to ensure that the module based route prefix
 * accurately points to a defined application module.
 *
 * @export
 * @class ModuleGuard
 * @implements {CanActivate}
 */
@Injectable({
	providedIn: 'root'
})
export class ModuleGuard
implements CanActivate
{
	/**
	 * Initializes a new instance of the ModuleGuard class.
	 *
	 * @param {Router} router
	 * The router used for site navigation.
	 * @param {ModuleService} moduleService
	 * The module service used for this guard.
	 * @param {OperationGroupApiService} operationGroupApiService
	 * The operation group api service used to confirm that a matching
	 * module has been defined in data storage.
	 * @param {CacheService} cacheService
	 * The cache service used for this guard.
	 * @memberof ModuleGuard
	 */
	public constructor(
		private readonly router: Router,
		private readonly moduleService: ModuleService,
		private readonly operationGroupApiService: OperationGroupApiService,
		private readonly cacheService: CacheService)
	{
	}

	/**
	 * Implements the can activate interface for this guard.
	 * This will accurately define if the url entered first segment
	 * for a module is defined in our system. If this is not found
	 * a message will be displayed to the user that the url is impoperly
	 * formed and route them to the dashboard.
	 *
	 * @param {ActivatedRouteSnapshot} next
	 * The expected next value for the activated route.
	 * @param {RouterStateSnapshot} _state
	 * The expected current value for the activated route.
	 * @returns {Promise<boolean>}
	 * A truthy defining if this guard will allow activation of the sent
	 * url based navigation.
	 * @memberof ModuleGuard
	 */
	public async canActivate(
		next: ActivatedRouteSnapshot,
		_state: RouterStateSnapshot): Promise<boolean>
	{
		this.cacheService.clearAllSubscriptions();

		if (AnyHelper.isNull(next.url[0]))
		{
			this.router.navigate([AppConstants.route.dashboardPage]);

			return Promise.resolve(false);
		}

		this.moduleService.name =
				StringHelper.toProperCase(next.url[0].path);

		return new Promise(
			async(resolve) =>
			{
				const filter: string =
					`Name eq '${this.moduleService.getContextMenuName()}'`;

				const matchingOperationGroups =
					await this.operationGroupApiService
						.aggregate(
							AppConstants.aggregateMethods.count,
							null,
							filter);

				if (matchingOperationGroups[0].value === 0)
				{
					EventHelper.dispatchBannerEvent(
						'Invalid Route Entered',
						`'${this.moduleService.name}' is not a valid module. `
							+ 'Redirecting to the dashboard.',
						AppConstants.activityStatus.error);

					this.router.navigate([AppConstants.route.dashboardPage]);
				}

				resolve(matchingOperationGroups[0].value > 0);
			});
	}
}