/**
 * @copyright WaterStreet. All rights reserved.
 */

import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	InsuranceConstants
} from '@insurance/constants/insurance-constants';

/**
 * A class containing static helper methods for insurance entities.
 *
 * @export
 * @class InsuranceHelper
 */
export class InsuranceHelper
{
	/**
	 * Gets or sets the set of commonly excluded transaction status values.
	 *
	 * @static
	 * @type {string[]}
	 * @memberof InsuranceHelper
	 */
	public static readonly transactionExcludedStatusValues: string[] =
		[
			InsuranceConstants.transactionStatusTypes.archived,
			InsuranceConstants.transactionStatusTypes.declined,
			InsuranceConstants.transactionStatusTypes.issued,
			InsuranceConstants.transactionStatusTypes.obsolete
		];

	/**
	 * Confirms that the status of the policy term transaction exists in the
	 * sent included status values and that this status value does not exist
	 * in the sent excluded status values.
	 *
	 * @static
	 * @param {string} status
	 * The policy term transaction status to be checked.
	 * @param {string[]} includedStatusValues
	 * If sent, this will be the set of possible status values that should
	 * return as a true conditional if it contains the sent status. This value
	 * defaults to an empty array.
	 * @param {string[]} excludedStatusValues
	 * If sent, this will be the set of possible status values that should
	 * return as a false conditional if it contains the sent status. This value
	 * will default to the set of archived, declined, issued, and obsolete
	 * status values.
	 * @returns {boolean}
	 * A value indicating whether the sent status value exists in the included
	 * status values and does not exist in the set of excluded status values.
	 * @memberof InsuranceHelper
	 */
	public static policyTermTransactionStatusCheck(
		status: string,
		includedStatusValues: string[] = [],
		excludedStatusValues: string[] = this.transactionExcludedStatusValues):
		boolean
	{
		return (includedStatusValues.length === 0
			|| includedStatusValues.includes(status))
			&& (excludedStatusValues.length === 0
				|| !excludedStatusValues.includes(status));
	}

	/**
	 * Gets the Ledger prior term transfer balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The ledger prior term transfer balance.
	 * @memberof InsuranceHelper
	 */
	public static getPriorTermTransferBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const transferBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.type ===
						InsuranceConstants.ledgerTransactionTypes.payment
						&& ledgerTransaction.data.category ===
							InsuranceConstants.ledgerTransactionCategories
								.transfer)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return transferBalance;
	}

	/**
	 * Gets the policy term written off balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The policy term written off balance.
	 * @memberof InsuranceHelper
	 */
	public static getPolicyTermWrittenOffBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const writtenOffBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.adjustmentType ===
						InsuranceConstants.ledgerTransactionAdjustmentTypes
							.writeOff)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return writtenOffBalance;
	}

	/**
	 * Gets the Ledger payments balance.
	 *
	 * @static
	 * @param {IEntityInstance[]} ledgerTransactions
	 * The LedgerTransaction entity instances.
	 * @return {number}
	 * The ledger payments balance.
	 * @memberof InsuranceHelper
	 */
	public static getPaymentsBalance(
		ledgerTransactions: IEntityInstance[]): number
	{
		const transferBalance: number =
			ledgerTransactions.filter(
				ledgerTransaction =>
					ledgerTransaction.data.type ===
						InsuranceConstants.ledgerTransactionTypes.payment
						&& ledgerTransaction.data.category !==
							InsuranceConstants.ledgerTransactionCategories
								.transfer)
				.reduce(
					(sum, transfer) =>
						sum + transfer.data.amount,
					0);

		return transferBalance;
	}
}