<ng-template
	[ngIf]="loading"
	[ngIfElse]="Content">
	<i class="loading-spinner theme-color fa fa-fw fa-spin fa-spinner">
	</i>
</ng-template>

<ng-template #Content>
	<div class="ui-g-12 text-left no-padding drill-in-entity-display">
		<div class="ui-g-12 nested-display-subtitle text-left">
			<div class="ellipsis-text">
				View: {{entityDisplayName}}
			</div>
			<span class="nested-display-actions">
				<ng-template
					ngFor
					let-action
					[ngForOf]="itemActions">
						<i class="fa fa-fw {{action.icon}} theme-color cursor-pointer"
							[ngClass]="{
								'selected-bottom-border': (action.id === 'View')
							}"
							(click)="action.command($event)"
							pTooltip="{{action.label}}"
							tooltipPosition="left"
							tooltipStyleClass="utility-menu-tooltip">
						</i>
				</ng-template>
			</span>
		</div>

		<p-scrollPanel styleClass="entity-container">
			<div class="ui-g-12 no-padding data-panel">
				<ng-template
					[ngIf]="(selectedItem != null)
						&& (formlyEntityLayout != null)">
					<app-dynamic-formly
						[dataSet]="selectedItem"
						[layoutSchema]="formlyEntityLayout"
						[context]="context">
					</app-dynamic-formly>
				</ng-template>
			</div>
		</p-scrollPanel>
	</div>
</ng-template>