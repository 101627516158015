/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	Activity
} from '@shared/implementations/application-data/activity';
import {
	ActivityService
} from '@shared/services/activity.service';
import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	CommonTableComponent
} from '@shared/components/common-table/common-table.component';
import {
	CommonTablePageDirective
} from '@shared/directives/common-table-page.directive';
import {
	Component
} from '@angular/core';
import {
	EventHelper
} from '@shared/helpers/event.helper';
import {
	FormControl
} from '@angular/forms';
import {
	FormlyConstants
} from '@shared/constants/formly.constants';
import {
	FormlyFieldConfig
} from '@ngx-formly/core';
import {
	ICommonTable
} from '@shared/interfaces/application-objects/common-table.interface';
import {
	ICommonTableColumn
} from '@shared/interfaces/application-objects/common-table-column.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	ISecurityGroup
} from '@shared/interfaces/security/security-group.interface';
import {
	ResolverService
} from '@shared/services/resolver.service';
import {
	SecurityGroupApiService
} from '@api/services/security/security-group.api.service';
import {
	SiteLayoutService
} from '@shared/services/site-layout.service';

/* eslint-enable max-len */
@Component({
	selector: 'app-manage-user-expand',
	templateUrl: './manage-user-expand.component.html',
	styleUrls: [
		'./manage-user-expand.component.scss'
	]
})

/**
 * A component representing an instance of the security applications
 * component.
 *
 * @export
 * @class ManageUserComponent
 * @extends {CommonTablePageDirective}
 * @implements {IDynamicComponent<CommonTableComponent, any>}
 */
export class ManageUserComponent
	extends CommonTablePageDirective
	implements IDynamicComponent<CommonTableComponent, any>
{
	/**
	 * Initializes a new instance of the ManageUserComponent class.
	 *
	 * @param {SecurityApplicationApiService} securityApplicationApiService
	 * The api service used to load security application data.
	 * @param {ActivityService} activityService
	 * The activity service used to handle data interactions and client
	 * messaging.
	 * @param {SiteLayoutService} siteLayoutService
	 * The site layout service used in this component to get its data.
	 * @param {ResolverService} resolver
	 * The resolver service used for dynamic logic and business rules.
	 * @memberof ManageUserComponent
	 */
	public constructor(
		public securityGroupApiService: SecurityGroupApiService,
		public activityService: ActivityService,
		public siteLayoutService: SiteLayoutService,
		public resolver: ResolverService)
	{
		super(resolver);
	}

	/**
	 * Gets or sets the context that will be set when implementing this
	 * as a dynamic component.
	 *
	 * @type {IDynamicComponentContext<CommonTableComponent, any>}
	 * @memberof BaseExpandComponent
	 */
	public context: IDynamicComponentContext<CommonTableComponent, any>;

	/**
	 * Gets or sets the table definitions for the common table view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserComponent
	 */
	public manageUserTableDefinitionsView: ICommonTable;

	/**
	 * Gets or sets the table definitions for the common table update view.
	 *
	 * @type {ICommonTable}
	 * @memberof ManageUserComponent
	 */
	public manageUserTableDefinitionsUpdate: ICommonTable;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public viewSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ManageUserComponent
	 */
	public viewColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateAvailableColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the common table columns.
	 *
	 * @type {ICommonTableColumn[]}
	 * @memberof ManageUserComponent
	 */
	public updateSelectedColumns: ICommonTableColumn[] = [];

	/**
	 * Gets or sets the column selection mode.
	 *
	 * @type {boolean}
	 * @memberof ManageUserComponent
	 */
	public updateColumnSelectionMode?: boolean = false;

	/**
	 * Gets or sets the error message displayed on the formly async validator.
	 *
	 * @type {string}
	 * @memberof ManageUserComponent
	 */
	public asyncValidatorErrorMessage: string;

	/**
	 * Gets or sets the security groups.
	 *
	 * @type {string}
	 * @memberof ManageUserComponent
	 */
	public securityGroups: ISecurityGroup[];

	/**
	 * Sets up variables used in this admin page based table.
	 *
	 * @memberof ManageUserComponent
	 */
	public setupPageVariables(): void
	{
		this.viewAvailableColumns =
			[
				{
					dataKey: 'securityGroup',
					columnHeader: 'Group Name',
					displayOrder: 1
				}
			];
		this.viewSelectedColumns =
			this.viewAvailableColumns;

		this.updateAvailableColumns =
			[
				...this.viewAvailableColumns
			];
		this.updateSelectedColumns =
			this.updateAvailableColumns;
	}

	/**
	 * Sets up the table definitions for a standard table
	 *
	 * @memberof ManageUserComponent
	 */
	public setupTableDefinitions(): void
	{
		this.manageUserTableDefinitionsView = {
			hideExpanderArrow: true,
			hideSettings: true,
			tableTitle: 'Group Membership',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 4
			},
			dataSetup: async() =>
			{
				await this.setSecurityGroups();
			},
			apiPromise: async () =>
			{
				const usersByGroup = [];

				for (const securityGroup of this.securityGroups)
				{
					usersByGroup.push(
						{
							users: await this.securityGroupApiService
								.getSecurityGroupUsers(securityGroup.id),
							group: securityGroup.name
						});
				}

				const groupNames = [];

				usersByGroup.forEach((groupUsers) =>
				{
					groupUsers.users.forEach((userId: number) =>
					{
						if (userId === this.context.data.data.id)
						{
							groupNames.push({securityGroup: groupUsers.group});
						}
					});
				});

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return groupNames;
			},
			availableColumns: this.viewAvailableColumns,
			selectedColumns: this.viewSelectedColumns,
			columnSelectionMode: this.viewColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.manageUserTableDefinitionsView.selectedColumns =
					selectedColumns;
				this.viewSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.manageUserTableDefinitionsView.columnSelectionMode =
					columnSelectionMode;
				this.viewColumnSelectionMode = columnSelectionMode;
			}
		};

		this.manageUserTableDefinitionsUpdate = {
			hideExpanderArrow: true,
			hideSettings: true,
			actions: {
				create: {
					displayCreateRow: false,
					layout: [
						{
							key: 'data.securityGroup',
							type: FormlyConstants.customControls.input,
							wrappers: [
								FormlyConstants.customControls
									.customFieldWrapper
							],
							templateOptions: {
								label: AppConstants.empty,
								disabled: false,
								required: true
							},
							asyncValidators: {
								validSecurityGroup: {
									expression: async(
										control: FormControl,
										field: FormlyFieldConfig) =>
										this.isValidSecurityGroup(
											control,
											field),
									message: AppConstants.empty
								}
							}
						}
					],
					items: [
						{
							label: 'Add',
							styleClass: AppConstants.cssClasses.pButtonPrimary,
							command: () => this.addUserSecurityGroup()
						}]
				},
				delete: {
					deleteStatement: async () =>
						'Confirm to remove user '
							+ this.context.data.data.userName
							+ ' from security group '
							+ `${this.commonTableContext.data.securityGroup}.`,
					items: [
						{
							label: 'Remove',
							styleClass: AppConstants.cssClasses.pButtonDanger,
							command: () => this.removeUserSecurityGroup()
						}],
				}
			},
			tableTitle: 'Group Membership',
			objectSearch: {
				filter: AppConstants.empty,
				orderBy: `Id ${AppConstants.sortDirections.descending}`,
				offset: 0,
				limit: AppConstants.dataLimits.large,
				virtualIndex: 0,
				virtualPageSize: 4
			},
			dataSetup: async() =>
			{
				await this.setSecurityGroups();
			},
			apiPromise: async () =>
			{
				const usersByGroup = [];

				for (const securityGroup of this.securityGroups)
				{
					usersByGroup.push(
						{
							users: await this.securityGroupApiService
								.getSecurityGroupUsers(securityGroup.id),
							group: securityGroup.name,
							id: securityGroup.id
						});
				}

				const groupNames = [];

				usersByGroup.forEach(
					(groupUsers) =>
					{
						groupUsers.users.forEach(
							(userId: number) =>
							{
								if (userId === this.context.data.data.id)
								{
									groupNames.push(
										<any>
										{
											id: groupUsers.id,
											securityGroup: groupUsers.group
										});
								}
							});
					});

				EventHelper.dispatchTableExpansionPanelLoadedEvent();

				return groupNames;
			},
			availableColumns: this.updateAvailableColumns,
			selectedColumns: this.updateSelectedColumns,
			columnSelectionMode: this.updateColumnSelectionMode,
			commonTableContext: (commonTableContext:
				IDynamicComponentContext<CommonTableComponent, any>) =>
			{
				this.commonTableContext = commonTableContext;
			},
			selectedColumnsChanged: (selectedColumns: ICommonTableColumn[]) =>
			{
				this.manageUserTableDefinitionsUpdate.selectedColumns =
					selectedColumns;
				this.updateSelectedColumns = selectedColumns;
			},
			columnSelectionModeChanged: (columnSelectionMode: boolean) =>
			{
				this.manageUserTableDefinitionsUpdate.columnSelectionMode =
					columnSelectionMode;
				this.updateColumnSelectionMode = columnSelectionMode;
			}
		};

		this.loadingTableDefinitions = false;
	}

	/**
	 * Set Security Groups.
	 *
	 * @private
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async setSecurityGroups(): Promise<void>
	{
		const securityGroups: ISecurityGroup[] =
			await this.securityGroupApiService
				.query(
					AppConstants.empty,
					AppConstants.empty);

		this.securityGroups = securityGroups;
	}

	/**
	 * Removes the user from security group.
	 *
	 * @private
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async removeUserSecurityGroup(): Promise<void>
	{
		const rowData: any = this.commonTableContext.source.rowData;
		const securityGroup =
			await this.securityGroupApiService
				.query(
					`Name eq '${rowData.securityGroup}'`,
					AppConstants.empty);

		await this.activityService.handleActivity(
			new Activity(
				this.securityGroupApiService
					.deleteSecurityGroupUser(
						securityGroup[0].id,
						this.context.data.data.id),
				`<strong>Removing User ${this.context.data.data.userName} `
					+ 'from Security Group '
					+ `${rowData.securityGroup}</strong>`,
				`<strong>Removed User ${this.context.data.data.userName} `
					+ 'from Security GroupcommonTableContext.source.rowData '
					+ `${rowData.securityGroup}</strong>`,
				`User ${this.context.data.data.userName} `
					+ 'was removed from Security Group '
					+ `${rowData.securityGroup}.`,
				`User ${this.context.data.data.userName} `
					+ 'was not removed from Security Group '
					+ `${rowData.securityGroup}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Add the user to security group.
	 *
	 * @private
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async addUserSecurityGroup(): Promise<void>
	{
		this.loadingTableDefinitions = false;
		const rowData: any = this.commonTableContext.source.rowData;
		const securityGroup =
			await this.securityGroupApiService
				.query(
					`Name eq '${rowData.securityGroup}'`,
					AppConstants.empty);

		await this.activityService.handleActivity(
			new Activity(
				this.securityGroupApiService
					.createSecurityGroupUser(
						securityGroup[0].id,
						this.context.data.data.id),
				`<strong>Adding User ${this.context.data.data.userName} `
					+ 'to Security Group '
					+ `${rowData.securityGroup}</strong>`,
				`<strong>Added User ${this.context.data.data.userName} `
					+ 'to Security Group '
					+ `${rowData.securityGroup}</strong>`,
				`User ${this.context.data.data.userName} `
					+ 'was added to Security Group '
					+ `${rowData.securityGroup}.`,
				`User ${this.context.data.data.userName} `
					+ 'was not added to Security Group '
					+ `${rowData.securityGroup}.`));

		this.restoreTableDefinition();
	}

	/**
	 * Defines and validates if the value is a valid Security Group.
	 *
	 * @returns {Promise<boolean>}
	 * @private
	 * @async
	 * @memberof OperationDefinitionsComponent
	 */
	private async isValidSecurityGroup(
		control: FormControl,
		field: FormlyFieldConfig): Promise<boolean>
	{
		const initialPromiseArray: Promise<any>[] =
			[
				this.securityGroupApiService.query(
					`Name eq '${control.value}'`,
					AppConstants.empty)
			];

		return Promise.all(
			initialPromiseArray)
			.then(async (
				[
					securityGroup
				]) =>
			{
				field.asyncValidators.validSecurityGroup.message =
					'Invalid security group.';
				let subscribedUser: boolean = false;
				if (!AnyHelper.isNullOrEmpty(securityGroup[0]))
				{
					const securityGroupUsers: number[] =
						await this.securityGroupApiService
							.getSecurityGroupUsers(securityGroup[0]?.id);
					securityGroupUsers.forEach((user: number) =>
					{
						if (user === this.context.data.data.id)
						{
							subscribedUser = true;
							field.asyncValidators
								.validSecurityGroup.message =
									'Group already taken.';
						}
					});
				}

				return Promise.resolve(
					!AnyHelper.isNullOrEmpty(securityGroup[0])
						&& subscribedUser === false);
			});
	}
}