/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	ApiFilterHelper
} from '@shared/helpers/api-filter.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output
} from '@angular/core';
import {
	EntityInstanceApiService
} from '@api/services/entities/entity-instance.api.service';
import {
	EntityInstanceComponent
} from '@entity/components/entity-instance/entity-instance.component';
import {
	EntityType
} from '@shared/implementations/entities/entity-type';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	ICommonListContext
} from '@shared/interfaces/dynamic-interfaces/common-list-context.interface';
import {
	ICommonListFilter
} from '@shared/interfaces/dynamic-interfaces/common-list-filter.interface';
import {
	ICommonListItem
} from '@shared/interfaces/dynamic-interfaces/common-list-item.interface';
import {
	ICommonListSort
} from '@shared/interfaces/dynamic-interfaces/common-list-sort.interface';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IDynamicComponentContext
} from '@shared/interfaces/application-objects/dynamic-component-context.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityInstanceDto
} from '@api/interfaces/entities/entity-instance.dto.interface';
import {
	MenuItem
} from 'primeng/api';
import {
	NoteDirective
} from '@shared/directives/note.directive';
import {
	SessionService
} from '@shared/services/session.service';

/* eslint-enable max-len */

@Component({
	selector: 'app-note-list',
	templateUrl: './note-list.component.html'
})

/**
 * A component representing context level notes list view.
 *
 * @export
 * @class NoteListComponent
 * @extends {NoteDirective}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 */
export class NoteListComponent
	extends NoteDirective
	implements OnInit, IDynamicComponent<Component, any>
{
	/**
	 * Initializes a new instance of the common list component.
	 *
	 * @param {EntityInstanceApiService} entityInstanceApiService
	 * The entity instance API service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type API service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @memberof NoteListComponent
	 */
	public constructor(
		public entityInstanceApiService: EntityInstanceApiService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService)
	{
		super(entityTypeApiService);
	}

	/**
	 * Gets or sets the context of this dynamic component that will be set
	 * during initialization. The source is the content component and
	 * the data will be associated data that we desire to pass explicitly.
	 *
	 * @type {IDynamicComponentContext<Component, any>}
	 * @memberof NoteListComponent
	 */
	@Input() public context: IDynamicComponentContext<Component, any>;

	/**
	 * Gets or sets the change selected item event emitter.
	 *
	 * @type {EventEmitter<IEntityInstance>}
	 * @memberof NoteListComponent
	 */
	@Output() public changeSelectedItem: EventEmitter<IEntityInstance> =
		new EventEmitter();

	/**
	 * Gets or sets a value representing the common list context to be loaded
	 * during view mode.
	 *
	 * @type {IDynamicComponentContext<
	 * 		NoteListComponent,
	 * 		ICommonListContext<IEntityInstanceDto>>}
	 * @memberof NoteListComponent
	 */
	public commonListContext: IDynamicComponentContext<
		NoteListComponent,
		ICommonListContext<IEntityInstanceDto>>;

	/**
	 * Gets or sets a value indicating whether the current display mode is
	 * loading.
	 *
	 * @type {boolean}
	 * @memberof NoteListComponent
	 */
	public loading: boolean = true;

	/**
	 * Gets or sets a collection of enabled filters.
	 *
	 * @type {ICommonListFilter[]}
	 * @memberof NoteListComponent
	 */
	public enabledFilters: ICommonListFilter[];

	/**
	 * Gets or sets the list of common sorters for the list view.
	 *
	 * @type {ICommonListSort[]}
	 * @memberof NoteListComponent
	 */
	public sorters: ICommonListSort[] = [
		<ICommonListSort>{
			name: 'Create Date',
			value: 'id',
			direction: 'DESC',
			iconAsc: 'fa fa-sort-amount-asc',
			iconDesc: 'fa fa-sort-amount-desc'
		}];

	/**
	 * Gets or sets the selected sorter.
	 *
	 * @type {ICommonListSort}
	 * @memberof NoteListComponent
	 */
	public sorter: ICommonListSort;

	/**
	 * Gets or sets a value representing the supported note types.
	 *
	 * @type {EntityType[]}
	 * @memberof NoteListComponent
	 */
	public supportedNoteTypes: EntityType[];

	/**
	 * Handles the on initialization event.
	 * This will gather and display associated entities based on the given
	 * context parameters.
	 *
	 * @async
	 * @returns {Promise<void>}
	 * @memberof NoteListComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		const childNoteTypes: string[]
			= this.getSupportedNoteTypes(
				(<EntityInstanceComponent>this.context.source)
					.entityDefinition
					.supportedChildTypes);

		this.supportedNoteTypes
			= await this.getEntityTypesFromNameList(childNoteTypes);

		this.refreshCommonListContext(
			this.loadNotes(
				this.enabledFilters,
				this.sorter || this.sorters[0]),
			this);
	}

	/**
	 * A method that handles any sorter change and refreshes data.
	 *
	 * @param {*} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListSort} sorter
	 * A value representing the enabled and selected sorter.
	 * @memberof NoteListComponent
	 */
	public handleSortingChange(
		source: any,
		sorter: ICommonListSort): void
	{
		this.sorter = sorter;
		this.refreshCommonListContext(
			this.loadNotes(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method that handles any filter change and refreshes data.
	 *
	 * @param {*} source
	 * A value representing the source component triggering the sort change
	 * event.
	 * @param {ICommonListFilter[]} filters
	 * A collection of enabled and active filters.
	 * @memberof NoteListComponent
	 */
	public handleFilterChange(
		source: any,
		filters: ICommonListFilter[]): void
	{
		this.enabledFilters = filters;
		this.refreshCommonListContext(
			this.loadNotes(
				this.enabledFilters,
				this.sorter),
			source);
	}

	/**
	 * A method to either create or refresh the common list context.
	 *
	 * @note
	 * The source control's which loading parameter, icon display to enable.
	 *
	 * @param {Promise<IEntityInstanceDto[]>} dataPromise
	 * A value representing the data promise to load into the context.
	 * @param {*} source
	 * A value representing the source component we are refreshing the context
	 * from.
	 * @memberof NoteListComponent
	 */
	public refreshCommonListContext(
		dataPromise: Promise<IEntityInstanceDto[]>,
		source: any): void
	{
		const firstLoad: boolean
			= AnyHelper.isNull(this.commonListContext) === true;
		source.loading = true;

		dataPromise
			.then(async (queryData: IEntityInstanceDto[]) =>
			{
				const data: ICommonListItem<IEntityInstanceDto>[]
					= queryData.map((item: IEntityInstanceDto) =>
						this.mapToListItem(item));

				if (firstLoad)
				{
					// Generate whole context.
					this.commonListContext = {
						data: this.generateCommonListContext(data),
						source: this
					};
				}
				else
				{
					// We need to refresh data only.
					this.commonListContext.data.data = data;
				}

				source.loading = false;

				if (AnyHelper.isFunction(
					source.performPostLoadActions))
				{
					source.performPostLoadActions();
				}
			})
			.catch((_error) => {
				source.loading = false;
			});
	}

	/**
	 * A method to generate the proper data promise for loading notes data.
	 *
	 * @param {ICommonListFilter[]} [filters]
	 * A value representing the enabled filters to apply to the data promise
	 * query.
	 * @param {ICommonListSort} [sorter]
	 * A value representing the enabled sorter to apply to the data promise
	 * query.
	 * @returns {Promise<IEntityInstanceDto[]>}
	 * A data promise with applied filters and sorter.
	 * @memberof NoteListComponent
	 */
	public loadNotes(
		filters?: ICommonListFilter[],
		sorter?: ICommonListSort): Promise<IEntityInstanceDto[]>
	{
		if (this.supportedNoteTypes.length === 0)
		{
			return Promise.resolve(<IEntityInstanceDto[]>[]);
		}

		let filter: string =
			ApiFilterHelper.getEntityTypeFilter(
				this.supportedNoteTypes);
		if (AnyHelper.isNull(filters) === false)
		{
			filters.forEach((item: ICommonListFilter) => {
				filter = AnyHelper.isNullOrWhitespace(filter)
					? item.value
					: `(${filter}) AND (${item.value})`;
			});
		}

		let orderBy: string;
		if (AnyHelper.isNull(sorter) === false)
		{
			orderBy = `${sorter.value} ${sorter.direction}`;
		}

		if (this.context.source instanceof EntityInstanceComponent)
		{
			this.entityInstanceApiService
				.entityInstanceTypeGroup
					= this.context.source.entityTypeGroup;

			return this.entityInstanceApiService
				.getChildren(
					this.context.source.id,
					filter,
					orderBy,
					0,
					100,
					null);
		}

		return null;
	}

	/**
	 * A method that generates the base notes common list context.
	 *
	 * @note
	 * This method will generate on firstLoad the default common list context
	 * with properties used for notes.
	 *
	 * @private
	 * @param {ICommonListItem<IEntityInstanceDto>[]} data
	 * A value representing the data to load within the common list context.
	 * @returns {ICommonListContext<IEntityInstanceDto>}
	 * A value representing the common list context for first load.
	 * @memberof NoteListComponent
	 */
	private generateCommonListContext(
		data: ICommonListItem<IEntityInstanceDto>[]):
		ICommonListContext<IEntityInstanceDto>
	{
		const listFilters: ICommonListFilter[] =
			<ICommonListFilter[]>
			[
				{
					name: 'My Notes',
					value: `ChangedById eq ${this.sessionService.user.id}`
				},
				{
					name: 'System',
					value: 'ChangedById eq 1'
				}
			];

		listFilters.push(
			...this.getFiltersForTypes(
				this.supportedNoteTypes));

		const generatedCommonListContext:
			ICommonListContext<IEntityInstanceDto> =
			<ICommonListContext<IEntityInstanceDto>>
			{
				data: data,
				searchable: true,
				sortable: true,
				sorters: this.sorters,
				searchFilterFormat:
					'content.Contains(\'${inputValue}\') eq true',
				actions: [
					<MenuItem>
					{
						icon: 'fa fa-plus-circle',
						command: (event: any) => {
							this.changeDisplayMode.emit(
								AppConstants.displayMode.create);

							event.stopPropagation();
						}
					}
				],
				filters: listFilters,
				onFilterChange: (source, filters) =>
					this.handleFilterChange(source, filters),
				onSortChange: (source, sorter) =>
					this.handleSortingChange(source, sorter)
			};

		return generatedCommonListContext;
	}

	/**
	 * A method that maps entity instance data to a common list item.
	 *
	 * @private
	 * @param {IEntityInstanceDto} entity
	 * A value representing the entity instance to map to common list item.
	 * @returns {ICommonListItem<IEntityInstanceDto>}
	 * A value representing the mapped common list item from the entity
	 * instance.
	 * @memberof NoteListComponent
	 */
	private mapToListItem(
		entity: IEntityInstanceDto): ICommonListItem<IEntityInstanceDto>
	{
		if (entity.changedById !== 1)
		{
			this.entityInstanceApiService
				.entityInstanceTypeGroup = 'Users';
			this.entityInstanceApiService
				.get(entity.changedById)
				.then((user: any) =>
				{
					if (AnyHelper.isNullOrWhitespace(
						user.data.userName) === false)
					{
						entity.data.userName =
							<string>user.data.userName;
					}
				});
		}

		// Entity data as any so we can access/modify and also
		// populate entity data with extra decorators.
		const data: any = entity.data;
		data.userName = entity.changedById === 1
			? 'System'
			: 'User ' + entity.changedById;

		// Define the list row item defaults and then tweak conditionally below
		const listItem: ICommonListItem<IEntityInstanceDto> = {
			item: entity,
			descriptionLineFormat: '${item.data.content}',
			informationLineFormat:
				' by ${item.data.userName}',
			informationLineRelativeDateTime: entity.changeDate,
			informationIcons: [],
			actions: [
				<MenuItem> {
					icon: 'fa fa-info-circle',
					command: (event: any) => {
						this.changeSelectedItem.emit(entity);
						this.changeDisplayMode.emit(
							AppConstants.displayMode.view);
						event.stopPropagation();
					}
				},
				<MenuItem> {
					icon: 'fa fa-trash',
					command: (event: any) => {
						this.changeSelectedItem.emit(entity);
						this.changeDisplayMode.emit(
							AppConstants.displayMode.delete);
						event.stopPropagation();
					}
				}
			]
		};

		return listItem;
	}

	/**
	 * Gets a collection of ICommonListFilter constructed for the
	 * entityTypes inputted.
	 *
	 * @private
	 * @param {EntityType[]} entityTypes
	 * A collection of EntityType to generate ICommonListFilter for.
	 * @returns {ICommonListFilter[]}
	 * A collection of ICommonListFilter representing the entityTypes.
	 * @memberof NoteListComponent
	 */
	private getFiltersForTypes(
		entityTypes: EntityType[]): ICommonListFilter[]
	{
		if ((entityTypes?.length || 0) <= 1)
		{
			return [];
		}

		return entityTypes.map((entityType: EntityType) =>
			<ICommonListFilter>{
				name: entityType.displayName,
				value: ApiFilterHelper.getEntityTypeFilter(entityType)
			});
	}
}