/**
 * @copyright WaterStreet. All rights reserved.
 */

/* eslint-disable @typescript-eslint/no-explicit-any */

import {
	AnyHelper
} from '@shared/helpers/any.helper';
import {
	AppConstants
} from '@shared/constants/app.constants';
import {
	Component,
	OnInit
} from '@angular/core';
import {
	DrawerListDirective
} from '@shared/directives/drawer-list-directive';
import {
	EntityService
} from '@entity/services/entity.service';
import {
	EntityTypeApiService
} from '@api/services/entities/entity-type.api.service';
import {
	IDynamicComponent
} from '@shared/interfaces/application-objects/dynamic-component.interface';
import {
	IEntityInstance
} from '@shared/interfaces/entities/entity-instance.interface';
import {
	IEntityType
} from '@shared/interfaces/entities/entity-type.interface';
import {
	IOwnershipGuardComponent
} from '@shared/interfaces/application-objects/ownership-guard-component';
import {
	Location
} from '@angular/common';
import {
	SessionService
} from '@shared/services/session.service';

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: [
		'./notes.component.scss'
	]
})

/**
 * A component representing context level notes.
 *
 * @export
 * @class NotesComponent
 * @extends {BaseDrawerListDirective<IEntityInstance>}
 * @implements {OnInit}
 * @implements {IDynamicComponent<Component, any>}
 * @implements {IOwnershipGuardComponent}
 */
export class NotesComponent
	extends DrawerListDirective<IEntityInstance>
	implements OnInit, IDynamicComponent<Component, any>,
		IOwnershipGuardComponent
{
	/**
	 * Initializes a new instance of the rules component.
	 *
	 * @param {EntityService} entityService
	 * The entity service.
	 * @param {EntityTypeApiService} entityTypeApiService
	 * The entity type api service.
	 * @param {SessionService} sessionService
	 * The session service.
	 * @param {Location} location
	 * The location object.
	 * @memberof NotesComponent
	 */
	public constructor(
		public entityService: EntityService,
		public entityTypeApiService: EntityTypeApiService,
		public sessionService: SessionService,
		public location: Location)
	{
		super();
	}

	/**
	 * Handles the on initialization event.
	 * This method will set configurable properties used in the drawer list
	 * directive and this component's view.
	 *
	 * @async
	 * @memberof NotesComponent
	 */
	public async ngOnInit(): Promise<void>
	{
		if (!await this.isPageOwnershipAllowed())
		{
			this.accessDeniedUrl = this.location.path();
			this.sessionIdentifier = this.sessionService.sessionId;
			this.resources =
				[
					'Note.*'
				];
			this.clientMessage =
				'Access is required to at least one note '
					+ 'entity type and version.';
			this.isOwnershipAllowed = false;
		}
	}

	/**
	 * Implements the ownership guard interface.
	 * This will calculate drawer ownership permissions.
	 *
	 * @async
	 * @returns {Promise<boolean>}
	 * A value signifying whether or not access is allowed to this drawer.
	 * @memberof NotesComponent
	 */
	public async isPageOwnershipAllowed(): Promise<boolean>
	{
		const entityType: IEntityType =
			await this.entityTypeApiService.getSingleQueryResult(
				AppConstants.commonProperties.name
					+ ' eq \'Note\'',
				AppConstants.empty,
				true);

		if (AnyHelper.isNull(entityType))
		{
			return false;
		}

		return this.entityService.verifyEntityTypeAccess(
			entityType);
	}
}